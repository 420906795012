<template>
    <v-form
        ref="form"
        lazy-validation
        class="form v-card v-sheet theme--light ma-4"
    >
        <v-row class="my-0">
            <v-col
                cols="12"
                class="d-flex align-center mt-0 pb-0 title"
            >
                <v-icon>mdi-format-list-bulleted</v-icon>
                <p class="form__title ml-2 mt-4">New Assignment</p>
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <v-text-field
                    outlined
                    label="Assignment Title"
                    v-model="assignmentTitle"
                    :rules="[rules.required]"
                    />
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col
                cols="4"
                class="my-0 py-0"
            >
                <v-autocomplete
                    outlined
                    label="Project"
                    :items="relatedProjectsList"
                    v-model="relatedProject"
                    item-text="name"
                    return-object
                    :rules="[rules.required]"
                ></v-autocomplete>
            </v-col>
            <v-col
                cols="4"
                class="my-0 py-0"
            >
                <v-autocomplete
                    outlined
                    label="Type of work"
                    item-text="value"
                    return-object
                    :items="typeOfWorkList"
                    v-model="typeOfWork"
                    :disabled="!relatedProject"
                    :rules="[rules.required]"
                ></v-autocomplete>
            </v-col>
            <v-col
                cols="4"
                class="my-0 py-0"
            >
                <v-autocomplete
                    outlined
                    label="PO Number"
                    :items="relatedPurchaseOrdersList"
                    v-model="purchaseOrder"
                    item-text="purchaseOrderNumber"
                    :disabled="!relatedProject"
                    return-object
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <v-menu
                    v-model="deliveryDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="formattedDeliveryDate"
                        label="Delivery Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rules.required]"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="deliveryDate"
                        :min="minDate"
                        @input="deliveryDateMenu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="my-0" v-if="isWithin48Hours" >
            <v-col
                cols="12"
                class="my-0 py-0"
            >
            <v-alert
                dense
                text
                icon="mdi-alert-circle-outline"
                type="info"
                class="form__alert mb-0"
                >
                    This assignment will be classified as urgent.
                </v-alert>
            </v-col>
        </v-row>
        <v-row class="my-4">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <span class="form__label--info">Provide related info  </span>
                <v-chip
                    class="ml-2 mb-1 chip--info"
                    :color="showFiles ? 'blue darken-2' : 'grey darken-2'"
                    outlined
                    dense
                    @click.prevent="showFilesToggler"
                >
                    Files
                </v-chip>
                <v-chip
                    class="ml-2 mb-1 chip--info"
                    :color="showLink ? 'blue darken-2' : 'grey darken-2'"
                    outlined
                    dense
                    @click.prevent="showLinkToggler"
                >
                    Link
                </v-chip>
                <v-chip
                    class="ml-2 mb-1 chip--info"
                    :color="showText ? 'blue darken-2' : 'grey darken-2'"
                    outlined
                    dense
                    @click.prevent="showTextToggler"
                >
                    Text
                </v-chip>
            </v-col>
        </v-row>
        <v-row class="my-0" v-if="showFiles || showLink">
            <v-col
                cols="6"
                class="my-0 py-0"
                v-if="showFiles"
            >
                <div
                    @dragover.prevent="handleDragOver"
                    @dragleave.prevent="handleDragLeave"
                    @drop.prevent="handleDrop"
                    :class="{ 'dropzone-active': isDragOver }"
                    class="file-input-wrapper"
                >
                    <v-file-input
                        ref="fileInput"
                        class="file-input"
                        chips
                        label="Related Files"
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        outlined
                        multiple
                        v-model="relatedFiles"
                        @change="handleFileChange"
                    ></v-file-input>
                </div>
            </v-col>
            <v-col
                cols="6"
                class="my-0 py-0"
                v-if="showLink"
            >
                <v-text-field
                    outlined
                    label="Related Link"
                    v-model="relatedLink"
                    />
            </v-col>
        </v-row>
        <v-row class="my-0" v-if="showText">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <v-textarea
                    outlined
                    label="Related Text"
                    auto-grow
                    rows="1"
                    v-model="relatedText"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col
                cols="6"
                class="my-0 py-0"
            >
                <v-text-field
                    outlined
                    type="number"
                    label="Estimated Word Count"
                    v-model="wordCount"
                    />
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col
                cols="12"
                class="d-flex align-center mt-0 pb-0 form__subtitle"
            >
                <span class="form__label--info mt-2">Select languages</span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            class="ml-2 mt-3 info__icon"
                            v-bind="attrs"
                            v-on="on">
                            mdi-information
                        </v-icon>
                    </template>
                    <span>Separate assignment will be created for each language</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="mt-4 mb-0">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <v-autocomplete
                    outlined
                    item-text="value"
                    return-object
                    chips
                    small-chips
                    label="Source Language"
                    :items="sourceLanguagesList"
                    v-model="sourceLanguages"
                    :rules="[rules.required]"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="mt-0 mb-0">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <v-autocomplete
                    outlined
                    chips
                    multiple
                    deletable-chips
                    small-chips
                    item-text="value"
                    return-object
                    :disabled="!relatedProject"
                    label="Target Languages"
                    :items="sortedTargetLanguagesList"
                    v-model="targetLanguages"
                    :rules="[rules.required]"
                >
                <template v-slot:selection="{ item, index }">
                    <v-chip
                        close
                        v-bind:key="index"
                        small
                        @click:close="remove(item)"
                    >
                        {{ item.value }}
                    </v-chip>
                    </template>

                    <template v-slot:item="{ item, index }">
                        <v-list-item @click.stop="toggleItem(item)">
                            <v-list-item-action>
                            <v-checkbox
                                :input-value="isChecked(item)"
                                @change.stop="toggleItem(item)"
                            ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                            <v-list-item-title>
                                {{ item.value }}
                            </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-slot:group="{ group }">
                    <v-subheader>
                        {{ group }}
                    </v-subheader>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
                <v-textarea
                    outlined
                    label="Note to manager"
                    auto-grow
                    rows="1"
                    v-model="noteToManager"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getSpecificEnum } from '@/utils/newDbUtils';
export default {
    name: "ReimbursementForm",
    props: {
        nativeForm: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            deliveryDateMenu: false,
            isAmountInputFocused: false,
            minDate: new Date().toISOString().substr(0, 10),
            showFiles: false,
            showLink: false,
            showText: false,
            requestTypes: [],
            geosList: [],
            isDragOver: false,
            rules: {
                required: (value) => !!value || value === 0 || "Required",
            },
        }
    },
    watch: {
        relatedProject: {
            async handler() {
                const response = await getSpecificEnum('request-types');
                this.requestTypes = response.data;
            },
            immediate: true,
        },
        relatedText: {
            handler() {
                if (this.relatedText) {
                    this.showText = true;
                }
            },
            immediate: true,
        },
        relatedFiles: {
            handler() {
                if (this.relatedFiles.length) {
                    this.showFiles = true;
                }
            },
            immediate: true,
        },
        relatedLink: {
            handler() {
                if (this.relatedLink) {
                    this.showLink = true;
                }
            },
            immediate: true,
        }
    },
    computed: {
        ...mapGetters('assignment', ['projectsList']),
        ...mapGetters("nativeForms", ["gGetDefaultNativeForms", "gGetClientFormData"]),
        ...mapGetters("auth", ["user"]),
        isWithin48Hours() {
            if (!this.deliveryDate) return false
            const currentDate = new Date();
            const selectedDate = new Date(this.deliveryDate);
            const twoDaysFromNow = new Date(currentDate.setDate(currentDate.getDate() + 2));
            return selectedDate < twoDaysFromNow;
        },
        formattedDeliveryDate() {
            return this.deliveryDate ? `${this.$moment(this.deliveryDate).format("MM-DD-YYYY")}` : "";
        },
        relatedProjectsList() {
            const projectsList = this.projectsList.filter(project => project.client?.id === this.gGetClientFormData?.id);
            if (projectsList && projectsList.length) return projectsList
            else return []    
        },
        typeOfWorkList() {
            if (this.requestTypes.length && this.relatedProject) {
                const typeOfWorks = this.requestTypes.filter(type => this.relatedProject.requestTypes.includes(type.id));
                return typeOfWorks
            } else return []
        },
        relatedPurchaseOrdersList() {
            if (this.relatedProject && this.relatedProject.purchaseOrders.length) {
                return this.relatedProject.purchaseOrders.filter(po => po.isActive);
            } else return []
        },
        sourceLanguagesList() {
            const sourceLanguages = this.geosList;
            return sourceLanguages
        },
        targetLanguagesList() {
            const projectsRelatedGeos = [{group: "Project Languages"}];
            const allOtherGeos = [{group: "All Languages"}];
            this.geosList.forEach(geo => {
                if (this.relatedProject?.geos.includes(geo.id)) {
                    projectsRelatedGeos.push({...geo, group: "Project Languages"})
                } else {
                    allOtherGeos.push({...geo, group: "All Languages"})
                }
            })
            let allGeosList = [...projectsRelatedGeos, ...allOtherGeos];
            return allGeosList;
        },
        sortedTargetLanguagesList() {
            const sorted = [...this.targetLanguagesList].sort((a, b) => {
                if (a.id && b.id && a.group === b.group) {
                    return a.value.localeCompare(b.value);
                } else if (a.id && b.id &&a.group === 'Project Languages') {
                    return -1;
                } else {
                    return 1;
                }
            });

            return sorted.map((item, index, array) => {
                const prevItem = array[index - 1];
                const isFirstInGroup = !prevItem || prevItem.group !== item.group;
                return {
                    ...item,
                    header: isFirstInGroup ? item.group : null
                };
            });
        },
        assignmentTitle: {
            get() {
                return this.gGetDefaultNativeForms[this.index].assignmentTitle
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "assignmentTitle",
                    value: val
                })
            }
        },
        relatedProject: {
            get() {
                return this.gGetDefaultNativeForms[this.index].relatedProject
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedProject",
                    value: val
                })
            }
        },
        purchaseOrder: {
            get() {
                return this.gGetDefaultNativeForms[this.index].purchaseOrder
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "purchaseOrder",
                    value: val
                })
            }
        },
        typeOfWork: {
            get() {
                return this.gGetDefaultNativeForms[this.index].typeOfWork
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "typeOfWork",
                    value: val
                })
            }
        },
        deliveryDate: {
            get() {
                return this.gGetDefaultNativeForms[this.index].deliveryDate
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "deliveryDate",
                    value: val
                })
            }
        },
        relatedFiles: {
            get() {
                return this.gGetDefaultNativeForms[this.index].relatedFiles
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedFiles",
                    value: val
                })
            }
        },
        relatedLink: {
            get() {
                return this.gGetDefaultNativeForms[this.index].relatedLink
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedLink",
                    value: val
                })
            }
        },
        relatedText: {
            get() {
                return this.gGetDefaultNativeForms[this.index].relatedText
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedText",
                    value: val
                })
            }
        },
        wordCount: {
            get() {
                return this.gGetDefaultNativeForms[this.index].wordCount
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "wordCount",
                    value: val
                })
            }
        },
        sourceLanguages: {
            get() {
                if (!this.gGetDefaultNativeForms[this.index].sourceLanguages) {
                    let val = this.sourceLanguagesList.find(el => el.value === "English (United States)");
                    this.aSetFieldValue({
                        index: this.index,
                        field: "sourceLanguages",
                        value: val
                    })
                }
                return this.gGetDefaultNativeForms[this.index].sourceLanguages
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "sourceLanguages",
                    value: val
                })
            }
        },
        targetLanguages: {
            get() {
                return this.gGetDefaultNativeForms[this.index].targetLanguages
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "targetLanguages",
                    value: val
                })
            }
        },
        noteToManager: {
            get() {
                return this.gGetDefaultNativeForms[this.index].noteToManager
            },
            set(val) {
                this.aSetFieldValue({
                    index: this.index,
                    field: "noteToManager",
                    value: val
                })
            }
        }
    },
    methods: {
        ...mapActions("nativeForms", ["aSetFieldValue"]),
        validateForm() {
            return this.$refs.form.validate();
        },
        remove(item) {
            this.targetLanguages = this.targetLanguages.filter(i => i.key !== item.key);
        },
        isChecked(item) {
            return this.targetLanguages.some(lang => lang.key === item.key);
        },
        toggleItem(item) {
            const index = this.targetLanguages.findIndex(lang => lang.key === item.key);
            if (index > -1) {
                this.targetLanguages.splice(index, 1);
            } else {
                this.targetLanguages.push(item);
            }
        },
        showFilesToggler() {
            if (this.showFiles) {
                this.showFiles = false;
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedFiles",
                    value: []
                })
            } else {
                this.showFiles = true;
            }
        },
        showLinkToggler() {
            if (this.showLink) {
                this.showLink = false;
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedLink",
                    value: ""
                })
            } else {
                this.showLink = true;
            }
        },
        showTextToggler() {
            if (this.showText) {
                this.showText = false;
                this.aSetFieldValue({
                    index: this.index,
                    field: "relatedText",
                    value: ""
                })
            } else {
                this.showText = true;
            }
        },
        handleDragOver() {
          this.isDragOver = true;
        },
        handleDragLeave() {
            this.isDragOver = false;
        },
        handleDrop(event) {
            this.isDragOver = false;
            const files = event.dataTransfer.files;
            this.addFiles(files);
        },
        handleFileChange(files) {
            this.addFiles(files);
        },
        addFiles(files) {
            files = Array.from(files).filter(file => !this.relatedFiles.some(existingFile => existingFile.name === file.name));
            this.relatedFiles = [...this.relatedFiles, ...files];
        }
    },
    async mounted() {
        const response = await getSpecificEnum("geos");
        this.geosList = response.data;
    }
}
</script>

<style lang="scss" scoped>
.form {
    .title {
        margin-top: -15px !important;
    }
    &__subtitle {
        margin-top: -35px !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        & .info__icon {
            font-size: 20px;
        }
    }
    padding: 10px;
    .v-text-field__details {
        display: none;
    }
    &__title {
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 18px;
    }
}
.form__label--info {
    font-size: 16px;
    font-weight: 400;
}
.chip--info {
    &:hover {
        cursor: pointer !important;
    }
}
</style>