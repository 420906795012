<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-select
      v-if="!isUpdating"
      class="caption status-selector"
      :class="{ 'type-selector': typeMode }"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="dropdownOptions"
      dense
      hide-details
      outlined
      item-text="value"
      :loading="dropdownOptionsLoading"
      :value="currentSelect"
      @change="(selected) => onChange({ item, selected })"
    />
    <v-alert v-if="isError" type="error" class="alert" dismissible>
      Error updating issue {{ typeMode ? "type" : "status" }}. Please try again.
    </v-alert>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { updateBugReport } from "@/utils/quickbaseUtils";

export default {
  name: "IssueTrackerDropdownSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    typeMode: {
      type: Boolean,
      required: false,
    },
    dropdownOptions: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
      isError: false,
      currentSelect: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    statusColor() {
      return this.dropdownOptions.find((el) => el.value === this.currentSelect)
        ?.color;
    },
  },
  watch: {
    value(newValue) {
      this.currentSelect = newValue;
    },
  },
  mounted() {
    this.currentSelect = this.value;
  },
  methods: {
    onChange({ item, selected }) {
      const bugReport = {
        ...item,
        LastUpdated: new Date(),
        LastUpdatedBy: this.user.Name,
      };
      this.currentSelect = selected;
      this.isUpdating = true;
      updateBugReport({
        issue: bugReport,
        [this.typeMode ? "type" : "status"]: selected,
      })
        .then(() => {
          this.$emit(
            "refresh-table-filters",
            item.index,
            `Issue${this.typeMode ? "Type" : "Status"}`,
            selected
          );
          this.isUpdating = false;
        })
        .catch((err) => {
          this.isUpdating = false;
          this.isError = true;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.status-selector {
  width: 200px;
}

.type-selector {
  width: 280px;
}
</style>
