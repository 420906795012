<template>
  <v-card>
    <EditorEditProjectItem />
    <v-card-actions>
      <v-btn
        block
        color="secondary"
        :loading="projectProcessing"
        @click="handleSaveButton"
        >save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { dub } from "@/providers/dubEditor";
import { ROUTES } from "@/utils/constants";
// components
import EditorEditProjectItem from "./EditorEditProjectItem";

export default {
  name: "EditorProjectModal",
  components: { EditorEditProjectItem },
  data() {
    return {
      projectProcessing: false,
    };
  },
  computed: {
    ...mapGetters("editor", ["editProjectData", "selectedProject"]),
    ...mapGetters("auth", ["user", "isAdmin"]),
  },
  methods: {
    ...mapActions("editor", [
      "setEditProjectData",
      "setProjectModalOpen",
      "setAllProjects",
      "setSelectedProject",
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    handleSaveButton() {
      this.projectProcessing = true;
      if (this.editProjectData.projectID) {
        // this is an existing project
        this.selectedProject
          .updateWithData(this.editProjectData)
          .then(() => {
            return dub.listProjects({
              userEmail: this.user.user.email,
              isAdmin: this.isAdmin,
            });
          })
          .then((allProjects) => {
            const updatedExistingProject = allProjects.find(
              (el) => el.projectID === this.selectedProject.projectID
            );
            this.setSelectedProject(updatedExistingProject);
            this.$router.push({
              path: `/${ROUTES.editor}`,
              query: { pid: updatedExistingProject.projectID },
            });
            this.setAllProjects(allProjects);
            this.setProjectModalOpen(false);
            this.projectProcessing = false;
            this.handleFlash({ response: { status: 200 }, show: true });
          })
          .catch((err) => {
            console.error(err);
            this.projectProcessing = false;
            this.handleFlash({ response: err, show: true });
          });
      } else {
        // this is a new project
        dub
          .createNewProject(this.editProjectData)
          .then(() => {
            return dub.listProjects({
              userEmail: this.user.user.email,
              isAdmin: this.isAdmin,
            });
          })
          .then((allProjects) => {
            this.setAllProjects(allProjects);
            this.setProjectModalOpen(false);
            this.projectProcessing = false;
            this.handleFlash({ response: { status: 200 }, show: true });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
