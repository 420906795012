<template>
  <v-snackbar
    v-model="flashMsgDisplaying"
    min-width="unset"
    :timeout="success ? 2000 : 5000"
    :color="success ? 'success' : 'error'"
    rounded="pill"
    content-class="text-center"
  >
    <v-icon color="white" class="mr-1">{{
      success ? "mdi-check-circle-outline" : "mdi-close-circle-outline"
    }}</v-icon>
    {{ flashMsgTxt ? flashMsgTxt : success ? "Success!" : flashMsgTxt }}
  </v-snackbar>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FlashMessage",
  computed: {
    ...mapGetters("flashMessage", ["status", "showFlashMsg", "flashMsgTxt"]),
    flashMsgDisplaying: {
      get: function () {
        return this.showFlashMsg;
      },
      set: function () {
        return this.handleFlash({
          response: {
            data: { message: this.flashMsgTxt, statusCode: this.status },
          },
          show: false,
        });
      },
    },
    success() {
      return this.status <= 204;
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
  },
};
</script>

<style lang="scss" scoped></style>
