<template>
  <div class="flex-fill">
    <div class="top-container">
      <!-- buttons -->
      <div
        :style="{ width: 'fit-content' }"
        class="d-flex align-center"
        v-if="isAdmin"
      >
        <v-btn color="secondary" @click="handleNewAssignment"
          >new assignment</v-btn
        >
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="elevation-1 mx-2"
              outlined
              :disabled="!isAnySelected"
              color="accent"
              v-bind="attrs"
              v-on="on"
            >
              Export Selected
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="() => handleMultiRowExport(selected, 'TEXT')">
              <v-list-item-title>Plain Text</v-list-item-title>
            </v-list-item>
            <v-list-item @click="() => handleMultiRowExport(selected, 'EXCEL')">
              <v-list-item-title>Excel</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="user.user.email === 'bohdanshumakov@gmail.com'"
          color="primary"
          :loading="idsUpdating || assignmentsLoading"
          @click="updateIds"
          >Update IDs</v-btn
        >
      </div>

      <!-- project selector -->
      <EditorAssignmentsTableControls />

      <!-- table search -->
      <div class="d-flex justify-end align-center">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <v-text-field
          solo
          dense
          hide-details
          append-icon="mdi-magnify"
          clearable
          v-model="filterSearch"
        />
      </div>
    </div>

    <v-skeleton-loader
      v-if="assignmentsLoading"
      type="table"
      height="100vh"
    ></v-skeleton-loader>
    <v-card
      v-if="filteredAssignments && !assignmentsLoading"
      class="editor-assignments-table"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="filteredAssignments"
        :show-select="isAdmin"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        group-by="sortDate"
        group-desc
        v-model="selected"
        @click:row="handleRowClick"
      >
        <!-- Assignment Details cell override -->
        <template v-slot:[`item.assignmentDetails`]="{ item }">
          <div :style="{ 'max-width': '200px' }">
            {{ formatDetails(item.assignmentDetails) }}
          </div>
        </template>
        <!-- Delivery Date cell override -->
        <template v-slot:[`item.deliveryDate`]="{ item }">
          {{ formatPickerDate(item.deliveryDate) }}
        </template>
        <!-- Delivered cell override -->
        <template v-slot:[`item.isDelivered`]="{ item }">
          <div>
            <v-icon v-if="item.isDelivered">mdi-check</v-icon>
          </div>
        </template>
        <!-- flow -->
        <template v-slot:[`item.flow`]="{ item }">
          <EditorTableFlowChip
            v-for="(flowStep, i) in item.flow"
            :key="flowStep.index"
            :flowStep="flowStep"
            :assignment="item"
            :index="i"
          />
        </template>
        <!-- actions -->
        <template v-slot:[`item.actions`]="{ item }" v-if="isAdmin">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleRowEdit(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
                <span class="ml-1">Edit</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleRowDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
                <span class="ml-1">Delete</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleRowExport(item)"
              >
                <v-icon>mdi-file-export</v-icon>
                <span class="ml-1">Export</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleMarkAsDelivered(item)"
              >
                <v-icon>mdi-check</v-icon>
                <span class="ml-1">Delivered</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleGoToDashboard(item)"
              >
                <v-icon>mdi-clipboard-list-outline</v-icon>
                <span class="ml-1">View on DB</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <!-- grouping override -->
        <template v-slot:[`group.header`]="{ group, headers }">
          <td class="pa-0 group-header-row" :colspan="headers.length">
            <h2 class="mx-4 font-weight-bold">
              {{ dueText }}: {{ formatTime(group) }}
            </h2>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import {
  getAllProjects,
  getAssignments,
  getAllStaff,
} from "@/utils/newDbUtils";
import {
  deleteAssignmentFromEditor,
  createSingleAssignmentText,
  createMultiAssignmentText,
  createExcel,
  saveHtmlAsPlaintext,
  handleAssignmentChangeDeliveryStatus,
  getSortField,
} from "@/utils/editorUtils";
import { timeUnits } from "@/utils/mixins";
import { DOMAIN, ROUTES } from "@/utils/constants";
// components
import EditorTableFlowChip from "./EditorTableFlowChip";
import EditorAssignmentsTableControls from "./EditorAssignmentsTableControls";

export default {
  name: "EditorAssignmentsTable",
  components: { EditorTableFlowChip, EditorAssignmentsTableControls },
  data() {
    return {
      selected: [],
      assignmentsLoading: false,
      assignmentsDatas: null,
      filterSearch: "",
      idsUpdating: false,
    };
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("editor", [
      "selectedProject",
      "tableAssignments",
      "tableAssignmentsNeedsRefresh",
      "editAssignmentModalOpen",
      "dbProjects",
    ]),
    ...mapGetters("auth", ["isAdmin", "user"]),
    isAnySelected() {
      return this.selected.length > 0;
    },
    tableHeaders() {
      if (this.isAdmin) {
        return [
          { text: "Assignment Name", value: "assignmentName" },
          { text: "Assignment Detail", value: "assignmentDetails" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Delivered", value: "isDelivered" },
          { text: "Flow", value: "flow" },
          { text: "", value: "actions" },
        ];
      } else {
        return [
          { text: "Assignment Name", value: "assignmentName" },
          { text: "Assignment Detail", value: "assignmentDetails" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Flow", value: "flow" },
        ];
      }
    },
    dueText() {
      return this.isAdmin ? "Delivery Date" : "Due";
    },
    filteredAssignments() {
      if (!this.tableAssignments) return [];

      if (!this.filterSearch) return this.tableAssignments;

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableHeaders.map((el) => el.value),
      };

      const fuse = new Fuse(this.tableAssignments, searchOptions);
      const results = fuse.search(this.filterSearch).map((el) => el.item);

      return results;
    },
  },
  watch: {
    tableAssignmentsNeedsRefresh(newValue) {
      if (newValue) {
        this.loadAssignmentsData(this.selectedProject);
        this.setTableAssignmentsNeedsRefresh(false);
      }
    },
    selectedProject(newValue) {
      this.loadAssignmentsData(newValue);
    },
  },
  mounted() {
    this.loadAssignmentsData(this.selectedProject);
  },
  methods: {
    ...mapActions("editor", [
      "setOpenAssignment",
      "setNewAssignmentModalOpen",
      "setEditAssignmentModalOpen",
      "setEditAssignmentID",
      "setTableAssignments",
      "setProjectPOs",
      "setProjectRequesters",
      "setTableAssignmentsNeedsRefresh",
      "setDbProjects",
    ]),
    formatTime(t) {
      return this.$moment(t).format("dddd, MMM D");
    },
    formatDetails(d) {
      return _.truncate(d, {
        length: 55,
        separator: " ",
      });
    },
    doDeleteAssignment(assignment) {
      this.assignmentsLoading = true;
      deleteAssignmentFromEditor({
        id: assignment.qbID,
        firebaseID: assignment.id,
        project: this.selectedProject,
      })
        .then(() => {
          return this.selectedProject.getAssignmentDatas({
            isAdmin: this.isAdmin,
            userEmail: this.user.user.email,
          });
        })
        .then((d) => {
          d.forEach((el) => {
            el.sortDate = getSortField({
              row: el,
              isAdmin: this.isAdmin,
              userEmail: this.user.user.email,
            });
          });
          this.setTableAssignments(d);
          this.assignmentsLoading = false;
        })
        .catch((err) => {
          this.assignmentsLoading = false;
          console.error(err);
        });
    },
    handleRowClick(row) {
      this.setOpenAssignment(row.id);

      this.$router.push({
        path: "editor",
        query: {
          pid: this.selectedProject.projectID,
          aid: row.id,
        },
      });
    },
    handleNewAssignment() {
      this.setNewAssignmentModalOpen(true);
    },
    handleRowDelete(id) {
      // TODO - make a confirmation dialog
      this.doDeleteAssignment(id);
    },
    handleRowEdit(id) {
      // TODO - make an edit dialog
      this.setEditAssignmentID(id);
      this.setEditAssignmentModalOpen(true);
    },
    handleRowExport(assignment) {
      createSingleAssignmentText(assignment).then((text) => {
        saveHtmlAsPlaintext({
          string: text,
          filename: assignment.exportHeader,
        });
      });
    },
    handleMultiRowExport(assignments, format) {
      if (format === "EXCEL") {
        createExcel(assignments);
      } else if (format === "TEXT") {
        createMultiAssignmentText(assignments).then((text) => {
          saveHtmlAsPlaintext({
            string: text,
            filename: "multi",
          });
        });
      }
    },
    handleMarkAsDelivered(assignment) {
      handleAssignmentChangeDeliveryStatus({
        assignment,
        deliveryStatus: !assignment.isDelivered,
      })
        .then(() => {
          this.setTableAssignmentsNeedsRefresh(true);
        })
        .catch((err) => console.error(err));
    },
    handleGoToDashboard(assignment) {
      window.open(
        `${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${this.$moment(
          assignment.payPeriod
        ).format("MMMM")}&ppy=${this.$moment(assignment.payPeriod).format(
          "YYYY"
        )}&Search=${assignment.assignmentDetails.split("\n")[0]}&DeliveryDate=${
          assignment.deliveryDate
        }&Person=${
          assignment.flow.find((el) => el.label === "Writer").person.name
        }&Role=Primary`,
        "_blank"
      );
    },
    loadAssignmentsData(project) {
      this.assignmentsLoading = true;
      project
        ?.getAssignmentDatas({
          userEmail: this.user.user.email,
          isAdmin: this.isAdmin,
        })
        .then((d) => {
          d.forEach((el) => {
            el.sortDate = getSortField({
              row: el,
              isAdmin: this.isAdmin,
              userEmail: this.user.user.email,
            });
          });
          this.setTableAssignments(d);
          this.assignmentsLoading = false;
          return getAllProjects();
        })
        .then((data) => {
          this.setDbProjects(data?.status === 500 ? [] : data);
          const currentProject = this.dbProjects.find(
            (el) => el.name === project.projectName
          );
          1;
          const active = currentProject?.purchaseOrders
            .filter((el) => el.isActive)
            .map((el) => ({
              text: el.purchaseOrderNumber,
              value: el.id,
            }));
          this.setProjectPOs(active);
          this.setProjectRequesters(
            currentProject?.clientRequesters
              .filter((el) => el.active)
              .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
          );
        })
        .catch((err) => console.error(err));
    },
    updateIds() {
      // for September 2023 data (filter Pay Period below to apply for other period if needed)
      this.idsUpdating = true;
      let updatesData = [],
        idsPromises = [];
      getAllStaff().then((data) => {
        const staffList = data;
        this.tableAssignments
          .filter((el) => el.payPeriod === "2023-09")
          .forEach((el) => {
            idsPromises.push(
              new Promise((resolve, reject) => {
                const primary = el.flow.find((step) => step.label === "Writer")
                    .person.name,
                  staffId = staffList.find(
                    (staff) =>
                      primary.includes(staff.user.firstName) &&
                      primary.includes(staff.user.lastName)
                  ).id;
                getAssignments({
                  query: {
                    page: 0,
                    size: 50,
                    ppm: this.$moment(el.payPeriod)
                      .format("MMMM")
                      .toUpperCase(),
                    ppy: this.$moment(el.payPeriod).format("YYYY"),
                    search: el.assignmentDetails.split("\n")[0],
                    deliveryDate: el.deliveryDate,
                    personId: staffId,
                    personRole: "primary",
                  },
                }).then((resp) => {
                  // remove undefined keys
                  const cleanAssgn = { ...el };
                  Object.keys(cleanAssgn).forEach(
                    (key) =>
                      cleanAssgn[key] === undefined && delete cleanAssgn[key]
                  );
                  updatesData.push({
                    id: el.id,
                    assignment: {
                      ...cleanAssgn,
                      qbID: resp.content[0]?.id ?? "",
                    },
                    originalData: cleanAssgn,
                  });
                  resolve("Update data generated!");
                });
              })
            );
          });
        Promise.all(idsPromises)
          .then(() => {
            const updatesDataPromises = updatesData.map((f) =>
              this.selectedProject.updateAssignmentWithData(f)
            );
            Promise.all(updatesDataPromises)
              .then(() => (this.idsUpdating = false))
              .catch((err) => {
                console.error(err);
                this.idsUpdating = false;
              });
          })
          .catch((err) => {
            console.error(err);
            this.idsUpdating = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.editor-assignments-table {
  .group-header-row {
    background-color: #9af9ef70 !important;
    color: #003a35 !important;
  }

  th {
    display: none;
  }
}
::v-deep {
  .v-data-table-header__icon {
    display: none;
  }
}
</style>
