<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="id"
    sort-by="name"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ClientsTableExpansion :clientInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import ClientsTableExpansion from "@/components/Clients/ClientsTableExpansion";

export default {
  name: "ClientsTable",
  components: {
    ClientsTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterStatus: {
      type: String,
      required: false,
    },
    filterVertical: {
      type: String,
      required: true,
    },
    filterSearch: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Client", value: "name" },
        { text: "Client Status", value: "clientStatus" },
        { text: "Vertical", value: "vertical" },
        { text: "Quickbooks Customer Name", value: "quickbooksCustomerName" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      if (this.tableData.length) {
        const fields = Object.entries(this.tableData[0]).reduce(
          (fields, entry) => {
            const nestedKey = entry[1]
              ? Object.keys(entry[1]).map(
                  (key) => `${entry[0]}${isNaN(key) ? `.${key}` : ""}`
                )
              : [];
            return [...fields, ...nestedKey];
          },
          []
        );
        return fields;
      } else {
        return [];
      }
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterStatus) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.clientStatus === this.filterStatus
        );
      }

      if (this.filterVertical) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.vertical === this.filterVertical
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
