<template>
  <div class="assignments-table mt-5">
    <div
      class="assignments-table__header-and-controls d-flex justify-space-between align-center px-4"
    >
      <div class="mr-2">Assignments</div>
      <div class="d-flex">
        <v-text-field
          class="search-field pt-4 pr-7"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          :loading="assignmentsLoading"
          :disabled="assignmentsLoading"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <ReportSelector
          :class="{ disabled: assignmentsLoading }"
          :selected="selectedReport"
          @change="handleChange"
        />
      </div>
    </div>
    <v-skeleton-loader
      v-if="assignmentsLoading"
      type="table"
      height="100vh"
    ></v-skeleton-loader>
    <div v-else>
      <template>
        <v-data-table
          :headers="outerTableHeaders"
          :header-props="{ sortIcon: null }"
          :items="groupedRecordsData"
          :search="search"
          :custom-filter="customSearch"
          :single-expand="true"
          item-key="Assignment Title"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:[`item.GEOS`]="{ item }">
            <v-chip v-for="(geo, index) in item.GEOS" :key="index">
              {{ geo }}
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <SubAssignmentsView
                :assignmentTitle="item['Assignment Title']"
                :cleanedTableData="tableData"
                :search="search"
              />
            </td>
          </template>
          <template slot="body.append" v-if="isSearchEmpty">
            <tr>
              <th>Pay Period Total</th>
              <th class="text-center">{{ sumAssignments }}</th>
              <th class="text-center">{{ sumQuantity }}</th>
              <th v-if="selectedPO"></th>
              <th class="text-center">{{ sumTotal }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");
// internal
import { getAssignments } from "@/utils/newDbUtils";
import {
  groupAssignmentsByTitle,
  getAssignmentTitles,
} from "@/utils/myCueUtils";
// components
import ReportSelector from "@/components/ui/ReportSelector";
import SubAssignmentsView from "@/components/MyCue/SubAssignmentsView";

export default {
  name: "MyCueAssignmentsTable",
  components: { ReportSelector, SubAssignmentsView },
  data() {
    return {
      assignmentsLoading: false,
      tableData: [],
      expanded: [],
      search: "",
      payPeriodMonth: {
        LAST: new Date().getMonth() === 0 ? this.$moment(new Date().getMonth() + 12, "M")
          .format("MMMM")
          .toUpperCase() : 
          this.$moment(new Date().getMonth(), "M")
          .format("MMMM")
          .toUpperCase(),
        THIS: this.$moment(new Date().getMonth() + 1, "M")
          .format("MMMM")
          .toUpperCase(),
        NEXT: this.$moment(new Date().getMonth() + 2, "M")
          .format("MMMM")
          .toUpperCase(),
      },
    };
  },
  watch: {
    myCueSelectedClient() {
      this.handleAssignments();
    },
    selectedReport() {
      this.handleAssignments();
    },
  },
  methods: {
    ...mapActions("myCue", ["setSelectedReport"]),
    handleChange(v) {
      this.setSelectedReport(v);
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
    handleAssignments() {
      this.assignmentsLoading = true;
      getAssignments({
        clientId: this.myCueSelectedClient.id,
        query: {
          ppy: new Date().getMonth() === 0 && this.selectedReport === "LAST" ? 
            new Date().getFullYear()-1 : 
            new Date().getFullYear(),
          ppm: this.payPeriodMonth[this.selectedReport],
        },
      }).then((data) => {
        if (this.user.user.userRole === "CLIENT") {
          const userProjectsIds = this.user.projects.map((el) => el.id);
          this.tableData = data.filter((el) =>
            userProjectsIds.includes(el.projectId)
          );
        } else this.tableData = data;
        this.assignmentsLoading = false;
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myCue", [
      "myCueSelectedClient",
      "selectedReport",
      "selectedPO",
    ]),
    outerTableFields() {
      if (this.selectedPO) {
        return [
          "Assignment Title",
          "Assignments",
          "Quantity",
          "Requester",
          "Total",
          "POs",
          "GEOS",
        ];
      } else {
        return ["Assignment Title", "Assignments", "Quantity", "Total", "GEOS"];
      }
    },
    groupedRecordsData() {
      if (!this.tableData.length) return [];
      let newTableData = [];
      if (this.selectedPO && this.selectedPO?.purchaseOrderNumber !== "All") {
        newTableData = this.tableData.filter((el) => el.pricing?.purchaseOrderNumber === this.selectedPO?.purchaseOrderNumber);
      } else {
        newTableData = this.tableData;
      }
      const allPOGroup = groupAssignmentsByTitle(newTableData);

      if (
        this.selectedPO !== null &&
        this.selectedPO?.purchaseOrderNumber !== "All"
      ) {
        return allPOGroup.filter((el) =>
          el.POs.includes(this.selectedPO?.purchaseOrderNumber)
        );
      } else {
        return allPOGroup;
      }
    },
    sumAssignments() {
      if (!this.groupedRecordsData) return 0;
      return _.sumBy(this.groupedRecordsData, "Assignments");
    },
    sumQuantity() {
      if (!this.groupedRecordsData) return 0;
      return _.sumBy(this.groupedRecordsData, "Quantity");
    },
    sumTotal() {
      if (!this.groupedRecordsData) return 0;
      return numeral(_.sumBy(this.groupedRecordsData, "actualTotal")).format(
        "$0.00"
      );
    },
    outerTableHeaders() {
      const retVal = this.outerTableFields.map((el) => {
        return {
          text: el,
          value: el,
        };
      });
      retVal.push({ text: "", value: "data-table-expand" });
      return retVal;
    },
    assignmentTitlesInRecords() {
      if (!this.tableData.length) return null;
      return getAssignmentTitles(this.tableData);
    },
    isSearchEmpty() {
      return this.search === "";
    },
  },
  mounted() {
    this.handleAssignments();
  },
};
</script>

<style lang="scss" scoped>
.assignments-table {
  min-width: 1165px;
  &__header-and-controls {
    .search-field {
      width: 400px;
    }
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
