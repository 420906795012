<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab href="#general">GENERAL</v-tab>
      <v-tab href="#staff-pool">STAFF POOL</v-tab>
      <v-tab href="#fragments">FRAGMENTS</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="general">
        <v-row class="mt-3">
          <v-col cols="12" sm="6">
            <v-autocomplete
              filled
              dense
              hide-details
              label="Project Name"
              item-text="name"
              :items="dbProjects"
              :value="editProjectData.projectName"
              @change="handleInput($event, 'projectName')"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              filled
              dense
              hide-details
              label="Work Type"
              :items="workTypeChoices"
              :value="editProjectData.workType"
              @change="handleInput($event, 'workType')"
            />
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="12" sm="12">
            <v-text-field
              filled
              dense
              hide-details
              label="Link To Styleguide"
              :value="editProjectData.styleGuideLink"
              @change="handleInput($event, 'styleGuideLink')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="staff-pool">
        <v-row class="mb-2 mt-3">
          <v-col cols="12" sm="12">
            <v-autocomplete
              outlined
              multiple
              chips
              hide-details
              :loading="staffLoading"
              :items="staffNameItems"
              return-object
              label="Staff Pool"
              :value="editProjectData.staffPool"
              @change="handleInput($event, 'staffPool')"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="fragments">
        <FragmentsEditor />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import _ from "lodash";
// internal
import { getEnum } from "@/utils/newDbUtils";
// components
import FragmentsEditor from "./FragmentsEditor.vue";

export default {
  name: "EditorEditProjectItem",
  components: {
    FragmentsEditor,
  },
  data() {
    return {
      staffNameItems: [],
      workTypeChoicesLoading: true,
      workTypeChoices: [],
      tab: "general",
    };
  },
  computed: {
    ...mapGetters("editor", [
      "editProjectData",
      "dbProjects",
      "staff",
      "staffLoading",
    ]),
  },
  watch: {
    staffLoading() {
      this.handleStaffNames();
    },
  },
  mounted() {
    this.getWorkTypeChoices();
    this.handleStaffNames();
  },
  methods: {
    ...mapActions("editor", ["setEditProjectData"]),
    handleInput(value, field) {
      const newValue = _.cloneDeep(this.editProjectData);
      newValue[field] = value;
      if (field === "staffPool") {
        newValue["staffPoolEmails"] = value.map((el) => el.value.email);
      }
      this.setEditProjectData(newValue);
    },
    handleStaffNames() {
      if (!this.staffLoading)
        this.staffNameItems = this.staff.map((el) => ({
          text: `${el.user.firstName} ${el.user.lastName}`,
          value: {
            email: el.user.email,
            name: `${el.user.firstName} ${el.user.lastName}`,
          },
        }));
    },
    getWorkTypeChoices() {
      getEnum("WorkTypeCategory")
        .then((d) => {
          this.workTypeChoicesLoading = false;
          this.workTypeChoices = d;
        })
        .catch((err) => {
          console.error(err);
          this.workTypeChoicesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
