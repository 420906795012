<template>
  <v-dialog :value="isActive" max-width="600" persistent>
    <v-card>
      <v-card-title class="headline"> Ask about this assignment </v-card-title>

      <v-card-text>
        Add a message below and an email will be sent to your Company Cue
        project manager along with the assignment details.
      </v-card-text>

      <v-container class="pa-6">
        <v-textarea
          v-model="message"
          class="message-box"
          name="message-box"
          label="Message"
          outlined
        />
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color=" darken-1" :loading="isSending" text @click="handleClose">
          Cancel
        </v-btn>

        <v-btn color="darken-1" :loading="isSending" text @click="handleSubmit">
          Send
        </v-btn>
      </v-card-actions>
      <v-progress-linear v-if="isSending" indeterminate />
    </v-card>
  </v-dialog>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { functions } from "@/providers/firebase";
import { FALLBACK_INQUIRY_EMAIL } from "@/utils/constants";
import { DOMAIN, ROUTES } from "@/utils/constants";
import { getOneProject } from "@/utils/newDbUtils";

export default {
  name: "InqueryModal",
  data() {
    return {
      message: "",
      isSending: false,
    };
  },
  props: {
    isActive: Boolean,
    rowData: Object,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("myCue", ["setEmailAlert"]),
    ...mapActions("flashMessage", ["handleFlash"]),
    async getRecipient() {
      /* if (process.env.VUE_APP_NODE_ENV === "development")
        return "bohdan.shumakov@inventorsoft.co"; */
      if (this.user.projects.length) {
        return this.user.projects.find(el => el.id === this.rowData.projectId) ? this.user.projects.find(el => el.id === this.rowData.projectId).inquiryEmail : FALLBACK_INQUIRY_EMAIL;
      } else return FALLBACK_INQUIRY_EMAIL;
    },
    getAssignmentDetailsLink() {
      if (this.rowData.schedule.payPeriod && this.rowData.assignmentDetails.assignmentDetail && this.rowData.assignmentDetails.geo) {
        const dateStr = this.rowData.schedule.payPeriod;
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const monthIndex = date.getMonth();
        const month = monthNames[monthIndex];
        return `${DOMAIN}/assignment-dashboard?ppm=${month}&ppy=${year}&Search=${this.rowData.assignmentDetails.assignmentDetail.replace(/ /g, "_")}&Geo=${this.rowData.assignmentDetails.geo}`
      } else {
        return `${DOMAIN}/assignment-dashboard?Search=${this.rowData.assignmentDetails.assignmentDetail.replace(/ /g, "_")}`
      }
    },
    async handleSubmit() {
      const sendInqueryEmail = functions.httpsCallable("sendEmail");

      this.isSending = true;

      // in development, just send all emails to dev - otherwise send to project manager
      const recpient = await this.getRecipient();

      // link back to CueHub Invoice Bot
      // const link = `${DOMAIN}/${ROUTES.invoiceBot}`;  invoices

      // assemble email message
      const messageWithLink = `
        <h4>Message from Client</h4>
        <ul>
          <li>Inquiry from: ${this.user.user.email}</li>
          <li>Assignment Details:</li>
          <ul>
            <li>Title: ${this.rowData.assignmentDetails.assignmentTitle ? this.rowData.assignmentDetails.assignmentTitle : 'No Title'}</li>
            <li>Details: ${this.rowData.assignmentDetails.assignmentDetail ? this.rowData.assignmentDetails.assignmentDetail : 'No Details'}</li>
            <li>GEO: ${this.rowData.assignmentDetails.geo ? this.rowData.assignmentDetails.geo : 'No GEO Data'}</li>
            <li>Delivery Date: ${this.rowData.schedule.deliveryDate ? this.$moment(this.rowData.schedule.deliveryDate).format('MM-DD-YYYY') : 'No Delivery Date Data'}</li>
            <li>Project Manager: ${this.rowData.projectManager ? this.rowData.projectManager.firstName + ' ' + this.rowData.projectManager.lastName : 'No Project Manager Data'}</li>
          </ul>
          <li>CueHub Link: ${this.getAssignmentDetailsLink()}</li>
          <li>Note: ${this.message}</li>
        </ul>
      `;

      sendInqueryEmail({
        from: '"Cue Hub" <cocueadmin@companycue.com>', // sender address
        to: recpient, // list of receivers
        subject: `[HUB] Client Inquiry: ${this.rowData.projectName}`, // Subject line
        html: messageWithLink,
      })
        .then(() => {
          this.setEmailAlert("SUCCESS");
          this.handleFlash({
              response: { message: 'Success!', statusCode: 200 },
              show: true,
            });
          this.isSending = false;
          this.message = "";
          this.$emit("closeModal");
        })
        .catch((err) => {
          console.error(err);
          this.setEmailAlert("FAIL");
          this.$emit("closeModal");
        });
    },
    handleClose() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped></style>
