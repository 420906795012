<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ priceListInfo.units }}</div>
        <h2>{{ priceListInfo.workTypeName }}</h2>
        <v-chip class="my-5">
          {{
            priceListInfo.workTypeCategory
              ? priceListInfo.workTypeCategory
              : "None"
          }}
        </v-chip>
        <div>
          <!-- Assignments -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Related Assignments</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :disabled="!priceListInfo?.assignments"
                :href="``"
              >
                <v-icon :left="priceListInfo?.assignments"
                  >mdi-clipboard-list-outline</v-icon
                >{{ priceListInfo?.assignments }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- Notes -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Note</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              priceListInfo.note
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12">
            <h3 class="pb-2">Rate Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Primary Price Card (Internal)</th>
                    <th class="text-center">Secondary Price Card (Internal)</th>
                    <th class="text-center">Price Card (Client)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{
                        priceListInfo.primaryPriceCard !== null
                          ? `$${priceListInfo.primaryPriceCard.toFixed(2)}`
                          : ""
                      }}
                    </td>
                    <td>
                      {{
                        priceListInfo.secondaryPriceCard !== null
                          ? `$${priceListInfo.secondaryPriceCard.toFixed(2)}`
                          : ""
                      }}
                    </td>
                    <td>
                      {{
                        priceListInfo.clientPriceCard !== null
                          ? `$${priceListInfo.clientPriceCard.toFixed(2)}`
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// internal
import { DOMAIN, ROUTES } from "@/utils/constants";

export default {
  name: "CustomerPriceListTableExpansion",
  props: {
    priceListInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAssignmentsDashboardPath() {
      return `${DOMAIN}/${ROUTES.assignmentDashboard}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
