export const myCueModule = {
  state: () => ({
    myCueSelectedClient: null,
    selectedReport: "THIS",
    selectedPO: null,
    emailAlert: null,
  }),
  mutations: {
    SET_MY_CUE_SELECTED_CLIENT(state, client) {
      state.myCueSelectedClient = client;
    },
    SET_SELECTED_REPORT(state, report) {
      state.selectedReport = report;
    },
    SET_SELECTED_PO(state, po) {
      state.selectedPO = po;
    },
    SET_EMAIL_ALERT(state, emailAlertState) {
      state.emailAlert = emailAlertState;
    },
  },
  actions: {
    setMyCueSelectedClient(context, client) {
      context.commit("SET_MY_CUE_SELECTED_CLIENT", client);
    },
    setSelectedReport(context, report) {
      context.commit("SET_SELECTED_REPORT", report);
    },
    setSelectedPO(context, po) {
      context.commit("SET_SELECTED_PO", po);
    },
    setEmailAlert(context, emailAlertState) {
      context.commit("SET_EMAIL_ALERT", emailAlertState);
    },
  },
  getters: {
    myCueSelectedClient: (state) => {
      return state.myCueSelectedClient;
    },
    selectedReport: (state) => {
      return state.selectedReport;
    },
    selectedPO: (state) => {
      return state.selectedPO;
    },
    emailAlert: (state) => {
      return state.emailAlert;
    },
  },
};
