<template>
  <v-card class="editor-new-assignment-card grey lighten-2 d-flex flex-column">
    <EditorNewAssignmentItem
      v-for="(newAssignmentData, i) in newAssignmentDatas"
      :key="`new-assignment-${i}`"
      :datasIndex="i"
    />
    <v-card-actions class="d-flex flex-row justify-end pr-4 pb-4">
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        :loading="assignmentsProcessing"
        @click="handleAddAnotherAssignment"
      >
        <v-icon>mdi-plus</v-icon>
        add another assignment
      </v-btn>
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        :loading="assignmentsProcessing"
        @click="handleDuplicateAssignment"
      >
        <v-icon>mdi-plus</v-icon>
        duplicate
      </v-btn>
      <v-btn
        width="30%"
        color="secondary"
        :loading="assignmentsProcessing"
        @click="handleCreateNewAssignments"
        >create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  BLANK_NEW_ASSIGNMENT_DATAS,
  createNewAssignmentsWithDatas,
  getSortField,
} from "@/utils/editorUtils";
// components
import EditorNewAssignmentItem from "./EditorNewAssignmentItem/EditorNewAssignmentItem";

export default {
  name: "EditorNewAssignmentModal",
  components: { EditorNewAssignmentItem },
  data() {
    return {
      assignmentsProcessing: false,
    };
  },
  computed: {
    ...mapGetters("editor", ["newAssignmentDatas", "selectedProject"]),
    ...mapGetters("auth", ["isAdmin", "user"]),
  },
  methods: {
    ...mapActions("editor", [
      "setNewAssignmentDatas",
      "setTableAssignments",
      "setNewAssignmentModalOpen",
    ]),
    handleAddAnotherAssignment() {
      let newPayload = _.cloneDeep(this.newAssignmentDatas);
      newPayload = [...newPayload, ...BLANK_NEW_ASSIGNMENT_DATAS];
      this.setNewAssignmentDatas(newPayload);
    },
    handleDuplicateAssignment() {
      let newPayload = _.cloneDeep(this.newAssignmentDatas);
      let lastAssn = _.last(newPayload);
      newPayload = [...newPayload, lastAssn];
      this.setNewAssignmentDatas(newPayload);
    },
    handleCreateNewAssignments() {
      this.assignmentsProcessing = true;
      createNewAssignmentsWithDatas({
        datas: this.newAssignmentDatas,
        project: this.selectedProject,
      })
        .then(() => {
          // reload the table
          return this.selectedProject.getAssignmentDatas({
            isAdmin: this.isAdmin,
            userEmail: this.user.user.email,
          });
        })
        .then((d) => {
          d.forEach((el) => {
            el.sortDate = getSortField({
              row: el,
              isAdmin: this.isAdmin,
              userEmail: this.user.user.email,
            });
          });
          this.setTableAssignments(d);
          this.setNewAssignmentModalOpen(false);
          this.assignmentsProcessing = false;
        })
        .catch((err) => {
          console.error(err);
          this.setNewAssignmentModalOpen(false);
          this.assignmentsProcessing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
