import _ from "lodash";
import {
  BLANK_REIMBURSEMENT_FORM_VALUES,
  BLANK_ASSIGNMENT_FORM_VALUES,
} from "@/utils/nativeFormsConstants";

export const nativeFormsModule = {
  state: () => ({
    defaultNativeForms: [],
    clientsList: [],
    clientFormData: null,
  }),
  mutations: {
    mSetDefaultNativeForms(state, data) {
      state.defaultNativeForms = [];
      state.defaultNativeForms.push(data);
    },
    mSetFieldValue(state, data) {
      state.defaultNativeForms[data.index][data.field] = data.value;
    },
    mDuplicateLastForm(state) {
      state.defaultNativeForms.push(
        _.cloneDeep(state.defaultNativeForms[state.defaultNativeForms.length - 1])
      );
    },
    mClearDefaultNativeForms(state) {
      state.defaultNativeForms = [];
    },
    mSetClientFormData(state, data) {
      state.clientFormData = data;
    },
    mSetClientsList(state, data) {
      state.clientsList = data;
    },
  },
  actions: {
    aSetDefaultNativeForms({ commit }) {
      commit("mSetDefaultNativeForms", _.cloneDeep(BLANK_REIMBURSEMENT_FORM_VALUES));
    },
    aSetDefaultAssignmentValues({ commit }) {
      commit("mSetDefaultNativeForms", _.cloneDeep(BLANK_ASSIGNMENT_FORM_VALUES));
    },
    aSetFieldValue({ commit }, data) {
      commit("mSetFieldValue", data);
    },
    aDuplicateLastForm({ commit }) {
      commit("mDuplicateLastForm");
    },
    aClearNativeFormsList({ commit }) {
      commit("mClearDefaultNativeForms");
    },
    aSetClientFormData({ commit }, data) {
      commit("mSetClientFormData", data);
    },
    aSetClientsList({ commit }, data) {
      commit("mSetClientsList", data);
    },
  },
  getters: {
    gGetDefaultNativeForms: (state) => state.defaultNativeForms,
    gGetClientsList: (state) => state.clientsList,
    gGetClientFormData: (state) => state.clientFormData,
  },
};
