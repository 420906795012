import { BLANK_NEW_ASSIGNMENT_DATAS } from "@/utils/assignmentUtils";

export const assignmentModule = {
  state: () => ({
    // modals
    newAssignmentModalOpen: false,
    // assignments
    newAssignmentDatas: BLANK_NEW_ASSIGNMENT_DATAS,
    geoOptions: [],
    mediaTypeOptions: [],
    requestTypeOptions: [],
    statusOptions: [],
    projectsList: [],
    driList: [],
    bulkEditing: false,
    bulkQueryEdit: false,
    updateBatchConfirmation: false,
  }),
  mutations: {
    SET_NEW_ASSIGNMENT_MODAL_OPEN(state, assignmentModalOpen) {
      state.newAssignmentModalOpen = assignmentModalOpen;
    },
    SET_NEW_ASSIGNMENT_DATAS(state, newDatas) {
      state.newAssignmentDatas = newDatas;
    },
    RESET_NEW_ASSIGNMENT_DATAS(state) {
      state.newAssignmentDatas = [...BLANK_NEW_ASSIGNMENT_DATAS];
    },
    DELETE_NEW_ASSIGNMENT_DATAS(state, datasIndex) {
      state.newAssignmentDatas.splice(datasIndex, 1);
    },
    SET_COMMON_DATA(state, { field, data }) {
      state[field] = [...data];
    },
    SET_BULK_EDITING(state, bulkEditing) {
      state.bulkEditing = bulkEditing;
    },
    SET_BULK_QUERY_EDIT(state, bulkQueryEdit) {
      state.bulkQueryEdit = bulkQueryEdit;
    },
    SET_UPDATE_BATCH_CONFIRMATION(state, updateBatchConfirmation) {
      state.updateBatchConfirmation = updateBatchConfirmation;
    },
  },
  actions: {
    setNewAssignmentModalOpen(context, assignmentModalOpen) {
      if (!assignmentModalOpen) {
        context.commit("RESET_NEW_ASSIGNMENT_DATAS");
        context.commit("SET_BULK_EDITING", false);
      }
      context.commit("SET_NEW_ASSIGNMENT_MODAL_OPEN", assignmentModalOpen);
    },
    setNewAssignmentDatas(context, newDatas) {
      context.commit("SET_NEW_ASSIGNMENT_DATAS", newDatas);
    },
    deleteNewAssignmentDatas(context, datasIndex) {
      context.commit("DELETE_NEW_ASSIGNMENT_DATAS", datasIndex);
    },
    setCommonData(context, { field, data }) {
      context.commit("SET_COMMON_DATA", { field, data });
    },
    setBulkEditing(context, bulkEditing) {
      context.commit("SET_BULK_EDITING", bulkEditing);
    },
    setBulkQueryEdit(context, bulkQueryEdit) {
      context.commit("SET_BULK_QUERY_EDIT", bulkQueryEdit);
    },
    setUpdateBatchConfirmation(context, updateBatchConfirmation) {
      context.commit("SET_UPDATE_BATCH_CONFIRMATION", updateBatchConfirmation);
    },
  },
  getters: {
    newAssignmentModalOpen: (state) => {
      return state.newAssignmentModalOpen;
    },
    newAssignmentDatas: (state) => {
      return state.newAssignmentDatas;
    },
    geoOptions: (state) => {
      return state.geoOptions;
    },
    mediaTypeOptions: (state) => {
      return state.mediaTypeOptions;
    },
    requestTypeOptions: (state) => {
      return state.requestTypeOptions;
    },
    statusOptions: (state) => {
      return state.statusOptions;
    },
    projectsList: (state) => {
      return state.projectsList;
    },
    driList: (state) => {
      return state.driList;
    },
    isEditMode: (state) => {
      return state.newAssignmentDatas[0].hasOwnProperty("id");
    },
    bulkEditing: (state) => {
      return state.bulkEditing;
    },
    bulkQueryEdit: (state) => {
      return state.bulkQueryEdit;
    },
    updateBatchConfirmation: (state) => {
      return state.updateBatchConfirmation;
    },
  },
};
