<template>
  <div class="general-splitter">
    <v-card class="mt-10 pa-3 card">
      <div class="general-splitter__section general-splitter__section-input">
        <h2>Input File</h2>
        <v-file-input
          class="my-4 file-input"
          v-model="inputFile"
          label="Drag or Import .xlsx File"
          outlined
        />
      </div>
      <div v-if="inputFile">
        <h2>Split on Columns</h2>
        <v-select
          class="column-select"
          :items="columnOptions"
          v-model="selectedColumns"
          multiple
          chips
          clearable
        />
        <div v-if="splitGroupings">
          <p>
            Splitting on these columns will create
            <span class="font-weight-bold">{{ splitGroupings.length }}</span>
            individual files.
          </p>
        </div>
        <div class="mt-4 d-flex justify-start">
          <v-btn
            color="secondary"
            class="px-4"
            :disabled="splitButtonDisabled"
            :loading="splitting"
            @click="doSplit"
          >
            DO SPLIT
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// internal
import {
  getHeaderRowsFromFile,
  getGroupsOnCustomColumns,
  doSplitOnCustomGroups,
} from "@/utils/spreadsheetUploadUtils";
// components

export default {
  name: "GeneralSplitter",
  data() {
    return {
      inputFile: null,
      columnOptions: [],
      selectedColumns: [],
      splitGroupings: null,
      splitting: false,
    };
  },
  computed: {
    splitButtonDisabled() {
      return this.selectedColumns.length === 0;
    },
  },
  watch: {
    inputFile(newVal) {
      this.columnOptions = [];
      this.selectedColumns = [];
      if (newVal) {
        getHeaderRowsFromFile(newVal).then((results) => {
          const filteredOptions = results.filter(
            (result) => result !== undefined && result.length > 0
          );
          this.columnOptions = filteredOptions;
        });
      }
    },
    selectedColumns(newColumns) {
      if (newColumns.length > 0) {
        this.calculateGroupings();
      } else {
        this.splitGroupings = null;
      }
    },
  },
  mounted() {},
  methods: {
    calculateGroupings() {
      getGroupsOnCustomColumns({
        file: this.inputFile,
        columns: this.selectedColumns,
      }).then((result) => {
        this.splitGroupings = result;
      });
    },
    doSplit() {
      this.splitting = true;
      doSplitOnCustomGroups({
        file: this.inputFile,
        groupings: this.splitGroupings,
      }).then(() => (this.splitting = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.general-splitter {
  max-width: 700px;
  margin: 0 auto;

  .file-input {
    max-width: 600px;
  }

  .column-select {
    max-width: 600px;
  }
}
</style>
