<template>
  <div>
    <v-data-table
      v-model="selectedRows"
      :loading="isStatusUpdating"
      :headers="headers"
      :items="filteredData"
      item-key="index"
      group-by="groupTitle"
      sort-by="groupSort"
      :header-props="{ sortIcon: null }"
      show-expand
      show-select
      group-desc
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :class="{ isStatusUpdating: 'status-updating' }"
    >
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers }">
        <td class="pa-0 group-header-row" :colspan="headers.length">
          <h2 class="mx-4 font-weight-bold">
            {{ group }}
          </h2>
        </td>
      </template>

      <!-- Request Date cell override -->
      <template v-slot:[`item.RequestDate`]="{ value }">
        <span>{{ formatDateForTableRow(value) }}</span>
      </template>

      <!-- GEO cell override -->
      <template v-slot:[`item.GEO`]="{ value }">
        <v-chip>{{ value }}</v-chip>
      </template>

      <!-- Issue type cell override -->
      <template v-slot:[`item.IssueType`]="{ item, value }">
        <IssueTrackerDropdownSelector
          :item="item"
          :value="value"
          :typeMode="true"
          :dropdownOptions="issueTypes"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-filters="updateTableData"
        />
      </template>

      <!-- Last Updated cell override -->
      <template v-slot:[`item.LastUpdated`]="{ value }">
        {{ formatDateForTableRow(value, true) }}
      </template>

      <!-- Last Updated By cell override -->
      <template v-slot:[`item.LastUpdatedBy`]="{ value }">
        {{ value }}
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.IssueStatus`]="{ item, value }">
        <IssueTrackerDropdownSelector
          :item="item"
          :value="value"
          :dropdownOptions="issueStatuses"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-filters="updateTableData"
        />
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <IssueTrackerTableExpansion :assignmentInfo="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// components
import IssueTrackerTableExpansion from "./IssueTrackerTableExpansion.vue";
import IssueTrackerDropdownSelector from "./IssueTrackerDropdownSelector.vue";

export default {
  name: "IssueTrackerTable",
  components: {
    IssueTrackerTableExpansion,
    IssueTrackerDropdownSelector,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterDate: {
      type: Array,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterProject: {
      type: Array,
      required: true,
    },
    filterMedia: {
      type: String,
      required: true,
    },
    filterEmployee: {
      type: String,
      required: true,
    },
    filterRole: {
      type: String,
      required: true,
    },
    filterGeo: {
      type: String,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
    filterStatus: {
      type: String,
      required: true,
    },
    isStatusUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    issueTypes: {
      type: Array,
      required: true,
    },
    issueStatuses: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        "RequestDate",
        "AssignmentDetail",
        "GEO",
        "IssueType",
        "LastUpdated",
        "LastUpdatedBy",
        "IssueStatus",
      ],
      selectedRows: [],
    };
  },
  computed: {
    ...mapGetters("spreadsheetUpload", ["selectedDashboardRows"]),
    ...mapGetters("auth", ["user"]),
    headers() {
      if (!this.tableFields) return [];

      let retVal = this.tableFields
        .filter((el) => this.tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => this.tableHeaders.indexOf(el.value));

      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    dataWithGroupInfo() {
      return this.tableData.map((el, i) => {
        return {
          ...el,
          groupTitle: el["AssignmentTitle"],
          groupType: "TITLE",
          groupSort: el["AssignmentDetail"],
          index: i,
        };
      });
    },
    filteredData() {
      let filteredData = [];

      const filterMap = ["iTunes FC", "iTunes Music"],
        inProjects = this.filterProject.map((el) => filterMap[el]);

      filteredData = this.dataWithGroupInfo.filter((el) => {
        return inProjects.includes(el["Assignment-ProjectName"]);
      });

      if (this.filterDate.length) {
        const datesRange = [],
          startDate = this.$moment(this.filterDate[0]),
          endDate = this.$moment(
            this.filterDate[1] ? this.filterDate[1] : this.filterDate[0]
          );
        for (
          let dateVar = new Date(startDate);
          dateVar <= endDate;
          dateVar.setDate(dateVar.getDate() + 1)
        ) {
          datesRange.push(this.$moment(new Date(dateVar)).format("YYYY-MM-DD"));
        }
        filteredData = filteredData.filter((el) => {
          return datesRange.includes(
            this.$moment(el["RequestDate"]).format("YYYY-MM-DD")
          );
        });
      }

      if (this.filterEmployee) {
        filteredData = filteredData.filter(
          (el) =>
            el["Assignment-Writer"] === this.filterEmployee ||
            el["Assignment-Editor"] === this.filterEmployee ||
            el["Assignment-Reviewer"] === this.filterEmployee
        );
      }

      if (this.filterRole) {
        filteredData = filteredData.filter(
          (el) => el[`Assignment-${this.filterRole}`] === this.filterEmployee
        );
      }

      if (this.filterGeo) {
        filteredData = filteredData.filter(
          (el) => el["GEO"] === this.filterGeo
        );
      }

      if (this.filterMedia) {
        filteredData = filteredData.filter(
          (el) => el["Assignment-MediaType"] === this.filterMedia
        );
      }

      if (this.filterType) {
        filteredData = filteredData.filter(
          (el) => el["IssueType"] === this.filterType
        );
      }

      if (this.filterStatus) {
        filteredData = filteredData.filter(
          (el) => el["IssueStatus"] === this.filterStatus
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(filteredData, searchOptions);
        filteredData = fuse.search(this.filterSearch).map((el) => el.item);
      } else {
        filteredData = filteredData;
      }
      return filteredData;
    },
  },
  watch: {
    selectedRows(newValues) {
      this.setSelectedDashboardRows(newValues);
    },
    selectedDashboardRows(newValues) {
      this.selectedRows = newValues;
    },
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["setSelectedDashboardRows"]),
    formatDateForTableRow(data, displayTime) {
      return this.$moment(data).format(
        `MM/DD/YYYY ${displayTime ? "hh:mm A" : ""}`
      );
    },
    updateTableData(index, key, value) {
      this.tableData[index][key] = value;
      this.tableData[index].LastUpdated = new Date();
      this.tableData[index].LastUpdatedBy = this.user.Name;
    },
  },
};
</script>

<style lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
}

.v-data-table {
  &__expanded__content {
    width: 100% !important;
    position: relative !important;
  }
  &__checkbox {
    padding-left: 8px;
  }
}

.status-updating {
  pointer-events: "none";
  opacity: 0.2;
}

.v-application .text-start {
  text-align: center !important;
}
</style>
