// imports
import axiosInstance from "./axiosInstance";

const terms = {
  TuneIn: 15,
  "Apple US": 45,
  default: 30,
};

const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};

const getTerms = (customer) => {
  let net;
  if (Object.keys(terms).includes(customer)) net = terms[customer];
  else net = terms.default;
  return net;
};

const getDueDate = (customer) => {
  const net = getTerms(customer);
  const now = new Date(Date.now());
  const due = addDays(now, net);
  return due;
};

const getInvoiceNumberLookup = (rows) => {
  const clientPOCombos = rows.map(
    (el) => `${el.quickBooksCustomerName}${el.purchaseOrderNumber}`
  );

  const uniqueList = [...new Set(clientPOCombos)];

  const retVal = {};
  uniqueList.forEach((el, i) => (retVal[el] = 1001 + i));

  return retVal;
};

export const doCSVGenerate = ({ rows, clientEmails }) => {
  const invoiceNumberLookup = getInvoiceNumberLookup(rows);

  const formattedObj = rows.map((row) => {
    const invoiceNo =
        invoiceNumberLookup[
          `${row.quickBooksCustomerName}${row.purchaseOrderNumber}`
        ],
      po = row.purchaseOrderNumber ? row.purchaseOrderNumber : "N/A",
      currentDate = new Date(Date.now()),
      net = getTerms(row.quickBooksCustomerName),
      worktypePrice = row.workTypePrice,
      worktype = worktypePrice.split(" ").slice(0, -1).join(" "),
      itemRate = parseFloat(worktypePrice.split(" ").at(-1)),
      quanitity = parseInt(row.quantity),
      clientTotal = parseFloat(row.totalAmount),
      clientEmailInfo = clientEmails.find(
        (el) => el.client === row.quickBooksCustomerName
      ),
      clientPrimaryEmail = clientEmailInfo?.primary_email || "";

    return {
      "*InvoiceNo": invoiceNo,
      Customer: `"${row.quickBooksCustomerName}"`,
      Memo: po,
      "*InvoiceDate": currentDate.toLocaleDateString(),
      "*DueDate": getDueDate(row.quickBooksCustomerName).toLocaleDateString(),
      Terms: `Net ${net}`,
      "Item(Product/Service)": worktype,
      ItemDescription: `"${row.assignmentDetails}"`,
      ItemQuantity: quanitity,
      ItemRate: itemRate.toFixed(2),
      ItemAmount: clientTotal.toFixed(2),
      Taxable: "N",
      Email: clientPrimaryEmail,
    };
  });

  const csvString = createCSVString(formattedObj);
  const blob = new Blob([csvString], {
    type: "text/plain",
  });
  saveAs(blob, "output.csv");
};

const createCSVString = (obj) => {
  let retVal = "";
  const headerRow = Object.keys(obj[0]);

  retVal = retVal.concat(headerRow.join(","), "\n");

  obj.forEach((row) => {
    retVal = retVal.concat(Object.values(row).join(","), "\n");
  });

  return retVal;
};

export const createQuickBooksRedirectLink = async () => {
  try {
    const response = await axiosInstance.get(`/quickbook`);
    return response;
  } catch (err) {
    return err.response;
  }
}

export const sendQuickBooksInvoices = async (body) => {
  try {
    const response = await axiosInstance.post(`/quickbook/generate_invoice`, body);
    return response;
  } catch (err) {
    return err.response;
  }
}
