<template>
  <div class="typeform">
    <!-- here's the typeform for {{ typeformData }} -->
    <div ref="typeform__embed" class="typeform__embed"></div>
    <v-snackbar
      v-model="typeformProcessing"
      min-width="fit-content"
      elevation="12"
      timeout="-1"
      color="warning"
      rounded="pill"
      content-class="text-center"
      bottom
    >
      <v-icon left>mdi-timer-sand</v-icon>Processing your answers...
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import * as typeformEmbed from "@typeform/embed";
// internal
import {
  getAllProjects,
  processAssignment,
  queryTypeformData,
  getCustomerPriceList,
} from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
import { BLANK_NEW_ASSIGNMENT_DATAS } from "@/utils/assignmentUtils";

export default {
  name: "Typeform",
  props: {
    typeformData: Object,
  },
  data() {
    return {
      projects: [],
      typeformProcessing: false,
    };
  },
  watch: {
    typeformData(newValue) {
      this.handleTypeform(newValue);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleTypeform(formData) {
      const element = this.$refs["typeform__embed"];
      typeformEmbed.makeWidget(
        element,
        `https://form.typeform.com/to/${formData.typeform_id}`,
        {
          hideFooter: true,
          hideHeaders: true,
          onSubmit: (event) => {
            this.typeformProcessing = true;
            setTimeout(() => {
              queryTypeformData({
                typeform_id: formData.typeform_id,
                response_id: event.response_id,
              }).then(async (resp) => {
                // process form answers
                const form = resp.items.find(
                  (el) => el.response_id === event.response_id
                );
                const getKeyValue = (key) => {
                  const retVal = form.answers.find(
                    (el) =>
                      el.field.ref === key ||
                      el.field.ref === `${key}1` ||
                      el.field.ref === `${key}2`
                  );
                  if (retVal) {
                    const dataType = retVal.type;
                    if (dataType === "choice") return retVal.choice.label;
                    else if (dataType === "choices")
                      return retVal.choices.labels.map(
                        (geo) => geo.split(" ")[0]
                      );
                    else return retVal[dataType];
                  } else return null;
                };
                const data = {
                  project: getKeyValue("project"),
                  clientRequester:
                    this.user?.user?.userRole === "CLIENT"
                      ? `${this.user.user.firstName} ${this.user.user.lastName}`
                      : getKeyValue("clientRequester"),
                  newClientRequester:
                    this.user?.user?.userRole === "CLIENT"
                      ? `${this.user.user.firstName} ${this.user.user.lastName}`
                      : getKeyValue("newClientRequester"),
                  inquiryEmail: getKeyValue("inquiryEmail"),
                  requestType: getKeyValue("requestType"),
                  assignmentTitle: getKeyValue("assignmentTitle"),
                  urgent: getKeyValue("urgent"),
                  wordCount: getKeyValue("wordCount"),
                  geos: getKeyValue("geos"),
                  deliveryDate: getKeyValue("deliveryDate"),
                  note: getKeyValue("note"),
                  link: getKeyValue("link"),
                  assignmentDetail: getKeyValue("assignmentDetail"),
                  files: getKeyValue("files"),
                  design: getKeyValue("design"),
                  formId: formData.typeform_id,
                  channel: formData.slack_channel_id,
                  dropboxLink: formData.dropbox_link,
                };
                // format data for assignment POST
                const isQuoteRequest = data.project.includes("Quote");
                const workTypes = isQuoteRequest
                  ? await getCustomerPriceList()
                  : null;
                const getQuoteRequestWorkType = () => {
                  const management = workTypes.find(
                    (el) => el.workTypeName === "Management"
                  );
                  return management ? management.id : null;
                };
                const relatedProject =
                  this.user?.user?.userRole === "CLIENT"
                    ? this.user.projects?.find((el) => el.name === data.project)
                    : this.projects.find(
                        (el) =>
                          el.name ===
                          (isQuoteRequest ? "Administrative" : data.project)
                      );
                const getClientRequesterId = () => {
                  const requester = relatedProject?.clientRequesters?.find(
                    (el) =>
                      `${el.user.firstName} ${el.user.lastName}` ===
                      data.clientRequester
                  );
                  return requester ? requester.user.id : null;
                };
                if (typeof data.geos === "string") {
                  let newData = data.geos;
                  data.geos = [newData];
                }
                // handle assignments
                const assignments = data.geos.map((geo) => ({
                  projectId: relatedProject?.id ?? null,
                  clientId:
                    (this.user?.user?.userRole === "CLIENT"
                      ? this.user.client?.id
                      : relatedProject?.client?.id) ?? null,
                  clientRequesterId:
                    this.user?.user?.userRole === "CLIENT"
                      ? this.user.user.id
                      : getClientRequesterId(),
                  assignmentDetails: {
                    assignmentTitle: `${
                      isQuoteRequest
                        ? `Quote Request from ${formData.company} - `
                        : ""
                    }${data.assignmentTitle}`,
                    wordCount: data.wordCount,
                    geo: isQuoteRequest ? "en-US" : geo,
                    assignmentDetail: isQuoteRequest
                      ? `${geo}${data.note ? ` | ${data.note}` : ""}`
                      : data.assignmentDetail,
                    link: data.link,
                    assignmentStatus: "Requested",
                    note: isQuoteRequest ? null : data.note,
                    requestType: data.requestType,
                    urgent: data.urgent.includes("Yes"),
                  },
                  schedule: {
                    requestDate:
                      BLANK_NEW_ASSIGNMENT_DATAS[0].schedule.requestDate,
                    deliveryDate: data.deliveryDate
                      ? data.deliveryDate.substring(0, 10)
                      : null,
                  },
                  document: null,
                  pricing: {
                    workTypes: [
                      {
                        customerPriceListId: isQuoteRequest
                          ? getQuoteRequestWorkType()
                          : null,
                        quantity: isQuoteRequest
                          ? null
                          : Number(data.wordCount)
                          ? Number(data.wordCount)
                          : null,
                        orderPosition: 1
                      },
                    ],
                  },
                  projectManagerId:
                    relatedProject?.projectManager?.user?.id ?? null,
                }));
                // POST assignments
                const promises = assignments.map((assignment) => {
                  return new Promise((resolve, reject) => {
                    processAssignment({ data: assignment }).then((resp) =>
                      resolve(resp)
                    );
                  });
                });
                Promise.all(promises)
                  .then((resp) => {
                    if (resp.every((el) => el.status === 201)) {
                      // POST Slack message
                      publishMessage({ mode: "request", data }).then(() => {
                        this.typeformProcessing = false;
                        this.handleFlash({
                          response: { data: { statusCode: 200 } },
                          show: true,
                        });
                      });
                    }
                  })
                  .catch((err) => {
                    this.typeformProcessing = false;
                    this.handleFlash({
                      response: err,
                      show: true,
                    });
                  });
              });
            }, 3000);
          },
        }
      );
    },
    getProjects() {
      getAllProjects().then((data) => {
        this.projects = data?.status === 500 ? [] : data;
      });
    },
  },
  mounted() {
    this.handleTypeform(this.typeformData);
    if (this.user?.user?.userRole !== "CLIENT") this.getProjects();
  },
};
</script>

<style lang="scss" scoped>
.typeform {
  &__embed {
    height: 900px;
  }
}
</style>
