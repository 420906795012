import _ from "lodash";
export const settingsModule = {
    state: () => ({
        fieldName: null,
        updateData: false,
        newField: {
            key: null,
            value: null,
            status: null
        },
        newFieldAutocomplete: [],
        enumsList: [],
        enumsListClone: [],
        showEnumsArchiveSwitcher: false
    }),
    mutations: {
      mSetFieldName(state, payload) {
        state.fieldName = payload;
      },
      mSetNewField(state, payload) {
        state.newField[payload.key] = payload.value;
      },
      mSetDataToUpdate(state, payload) {
        state.updateData = payload
      },
      mSetEnumsList(state, payload) {
        state.enumsList = [];
        payload.forEach((item) => {
          state.enumsList.push({
            ...item,
            checked: false,
          });
        });
        state.enumsListClone = _.cloneDeep(state.enumsList);
      },
      mUpdateEnumsList(state, payload) {
        state.enumsList = [];
        payload.forEach((item) => {
          state.enumsList.push({
            ...item,
            checked: false,
          });
        });
      },
      mSetShowArchiveSwitcher(state, payload) {
        state.showEnumsArchiveSwitcher = payload
      },
      mClearNewField(state) {
        state.newField = {
          key: null,
          value: null,
          status: null
        };
        state.newFieldAutocomplete = [];
      },
      mSetNewFieldAutocomplete(state, payload) {
        state.newFieldAutocomplete = payload
      }
    },
    actions: {
      aSetFieldName({ commit }, payload) {
        commit("mSetFieldName", payload);
      },
      aSetNewField({ commit }, payload) {
        commit("mSetNewField", payload);
      },
      aSetEnumsList({ commit }, payload) {
        commit("mSetEnumsList", payload);
      },
      aUpdateEnumsList({ commit }, payload) {
        commit("mUpdateEnumsList", payload);
      },
      aClearNewField({ commit }) {
        commit("mClearNewField");
      },
      aSetDataToUpdate({ commit }, payload) {
        commit("mSetDataToUpdate", payload);
      },
      aUpdateShowArchiveSwitcher({ commit }, payload) {
        commit("mSetShowArchiveSwitcher", payload);
      },
      aClearNewField({ commit }) {
        commit("mClearNewField");
      },
      aSetNewFieldAutocomplete({ commit }, payload) {
        commit("mSetNewFieldAutocomplete", payload);
      }
    },
    getters: {
      gGetFieldName(state) {
        return state.fieldName;
      },
      gGetNewField(state) {
        return state.newField;
      },
      gGetEnumsList(state) {
        let list = state.enumsList;
        if (!state.showEnumsArchiveSwitcher) {
          list = list.filter((item) => item.status === "ACTIVE");
          return list.sort((a, b) => a.displayOrder - b.displayOrder);
        } else {
          return list.sort((a, b) => a.displayOrder - b.displayOrder);
        }
      },
      gGetEnumsListClone(state) {
        return state.enumsListClone;
      },
      gGetDataToUpdate(state) {
        return state.updateData;
      },
      gGetShowArchiveSwitcher(state) {
        return state.showEnumsArchiveSwitcher
      },
      gGetNewFieldAutocomplete(state) {
        return state.newFieldAutocomplete
      }
    },
  };
  