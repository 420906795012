export const flashMessageModule = {
  state: () => ({
    status: null,
    showFlashMsg: false,
    flashMsgTxt: "",
  }),
  mutations: {
    HANDLE_FLASH(state, { response, show }) {
      state.status =
        response?.data?.statusCode ||
        response?.data?.status ||
        response?.status ||
        response?.statusCode;
      state.flashMsgTxt = response?.data?.message || response?.data?.error;
      state.showFlashMsg = show;
    },
  },
  actions: {
    handleFlash(context, response) {
      context.commit("HANDLE_FLASH", response);
    },
  },
  getters: {
    status: (state) => {
      return state.status;
    },
    showFlashMsg: (state) => {
      return state.showFlashMsg;
    },
    flashMsgTxt: (state) => {
      return state.flashMsgTxt;
    },
  },
};
