<template>
  <v-data-table
    :class="{ laptop, laptopSmaller }"
    :headers="headers"
    :items="filteredData"
    item-key="id"
    sort-by="name"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- Project Manager cell override -->
    <template v-slot:[`item.projectManager`]="{ value }">
      {{ value ? `${value.firstName} ${value.lastName}` : "" }}
    </template>

    <!-- Inquiry Email cell override -->
    <template v-slot:[`item.inquiryEmail`]="{ value }">
      <v-btn
        fab
        x-small
        outlined
        elevation="1"
        color="accent"
        :disabled="!value"
        @click.stop="sendEmail(value)"
        ><v-icon>mdi-email-plus-outline</v-icon></v-btn
      >
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ProjectsTableExpansion :projectInfo="item" :checklist="checklist" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import ProjectsTableExpansion from "@/components/Projects/ProjectsTableExpansion";

export default {
  name: "ProjectsTable",
  components: {
    ProjectsTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterClient: {
      type: String,
      required: false,
    },
    filterPM: {
      type: String,
      required: false,
    },
    filterEstStartDate: {
      type: Array,
      required: false,
    },
    filterStatus: {
      type: String,
      required: false,
    },
    filterPriority: {
      type: String,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    checklist: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Client", value: "client[name]" },
        { text: "Project", value: "name" },
        { text: "Status", value: "projectStatus" },
        { text: "Priority", value: "priority" },
        { text: "Project Type", value: "projectType" },
        { text: "Project Manager", value: "projectManager" },
        { text: "Project Contact", value: "projectContact" },
        { text: "Inquiry Email", value: "inquiryEmail" },
        { text: "Client Status", value: "client[clientStatus]" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      if (this.tableData.length) {
        const fields = Object.entries(this.tableData[0]).reduce(
          (fields, entry) => {
            const nestedKey = entry[1]
              ? Object.keys(entry[1]).map(
                  (key) => `${entry[0]}${isNaN(key) ? `.${key}` : ""}`
                )
              : [];
            return [...fields, ...nestedKey];
          },
          []
        );
        return fields;
      } else {
        return [];
      }
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterClient) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.client?.name === this.filterClient
        );
      }

      if (this.filterPM) {
        dateFilteredData = dateFilteredData.filter(
          (el) =>
            this.filterPM.includes(el.projectManager?.firstName) &&
            this.filterPM.includes(el.projectManager?.lastName)
        );
      }

      if (this.filterEstStartDate.length) {
        const startDate = this.$moment(this.filterEstStartDate[0]),
          endDate = this.$moment(
            this.filterEstStartDate[1]
              ? this.filterEstStartDate[1]
              : this.filterEstStartDate[0]
          );
        dateFilteredData = dateFilteredData.filter((el) => {
          return this.getDatesRange(startDate, endDate).includes(
            this.$moment(el.estStartDate).format("MM-DD-YYYY")
          );
        });
      }

      if (this.filterStatus) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.projectStatus === this.filterStatus
        );
      }

      if (this.filterPriority) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.priority === this.filterPriority
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    getDatesRange(start, end) {
      let datesRange = [];
      for (
        let dateVar = new Date(start);
        dateVar <= end;
        dateVar.setDate(dateVar.getDate() + 1)
      ) {
        datesRange.push(this.$moment(new Date(dateVar)).format("MM-DD-YYYY"));
      }
      return datesRange;
    },
    formatDateForTableRow(date) {
      return date ? this.$moment(date).format("MM-DD-YYYY") : "";
    },
    sendEmail(email) {
      window.open(`mailto:${email}`);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
