<template>
  <div class="not-found d-flex justify-center align-center flex-column">
    <v-icon class="not-found__icon" color="primary"
      >mdi-help-circle-outline</v-icon
    >
    <div class="text-center pa-3">
      Oops!<br />{{
        requestFormMissing
          ? "Looks like your Company doesn't have a Request Form."
          : "There is no such page."
      }}<br />{{
        requestFormMissing
          ? "Please contact your Project Manager."
          : "Please use the menu at the top left to navigate."
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  computed: {
    requestFormMissing() {
      return this.$route.query.hasOwnProperty("requestFormMissing");
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  height: 100%;
  &__icon {
    height: 500px;
    width: 500px;
    font-size: 600px;
  }
}
</style>
