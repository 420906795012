<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="3" class="pa-0">
        <div class="overline">{{ hardwareInfo.deviceBrand }}</div>
        <h2>{{ hardwareInfo.model }}</h2>
        <v-chip class="my-5">
          {{ hardwareInfo.deviceCategory }}
        </v-chip>
        <div>
          <!-- Device Serial Number -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Serial Number</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo.serialNumber
            }}</v-col>
          </v-row>

          <!-- User -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >User</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              `${hardwareInfo.firstName} ${hardwareInfo.lastName}`
            }}</v-col>
          </v-row>

          <!-- Hardware User Agreement -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Hardware User Agreement</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                v-if="hardwareInfo.hardwareAgreement"
                color="accent"
                :loading="hardwareAgreementLoading"
                @click="
                  downloadFile(
                    hardwareInfo.hardwareAgreement,
                    'hardwareAgreement'
                  )
                "
              >
                Download<v-icon right>mdi-download</v-icon> </v-btn
              ><v-icon v-else color="secondary" :disabled="!hardwareInfo.onHand"
                >mdi-file-document-remove-outline</v-icon
              >
            </v-col>
          </v-row>

          <!-- Projects -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Projects</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo.projects?.join(", ")
            }}</v-col>
          </v-row>

          <!-- Notes -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Notes</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo.notes
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="9">
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12" sm="6">
            <h3 class="pb-2">On Hand Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">On Hand</th>
                    <th class="text-center">On Hand Start Date</th>
                    <th class="text-center">On Hand End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon v-if="hardwareInfo.onHand">mdi-check</v-icon>
                    </td>
                    <td>
                      {{ formatDate(hardwareInfo.onHandStartDate) }}
                    </td>
                    <td>
                      {{ formatDate(hardwareInfo.onHandEndDate) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" sm="6">
            <h3 class="pb-2">Purchase Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Date</th>
                    <th class="text-center">Price</th>
                    <th class="text-center">Receipt</th>
                    <th class="text-center">Purchased By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ formatDate(hardwareInfo.purchaseDate) }}
                    </td>
                    <td>
                      {{
                        hardwareInfo.purchasePrice
                          ? `$${hardwareInfo.purchasePrice}`
                          : ""
                      }}
                    </td>
                    <td>
                      <v-btn
                        v-if="hardwareInfo.purchaseReceipt"
                        icon
                        :loading="purchaseReceiptLoading"
                        @click="
                          downloadFile(
                            hardwareInfo.purchaseReceipt,
                            'purchaseReceipt'
                          )
                        "
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-icon v-else>mdi-file-document-remove-outline</v-icon>
                    </td>
                    <td>{{ hardwareInfo.purchasedBy }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="table-bg text-center pa-0 mt-6">
          <v-col cols="12" :sm="hardwareInfo.purchasedBy === 'User' ? 6 : 12">
            <h3 class="pb-2">On Hand History</h3>
            <v-simple-table v-if="hardwareInfo.hardwareHistory.length">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">User</th>
                    <th class="text-center">On Hand Start Date</th>
                    <th class="text-center">On Hand End Date</th>
                    <th class="text-center">Hardware User Agreement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record, index) in hardwareInfo.hardwareHistory"
                    :key="index"
                  >
                    <td>
                      {{ `${record.firstName} ${record.lastName}` }}
                    </td>
                    <td>
                      {{ formatDate(record.startDate) }}
                    </td>
                    <td>
                      {{ formatDate(record.endDate) }}
                    </td>
                    <td>
                      <v-btn
                        v-if="record.agreement"
                        icon
                        :loading="record.agreementLoading"
                        @click="
                          downloadFile(
                            record.agreement,
                            `${record.firstName} ${record.lastName}_${hardwareInfo.deviceBrand}_${hardwareInfo.model}_${hardwareInfo.deviceCategory}_Hardware Agreement_${record.startDate}.pdf`,
                            index
                          )
                        "
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-icon v-else>mdi-file-document-remove-outline</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card
              class="d-flex justify-center align-center"
              flat
              height="96px"
              v-else
            >
              <v-card-text class="d-flex justify-center align-center"
                ><v-icon large class="mr-1"
                  >mdi-clipboard-text-clock-outline</v-icon
                >No history to show.</v-card-text
              >
            </v-card>
          </v-col>
          <v-col v-if="hardwareInfo.purchasedBy === 'User'" cols="12" sm="6">
            <h3 class="pb-2">Reimbursement Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Date</th>
                    <th class="text-center">Amount</th>
                    <th class="text-center">Record</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ formatDate(hardwareInfo.reimbursementDate) }}
                    </td>
                    <td>
                      {{
                        hardwareInfo.reimbursementAmount
                          ? `$${hardwareInfo.reimbursementAmount}`
                          : ""
                      }}
                    </td>
                    <td>
                      <v-btn
                        v-if="hardwareInfo.reimbursementRecord"
                        icon
                        :href="`${fullAssignmentDashboardPath}?ppm=${$moment(
                          hardwareInfo.reimbursementDate
                        ).format('MMMM')}&ppy=${$moment(
                          hardwareInfo.reimbursementDate
                        ).format('YYYY')}&Search=${
                          hardwareInfo.reimbursementRecord
                        }`"
                        target="_blank"
                      >
                        <v-icon>mdi-account-cash</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// library
import { saveAs } from "file-saver";
// internal
import { toTitleCase, getFile } from "@/utils/newDbUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";

export default {
  name: "CueHardwareTableExpansion",
  props: {
    hardwareInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hardwareAgreementLoading: false,
      purchaseReceiptLoading: false,
    };
  },
  computed: {
    fullAssignmentDashboardPath() {
      return `${DOMAIN}/${ROUTES.assignmentDashboard}`;
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment.unix(date).format("MM-DD-YYYY") : "";
    },
    downloadFile(url, fileName, historyRecord) {
      if (historyRecord !== undefined)
        this.hardwareInfo.hardwareHistory[
          historyRecord
        ].agreementLoading = true;
      else this[`${fileName}Loading`] = true;
      getFile({ url }).then(async (resp) => {
        const base64Response = await fetch(
            `data:application/pdf;base64,${resp}`
          ),
          blob = await base64Response.blob(),
          blobName = historyRecord
            ? fileName
            : `${
                fileName.includes("hardware")
                  ? `${this.hardwareInfo.firstName} ${this.hardwareInfo.lastName}_`
                  : ""
              }${this.hardwareInfo.deviceBrand}_${this.hardwareInfo.model}_${
                this.hardwareInfo.deviceCategory
              }_${toTitleCase(fileName)}.pdf`;
        saveAs(blob, blobName);
        if (historyRecord !== undefined)
          this.hardwareInfo.hardwareHistory[
            historyRecord
          ].agreementLoading = false;
        else this[`${fileName}Loading`] = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
