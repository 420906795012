<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-space-between">
      <h3 class="mb-1">{{ fragment.fragmentName }}</h3>
      <div class="overline text--gray text--lighten-2">
        <span>{{ countNumerator }}</span>
        <span>/</span>
        <span>{{ countDenominator }}</span>
        <span> {{ countUnits }}</span>
      </div>
    </div>
    <div
      class="white elevation-2 rounded editor-container"
      ref="editor-container"
    >
      <div v-if="!isEditable" class="overlay"></div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import _ from "lodash";
import { EditorView } from "prosemirror-view";
// internal
import { dub } from "@/providers/dubEditor";
import { initState } from "@/utils/editorUtils";
import { EDITOR_AUTOSAVE_INTERVAL } from "@/utils/editorUtils";

export default {
  name: "EditorFragmentEditor",
  props: {
    fragment: {
      type: Object,
      required: true,
    },
    flow: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      view: null,
      state: null,
      editorContent: {},
      editorCreated: false,
      debounceSave: _.debounce(
        () => {
          this.doSave();
        },
        EDITOR_AUTOSAVE_INTERVAL,
        { leading: false, trailing: true }
      ),
    };
  },
  computed: {
    ...mapGetters("editor", ["flowStage", "openAssignment"]),
    ...mapGetters("auth", ["isAdmin", "user"]),
    selectedDocumentID() {
      // return this.fragment.documents[this.flowStage].id;
      return this.flow[this.flowStage].documents[this.fragment.fragmentIndex]
        .id;
    },
    textContents() {
      if (!this.state || !this.state.doc) {
        return "";
      }
      return this.state.doc.textContent;
    },
    wordCount() {
      // get number of words in this.textContents
      if (this.textContents === "") return 0;
      return this.textContents.trim().split(" ").length;
    },
    charCount() {
      // get number of characters in this.textContents
      if (this.textContents === "") return 0;
      return this.textContents.length;
    },
    countNumerator() {
      return this.fragment.isCountWords ? this.wordCount : this.charCount;
    },
    countDenominator() {
      return this.fragment.maxCount;
    },
    countUnits() {
      return this.fragment.isCountWords ? "words" : "char";
    },
    isEditable() {
      return (
        this.isAdmin ||
        this.flow[this.flowStage].person.email === this.user.user.email
      );
    },
  },
  watch: {
    selectedDocumentID() {
      this.loadDocumentToEditor();
    },
  },
  mounted() {
    this.loadDocumentToEditor();
  },
  methods: {
    doSave() {
      dub
        .saveDataToID({
          assignmentID: this.openAssignment,
          documentID: this.selectedDocumentID,
          data: this.editorContent,
        })
        .catch((err) => console.error(err));
    },
    dispatchTransaction(tx) {
      const jsonData = tx.doc.toJSON();
      this.editorContent = jsonData;
      this.state = this.state.apply(tx);
      this.view.updateState(this.state);
      this.debounceSave();
    },
    loadDocumentToEditor() {
      if (!this.openAssignment || !this.selectedDocumentID) {
        return;
      }
      dub
        .getSingleDoc({
          assignmentID: this.openAssignment,
          documentID: this.selectedDocumentID,
        })
        .then((doc) => {
          this.editorContent = doc?.data;
          this.state = initState(this.editorContent);
          if (!this.editorCreated) {
            this.view = new EditorView(this.$refs["editor-container"], {
              state: this.state,
              dispatchTransaction: this.dispatchTransaction,
              editable: () => this.isEditable,
            });
            this.editorCreated = true;
          } else {
            this.view.updateState(this.state);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-container {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
