import { dataFileHeaders } from "@/utils/dataUploadConstants";
import _ from "lodash";
const Excel = require("exceljs");

export const getRecords = (worksheet) => {
  const recordsArray = [];
  try {
    let headers = worksheet.getRow(1).values;
    const headerLookup = _.invert(
      dataFileHeaders
    );
    headers = headers.map((el) => headerLookup[el]);

    window.ws = worksheet;
    worksheet.eachRow((row, i) => {
      const rowValues = row.values;
      if (i === 1) return;
      const newRecord = {};
      
      headers.forEach((header, j) => {
        if (header) {
          newRecord[header] = rowValues[j];
        }
      });
      
      recordsArray.push(newRecord);
    });
    return recordsArray;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTableDataFromSource = async (file) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix
  const records = getRecords(sheet);
  if (!records) {
    console.error("error in getRecords from source file");
    // handle error of bad source file processing
  }
  return records;
};