import { render, staticRenderFns } from "./EditorProjectModal.vue?vue&type=template&id=4696ff28&scoped=true&"
import script from "./EditorProjectModal.vue?vue&type=script&lang=js&"
export * from "./EditorProjectModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4696ff28",
  null
  
)

export default component.exports