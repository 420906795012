<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="4" class="pa-0">
        <div class="overline">{{ poInfo.client?.name }}</div>
        <h2>
          {{ poInfo.purchaseOrderNumber }}
        </h2>
        <v-chip class="my-5">
          {{ poInfo.isActive ? "Active" : "Inactive" }}
        </v-chip>
        <div>
          <!-- Currency -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Currency</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              poInfo.currency
            }}</v-col>
          </v-row>

          <!-- Pre-Paid -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Pre-Paid</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              poInfo.prePaid ? "Yes" : "No"
            }}</v-col>
          </v-row>

          <!-- Document Proving -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Document Proving</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :disabled="!poInfo.documentProving"
                :loading="documentProvingLoading"
                @click="downloadFile"
              >
                Download<v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Project -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Project</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              poInfo.project?.name
            }}</v-col>
          </v-row>

          <!-- Client Requesters -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Client Requesters</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <ul>
                <li v-for="cr in poInfo.clientRequesters" :key="cr.user.id">
                  {{ `${cr.user.firstName} ${cr.user.lastName}` }}
                </li>
              </ul>
            </v-col>
          </v-row>

          <!-- Project Manager-->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Project Manager</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              poInfo.project?.projectManager ? poInfo.project?.projectManager?.firstName + " " + poInfo.project?.projectManager?.lastName : null
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="8">
        <POViz :data="poInfo" />
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12">
            <h3 class="pb-2">Related Data</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center width">Client</th>
                    <th class="text-center width">Project</th>
                    <th class="text-center width">Requester</th>
                    <th class="text-center width">Assignments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="width">
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullClientsDashboardPath}?Search=${poInfo.client?.name}`"
                        :disabled="!poInfo.client?.name"
                        target="_blank"
                      >
                        <v-icon>mdi-folder-account-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullProjectsDashboardPath}?Client=${poInfo.client?.name}&Search=${poInfo.project?.name}`"
                        :disabled="!poInfo.project?.name"
                        target="_blank"
                      >
                        <v-icon>mdi-folder-multiple-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullClientRequestersDashboardPath}?Project=${poInfo.project?.name}`"
                        :disabled="
                          !poInfo.project?.name ||
                          !poInfo.clientRequesters.length
                        "
                        target="_blank"
                      >
                        <v-icon>mdi-account-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullAssignmentsDashboardPath}?Search=${poInfo.purchaseOrderNumber}`"
                        :disabled="!poInfo.assignmentsCount"
                        target="_blank"
                      >
                        <v-icon>mdi-clipboard-list-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Notes -->
    <v-row class="mx-0">
      <v-col cols="12" sm="2" class="py-1 px-0 font-weight-bold"
        >Notes</v-col
      >
      <v-col class="py-1 px-0" cols="12" sm="10">{{ poInfo.notes }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
// library
import { saveAs } from "file-saver";
// internal
import { getFile } from "@/utils/newDbUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";
import SHK from "@/assets/SHK.svg";
// components
import POViz from "@/components/MyCue/POViz";

export default {
  name: "PurchaseOrdersTableExpansion",
  components: { POViz },
  props: {
    poInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { SHK, documentProvingLoading: false };
  },
  computed: {
    fullClientsDashboardPath() {
      return `${DOMAIN}/${ROUTES.clients}`;
    },
    fullProjectsDashboardPath() {
      return `${DOMAIN}/${ROUTES.projects}`;
    },
    fullClientRequestersDashboardPath() {
      return `${DOMAIN}/${ROUTES.clientRequesters}`;
    },
    fullAssignmentsDashboardPath() {
      return `${DOMAIN}/${ROUTES.assignmentDashboard}`;
    },
  },
  methods: {
    downloadFile() {
      this.documentProvingLoading = true;
      getFile({
        table: "purchase-order",
        file: "document-proving",
        entityId: this.poInfo.id,
      }).then(async (resp) => {
        const base64Response = await fetch(
          `data:application/pdf;base64,${resp}`
        );
        const blob = await base64Response.blob();
        saveAs(blob, `${this.poInfo.purchaseOrderNumber}_Document Proving.pdf`);
        this.documentProvingLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.width {
  width: 25%;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.table-bg {
  margin-top: -25px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>