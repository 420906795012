<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="id"
    group-by="workTypeCategory"
    sort-by="id"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- grouping override -->
    <template v-slot:[`group.header`]="{ group, headers }">
      <td class="pa-0 group-header-row" :colspan="headers.length">
        <h2 class="mx-4 font-weight-bold d-inline">
          {{ group }}
        </h2>
        <v-chip color="font-weight-bold white" small>
          {{ `${getPricesAmount(group)} price(s)` }}
        </v-chip>
      </td>
    </template>

    <!-- Writer Price Card cell override -->
    <template v-slot:[`item.primaryPriceCard`]="{ value }">
      <span>{{ value !== null ? `$${value.toFixed(2)}` : "" }}</span>
    </template>

    <!-- Editor Price Card cell override -->
    <template v-slot:[`item.secondaryPriceCard`]="{ value }">
      <span>{{ value !== null ? `$${value.toFixed(2)}` : "" }}</span>
    </template>

    <!-- Client Price Card cell override -->
    <template v-slot:[`item.clientPriceCard`]="{ value }">
      <span>{{ value !== null ? `$${value.toFixed(2)}` : "" }}</span>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <CustomerPriceListTableExpansion :priceListInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import CustomerPriceListTableExpansion from "@/components/CustomerPriceList/CustomerPriceListTableExpansion";

export default {
  name: "CustomerPriceListTable",
  components: {
    CustomerPriceListTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterWorkTypeCategory: {
      type: String,
      required: false,
    },
    filterUnits: {
      type: String,
      required: true,
    },
    filterSearch: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Work Type", value: "workTypeName" },
        { text: "Primary Price Card (Internal)", value: "primaryPriceCard" },
        {
          text: "Secondary Price Card (Internal)",
          value: "secondaryPriceCard",
        },
        { text: "Price Card (Client)", value: "clientPriceCard" },
        { text: "Units", value: "units" },
        { text: "Work Type Category", value: "workTypeCategory" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      return this.tableData.length ? Object.keys(this.tableData[0]) : [];
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterWorkTypeCategory) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.workTypeCategory === this.filterWorkTypeCategory
        );
      }

      if (this.filterUnits) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.units === this.filterUnits
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    getPricesAmount(workTypeCategory) {
      return this.filteredData.filter((el) =>
        workTypeCategory
          ? el.workTypeCategory === workTypeCategory
          : el.workTypeCategory === null
      ).length;
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
}
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
.d-inline {
  vertical-align: middle;
}
</style>
