<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="4" class="pa-0">
        <div class="overline">{{ projectInfo.projectType }}</div>
        <h2>
          {{ projectInfo.name }}
        </h2>
        <v-chip class="my-5">
          {{ projectInfo.projectStatus }}
        </v-chip>
        <div>
          <!-- Description -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Description</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              projectInfo.description
            }}</v-col>
          </v-row>

          <!-- PM -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Project Manager</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              projectInfo.projectManager
                ? `${projectInfo.projectManager?.firstName} ${projectInfo.projectManager?.lastName}`
                : ""
            }}</v-col>
          </v-row>

          <!-- Slack channel -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Slack post channel ID</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              projectInfo.slackChannel
            }}</v-col>
          </v-row>

          <!-- Start Date -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Est Start Date</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              {{ formatDate(projectInfo.estStartDate) }}</v-col
            >
          </v-row>

          <!-- End Date -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Est End Date</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              {{ formatDate(projectInfo.estEndDate) }}</v-col
            >
          </v-row>

          <!-- Priority -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Priority</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              projectInfo.priority
            }}</v-col>
          </v-row>

          <!-- Client -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Client</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              projectInfo.client?.name
            }}</v-col>
          </v-row>

          <!-- Client Requesters -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Client Requesters</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              projectInfo.clientRequesters.length
                ? projectInfo.clientRequesters
                    .map(
                      (clientRequester) =>
                        `${clientRequester?.user?.firstName} ${clientRequester?.user?.lastName}`
                    )
                    .join(", ")
                : ""
            }}</v-col>
          </v-row>

          <!-- External Tracking Document -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >External Tracking Document</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :href="projectInfo.externalTrackingDocument"
                target="_blank"
                :disabled="!projectInfo.externalTrackingDocument"
              >
                Open<v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- SOW -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >SOW</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :disabled="!projectInfo.sow"
                :loading="sowLoading"
                @click="downloadFile"
              >
                Download<v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Staff -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Staff</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <ul>
                <li v-for="staff in projectInfo.staff" :key="staff.user.id">
                  {{ `${staff.user.firstName} ${staff.user.lastName}` }}
                </li>
              </ul>
            </v-col>
          </v-row>

          <!-- Checklist -->
          <v-row class="mx-0">
            <v-col cols="12" class="py-1 px-0 font-weight-bold">
              <v-list disabled>
                <v-subheader>CHECKLIST</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in checklist" :key="i">
                    <v-list-item-icon>
                      <v-icon
                        v-text="
                          projectInfo.checklist.includes(checklist[i])
                            ? 'mdi-check-bold'
                            : 'mdi-close-thick'
                        "
                        :color="
                          projectInfo.checklist.includes(checklist[i])
                            ? 'success'
                            : 'error'
                        "
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="checklist[i]"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="8">
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12">
            <h3 class="pb-2">Communication Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Project Contact</th>
                    <th class="text-center">Project Contact (additional)</th>
                    <th class="text-center">Inquiry Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b v-if="projectInfo.projectContact">{{
                        projectInfo.projectContact
                      }}</b>
                      {{ `${projectInfo.role ? `/ ${projectInfo.role}` : ""}` }}
                    </td>
                    <td>
                      <b v-if="projectInfo.projectContact2">{{
                        projectInfo.projectContact2
                      }}</b>
                      {{
                        `${projectInfo.role2 ? `/ ${projectInfo.role2}` : ""}`
                      }}
                    </td>
                    <td>
                      {{ projectInfo.inquiryEmail ?? "" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="table-bg text-center pa-0 mt-6">
          <v-col cols="12">
            <h3 class="pb-2">Related Data</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Client</th>
                    <th class="text-center">Requesters</th>
                    <th class="text-center">Purchase Orders</th>
                    <th class="text-center">Assignments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullClientsDashboardPath}?Search=${projectInfo.client?.name}`"
                        :disabled="!projectInfo.client?.name"
                        target="_blank"
                      >
                        <v-icon>mdi-folder-account-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullClientRequestersDashboardPath}?Project=${projectInfo.name}`"
                        :disabled="!projectInfo.clientRequesters.length"
                        target="_blank"
                      >
                        <v-icon>mdi-account-alert-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullPosDashboardPath}?Client=${projectInfo.client?.name}&Project=${projectInfo.name}`"
                        :disabled="!projectInfo.purchaseOrders.length"
                        target="_blank"
                      >
                        <v-icon>mdi-octagram-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullAssignmentsDashboardPath}?Project=${projectInfo.name}`"
                        :disabled="!projectInfo.client?.assignmentsCount"
                        target="_blank"
                      >
                        <v-icon>mdi-clipboard-list-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// internal
import { getFile } from "@/utils/newDbUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";

export default {
  name: "ProjectsTableExpansion",
  props: {
    projectInfo: {
      type: Object,
      required: true,
    },
    checklist: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sowLoading: false,
    };
  },
  computed: {
    fullClientsDashboardPath() {
      return `${DOMAIN}/${ROUTES.clients}`;
    },
    fullPosDashboardPath() {
      return `${DOMAIN}/${ROUTES.purchaseOrders}`;
    },
    fullClientRequestersDashboardPath() {
      return `${DOMAIN}/${ROUTES.clientRequesters}`;
    },
    fullAssignmentsDashboardPath() {
      return `${DOMAIN}/${ROUTES.assignmentDashboard}`;
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("MM-DD-YYYY") : "";
    },
    downloadFile() {
      this.sowLoading = true;
      getFile({
        table: "project",
        file: "sow",
        entityId: this.projectInfo.id,
      }).then(async (resp) => {
        const base64Response = await fetch(
          `data:application/pdf;base64,${resp}`
        );
        const blob = await base64Response.blob();
        saveAs(blob, `${this.projectInfo.name}_SOW.pdf`);
        this.sowLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
