<template>
    <div class="container">
        <v-card class="card d-flex flex-column grey lighten-2">
            <div v-if="!loading">
                <ReimbursementForm
                    ref="reimbursementForm"
                    v-for="(nativeForm, i) in gGetDefaultNativeForms"
                    :key="i"
                    :nativeForm="nativeForm"
                    :index="i"
                />
            </div>
            <v-skeleton-loader
                v-if="loading"
                type="list-item-three-line@5"
            ></v-skeleton-loader>
            <v-card-actions class="d-flex justify-end pr-4 pb-4">
            <v-btn
                depressed
                class="mx-3 grey lighten-2"
                :loading="loading"
                @click="cancelHandle"
            >
                Cancel
            </v-btn>
            <v-btn
                outlined
                color="blue darken-2"
                :loading="loading"
                @click="aDuplicateLastForm"
            >
                Duplicate
            </v-btn>
            <v-btn
                color="blue darken-2"
                class="request-btn mx-3"
                :loading="loading"
                @click="handleRequest"
                >Submit</v-btn
            >
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAllProjects, processAssignment } from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
import ReimbursementForm from "@/components/PaymentRequestForm/ReimbursementForm";
export default {
    name: "PaymentRequestForm",
    components: {
        ReimbursementForm
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters("nativeForms", ["gGetDefaultNativeForms"]),
        ...mapGetters("auth", ["user"]),
    },
    methods: {
        ...mapActions("assignment", ["setCommonData"]),
        ...mapActions("nativeForms", ["aSetDefaultNativeForms", "aDuplicateLastForm", "aClearNativeFormsList"]),
        ...mapActions("flashMessage", ["handleFlash"]),
        handleData() {
            this.loading = true;
            let dropdowns = [getAllProjects()];
            Promise.all(dropdowns)
            .then((resp) => {
                this.setCommonData({ field: "projectsList", data: resp[0] });
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        cancelHandle() {
            this.aClearNativeFormsList();
            this.$router.push({ name: "Payment Request Form" });
        },
        confirmFormValid() {
            let validator = [];
            this.$refs.reimbursementForm.forEach((el) =>
                validator.push(el.validateForm())
            );
            return validator.every((value) => value === true);
        },
        async handleRequest() {
            if (!this.confirmFormValid()) {
                this.handleFlash({
                    response: { 
                        status: 400,
                        data: {
                            message: "Please fill out all required fields.",
                        }
                    },
                    show: true,
                });
                return;
            }
            this.loading = true;
            let assignmentList = [];
            this.gGetDefaultNativeForms.forEach((nativeForm) => {
                const assignment = {
                  assignmentDetails: {
                    assignmentTitle: `Reimbursement Request - ${nativeForm.reimbursementCategory}`,
                    geo: "en-US",
                    assignmentDetail: `Administrative - ${nativeForm.requestType} - ${nativeForm.reimbursementDescription}`,
                    mediaType: null,
                    primaryAccepted: true,
                    assignmentStatus: "Requested",
                  },
                  schedule: {
                    requestDate: new Date().toISOString().split('T')[0],
                    deliveryDate: nativeForm.purchaseDeliveryDate,
                    primaryDueDate: nativeForm.purchaseDeliveryDate,
                    payPeriod: this.$moment().format("YYYY-MM"),
                  },
                  document: null,
                  pricing: {
                    workTypes: [
                      {
                        customerPriceListId: null,
                        quantity: Number(nativeForm.reimbursementAmount),
                        orderPosition: 1
                      },
                    ],
                  },
                  clientId: null,
                  projectId: nativeForm.relatedProject.id,
                  primaryId: this.user.user.id,
                  projectManagerId: nativeForm.relatedProject.projectManager?.id,
                  addedDocuments: [],
                };
                if (nativeForm.reimbursementFiles.length) {
                    nativeForm.reimbursementFiles.forEach((file) => {
                        const fullFileName = file.name;
                        const fileExtension = fullFileName.split('.').pop();
                        const fileName = fullFileName.replace(`.${fileExtension}`, '');
                        let fileItem = {
                            id: 0,
                            documentData: null,
                            fileName: fileName,
                            fileExtension: fileExtension,
                            toDelete: false
                        };
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onloadend = () => (fileItem.documentData = reader.result?.split(",")[1]);
                        reader.onerror = error => {
                            console.error('Error reading file:', error);
                        };
                        assignment.addedDocuments.push(fileItem);
                    })
                }
                assignmentList.push(assignment);
            });
            await Promise.all(
                assignmentList.map(assignment => 
                    processAssignment({ data: assignment })
                    .then(resp => {
                            console.log('resp', resp);
                            const project = this.projectsList?.find(project => project.id === resp?.data?.project?.id);
                            const staffManager = project?.staff?.find(staff => staff.user?.id === resp?.data?.projectManager?.id);
                            publishMessage({
                                mode: "reimbursement",
                                data: {
                                    user: resp?.data?.createdBy?.firstName + " " + resp?.data?.createdBy?.lastName,
                                    project: resp?.data?.project?.name,
                                    workCategory: resp?.data?.assignmentDetails?.assignmentTitle?.split(' - ')[1] || '',
                                    quantity: resp?.data?.pricing?.workTypes[0]?.quantity,
                                    requestType1: resp?.data?.assignmentDetails?.assignmentDetail?.split(' - ')[1] || '',
                                    deliveryDate: resp?.data?.schedule?.deliveryDate,
                                    pmSlackTag: staffManager?.adminDetails?.slackIdPm ? `<@${staffManager?.adminDetails?.slackIdPm}>` : `*${resp?.data?.projectManager?.firstName} ${resp?.data?.projectManager?.lastName}*`,
                                    assignmentDetail: resp?.data?.assignmentDetails?.assignmentDetail,
                                    geo: resp?.data?.assignmentDetails?.geo
                                },
                            })
                        })
                        .catch(err => {
                            console.log(err);
                        })
                )
            )
            .then(() => {
                publishMessage({
                    mode: "reimbursementConfirmation",
                    data: {
                        userSlackTag: this.user.adminDetails.slackIdStaff,
                    },
                })
                this.handleFlash({
                    response: {
                        data: {
                            statusCode: 200,
                            message: "Reimbursement request was submitted successfully.",
                        }
                    },
                    show: true,
                });
                this.aClearNativeFormsList();
                this.loading = false;
                this.$router.push({ name: "Payment Request Form" });
            })
            .catch(err => {
                this.handleFlash({
                    response: { data: "Something went wrong" },
                    show: true,
                });
                console.error("Error processing assignments: ", err);
                this.loading = false;
            });
        }
    },
    mounted() {
        this.aSetDefaultNativeForms();
        this.handleData();
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
        min-width: 800px;
        max-width: 800px;
        & .request-btn {
            color: #fff !important;
        }
    }
}
</style>