import { render, staticRenderFns } from "./PMDashboardAssignmentsTable.vue?vue&type=template&id=660da50e&scoped=true&"
import script from "./PMDashboardAssignmentsTable.vue?vue&type=script&lang=js&"
export * from "./PMDashboardAssignmentsTable.vue?vue&type=script&lang=js&"
import style0 from "./PMDashboardAssignmentsTable.vue?vue&type=style&index=0&id=660da50e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660da50e",
  null
  
)

export default component.exports