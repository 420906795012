<template>
  <div class="sub-assignments-view overflow-x-hidden">
    <v-container class="sub-assignments-view__container mx-0 my-5 px-0">
      <v-row class="sub-assignments-view__header-row mx-0 font-weight-medium">
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          GEO
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Quantity
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Total
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Task ID
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="2">
          Detail
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Billed
        </v-col>
        <v-col cols="12" sm="5"> </v-col>
      </v-row>

      <!--  -->

      <v-row
        v-for="(row, i) in filteredAssigments"
        :key="i + '-row'"
        class="sub-assignments-view__row"
        :class="{ 'alt-background': i % 2 === 0 }"
      >
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ row.assignmentDetails.geo }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ row.quantity }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ getPriceString(row.pricing.clientTotal) }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ row.assignmentDetails.taskId }}
        </v-col>
        <v-col align-self="center" cols="12" sm="2">
          {{ row.assignmentDetails.assignmentDetail }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          <v-icon v-if="row.assignmentDetails.billed">mdi-check</v-icon>
        </v-col>
        <v-col class="d-flex align-center pl-0 pr-2" cols="12" sm="5">
          <AssignmentProgressIndicator class="mr-1" :data="row" />
          <v-icon
            v-if="isAdmin || isManager"
            class="sub-assignments-view__db-link"
            @click="handleLinkClick(row)"
            >mdi-link</v-icon
          >
          <v-icon
            v-if="user.user.userRole === 'CLIENT'"
            class="sub-assignments-view__db-link"
            @click="handleQuestionClick(row)"
            >mdi-email-outline</v-icon
          >
        </v-col>
      </v-row>
    </v-container>
    <InqueryModal
      :isActive="inqueryModalActive"
      :rowData="inqueryRowData"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");
// internal
import { DOMAIN, ROUTES } from "@/utils/constants";
// components
import AssignmentProgressIndicator from "@/components/MyCue/AssignmentProgressIndicator";
import InqueryModal from "@/components/MyCue/InqueryModal";

export default {
  name: "SubAssignmentsView",
  components: { AssignmentProgressIndicator, InqueryModal },
  data() {
    return {
      inqueryModalActive: false,
      inqueryRowData: null,
    };
  },
  props: {
    cleanedTableData: Array,
    assignmentTitle: String,
    search: String,
  },
  methods: {
    getPriceString(d) {
      return numeral(d).format("$0.00");
    },
    handleLinkClick(item) {
      // link to dashboard
      const url = `${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${this.$moment(
        item.schedule.payPeriod
      ).format("MMMM")}&ppy=${this.$moment(item.schedule.payPeriod).format(
        "YYYY"
      )}&Search=${item.assignmentDetails.assignmentTitle}&DeliveryDate=${
        item.schedule.deliveryDate
      }&Person=${item.primary}&Role=Primary&Project=${item.projectName}&Geo=${
        item.assignmentDetails.geo
      }`;
      window.open(url, "_blank");
    },
    handleQuestionClick(d) {
      this.inqueryRowData = d;
      this.inqueryModalActive = true;
    },
    handleCloseModal() {
      this.inqueryModalActive = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    ...mapGetters("myCue", ["selectedPO"]),
    searchIsActive() {
      return this.search !== "" && this.search !== null;
    },
    filteredAssigments() {
      let retval;
      let newTableData = [];
      if (this.selectedPO && this.selectedPO?.purchaseOrderNumber !== "All") {
        newTableData = this.cleanedTableData.filter((el) => el.pricing?.purchaseOrderNumber === this.selectedPO?.purchaseOrderNumber);
      } else {
        newTableData = this.cleanedTableData;
      }

      if (!this.searchIsActive) {
        retval = newTableData.filter(
          (el) => el.assignmentDetails.assignmentTitle === this.assignmentTitle
        );
      } else {
        retval = newTableData.filter((el) => {
          return (
            el.assignmentDetails.assignmentTitle === this.assignmentTitle &&
            el.assignmentDetails.assignmentDetail
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      }

      retval = _.reverse(
        _.sortBy(retval, (d) => {
          return new Date(d.schedule.deliveryDate);
        })
      );

      return retval;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-assignments-view {
  position: relative;
  &__container {
    max-width: unset !important;
    width: 100%;
  }

  &__row {
    margin: 5px 0px;

    &.alt-background {
      background-color: #f5f5f5;
    }
  }

  &__db-link {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
</style>
