// libraries
import _ from "lodash";
const numeral = require("numeral");

export const getAssignmentTitles = (recordsData) => {
  return _.uniq(recordsData.map((el) => el.assignmentDetails.assignmentTitle));
};

export const groupAssignmentsByTitle = (recordsData) => {
  // remove internal records with zero totals
  const external = recordsData.filter((el) => el.pricing.clientTotal !== 0);
  // create group by assignment title
  const groupedObj = _.groupBy(
    external,
    (el) => el.assignmentDetails.assignmentTitle
  );
  const retVal = _.keys(groupedObj).map((assignmentTitle) => {
    const getSum = (field, nestedKey) => {
      return _.sum(
        groupedObj[assignmentTitle].map((el) =>
          nestedKey ? el[field][nestedKey] : el[field]
        )
      );
    };
    return {
      "Assignment Title": assignmentTitle,
      Quantity: getSum("quantity"),
      Total: numeral(getSum("pricing", "clientTotal")).format("$0.00"),
      Assignments: groupedObj[assignmentTitle].length,
      GEOS: _.uniq(
        groupedObj[assignmentTitle].map((el) => el.assignmentDetails.geo)
      ).sort(),
      POs: _.uniq(
        groupedObj[assignmentTitle].map((el) => el.pricing.purchaseOrderNumber)
      ).join(", "),
      actualTotal: getSum("pricing", "clientTotal"),
      Requester: _.uniq(
        groupedObj[assignmentTitle].map((el) => el.clientRequester)
      ).join(", "),
      Descriptions: _.uniq(
        groupedObj[assignmentTitle].map(
          (el) => el.assignmentDetails.assignmentDetail
        )
      ).join(" "),
    };
  });

  return retVal;
};
