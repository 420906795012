<template>
  <div class="d-flex flex-column comment mx-3 mb-6 rounded">
    <div
      class="d-flex justify-space-between comment__top font-weight-bold px-3 py-1"
    >
      <div class="comment__name">
        {{ comment.userEmail }}
      </div>
      <div class="comment__time">
        {{ formattedTimestamp }}
      </div>
    </div>
    <div class="comment__comment pa-3">
      {{ comment.data }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EditorSingleComment",
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedTimestamp() {
      return this.$moment(this.comment.timeStamp).format("MMM D YYYY, h:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  &__top {
    background-color: #3fa198bb;
    color: white;
    border-radius: 5px 5px 0px 0px;
  }
  &__comment {
    background-color: #3fa19822;
    border-radius: 0px 0px 5px 5px;
  }
}
</style>
