<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="id"
    group-by="client[name]"
    sort-by="activeDate"
    sort-desc
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >

  <!-- grouping override -->
    <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
      <td
        class="pa-0 group-header-row"
        :colspan="headers.length"
        @click="toggle"
      >
        <v-btn
          :ref="group"
          :data-open="isOpen"
          large
          icon
          class="ml-1 vertical-middle"
          @click.stop="toggle"
        >
          <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
        <h2 class="mr-4 font-weight-bold d-inline vertical-middle">
          {{ group }}
        </h2>
        <v-chip class="font-weight-bold white vertical-middle elevation-2">
          {{ getActiveOfAvailable(group) }}
        </v-chip>
      </td>
    </template>

    <!-- Billed cell override -->
    <template v-slot:[`item.activePurchaseOrderUsedPercentage`]="{ value }">
      <v-chip color="red" outlined v-if="value > 74">{{ value + " %" }}</v-chip>
      <span v-else>{{ value !== null ? value + " %" : "0 %"}}</span>
    </template>

    <!-- Remainig cell override -->
    <template v-slot:[`item.activePurchaseOrderRemaining`]="{ value, item }">
      <span>{{ value !== null ? remaining(value) : remaining(item.totalAmount) }}</span>
    </template>

    <!-- Active Date cell override -->
    <template v-slot:[`item.activeDate`]="{ value }">
      <span>{{ formatDateForTableRow(value) }}</span>
    </template>

    <!-- End Date cell override -->
    <template v-slot:[`item.endDate`]="{ value }">
      <span>{{ formatDateForTableRow(value) }}</span>
    </template>

    <!-- Is Active cell override -->
    <template v-slot:[`item.isActive`]="{ value }">
      <v-icon v-if="value">mdi-check</v-icon>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <PurchaseOrdersTableExpansion :poInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import PurchaseOrdersTableExpansion from "@/components/PurchaseOrders/PurchaseOrdersTableExpansion";

export default {
  name: "PurchaseOrdersTable",
  components: {
    PurchaseOrdersTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterClient: {
      type: String,
      required: false,
    },
    filterProject: {
      type: String,
      required: false,
    },
    filterProjectManager: {
      type: String,
      required: false,
    },
    filterActiveDate: {
      type: Array,
      required: false,
    },
    filterEndDate: {
      type: Array,
      required: false,
    },
    filterActive: {
      type: Boolean,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Client", value: "client[name]" },
        { text: "Project", value: "project[name]" },
        { text: "PO Number", value: "purchaseOrderNumber" },
        { text: "Remaining", value: "activePurchaseOrderRemaining" },
        { text: "Billed %", value: "activePurchaseOrderUsedPercentage" },
        { text: "Active Date", value: "activeDate" },
        { text: "End Date", value: "endDate" },
        { text: "Is Active", value: "isActive" },
        { text: "", value: "data-table-expand" },
      ],
      updated: 0,
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      if (this.tableData.length) {
        const fields = Object.entries(this.tableData[0]).reduce(
          (fields, entry) => {
            const nestedKey = entry[1]
              ? Object.keys(entry[1]).map(
                  (key) => `${entry[0]}${isNaN(key) ? `.${key}` : ""}`
                )
              : [];
            return [...fields, ...nestedKey];
          },
          []
        );
        return fields;
      } else {
        return [];
      }
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterClient) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.client?.name === this.filterClient
        );
      }

      if (this.filterProject) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.project?.name === this.filterProject
        );
      }

      if (this.filterProjectManager) {
        var nameParts = this.filterProjectManager.split(" ");
        var firstName = nameParts[0];
        var lastName = nameParts.slice(1).join(" ");
        dateFilteredData = dateFilteredData.filter(
          (el) => el.project?.projectManager?.firstName === firstName && el.project?.projectManager?.lastName === lastName
        );
      }

      if (this.filterActiveDate.length) {
        const startDate = this.$moment(this.filterActiveDate[0]),
          endDate = this.$moment(
            this.filterActiveDate[1]
              ? this.filterActiveDate[1]
              : this.filterActiveDate[0]
          );
        dateFilteredData = dateFilteredData.filter((el) => {
          return this.getDatesRange(startDate, endDate).includes(
            this.$moment(el.activeDate).format("MM-DD-YYYY")
          );
        });
      }

      if (this.filterEndDate.length) {
        const startDate = this.$moment(this.filterEndDate[0]),
          endDate = this.$moment(
            this.filterEndDate[1]
              ? this.filterEndDate[1]
              : this.filterEndDate[0]
          );
        dateFilteredData = dateFilteredData.filter((el) => {
          return this.getDatesRange(startDate, endDate).includes(
            this.$moment(el.endDate).format("MM-DD-YYYY")
          );
        });
      }

      if (this.filterActive) {
        dateFilteredData = dateFilteredData.filter((el) => el.isActive);
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    remaining(value) {
      const isNegative = value < 0;
      const formattedValue = Math.abs(value).toLocaleString();
      return (isNegative ? "-$" : "$") + formattedValue;
    },
    getDatesRange(start, end) {
      let datesRange = [];
      for (
        let dateVar = new Date(start);
        dateVar <= end;
        dateVar.setDate(dateVar.getDate() + 1)
      ) {
        datesRange.push(this.$moment(new Date(dateVar)).format("MM-DD-YYYY"));
      }
      return datesRange;
    },
    formatDateForTableRow(date) {
      return date ? this.$moment(date).format("MM-DD-YYYY") : "";
    },
    getActiveOfAvailable(group) {
      const active = this.tableData.filter((el) => el.client.name === group && el.isActive);
      const allPOs = this.tableData.filter(
        (el) => el.client.name === group
      )
      return `${active.length} Active | ${allPOs.length} All`;
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
}
.vertical-middle {
  vertical-align: middle;
}
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>