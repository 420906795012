export const dataFileHeaders = {
    assignmentTitle: "Assignment Title",
    projectManager: "Project Manager",
    client_requester: "Client Requester",
    deliveryDate: "Delivery Date",
    deliveryDue: "Delivery Due Time",
    editingDue: "Secondary Due Time",
    editingDueDate: "Secondary Due Date",
    editor: "Secondary",
    fileLocation: "File Location",
    geo: "GEO",
    media_type: "Media Type",
    payPeriod: "Pay Period",
    purchaseOrderId: "Purchase Order",
    relatedProject: "Project",
    requestTime: "Request Time",
    request_date: "Request Date",
    request_type: "Request Type",
    reviewer: "QC",
    reviewingDue: "QC Due Time",
    reviewingDueDate: "QC Due Date",
    source_geo: "Source GEO",
    status: "Assignment Status",
    task_id: "Task ID",
    task_name: "Project",
    word_count: "Word Count",
    writer: "Primary",
    writingDue: "Primary Due Time",
    writingDueDate: "Primary Due Date",
    link: "Link",
    emailSubjectLine: "E-mail Subject Line",
    contentId: "Content ID",
    assignmentId: "Assignment ID",
    assignmentDetail: "Assignment Detail",
    keyword: "Keyword",
    genre: "Genre",
    workType1: "Work Type 1",
    workType1Quantity: "Work Type 1 Quantity",
    workType2: "Work Type 2",
    workType2Quantity: "Work Type 2 Quantity",
    workType3: "Work Type 3",
    workType3Quantity: "Work Type 3 Quantity",
    workType4: "Work Type 4",
    workType4Quantity: "Work Type 4 Quantity",
    workType5: "Work Type 5",
    workType5Quantity: "Work Type 5 Quantity",
    workType6: "Work Type 6",
    workType6Quantity: "Work Type 6 Quantity",
    note: "Note"
};