<template>
  <div class="settings">
    <v-container
      fluid
      class="container-modifier">
      <v-row>
        <v-col cols="12">
          <v-alert
            text
            color="info"
          >
            <ul>
              <li>Use this section to define field values in CueHub</li>
              <li>Archived values will no longer be available for selection</li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <!-- Mas Data Upload
      <v-row>
        <v-col cols="8">
          <v-file-input
              ref="fileInput"
              class="file-input"
              chips
              label="Enums List file only"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              outlined
              v-model="geosFile"
          ></v-file-input>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" class="white--text" :loading="loading" @click="handleEnumsList">Upload</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-file-input
              ref="fileInput"
              class="file-input"
              chips
              label="Work Type in projects update"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              outlined
              v-model="geosFile"
          ></v-file-input>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" class="white--text" :loading="loading" @click="handleUpload">Upload</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-file-input
              ref="fileInput"
              class="file-input"
              chips
              label="GEOs in projects update"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              outlined
              v-model="geosFile"
          ></v-file-input>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" class="white--text" :loading="loading" @click="handleUpload1">Upload</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-file-input
              ref="fileInput"
              class="file-input"
              chips
              label="Request Types in projects update"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              outlined
              v-model="geosFile"
          ></v-file-input>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" class="white--text" :loading="loading" @click="handleUpload2">Upload</v-btn>
        </v-col>
      </v-row> -->
      <v-row class="mt-0">
        <v-col cols="3">
          <v-autocomplete
            :disabled="loading"
            :loading="loading"
            v-model="fieldName"
            :items="fieldNameValues"
            label="Field Name"
            solo
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-switch
            class="mt-0 mb-3"
            v-model="showArchiveSwitcher"
            label="Show archived values"
          ></v-switch>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            color="blue darken-2"
            class="white--text"
            :loading="loading"
            @click="openModal">
            <v-icon>mdi-plus</v-icon>
             Add
          </v-btn>
          <v-btn
            color="blue darken-2"
            class="white--text ml-1"
            :loading="loading"
            @click="openUnarchiveModal">
             Unarchive
          </v-btn>
          <v-btn
            color="#FF5252"
            class="white--text ml-1"
            :loading="loading"
            @click="openArchiveModal">
            Archive
          </v-btn>
          <v-btn
            color="#FF5252"
            class="white--text ml-1"
            :loading="loading"
            @click="openDeleteModal">
            <v-icon>mdi-delete</v-icon>
            delete
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <OptionsTable 
            @update:item="handleUpdate($event)"/>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      max-width="800"
      :value="modalOpen"
      :persistent="true"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
    >
      <NewFieldValue
        @cancel="closeModal"/>
    </v-dialog>
    <v-dialog
      max-width="800"
      :value="modalOpenConfirmation"
      :persistent="true"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
    >
    <v-card>
        <v-card-title class="text">
          Are you sure you want to {{ modalOpenConfirmation }} {{ fieldName }} value?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="mx-3 grey lighten-2"
            :loading="loading"
            @click="modalOpenConfirmation = ''">
            Cancel
          </v-btn>
          <v-btn
            v-if="modalOpenConfirmation === 'delete'"
            color="#FF5252"
            class="request-btn mx-3 white--text"
            :loading="loading"
            @click.prevent="handleDelete"
            >
            delete
          </v-btn>
          <v-btn
            v-else-if="modalOpenConfirmation === 'archive'"
            color="#FF5252"
            class="request-btn mx-3 white--text"
            :loading="loading"
            @click.prevent="handleArchive('ARCHIVED')"
            >
            archive
          </v-btn>
          <v-btn
            v-else-if="modalOpenConfirmation === 'unarchive'"
            color="#FF5252"
            class="request-btn mx-3 white--text"
            :loading="loading"
            @click.prevent="handleArchive('ACTIVE')"
            >
            unarchive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import { mapActions, mapGetters } from 'vuex';
import { removeEnum, getSpecificEnum, createNewEnum, updateEnum, updateProject1, updateProject, updateProject2, getAllProjects, getCustomerPriceList } from '@/utils/newDbUtils';
import OptionsTable from '@/components/Settings/OptionsTable';
import NewFieldValue from '@/components/Settings/NewFieldValue.vue';
export default {
  name: 'Settings',
  components: {
    OptionsTable,
    NewFieldValue
  },
  data() {
    return {
      modalOpen: false,
      modalOpenConfirmation: '',
      loading: false,
      geosFile: null,
      updateWindowWidth: 0
    }
  },
  computed: {
    ...mapGetters("settings", ["gGetFieldName", "gGetEnumsList", "gGetShowArchiveSwitcher"]),
    fieldNameValues() {
      return [
        "GEO",
        "Request Type",
        "Media Type",
        "Keyword",
        "Genre"
      ]
    },
    showArchiveSwitcher: {
      get() {
        return this.gGetShowArchiveSwitcher;
      },
      set(value) {
        this.aUpdateShowArchiveSwitcher(value);
      }
    },
    fieldName: {
      get() {
        return this.gGetFieldName;
      },
      async set(value) {
        this.aSetFieldName(value);
        if (value) {
          this.loading = true;
          const result = await getSpecificEnum(this.enumRequestType());
          this.aSetEnumsList(result.data);
          this.loading = false;
        }
      }
    }
  },
  methods: {
    ...mapActions("settings", ["aSetFieldName", "aSetEnumsList", "aSetDataToUpdate", "aSetNewField", "aUpdateShowArchiveSwitcher", "aClearNewField", "aSetNewFieldAutocomplete"]),
    ...mapActions("flashMessage", ["handleFlash"]),
    updateWindowWidthFunc() {
      this.updateWindowWidth = window.innerWidth;
    },
    /* async handleUpload() {
      let priceList = await getCustomerPriceList();
      let projects = await getAllProjects();
      const file = this.geosFile;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Process the json to create the desired array of objects
        let rows = json.slice(1).map((row, index) => {
          return {
            value: row[1],
            key: row[0] || undefined,
            displayOrder: index,
            status: "ACTIVE",
          };
        });
        console.log(rows);

        let result = {};
        let currentKey = null;

        rows.forEach(item => {
          if (item.key) {
            currentKey = item.key;
            if (!result[currentKey]) {
              result[currentKey] = [];
            }
            result[currentKey].push(item.value);
          } else if (currentKey) {
            result[currentKey].push(item.value);
          }
        });

        // Convert the result object to an array
        let finalArray = Object.keys(result).map(key => {
          return {
            key: key,
            value: result[key]
          };
        });

        console.log('Final array:', finalArray);
        let finalArray2 = finalArray.map(el => {
          return {
            projectId: projects.find(project => project.name === el.key).id,
            workTypes: el.value.map(workType => {
              console.log(workType, priceList.find(price => price.workTypeName === workType))
              return priceList.find(price => price.workTypeName === workType).id
            })
          }
        })

        console.log('Final array 2:', finalArray2);

        try {
          this.loading = true;
          const createPromises = finalArray2.map(row => updateProject({id: row.projectId, data: row.workTypes}));
          const results = await Promise.all(createPromises);
          console.log('All enums created:', results);
          this.loading = false;
        } catch (error) {
          console.error('Error creating enums:', error);
          this.loading = false;
        }
      };

      reader.readAsArrayBuffer(file);
    },

    async handleUpload1() {
      let geosList = await getSpecificEnum('geos');
      let priceList = geosList.data;
      let projects = await getAllProjects();
      const file = this.geosFile;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Process the json to create the desired array of objects
        let rows = json.slice(1).map((row, index) => {
          return {
            value: row[1],
            key: row[0] || undefined,
            displayOrder: index,
            status: "ACTIVE",
          };
        });
        console.log(priceList);

        let result = {};
        let currentKey = null;

        rows.forEach(item => {
          if (item.key) {
            currentKey = item.key;
            if (!result[currentKey]) {
              result[currentKey] = [];
            }
            result[currentKey].push(item.value);
          } else if (currentKey) {
            result[currentKey].push(item.value);
          }
        });

        // Convert the result object to an array
        let finalArray = Object.keys(result).map(key => {
          return {
            key: key,
            value: result[key]
          };
        });
        console.log('priceList', priceList);
        console.log('Final array:', finalArray);
        let finalArray2 = finalArray.map(el => {
          return {
            projectId: projects.find(project => project.name === el.key).id,
            workTypes: el.value.map(workType => {
              console.log(workType, priceList.find(price => price.key === workType))
              return priceList.find(price => price.key === workType).id
            })
          }
        })

        console.log('Final array 2:', finalArray2);

        try {
          this.loading = true;
          const createPromises = finalArray2.map(row => updateProject1({id: row.projectId, data: row.workTypes}));
          const results = await Promise.all(createPromises);
          console.log('All enums created:', results);
          this.loading = false;
        } catch (error) {
          console.error('Error creating enums:', error);
          this.loading = false;
        }
      };

      reader.readAsArrayBuffer(file);
    },
    async handleUpload2() {
      let geosList = await getSpecificEnum('request-types');
      let priceList = geosList.data;
      let projects = await getAllProjects();
      const file = this.geosFile;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Process the json to create the desired array of objects
        let rows = json.slice(1).map((row, index) => {
          return {
            value: row[1],
            key: row[0] || undefined,
            displayOrder: index,
            status: "ACTIVE",
          };
        });
        console.log(priceList);

        let result = {};
        let currentKey = null;

        rows.forEach(item => {
          if (item.key) {
            currentKey = item.key;
            if (!result[currentKey]) {
              result[currentKey] = [];
            }
            result[currentKey].push(item.value);
          } else if (currentKey) {
            result[currentKey].push(item.value);
          }
        });

        // Convert the result object to an array
        let finalArray = Object.keys(result).map(key => {
          return {
            key: key,
            value: result[key]
          };
        });
        console.log('priceList', priceList);
        console.log('Final array:', finalArray);
        let finalArray2 = finalArray.map(el => {
          return {
            projectId: projects.find(project => project.name === el.key).id,
            workTypes: el.value.map(workType => {
              console.log(workType, priceList.find(price => price.key === workType))
              return priceList.find(price => price.key === workType).id
            })
          }
        })

        console.log('Final array 2:', finalArray2);

        try {
          this.loading = true;
          const createPromises = finalArray2.map(row => updateProject2({id: row.projectId, data: row.workTypes}));
          const results = await Promise.all(createPromises);
          console.log('All enums created:', results);
          this.loading = false;
        } catch (error) {
          console.error('Error creating enums:', error);
          this.loading = false;
        }
      };

      reader.readAsArrayBuffer(file);
    },

    handleEnumsList() {
      console.log(this.geosFile);
      const file = this.geosFile;
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Process the json to create the desired array of objects
        let count = 0;
        let rows = json.slice(1).map((row, index) => {
          let item = {
            value: row[1],
            key: row[0],
            displayOrder: index,
            status: "ACTIVE",
          }
          count++;
          return item;
        });
        console.log(rows);
        try {
          this.loading = true;
          const createPromises = rows.map(row => createNewEnum({type: this.enumRequestType(), data: row}));
          const results = await Promise.all(createPromises);
          console.log('All enums created:', results);
          this.loading = false;
        } catch (error) {
          console.error('Error creating enums:', error);
          this.loading = false;
        }
      };

      reader.readAsArrayBuffer(file);
    }, */
    openModal() {
      if (!this.fieldName) {
        this.handleFlash({
          response: { 
            status: 400,
            data: {
              message: "Please select a field name first.",
            }
          },
          show: true,
        });
        return;
      }
      this.modalOpen = true
    },
    closeModal() {
      this.aClearNewField();
      this.aSetDataToUpdate(false);
      this.modalOpen = false
    },
    enumRequestType() {
      switch (this.gGetFieldName) {
        case "GEO":
          return "geos";
        case "Request Type":
          return "request-types";
        case "Media Type":
          return "media-types";
        case "Keyword":
          return "keywords";
        case "Genre":
          return "genre";
        default:
          return null;
      }
    },
    handleUpdate(item) {
      this.aSetDataToUpdate(true);
      this.aSetNewField({key: "key", value: item.key});
      this.aSetNewField({key: "value", value: item.value});
      this.aSetNewField({key: "displayOrder", value: item.displayOrder});
      this.aSetNewField({key: "id", value: item.id});
      this.aSetNewField({key: "status", value: item.status});
      if (item.genres && item.genres.length) {
        this.aSetNewFieldAutocomplete(item.genres);
      } else {
        this.aSetNewFieldAutocomplete([]);
      }
      this.modalOpen = true;
    },
    openDeleteModal() {
      this.modalOpenConfirmation = "delete";
    },
    openArchiveModal() {
      this.modalOpenConfirmation = "archive";
    },
    openUnarchiveModal() {
      this.modalOpenConfirmation = "unarchive";
    },
    async handleArchive(archiveType) {
      const checkedItems = this.gGetEnumsList.filter(item => item.checked);
      if (!checkedItems.length) {
        this.handleFlash({
          response: { 
            status: 400,
            data: {
              message: "Please select at least one item to archive.",
            }
          },
          show: true,
        });
        this.modalOpenConfirmation = '';
        return;
      }
      try {
        this.loading = true;
        const fieldType = this.enumRequestType();
        const archivePromises = checkedItems.map(item => {
          delete item.checked;
          return updateEnum(
            {
              type: fieldType,
              id: item.id,
              data: {
                ...item,
                status: archiveType,
              }
            }
        )});
        await Promise.all(archivePromises)
          .then(async (res) => {
            const result = await getSpecificEnum(this.enumRequestType());
            this.aSetEnumsList(result.data);
            console.log(res);
            this.handleFlash({ response: { status: 200 }, show: true });
            this.loading = false;
            this.modalOpenConfirmation = '';
          })
      } catch (error) {
        console.log(error);
      }
    },
    async handleDelete() {
      const itemsToRemove = this.gGetEnumsList.filter(item => item.checked);
      if (!itemsToRemove.length) {
        this.handleFlash({
          response: { 
            status: 400,
            data: {
              message: "Please select at least one item to delete.",
            }
          },
          show: true,
        });
        this.modalOpenConfirmation = '';
        return;
      }
      this.loading = true;
      const fieldType = this.enumRequestType();
      const deletePromises = itemsToRemove.map(item => removeEnum({type: fieldType, id: item.id}));
      try {
        await Promise.all(deletePromises)
          .then(async (res) => {
            const result = await getSpecificEnum(this.enumRequestType());
            this.aSetEnumsList(result.data);
            this.handleFlash({ response: res[0], show: true });
            this.loading = false;
            this.modalOpenConfirmation = '';
          })
      } catch (error) {
        console.error('Error deleting items:', error);
        this.loading = false;
      }
    }
  },
  async mounted() {
    this.aSetFieldName("GEO");
    const result = await getSpecificEnum("geos");
    this.aSetEnumsList(result.data);
    window.addEventListener('resize', this.updateWindowWidthFunc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidthFunc);
  }
}
</script>

<style lang="scss" scoped>
.container-modifier {
  max-width: 95%;
}
</style>