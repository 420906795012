<template>
  <a :href="selectedProject.styleGuideLink" target="_blank"
    >Go to style guide</a
  >
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// library
// internal
// components
export default {
  name: "EditorStyleGuide",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("editor", ["selectedProject"]),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
