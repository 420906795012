// imports
import { saveAs } from "file-saver";
import {
  sourceFileHeaders,
  shakespeareHeaders,
  shakespeareLocHeaders,
  cueHeaders,
} from "@/utils/spreadsheetUploadConstants";
import store from "@/store/index";
import _ from "lodash";
import moment from "moment";
import { getOauthToken, getAccessToken, getRefreshToken } from "./newDbUtils";
const Excel = require("exceljs");
const JSZip = require("jszip");
const flatten = require("flat");

// constants
export const iTunesWorkTypeReference = {
  "en-US": {
    "cs-CZ": "LOC_08-03-16",
    "el-GR": "LOC_09-02-17",
    "it-IT": "LOC_09-02-18",
    "nl-NL": "LOC_12-02-23",
    "pt-PT": "LOC_09-02-17",
    "ru-RU": "LOC_07-02-14",
    "uk-UA": "LOC_09-02-16",
    "es-US": "LOC_09-02-17",
    "es-ES": "LOC_09-02-16",
    "pl-PL": "LOC_08-01-16",
    "es-MX": "LOC_09-02-16",
    "de-DE": "LOC_13-02-25",
    "de-CH": "LOC_13-02-23",
    "ar-SA": "LOC_09-03-19",
    "ja-JP": "LOC_15-05-30",
    "ko-KR": "LOC_12-06-27",
    "hu-HU": "LOC_09-03-16",
  },
  "en-GB": {
    "cs-CZ": "LOC_08-03-16",
    "el-GR": "LOC_09-02-17",
    "it-IT": "LOC_09-02-18",
    "nl-NL": "LOC_12-02-23",
    "pt-PT": "LOC_09-02-17",
    "ru-RU": "LOC_07-02-14",
    "uk-UA": "LOC_09-02-16",
    "es-US": "LOC_09-02-17",
    "es-ES": "LOC_09-02-16",
    "pl-PL": "LOC_08-01-16",
    "es-MX": "LOC_09-02-16",
    "de-DE": "LOC_13-02-25",
    "de-CH": "LOC_13-02-23",
    "ar-SA": "LOC_09-03-19",
    "ja-JP": "LOC_15-05-30",
    "ko-KR": "LOC_12-06-27",
    "hu-HU": "LOC_09-03-16",
  },
  "ko-KR": {
    "en-US": "LOC_13-02-26",
    "en-GB": "LOC_13-02-26",
  },
};

////////////////////////////
// assignment utilities
////////////////////////////
export const isAdaptation = ({ locale, allLocales, getSourceLocale, sourceGeo, requestType, tableRows, shakespeare }) => {
  const adaptionPairs = {
    // "es-MX": "es-ES", // TURNING THIS OFF PER INSTRUCTIONS
    "de-CH": "de-DE",
    "en-AU": "en-US",
    "en-GB": "en-US",
    "en-CA": "en-US",
  };

  if (shakespeare) {
    if (locale === "de-CH" && allLocales?.includes("de-DE")) {
      if (tableRows.find((row) => row.geo === "de-CH").content_id === tableRows.find((row) => row.geo === "de-DE").content_id) {
        return true;
      } else {
        return false;
      }
    }
  }

  const usAdaptationTasks = ["en-GB", "en-CA", "en-AU"]

  const sourceLang = adaptionPairs[locale];
  if (requestType === "Copywriting" && locale === "de-CH" && sourceGeo === "en-US" && allLocales.includes("de-DE")) return true;
  if (requestType === "Localization" && locale === "de-CH" && sourceGeo === "en-US") return false;
  if (getSourceLocale) return sourceLang ?? null;
  else if(sourceGeo === "en-US" && usAdaptationTasks.includes(locale) || 
    ((sourceGeo === "en-US" || sourceGeo === "en-GB" && locale === "de-CH" && allLocales.includes("de-DE")) && usAdaptationTasks.includes(locale) && allLocales && allLocales.filter(item => usAdaptationTasks.includes(item)).length >= 2))
    return true
  else if ((sourceGeo === "en-US" || sourceGeo === "en-GB" && locale === "de-CH" && allLocales.includes("de-DE")) && Object.keys(adaptionPairs).includes(locale) && requestType !== "Localization")
    return allLocales?.includes(sourceLang);
  else return false;
};

export const getFCWorkType = ({
  workType,
  workType1,
  locale,
  sourceLocale,
  allLocales,
  localizationMode,
  adaptationRequest,
  sourceGeo,
  shakespeare,
  tableRows,
  requestType
}) => {
  const ADAPTION_WORK_TYPES = {
    "de-CH": "ADP_04-00-09",
    "en-AU": "ADP_04-00-06",
    "en-GB": "ADP_04-00-06",
    "en-CA": "ADP_04-00-06",
  };
  
  if (shakespeare && isAdaptation({
    locale,
    allLocales,
    sourceGeo,
    requestType,
    tableRows,
    shakespeare
  })) {
    return ADAPTION_WORK_TYPES[locale];
  }

  if (localizationMode) {
    if (locale === "en-US" || locale === "en-GB") return "LOC_13-02-26";
    return iTunesWorkTypeReference[sourceLocale || "en-US"][locale];
  } else {
    return isAdaptation({ locale, allLocales, sourceGeo }) || adaptationRequest
      ? workType1
        ? ADAPTION_WORK_TYPES[locale]
        : null
      : workType;
  }
};

export const getFCWordCount = ({ wordCount, locale, allLocales }) => {
  if (isAdaptation({ locale, allLocales })) {
    return null;
  } else {
    return wordCount;
  }
};

export const getFCRequestType = ({ locale, allLocales, sourceGeo, requestType, tableRows, shakespeare }) => {
  if (shakespeare) {
    if (locale === "de-CH" && allLocales.includes("de-DE")) {
      if (tableRows.find((row) => row.geo === "de-CH").content_id === tableRows.find((row) => row.geo === "de-DE").content_id) {
        return "Adaptation";
      } else {
        return requestType;
      }
    }
  }
  if (isAdaptation({ locale, allLocales, sourceGeo, requestType })) {
    return "Adaptation";
  } else if (requestType === "Localization") {
    return "Localization";
  } else {
    return "Copywriting";
  }
};

export const getFCWriterDate = ({
  locale,
  allLocales,
  writerDate,
  adaptationDate,
}) => {
  if (isAdaptation({ locale, allLocales })) {
    return adaptationDate;
  } else {
    return writerDate;
  }
};

export const getFCWriterTime = ({
  locale,
  allLocales,
  writerTime,
  adaptationTime,
}) => {
  if (isAdaptation({ locale, allLocales })) {
    return adaptationTime;
  } else {
    return writerTime;
  }
};

////////////////////////////
// handle input file parsing
////////////////////////////
export const getRecords = (worksheet) => {
  const recordsArray = [];
  store._actions["spreadsheetUpload/setShakespeareLoc"][0](
    worksheet._rows[0].values.includes("Name Ref.")
  );
  store._actions["spreadsheetUpload/setShakespeare"][0](
    worksheet._rows[0].values.includes("WORKFLOW_ID")
  );
  store._actions["spreadsheetUpload/setCueCopywriting"][0](
    worksheet._rows[0].values.includes("QC") &&
      (worksheet._rows[1].values.includes("Copywriting") ||
        worksheet._rows[1].values.includes("Metadata"))
  );
  store._actions["spreadsheetUpload/setCopywriting"][0](
    worksheet._rows[1].values.includes("Copywriting") &&
      !store.getters["spreadsheetUpload/shakespeare"] &&
      !store.getters["spreadsheetUpload/cueCopywriting"]
  );
  store._actions["spreadsheetUpload/setCueLoc"][0](
    worksheet._rows[0].values.includes("QC") &&
      worksheet._rows[1].values.includes("Localization")
  );
  try {
    let headers = worksheet.getRow(1).values,
      vendorIndex;
    const headerLookup = _.invert(
      store.getters["spreadsheetUpload/shakespeare"]
        ? shakespeareHeaders
        : store.getters["spreadsheetUpload/shakespeareLoc"]
        ? shakespeareLocHeaders
        : store.getters["spreadsheetUpload/cueLoc"] ||
          store.getters["spreadsheetUpload/cueCopywriting"]
        ? cueHeaders
        : sourceFileHeaders
    );
    headers = headers.map((el) => headerLookup[el]);

    window.ws = worksheet;

    worksheet.eachRow((row, i) => {
      const rowValues = row.values;
      if (rowValues.includes("Vendor"))
        vendorIndex = rowValues.indexOf("Vendor");
      // skip first row
      if (i === 1) return;

      const newRecord = {};
      if (vendorIndex) {
        if (rowValues[vendorIndex] === "Company Cue") {
          headers.forEach((header, j) => {
            if (header) {
              newRecord[header] = rowValues[j];
            }
          });
        } else {
          return;
        }
      } else {
        headers.forEach((header, j) => {
          if (header) {
            newRecord[header] = rowValues[j];
          }
        });
      }
      recordsArray.push(newRecord);
    });
    return recordsArray;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUniqueRowValue = (rows, key) => {
  return _.uniq(rows.map((el) => el[key]));
};

export const getSourceFileTableRows = (records) => {
  const cueDataParser = (row, isLoc) => {
    let formKeys = [
      "relatedProject",
      "po",
      "requestTime",
      "fileLocation",
      "writingDueDate",
      "writingDue",
      "editingDueDate",
      "editingDue",
      "reviewingDueDate",
      "reviewingDue",
      "deliveryDate",
      "deliveryDue",
      "payPeriod",
    ];
    if (isLoc) formKeys.push("workType");
    formKeys.forEach((key) =>
      store._actions["spreadsheetUpload/setFormValue"][0]({
        key,
        value: row[key]
          ? key.endsWith("Date")
            ? moment(row[key]).format("YYYY-MM-DD")
            : key.endsWith("Due") || key.endsWith("Time")
            ? moment(row[key], "h:mm A").format("hh:mm A")
            : key === "payPeriod"
            ? moment(row[key]).format("YYYY-MM")
            : key === "fileLocation" && row[key].hasOwnProperty("hyperlink")
            ? row[key].text
            : row[key]
          : null,
      })
    );
  };
  if (
    store.getters["spreadsheetUpload/shakespeare"] ||
    store.getters["spreadsheetUpload/shakespeareLoc"]
  )
    store._actions["spreadsheetUpload/setFormValue"][0]({
      key: "fileLocation",
      value: "https://wrkflow.app/",
    });
  if (
    store.getters["spreadsheetUpload/shakespeare"] ||
    store.getters["spreadsheetUpload/cueCopywriting"]
  ) {
    if (store.getters["spreadsheetUpload/cueCopywriting"]) {
      cueDataParser(records[0], false);
      store._actions["spreadsheetUpload/setFormValue"][0]({
        key: "assignmentTitle",
        value: records[0].assignmentTitle,
      });
    } else {
      store._actions["spreadsheetUpload/setFormValue"][0]({
        key: "emailSubjectLine",
        value: "Assignment Detail",
      });
      store._actions["spreadsheetUpload/setFormValue"][0]({
        key: "relatedProject",
        value: "iTunes FC",
      });
    }
    return records.map((task) =>
      store.getters["spreadsheetUpload/cueCopywriting"]
        ? {
            task_name: String(task.task_name),
            geo: task.geo,
            client_requester: task.client_requester,
            request_type: task.request_type,
            media_type: task.media_type,
            request_date: task.request_date,
            task_id: task.task_id ? task.task_id : String(task.task_name), // define ID anyway to preserve Shakespeare data format
            writer: task.writer,
            editor: task.editor,
            reviewer: task.reviewer,
            workType: task.workType,
            quantity: task.quantity,
            status: task.status,
          }
        : {
            task_name: String(task.task_name),
            geo: task.geo,
            keyword: "Apple Music",
            request_type: "Copywriting",
            request_date: new Date(
              new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            ),
            task_id: task.task_id,
            content_id: task.content_id,
            video_content_id: task.video_content_id,
            writer: task.writer,
          }
    );
  } else if (store.getters["spreadsheetUpload/shakespeareLoc"]) {
    const groupedByTaskName = _.groupBy(records, (el) => el.task_id);
    return Object.keys(groupedByTaskName).map((task_name) => {
      const groupedRows = groupedByTaskName[task_name];
      const firstRow = groupedRows[0];
      store.getters["spreadsheetUpload/shakespeareLoc"]
        ? store._actions["spreadsheetUpload/setFormValue"][0]({
            key: "clientRequester",
            value: "Shakespeare",
          })
        : store._actions["spreadsheetUpload/setFormValue"][0]({
            key: "note",
            value: firstRow["note"] ?? null,
          });
      if (store.getters["spreadsheetUpload/cueLoc"])
        cueDataParser(firstRow, true);
      else
        store._actions["spreadsheetUpload/setFormValue"][0]({
          key: "relatedProject",
          value: "iTunes FC",
        });
      const retVal = {
        true_task_name: firstRow["task_name"],
        task_name: groupedRows
          .filter((el) => !!el.geo_divider)
          .map((el) => ({ geo: el.geo, writer: el.writer }))[0]?.geo,
        source_geo: firstRow["source_geo"] ?? null,
        geos: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? groupedRows
              .filter((el) => !!el.geo_divider)
              .map((el) => ({ geo: el.geo, writer: el.writer }))
          : store.getters["spreadsheetUpload/cueLoc"]
          ? groupedRows.map((el) => ({
              geo: el.geo,
              writer: el.writer,
              editor: el.editor,
              reviewer: el.reviewer,
            }))
          : getUniqueRowValue(groupedRows, "geo"),
        subject_line: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? firstRow["task_name"]
          : firstRow["subject_line"] ?? null,
        word_count: Number(firstRow["word_count"]),
        assignmentTitle: firstRow["assignmentTitle"],
        media_type: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? "Apps"
          : firstRow["media_type"],
        keyword: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? "MAS"
          : firstRow["keyword"] ?? null,
        request_type: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? "Localization"
          : firstRow["request_type"],
        request_date: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? new Date(
              new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            )
          : moment(firstRow["request_date"]).format("MM-DD-YYYY"),
        task_id: firstRow["task_id"],
        link: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? null
          : firstRow["link"] ?? null,
      };
      Object.assign(
        retVal,
        store.getters["spreadsheetUpload/shakespeareLoc"]
          ? {
              content_id: firstRow["content_id"],
            }
          : {
              client_requester: firstRow["client_requester"],
              due_date:
                moment(
                  firstRow["due_date"]?.toLocaleString("en-US", {
                    timeZone: "Europe/Belfast",
                  })
                ).format("MM-DD-YYYY") ?? null,
              assignment_ids: store.getters["spreadsheetUpload/cueLoc"]
                ? null
                : getUniqueRowValue(groupedRows, "assignment_id"),
            }
      );
      return retVal;
    });
  } else {
    const groupedByTaskName = _.groupBy(records, (el) => el.task_name);
    return Object.keys(groupedByTaskName).map((task_name) => {
      const groupedRows = groupedByTaskName[task_name];
      const firstRow = groupedRows[0];
      store.getters["spreadsheetUpload/shakespeareLoc"]
        ? store._actions["spreadsheetUpload/setFormValue"][0]({
            key: "clientRequester",
            value: "Shakespeare",
          })
        : store._actions["spreadsheetUpload/setFormValue"][0]({
            key: "note",
            value: firstRow["note"] ?? null,
          });
      if (store.getters["spreadsheetUpload/cueLoc"])
        cueDataParser(firstRow, true);
      else
        store._actions["spreadsheetUpload/setFormValue"][0]({
          key: "relatedProject",
          value: "iTunes FC",
        });
      const retVal = {
        task_name: String(task_name),
        source_geo: firstRow["source_geo"] ?? null,
        geos: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? groupedRows
              .filter((el) => !!el.geo_divider)
              .map((el) => ({ geo: el.geo, writer: el.writer }))
          : store.getters["spreadsheetUpload/cueLoc"]
          ? groupedRows.map((el) => ({
              geo: el.geo,
              writer: el.writer,
              editor: el.editor,
              reviewer: el.reviewer,
            }))
          : getUniqueRowValue(groupedRows, "geo"),
        subject_line: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? task_name
          : firstRow["subject_line"] ?? null,
        word_count: Number(firstRow["word_count"]),
        assignmentTitle: firstRow["assignmentTitle"],
        media_type: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? "Apps"
          : firstRow["media_type"],
        keyword: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? "MAS"
          : firstRow["keyword"] ?? null,
        request_type: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? "Localization"
          : firstRow["request_type"],
        request_date: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? new Date(
              new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            )
          : moment(firstRow["request_date"]).format("MM-DD-YYYY"),
        task_id: firstRow["task_id"],
        link: store.getters["spreadsheetUpload/shakespeareLoc"]
          ? null
          : firstRow["link"] ?? null,
        shakespeare_link: records[0].shakespeare_link 
          ? records.map((el) =>{
            return {
              task_name: el.task_name,
              geo: el.geo,
              shakespeare_link: el.shakespeare_link
            }
          })
          : null
      };
      Object.assign(
        retVal,
        store.getters["spreadsheetUpload/shakespeareLoc"]
          ? {
              content_id: firstRow["content_id"],
            }
          : {
              client_requester: firstRow["client_requester"],
              due_date:
                moment(
                  firstRow["due_date"]?.toLocaleString("en-US", {
                    timeZone: "Europe/Belfast",
                  })
                ).format("MM-DD-YYYY") ?? null,
              assignment_ids: store.getters["spreadsheetUpload/cueLoc"]
                ? null
                : getUniqueRowValue(groupedRows, "assignment_id"),
            }
      );
      return retVal;
    });
  }
};

export const getTableDataFromSource = async (file) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix
  const records = getRecords(sheet);
  if (records.length) {
    records.forEach((record) => {
      if (record && record.writer && record.writer.includes(" (EV)")) {
        record.writer = record.writer.replace(" (EV)", "");
      }
    })
  }
  if (!records) {
    console.error("error in getRecords from source file");
    // handle error of bad source file processing
  }
  const sourceFileTableRows = getSourceFileTableRows(records);
  return sourceFileTableRows;
};

export const getStaffMapFromRows = (rows) => {
  const retVal = {};

  if (store.getters["spreadsheetUpload/shakespeareLoc"]) {
    rows.forEach((row) => {
      if (row.true_task_name) {
        row.task_name = row.true_task_name;
      }
    });
  }

  rows.forEach((row) => {
    const subRetVal = {},
      specailGeosRefs = {
        workType1Reference: {
          default: "CWR_500-60-1100",
          "ar-SA": "CWR_500-60-1100",
          "pt-PT": "CWR_500-60-1100",
          "ru-RU": "CWR_500-60-1100",
          "uk-UA": "CWR_500-60-1100",
          "es-ES": "CWR_500-60-1100",
          "es-MX": "CWR_500-60-1100",
          "it-IT": "CWR_500-60-1100",
          "nl-NL": "CWR_500-60-1100",
          "de-DE": "CWR_500-60-1100",
        },
        workType2Reference: {
          default: "CWR_1000-120-1500",
          "ar-SA": "CWR_1000-120-1500",
          "pt-PT": "CWR_1000-120-1500",
          "ru-RU": "CWR_1000-120-1500",
          "uk-UA": "CWR_1000-120-1500",
          "es-ES": "CWR_1000-120-1500",
          "es-MX": "CWR_1000-120-1500",
          "it-IT": "CWR_1000-120-1500",
          "nl-NL": "CWR_1000-120-1500",
          "de-DE": "CWR_1000-120-1500",
        },
        workType3Reference: {
          default: "CWR_1500-180-2800",
          "ar-SA": null,
          "pt-PT": null,
          "ru-RU": null,
          "uk-UA": null,
          "es-ES": null,
          "es-MX": null,
          "it-IT": null,
          "nl-NL": null,
          "de-DE": null,
        },
        workType4Reference: {
          default: "CWR_2500-300-3800",
          "ar-SA": null,
          "pt-PT": null,
          "ru-RU": null,
          "uk-UA": null,
          "es-ES": null,
          "es-MX": null,
          "it-IT": null,
          "nl-NL": null,
          "de-DE": null,
        },
        workType5Reference: {
          default: "CWR_5000-500-7000",
          "ar-SA": null,
          "pt-PT": null,
          "ru-RU": null,
          "uk-UA": null,
          "es-ES": "CWR_5000-500-7000",
          "es-MX": "CWR_5000-500-7000",
          "it-IT": "CWR_5000-500-7000",
          "nl-NL": "CWR_5000-500-7000",
          "de-DE": "CWR_5000-500-7000",
        },
        workType6Reference: {
          default: "CWR_300-100-500",
          "ar-SA": null,
          "pt-PT": null,
          "ru-RU": null,
          "uk-UA": null,
          "es-ES": null,
          "es-MX": null,
          "it-IT": null,
          "nl-NL": null,
          "de-DE": null,
        },
      },
      handleWorkTypeRef = (ref, geo) => {
        return specailGeosRefs[ref]?.[geo] ?? specailGeosRefs[ref].default;
      };

    if (
      store.getters["spreadsheetUpload/shakespeare"] ||
      store.getters["spreadsheetUpload/cueCopywriting"]
    ) {
      subRetVal[row.geo] = {
        writer: row.writer,
        editor: store.getters["spreadsheetUpload/shakespeare"]
          ? null
          : row.editor,
        reviewer: store.getters["spreadsheetUpload/shakespeare"]
          ? null
          : row.reviewer,
        writerOverloaded: false,
        editorOverloaded: false,
        reviewerOverloaded: false,
        workType1Reference: store.getters["spreadsheetUpload/shakespeare"]
          ? handleWorkTypeRef("workType1Reference", row.geo)
          : row.workType,
        workType2Reference: store.getters["spreadsheetUpload/shakespeare"]
          ? handleWorkTypeRef("workType2Reference", row.geo)
          : null,
        workType3Reference: store.getters["spreadsheetUpload/shakespeare"]
          ? handleWorkTypeRef("workType3Reference", row.geo)
          : null,
        workType4Reference: store.getters["spreadsheetUpload/shakespeare"]
          ? handleWorkTypeRef("workType4Reference", row.geo)
          : null,
        workType5Reference: store.getters["spreadsheetUpload/shakespeare"]
          ? handleWorkTypeRef("workType5Reference", row.geo)
          : null,
        workType6Reference: store.getters["spreadsheetUpload/shakespeare"]
          ? handleWorkTypeRef("workType6Reference", row.geo)
          : null,
        workType1Quantity: store.getters["spreadsheetUpload/shakespeare"]
          ? null
          : row.quantity,
        workType2Quantity: null,
        workType3Quantity: null,
        workType4Quantity: null,
        workType5Quantity: null,
        workType6Quantity: null,
      };
      subRetVal["content_id"] = row["content_id"];
      subRetVal["task_name"] = row["task_name"];

      retVal[row.task_id] = subRetVal;
    } else {
      subRetVal["wordCount"] = row["word_count"];
      row.geos.forEach((geo) => {
        const index = row.geos.indexOf(geo);
        subRetVal[
          store.getters["spreadsheetUpload/shakespeareLoc"] ||
          store.getters["spreadsheetUpload/cueLoc"]
            ? geo.geo
            : geo
        ] = {
          writer:
            store.getters["spreadsheetUpload/shakespeareLoc"] ||
            store.getters["spreadsheetUpload/cueLoc"]
              ? geo.writer
              : null,
          editor: store.getters["spreadsheetUpload/cueLoc"] ? geo.editor : null,
          reviewer: store.getters["spreadsheetUpload/cueLoc"]
            ? geo.reviewer
            : null,
          writerOverloaded: false,
          editorOverloaded: false,
          reviewerOverloaded: false,
          assignmentId:
            store.getters["spreadsheetUpload/shakespeareLoc"] ||
            store.getters["spreadsheetUpload/cueLoc"]
              ? null
              : row.assignment_ids[index],
        };
        if (store.getters["spreadsheetUpload/backstageCopywriting"])
          Object.assign(subRetVal[geo], {
            workType1Reference: handleWorkTypeRef(
              "workType1Reference",
              row.geo
            ),
            workType2Reference: handleWorkTypeRef(
              "workType2Reference",
              row.geo
            ),
            workType3Reference: handleWorkTypeRef(
              "workType3Reference",
              row.geo
            ),
            workType4Reference: handleWorkTypeRef(
              "workType4Reference",
              row.geo
            ),
            workType5Reference: handleWorkTypeRef(
              "workType5Reference",
              row.geo
            ),
            workType6Reference: handleWorkTypeRef(
              "workType6Reference",
              row.geo
            ),
            workType1Quantity: null,
            workType2Quantity: null,
            workType3Quantity: null,
            workType4Quantity: null,
            workType5Quantity: null,
            workType6Quantity: null,
          });
      });
      if (!store.getters["spreadsheetUpload/backstageCopywriting"]) {
        subRetVal["wordCount"] = row["word_count"];
        if (store.getters["spreadsheetUpload/cueLoc"])
          subRetVal["assignmentTitle"] = row["assignmentTitle"];
      }

      if (store.getters["spreadsheetUpload/shakespeareLoc"]) {
        retVal[row.task_name] = Object.assign(
          retVal[row.task_name] || {},
          subRetVal
        )
      } else {
        retVal[row.task_name] = subRetVal;
      }
    }
  });

  return retVal;
};

export const getHeaderRowsFromFile = async (file) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix
  const firstRow = sheet.getRow(1);
  return firstRow.values;
};

export const getGroupsOnCustomColumns = async ({ file, columns }) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix

  // get addresses of column headers
  const columnAddresses = [];
  columns.forEach((columnLabel) => {
    const address = sheet.model.rows[0].cells.find(
      (cell) => cell.value === columnLabel
    ).address;

    // extract just letters from string
    const addressLetters = address.match(/[a-zA-Z]+/g)[0];
    columnAddresses.push(addressLetters);
  });

  // get all the values in each of the column address columns
  const columnValues = columnAddresses.map((address) => {
    return sheet.getColumn(address).values;
  });

  // use lodash to zip all of the arrays in columnValues together
  const zippedColumnValues = _.zip(...columnValues);

  // get list of unique arrays in zippedColumnValues
  const uniqueColumnValues = _.uniqWith(zippedColumnValues, _.isEqual);

  // first row is undefined and second is header, remove them
  const uniqueColumnValuesWithoutHeaders = uniqueColumnValues.slice(2);

  // key values in uniqueColumnValuesWithoutHeaders by values in columnAddresses
  const uniqueColumnValuesWithKeys = uniqueColumnValuesWithoutHeaders.map(
    (row, i) => {
      const retVal = {};
      columnAddresses.forEach((columnLabel, j) => {
        retVal[columnLabel] = row[j];
      });
      return retVal;
    }
  );

  return uniqueColumnValuesWithKeys;
};

export const doSplitOnCustomGroups = async ({ file, groupings }) => {
  const filePromises = groupings.map((grouping) => {
    // remove extension from file.name string and split it to process
    let originalFileName = file.name.split(".")[0].split("_");
    // separate ID to insert GEO before it
    const id = originalFileName.splice(-1);

    const fileName = `${originalFileName.join("_")}_${createFileNameFromValues(
      Object.values(grouping)
    )}_${id}`;
    return createFilteredFile({ file, filters: grouping, fileName });
  });

  await Promise.all(filePromises).then((files) => {
    createSingleLevelZipDownload(files);
  });
};

////////////////////////////
// file splitting
////////////////////////////
const removeXLSX = (excelFileName) => {
  return excelFileName.split(".xlsx")[0];
};

export const doFileSplit = (files, sourceMap) => {
  const allPromises = [];

  sourceMap.forEach((task) => {
    task.geos.forEach((taskGeo) => {
      const file = files.find((el) => removeXLSX(el.name) === task.task_name);
      allPromises.push(
        createGeoFilteredFile(file, taskGeo).then((f) => {
          return f;
        })
      );
    });
  });

  Promise.all(allPromises).then((values) => {
    createZipDownload(values);
  });
};

export const getStaffAssignmentsByPerson = (staffAssignments, onlyWriters) => {
  const transformed = flatten(staffAssignments, { delimiter: ";" });
  let assignmentDatas = [];
  Object.keys(transformed).forEach((key) => {
    let [taskName, geo, role] = key.split(";");
    if (store.getters["spreadsheetUpload/shakespeare"])
      taskName = transformed[`${key.split(";")[0]};task_name`];
    const person = transformed[key];
    if (role === "writer") {
      assignmentDatas.push({
        taskName,
        geo,
        role,
        person,
      });
    }

    if (!onlyWriters) {
      if (role === "editor" || role === "reviewer") {
        assignmentDatas.push({
          taskName,
          geo,
          role,
          person,
        });
      }
    }
  });

  return assignmentDatas;
};

export const doFileSplitByPerson = async ({ files, staffAssignments, staffNameGEOPairs }) => {
  try {
    /* const authToken = await getOauthToken();
    window.open(authToken.data);
    const resp = await getRefreshToken();
    const { refreshToken, expiresAt, accessToken } = resp.data; */
    const resp = await getAccessToken({
      refreshToken: process.env.VUE_APP_DROPBOX_REFRESH_TOKEN,
      expiresAt: process.env.VUE_APP_DROPBOX_REFRESH_TOKEN_EXPIRES_AT,
      accessToken: process.env.VUE_APP_DROPBOX_ACCESS_TOKEN
    });
    if (resp.status !== 200) {
      throw resp;
    }
    const token = resp.data.accessToken;
    const assignmentDatas = _.groupBy(getStaffAssignmentsByPerson(staffAssignments, true), (el) => el.person);

    for (const person in assignmentDatas) {
      if (person !== "") {
        const assignments = assignmentDatas[person];
        store.dispatch("spreadsheetUpload/setSplitByPersonFilesAmount", store.getters["spreadsheetUpload/splitByPersonFilesAmount"] + assignments.length);
      }
    }

    for (const person in assignmentDatas) {
      if (person !== "") {
        const assignments = assignmentDatas[person];
        const retryQueue = [];
        for (const assignment of assignments) {
          const file = files.find((el) => removeXLSX(el.name) === assignment.taskName);
          const { file: filteredFile, geo } = await createGeoFilteredFile(file, assignment.geo);

          try {
            await uploadFile(token, filteredFile, geo, person, staffNameGEOPairs);
            store.dispatch("spreadsheetUpload/setSplitByPersonSend", store.getters["spreadsheetUpload/splitByPersonSend"] + 1);
          } catch (error) {
            console.error(error);
            if (error.status === 429) {
              retryQueue.push({ file: filteredFile, geo });
            }
          }
        }

        // Retry failed uploads
        for (const item of retryQueue) {
          await uploadFile(token, item.file, item.geo, person, staffNameGEOPairs);
        }
      } else {
        return("Choose the writers for all GEOs.");
      }
    }

    return ("File(s) uploaded!");
  } catch (error) {
    return(error);
    // Handle the overall error here
  }
};

const uploadFile = async (token, file, geo, person, staffNameGEOPairs) => {
  const personObj = staffNameGEOPairs.find((el) => el.name === person);
  const response = await fetch("https://content.dropboxapi.com/2/files/upload", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
      "Dropbox-API-Arg": JSON.stringify({
        path: `/${geo.toUpperCase()}_Apps/${geo.toUpperCase()}_Assigned/${personObj.initials}_Assigned/${file.name}`,
        mode: "add",
        autorename: true,
        mute: false,
      }),
    },
    body: file,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      statusText: response.statusText,
    };
  }
  return response.json();
};


const createGeoFilteredFile = async (file, geo) => {
  const fileNameParts = file.name.split(/(\d\d\d\d\d+)/);
  const fileNameWithGeo = `${fileNameParts[0]}${geo}_${fileNameParts[1]}${fileNameParts[2]}`;
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix

  // find address of 'Locale' column
  const localeCell = sheet.model.rows[0].cells.find(
    (cell) => cell.value === "Locale"
  ).address;

  // extract just letters from string
  const localeCol = localeCell.replace(/[0-9].*/, "");

  // get number of rows in sheet
  const numRows = sheet.model.rows.length;

  // loop through rows starting at the end and remove any that don't match geo
  for (let i = numRows + 1; i > 1; i--) {
    if (sheet.getCell(localeCol + i).value !== geo) {
      sheet.spliceRows(i, 1);
    }
  }

  // create file and return
  const buffData = await workbook.xlsx.writeBuffer();
  var blob = new Blob([buffData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  blob.lastModifiedDate = new Date();
  blob.name = `${fileNameWithGeo}`;
  return {
    geo,
    name: removeXLSX(fileNameWithGeo),
    file: blob,
  };
};

const createFilteredFile = async ({ file, filters, fileName }) => {
  // setup workbook
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix

  // get number of rows in sheet
  const numRows = sheet.model.rows.length;

  for (let i = numRows + 1; i > 1; i--) {
    const truthMap = Object.keys(filters).map((column) => {
      if (sheet.getCell(column + i).value === filters[column]) {
        return true;
      } else {
        return false;
      }
    });

    // check if all values in truthMap are true
    if (!truthMap.every((el) => el === true)) {
      sheet.spliceRows(i, 1);
    }
  }

  // create file and return
  const buffData = await workbook.xlsx.writeBuffer();
  var blob = new Blob([buffData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  blob.lastModifiedDate = new Date();
  blob.name = `${fileName}`;
  return {
    name: `${fileName}`,
    file: blob,
  };
};

////////////////////////////
// file downloading
////////////////////////////
const createZipDownload = (allFileInfos, fileName) => {
  const newFileName = fileName || "files";
  const zip = new JSZip();

  allFileInfos.forEach((f) => {
    zip.file(`${f.geo}/${f.name}.xlsx`, f.file);
  });
  zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${newFileName}.zip`);
  });
};

const createSingleLevelZipDownload = (allFileInfos, fileName) => {
  const newFileName = fileName || "files";
  const zip = new JSZip();

  allFileInfos.forEach((f) => {
    zip.file(`${f.name}.xlsx`, f.file);
  });
  zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${newFileName}.zip`);
  });
};

const createFileNameFromValues = (values) => {
  // values need to be escaped
  const escapedValues = values.map((el) => {
    return el.replace(/[^a-zA-Z0-9-]/g, (match) => {
      return match === '-' ? match : '_';
    });
  });
  const fileName = `${escapedValues.join("_")}`;
  return fileName;
};