<template>
  <div
    class="assignment-viz elevation-3 grey lighten-4"
    v-if="
      personAssignments.length > 0 &&
      (formValues.writingDueDate || formValues.editingDueDate)
    "
  >
    <div
      v-for="(person, i) in personAssignments"
      :key="`bar-${i}`"
      :class="`assignment-viz__row ${getGroupSpacing(i)}`"
      class="flex-wrap"
    >
      <div
        class="subtitle-1 text-center"
        :style="{
          position: 'relative',
          width: '100%',
        }"
        @mouseenter="() => handleMouseOverName(person.person)"
        @mouseleave="() => handleMouseOverName(null)"
      >
        {{ person.person }}
        <h3 v-if="!wordCountValidated && !backstageCopywriting">
          Add Word Count
        </h3>
        <div
          class="tooltip tooltip-name-hover"
          :style="{
            display: getTooltimeNameDisplay(person.person),
          }"
        >
          <div v-if="personWordCounts && personWordCounts.length === 0">
            No upcoming assignments
          </div>
          <div
            class="d-flex font-weight-bold"
            v-if="personWordCounts && personWordCounts.length > 0"
          >
            <div class="tooltip-name-hover__block">Due Date</div>
            <div class="tooltip-name-hover__block">Writing Total</div>
            <div class="tooltip-name-hover__block">Editing Total</div>
          </div>
          <div
            v-for="(wc, i) in personWordCounts"
            :style="{
              backgroundColor: i % 2 === 0 ? '#fff' : '#f5f5f5',
            }"
            :key="`wc-row-${i}`"
            class="d-flex"
          >
            <div class="tooltip-name-hover__block">
              {{ wc.date }}
            </div>
            <div class="tooltip-name-hover__block">
              {{ wc.writingTot ? wc.writingTot : "None" }}
            </div>
            <div class="tooltip-name-hover__block">
              {{ wc.editingTot ? wc.editingTot : "None" }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-center" :style="{ width: '15%' }">
        <div
          v-if="
            (formValues.writingDueDate && person.writingWordCount) ||
            (formValues.editingDueDate && person.editingWordCount)
          "
          class="subtitle-2"
        >
          CURRENT
        </div>
        <div
          v-if="formValues.writingDueDate && person.writingWordCount"
          class="d-flex justify-end"
          :style="{ width: '100%', height: '24px' }"
        >
          <div class="subtitle-2"><b>W:</b></div>
          <div
            class="ml-2 subtitle-2 d-flex justify-center"
            :style="{ ...barStyles, width: '50px' }"
          >
            {{ person.writingWordCount }}
          </div>
        </div>
        <div
          v-if="formValues.editingDueDate && person.editingWordCount"
          class="d-flex justify-end"
          :style="{ width: '100%', height: '24px' }"
        >
          <div class="subtitle-2"><b>E:</b></div>
          <div
            class="ml-2 subtitle-2 d-flex justify-center"
            :style="{ ...barStyles, width: '50px' }"
          >
            {{ person.editingWordCount }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-center" :style="{ width: '70%' }">
        <div
          v-if="
            (formValues.writingDueDate && person.writingWordCount) ||
            (formValues.editingDueDate && person.editingWordCount)
          "
          class="subtitle-2"
        >
          TOTAL/MAX
        </div>
        <div
          v-if="formValues.writingDueDate && person.writingWordCount"
          class="d-flex"
          :style="{ width: '100%', height: '24px' }"
        >
          <div
            class="bars-col"
            :style="{
              ...barStyles,
            }"
          >
            <div
              class="subtitle-2 d-flex"
              :style="{
                borderRadius: '4px',
                borderRight: '1px solid #c0c0c0',
                width: `${
                  person.writingMaxCapacity > 0
                    ? ((person.writingMaxCapacity -
                        person.writingCapacity +
                        person.writingWordCount) /
                        person.writingMaxCapacity) *
                      100
                    : 100
                }%`,
                background:
                  person.writingCapacity - person.writingWordCount >= 0 &&
                  person.writingMaxCapacity > 0
                    ? 'repeating-linear-gradient(-45deg, rgba(158, 185, 243, 1), rgba(158, 185, 243, 1) 3px, #f5f5f5 3px, #f5f5f5 6px)'
                    : '#ee7975',
                color:
                  person.writingCapacity - person.writingWordCount >= 0 &&
                  person.writingMaxCapacity > 0
                    ? ''
                    : '#fff',
              }"
            >
              <div
                class="d-flex align-center justify-center"
                :style="{
                  width: `${
                    person.writingCapacity - person.writingWordCount >= 0 &&
                    person.writingMaxCapacity > 0
                      ? (person.writingWordCount /
                          (person.writingMaxCapacity -
                            person.writingCapacity +
                            person.writingWordCount)) *
                        100
                      : 100
                  }%`,
                  borderRadius: '4px',
                  borderRight: '1px solid #c0c0c0',
                  background:
                    person.writingCapacity - person.writingWordCount >= 0
                      ? 'rgba(158, 185, 243, 1)'
                      : '#ee7975',
                }"
              >
                <b
                  :style="[
                    (person.writingWordCount / person.writingMaxCapacity) *
                      100 <
                      20 &&
                    person.writingCapacity - person.writingWordCount >= 0
                      ? { position: 'absolute', right: '10px' }
                      : {},
                  ]"
                >
                  {{
                    wordCountValidated
                      ? person.writingMaxCapacity -
                        person.writingCapacity +
                        person.writingWordCount +
                        "/" +
                        person.writingMaxCapacity
                      : "Add Word Count"
                  }}
                </b>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="formValues.editingDueDate && person.editingWordCount"
          class="d-flex"
          :style="{ width: '100%', height: '24px' }"
        >
          <div
            class="bars-col"
            :style="{
              ...barStyles,
            }"
          >
            <div
              class="subtitle-2 d-flex"
              :style="{
                borderRadius: '4px',
                borderRight: '1px solid #c0c0c0',
                width: `${
                  person.editingMaxCapacity > 0
                    ? ((person.editingMaxCapacity -
                        person.editingCapacity +
                        person.editingWordCount) /
                        person.editingMaxCapacity) *
                      100
                    : 100
                }%`,
                background:
                  person.editingCapacity - person.editingWordCount >= 0 &&
                  person.editingMaxCapacity > 0
                    ? 'repeating-linear-gradient(-45deg, rgba(220, 176, 242, 1), rgba(220, 176, 242, 1) 3px, #f5f5f5 3px, #f5f5f5 6px)'
                    : '#ee7975',
                color:
                  person.editingCapacity - person.editingWordCount >= 0 &&
                  person.editingMaxCapacity > 0
                    ? ''
                    : '#fff',
              }"
            >
              <div
                class="d-flex align-center justify-center"
                :style="{
                  width: `${
                    person.editingCapacity - person.editingWordCount >= 0 &&
                    person.editingMaxCapacity > 0
                      ? (person.editingWordCount /
                          (person.editingMaxCapacity -
                            person.editingCapacity +
                            person.editingWordCount)) *
                        100
                      : 100
                  }%`,
                  borderRadius: '4px',
                  borderRight: '1px solid #c0c0c0',
                  background:
                    person.editingCapacity - person.editingWordCount >= 0
                      ? 'rgba(220, 176, 242, 1)'
                      : '#ee7975',
                }"
              >
                <b
                  :style="[
                    (person.editingWordCount / person.editingMaxCapacity) *
                      100 <
                      20 &&
                    person.editingCapacity - person.editingWordCount >= 0
                      ? { position: 'absolute', right: '10px' }
                      : {},
                  ]"
                >
                  {{
                    wordCountValidated
                      ? person.editingMaxCapacity -
                        person.editingCapacity +
                        person.editingWordCount +
                        "/" +
                        person.editingMaxCapacity
                      : "Add Word Count"
                  }}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-center" :style="{ width: '15%' }">
        <div
          v-if="
            (formValues.writingDueDate && person.writingWordCount) ||
            (formValues.editingDueDate && person.editingWordCount)
          "
          class="subtitle-2"
        >
          LEFT
        </div>
        <div
          v-if="formValues.writingDueDate && person.writingWordCount"
          class="d-flex"
          :style="{ width: '100%', height: '24px' }"
        >
          <div
            class="bars-col subtitle-2 d-flex justify-center"
            :style="{ ...barStyles }"
          >
            {{ person.writingCapacity - person.writingWordCount }}
          </div>
        </div>
        <div
          v-if="formValues.editingDueDate && person.editingWordCount"
          class="d-flex"
          :style="{ width: '100%', height: '24px' }"
        >
          <div
            class="bars-col subtitle-2 d-flex justify-center"
            :style="{ ...barStyles }"
          >
            {{ person.editingCapacity - person.editingWordCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
import moment from "moment";
// internal
import { getAssignments, getAllStaff, getEvents, getStaffCapacity } from "@/utils/newDbUtils";
import { getStaffAssignmentsByPerson } from "@/utils/spreadsheetUploadUtils";

export default {
  name: "AssignmentVisualization",
  data() {
    return {
      barStyles: {
        width: "100%",
        height: "22px",
        boxSizing: "border-box",
        border: "1px solid #c0c0c0",
        borderRadius: "4px",
      },
      hoveredName: null,
      personWordCounts: null,
      assignmentsList: [],
      writerAssignmentsList: [],
      editorAssignmentsList: [],
      staffList: [],
      eventsList: [],
      fetchAssignmentsDate: null
    };
  },
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "staffAssignments",
      "formValues",
      "wordCountValidated",
      "capacityPool",
      "defaultCapacityPool",
      "adjustedCapacityPool",
      "backstageCopywriting",
    ]),
    personAssignments() {
      let assignments = getStaffAssignmentsByPerson(
        this.staffAssignments,
        false
      ).filter((el) => el.person !== null && el.role !== "reviewer");
      if (_.isEmpty(assignments)) {
        return [];
      } else {
        assignments = assignments.map((el) => {
          return {
            ...el,
            wordCount: this.staffAssignments[el.taskName].wordCount,
          };
        });
        assignments = _.groupBy(assignments, (el) => el.person);
        const retVal = [];
        Object.keys(assignments).forEach((person) => {
          const personFirstName = person.split(" ")[0];
          const personLastName = person.split(" ").slice(1).join(" ");
          const personFromStaffList = this.staffList.find((el) => {
            return el.user.lastName === personLastName && el.user.firstName === personFirstName;
          })
          const events = this.eventsList.filter(el => el.personName?.firstName === personFirstName && el.personName?.lastName === personLastName);
          let accumulator = 0,
            writingWordCount = 0,
            editingWordCount = 0,
            writingCapacity = 0,
            editingCapacity = 0,
            writingMaxCapacity = 0,
            editingMaxCapacity = 0;
          const assignmentsAccumulator = [];
          assignments[person].forEach((el) => {
            el.wordCount ? el.wordCount : (el.wordCount = 0);
            const r = {
              ...el,
              start: accumulator,
              end: accumulator + el.wordCount,
            };
            accumulator += el.wordCount;
            assignmentsAccumulator.push(r);
            if (el.role === "writer") writingWordCount += el.wordCount;
            if (el.role === "editor") editingWordCount += el.wordCount;
          });
          if (this.formValues.writingDueDate) {
            writingMaxCapacity = personFromStaffList?.adminDetails?.defaultDailyEditing ? 
              personFromStaffList.adminDetails.defaultDailyEditing : 
              2000;
            writingCapacity = writingMaxCapacity;
            const writerList = this.writerAssignmentsList.filter(
              (el) => el.name === person
            );
            if (writerList.length > 0) {
              writerList.forEach((el) => {
                if (el?.primaryWordCount) {
                  writingCapacity = writingCapacity - el?.primaryWordCount;
                }
              })
            }
            if (events.length > 0) {
              events.forEach((el) => {
                const givenDate = moment(this.formValues.writingDueDate);
                const startPeriod = moment(el.startDate);
                const endPeriod = moment(el.endDate);
                if (givenDate.isBetween(startPeriod, endPeriod, null, '[]')) {
                  writingMaxCapacity = el?.adjustedWeeklyWriting ? el?.adjustedWeeklyWriting : 0;
                }
              })
            }
          }
          if (this.formValues.editingDueDate) {
            editingMaxCapacity = personFromStaffList?.adminDetails?.defaultDailyEditing ? 
              personFromStaffList.adminDetails.defaultDailyEditing : 
              2000;
            editingCapacity = editingMaxCapacity;
            const editorList = this.editorAssignmentsList.filter(
              (el) => el.name === person
            );
            if (editorList.length > 0) {
              editorList.forEach((el) => {
                if (el?.secondaryWordCount) {
                  editingCapacity = editingCapacity - el?.secondaryWordCount;
                }
              })
            }
            if (events.length > 0) {
              events.forEach((el) => {
                const givenDate = moment(this.formValues.editingDueDate);
                const startPeriod = moment(el.startDate);
                const endPeriod = moment(el.endDate);
                if (givenDate.isBetween(startPeriod, endPeriod, null, '[]')) {
                  editingMaxCapacity = el?.adjustedDailyEditing ? el?.adjustedDailyEditing : 0;
                }
              })
            }
          }
          
      
          retVal.push({
            person: person,
            personId: personFromStaffList?.user.id,
            assignments: assignmentsAccumulator,
            totalWordCount: accumulator,
            writingWordCount: writingWordCount,
            editingWordCount: editingWordCount,
            writingCapacity: writingCapacity,
            editingCapacity: editingCapacity,
            writingMaxCapacity: writingMaxCapacity,
            editingMaxCapacity: editingMaxCapacity,
            geo: personFromStaffList?.adminDetails?.geos[0], // a bit lazy here, person's geo is the first assignment in their list. Correct in most cases.
          });
        });

        return retVal;
      }
    },
    assignmentsListUpdate() {
      return getStaffAssignmentsByPerson(
        this.staffAssignments,
        false
      ).filter((el) => el.person !== null && el.role !== "reviewer");
    }
  },
  watch: {
    'formValues.writingDueDate': {
      async handler() {
        if (this.personAssignments.length && !this.assignmentsList.length || this.fetchAssignmentsDate !== this.formValues.writingDueDate) {
          let idList = [];
          this.personAssignments.forEach((el) => {
            idList.push(el.personId);
          })
          this.fetchAssignmentsDate = this.formValues.writingDueDate;
          const result = await getStaffCapacity({idList: idList.join(","), selectedDate: this.formValues.writingDueDate});
          this.writerAssignmentsList = result.data;
        }
      }
    },
    'formValues.editingDueDate': {
      async handler() {
        if (this.personAssignments.length && !this.assignmentsList.length || this.fetchAssignmentsDate !== this.formValues.editingDueDate) {
          let idList = [];
          this.personAssignments.forEach((el) => {
            idList.push(el.personId);
          })
          this.fetchAssignmentsDate = this.formValues.editingDueDate;
          const result = await getStaffCapacity({idList: idList.join(","), selectedDate: this.formValues.editingDueDate});
          this.editorAssignmentsList = result.data;
        }
      }
    },
    assignmentsListUpdate: {
      async handler() {
        if (this.fetchAssignmentsDate && this.formValues.editingDueDate) {
          let idList = [];
          this.personAssignments.forEach((el) => {
            idList.push(el.personId);
          })
          this.fetchAssignmentsDate = this.formValues.editingDueDate;
          const result = await getStaffCapacity({idList: idList.join(","), selectedDate: this.formValues.editingDueDate});
          this.editorAssignmentsList = result.data;
        }
        if (this.fetchAssignmentsDate && this.formValues.writingDueDate) {
          let idList = [];
          this.personAssignments.forEach((el) => {
            idList.push(el.personId);
          })
          this.fetchAssignmentsDate = this.formValues.writingDueDate;
          const result = await getStaffCapacity({idList: idList.join(","), selectedDate: this.formValues.writingDueDate});
          this.editorAssignmentsList = result.data;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["setStaffAssignmentOverloading"]),
    getGroupSpacing(index) {
      if (index === 0) {
        return "";
      } else if (
        this.personAssignments[index].geo !==
        this.personAssignments[index - 1].geo
      ) {
        return "group-start-spacing";
      } else {
        return "";
      }
    },
    getTooltimeNameDisplay(person) {
      if (!this.hoveredName) return "none";

      if (person === this.hoveredName) {
        return "block";
      } else {
        return "none";
      }
    },
    getWordCountAndDatesForPerson(person) {
      const filtered = this.capacityPool.filter(
        (el) => el["RelatedPerson"] === person && el["WordCount(tot)"] !== null
      );

      const separated = [];
      filtered.forEach((el) => {
        const obj = { name: el["RelatedPerson"], date: el["DueDate"] };
        if (el["Primary/Reviewer"] === "Primary")
          Object.assign(obj, {
            writingTot: el["WordCount(tot)"],
          });
        if (el["Primary/Reviewer"] === "Reviewer")
          Object.assign(obj, {
            editingTot: el["WordCount(tot)"],
          });
        separated.push(obj);
      });

      const separatedCopy = [...separated];
      const merged = [...separated, ...separatedCopy].reduce(
        (mergedRes, curEl) => {
          let matchingElementIdx = mergedRes.findIndex(
            (el) => el.date === curEl.date
          );
          if (matchingElementIdx !== -1) {
            mergedRes[matchingElementIdx] = {
              ...mergedRes[matchingElementIdx],
              ...curEl,
            };
          } else {
            mergedRes = [...mergedRes, curEl];
          }
          return mergedRes;
        },
        []
      );
      return merged;
    },
    handleMouseOverName(person) {
      if (!person) {
        this.personWordCounts = null;
        this.hoveredName = null;
      }

      if (this.capacityPool && person) {
        this.personWordCounts = this.getWordCountAndDatesForPerson(person);
        this.hoveredName = person;
      }
    },
  },
  async mounted() {
    this.staffList = await getAllStaff();
    this.eventsList = await getEvents();
  }
};
</script>

<style lang="scss" scoped>
.assignment-viz {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
  height: fit-content;

  .tooltip {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #000;
    z-index: 999;
    pointer-events: none;
    display: none;
  }

  .tooltip-name-hover {
    &__block {
      width: 120px;
    }
  }

  &__row {
    display: flex;
    margin: 0px !important;
    padding: 5px 0px;

    &.group-start-spacing {
      margin-top: 15px !important;
      border-top: 1px solid rgb(106, 106, 106);
    }

    .bars-col {
      flex: 1 1 auto;
      position: relative;
      display: flex;
      margin-left: 10px;

      > div {
        &:only-child {
          > .bar {
            border-radius: 4px !important;
          }
        }

        &:first-child {
          > .bar {
            border-radius: 4px 0px 0px 4px;
          }
        }

        &:last-child {
          > .bar {
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }
  }
}
</style>
