// imports
const Excel = require("exceljs");
import { saveAs } from "file-saver";
import moment from "moment";

// report creation
export const generateReport = async (data) => {
  const workbook = new Excel.Workbook(),
    font = {
      name: "Arial",
      size: 10,
    },
    headerStyler = (worksheet, row, fill) => {
      worksheet.getRow(row).font = { bold: true };
      worksheet.getRow(row).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: fill },
      };
      worksheet.getRow(row).height = 20;
      worksheet.getRow(row).alignment = { vertical: "middle" };
      worksheet.getRow(row).border = {
        bottom: { style: "thick", color: { argb: "d9d9d9" } },
      };
    },
    cellDyer = (worksheet, address, color) => {
      worksheet.getCell(address).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
    };

  // add issue tracker data
  const reportWorksheet = workbook.addWorksheet("Report");
  reportWorksheet.columns = [
    {
      header: "Request Date",
      key: "request_date",
      width: 15,
      style: { font },
    },
    {
      header: "Workflow ID",
      key: "workflow_id",
      width: 40,
      style: { font },
    },
    {
      header: "Content ID",
      key: "content_id",
      width: 15,
      style: { font },
    },
    {
      header: "Assignment Detail",
      key: "detail",
      width: 30,
      style: { font },
    },
    { header: "GEO", key: "geo", width: 8, style: { font } },
    {
      header: "Issue Type",
      key: "type",
      width: 30,
      style: { font },
    },
    {
      header: "Solved?",
      key: "solved",
      width: 8,
      style: { font },
    },
    {
      header: "Notes",
      key: "note",
      width: 30,
      style: { font },
    },
    {
      header: "Resolution",
      key: "res",
      width: 30,
      style: { font },
    },
  ];
  headerStyler(reportWorksheet, 1, "c9daf8");

  ["G1", "H1"].forEach((cell) => cellDyer(reportWorksheet, cell, "d9d9d9"));

  const issueTypes = [
    ...new Set(
      data.map((el) => {
        return el.IssueType;
      })
    ),
  ];
  issueTypes.forEach((type) => {
    const row = reportWorksheet.addRow({
      request_date: type.toUpperCase(),
    });
    headerStyler(reportWorksheet, row.number, "b7e1cd");
    reportWorksheet.mergeCells(`A${row.number}:I${row.number}`);
    data
      .filter((el) => el.IssueType === type)
      .forEach((issue) => {
        const issueRow = reportWorksheet.addRow({
          request_date: issue.RequestDate,
          workflow_id: issue["Assignment-TaskID"],
          content_id: issue["Assignment-ContentID"],
          detail: issue.AssignmentDetail,
          geo: issue.GEO,
          type: issue.IssueType,
          solved: issue.IssueStatus === "Resolved" ? "YES" : "NO",
        });
        cellDyer(
          reportWorksheet,
          `G${issueRow.number}`,
          `${issue.IssueStatus === "Resolved" ? "b6d7a8" : "f4cccc"}`
        );
      });
  });

  // save and download file
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `CC_Shakespeare_Bug_Report__${moment(new Date()).format(
          "MM.DD.YYYY"
        )}.xlsx`
      )
    )
    .catch((err) => console.error("Error writing Excel export", err));
};
