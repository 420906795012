import { render, staticRenderFns } from "./FragmentsEditor.vue?vue&type=template&id=2a6913ea&scoped=true&"
import script from "./FragmentsEditor.vue?vue&type=script&lang=js&"
export * from "./FragmentsEditor.vue?vue&type=script&lang=js&"
import style0 from "./FragmentsEditor.vue?vue&type=style&index=0&id=2a6913ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6913ea",
  null
  
)

export default component.exports