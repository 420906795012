<template>
  <div class="login d-flex justify-center align-center">
    <v-card class="login__card pa-7">
      <v-form ref="form" @submit.prevent="handleSubmit">
        <v-card-title class="pa-0">Login</v-card-title>
        <div class="caption">User email</div>
        <v-text-field
          class="login__card__input"
          v-model="userValue"
          outlined
          :error="loginFailed"
          :error-messages="errMsg"
          :rules="[rules.required]"
        ></v-text-field>
        <div class="caption">Password</div>
        <v-text-field
          class="login__card__input"
          type="password"
          v-model="passValue"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
        <v-btn type="submit" color="secondary">submit</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { doLogin } from "@/utils/newDbUtils";

export default {
  data() {
    return {
      loginFailed: false,
      errMsg: "",
      userValue: "",
      passValue: "",
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", [
      "setIsAdmin",
      "setIsManager",
      "setIsDubEditor",
      "setUser",
      "setShowAddressUpdateWindow"
    ]),
    handleSubmit() {
      if (this.$refs.form.validate()) {
        doLogin({ email: this.userValue, password: this.passValue }).then(
          (resp) => {
            if (resp.token) {
              localStorage.setItem("token", resp.token);
              localStorage.setItem("refreshToken", resp.refresh);
              this.setIsAdmin(resp.data.user.userRole === "ADMIN");
              this.setIsManager(resp.data.user.userRole === "MANAGER");
              this.setIsDubEditor(resp.data?.adminDetails?.dubEditorAccess);
              this.setUser(resp.data);
              if (this.user.user?.userRole !== "CLIENT") {
                if (!this.user.adminDetails.vendor) {
                  if (
                    !this.user.hrDetails?.streetAddress ||
                    !this.user.hrDetails?.city ||
                    !this.user.hrDetails?.zipCode
                  ) {
                    this.setShowAddressUpdateWindow(true);
                  } else {
                    this.setShowAddressUpdateWindow(false);
                  }
                }
              }
              const toRoute = this.$route.query.to || "/";
              resp.data?.adminDetails?.vendor ? this.$router.push("/payment-requests") : this.$router.push(toRoute);
              this.loginFailed = false;
            } else {
              this.errMsg = resp.data.message;
              this.loginFailed = true;
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  width: 100%;
  &__card {
    &__input {
      width: 300px;
    }
  }
}
</style>
