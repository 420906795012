var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('NavBar'),_c('FlashMessage'),_c('v-dialog',{attrs:{"max-width":"460","persistent":""},model:{value:(_vm.showAddressUpdateWindow),callback:function ($$v) {_vm.showAddressUpdateWindow=$$v},expression:"showAddressUpdateWindow"}},[_c('div',{staticClass:"address-update"},[_c('h6',{staticClass:"address-update--title"},[_vm._v("Action Needed: Update Address")]),_c('p',{staticClass:"address-update--text"},[_vm._v("Please review and update your address details by clicking the button below.")]),_c('v-btn',{staticClass:"address-update--btn",attrs:{"color":"#1976D2"},on:{"click":_vm.handleAddressUpdate}},[_vm._v("Update Address")])],1)]),_c('v-main',{class:{ 
      'grey lighten-4': _vm.$route.name !== 'Reimbursement Request Form' 
        || _vm.$route.name !== 'Request an Assignment'
        || _vm.$route.name !== 'Vendor Invoice Form',
      'grey darken-2': _vm.$route.name === 'Reimbursement Request Form' 
        || _vm.$route.name === 'Request an Assignment'
        || _vm.$route.name === 'Vendor Invoice Form'
      }},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }