<template>
  <v-container class="issue-tracker-table-expansion pb-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ assignmentInfo["AssignmentTitle"] }}</div>
        <h2>Date Reported: {{ assignmentInfo["DateReported"] }}</h2>
        <div class="my-5">
          <!-- Project -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Project</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Assignment-ProjectName"]
            }}</v-col>
          </v-row>

          <!-- Media Type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Media Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Assignment-MediaType"]
            }}</v-col>
          </v-row>

          <!-- Delivery Date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Delivery Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              formatDueTime(assignmentInfo["Assignment-DeliveryDate"])
            }}</v-col>
          </v-row>

          <!-- Delivery Due -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Delivery Due</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Assignment-DeliveryDue(TimeOfDay)"]
            }}</v-col>
          </v-row>

          <!-- Task ID -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Task ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Assignment-TaskID"]
            }}</v-col>
          </v-row>

          <!-- Content ID -->
          <v-row v-if="isAdmin">
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Content ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Assignment-ContentID"]
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Assignees & Due Dates</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Step</th>
                  <th class="text-center">Person</th>
                  <th class="text-center">Due</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Writer</td>
                  <td>{{ assignmentInfo["Assignment-Writer"] }}</td>
                  <td>
                    {{
                      formatDueTime(
                        assignmentInfo["Assignment-WritingDueDate"],
                        assignmentInfo["Assignment-WritingDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                </tr>
                <tr v-if="assignmentInfo['Assignment-EditingDueDate'] !== ''">
                  <td>Editor</td>
                  <td>{{ assignmentInfo["Assignment-Editor"] }}</td>
                  <td>
                    {{
                      formatDueTime(
                        assignmentInfo["Assignment-EditingDueDate"],
                        assignmentInfo["Assignment-EditingDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                </tr>
                <tr v-if="assignmentInfo['Assignment-ReviewingDueDate'] !== ''">
                  <td>Reviewer</td>
                  <td>{{ assignmentInfo["Assignment-Reviewer"] }}</td>
                  <td>
                    {{
                      formatDueTime(
                        assignmentInfo["Assignment-ReviewingDueDate"],
                        assignmentInfo["Assignment-ReviewingDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end px-3">
      <!-- bottom controls -->
      <v-btn
        class="accent"
        @click="() => handleOpenInQB(assignmentInfo['RelatedAssignment'])"
        >open in quickbase</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { appIDs, realmID } from "@/utils/quickbaseUtils";

export default {
  name: "IssueTrackerTableExpansion",
  props: {
    assignmentInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin"]),
  },
  methods: {
    formatDueTime(dueDate, dueTime) {
      const dayString = this.$moment(dueDate).format("MM/DD/YY");
      return dueTime ? `${dayString} ${dueTime}` : dayString;
    },
    handleOpenInQB(rid) {
      const url = `https://${realmID}/db/${appIDs.iTunesProjectManager.assignments}?a=dr&rid=${rid}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.issue-tracker-table-expansion {
  width: 100%;
}

.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
