<template>
  <v-card class="editor-new-assignment d-flex ma-4">
    <v-container class="editor-new-assignment__left editor-new-assignment__col">
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            class="assignment-name"
            outlined
            hide-details
            label="Assignment Name"
            :value="startingAssignmentName"
            @change="updateField($event, 'assignmentName')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea
            outlined
            label="Assignment Detail"
            hide-details
            :value="startingAssignmentDetails"
            @change="updateField($event, 'assignmentDetails')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            outlined
            label="Export Header"
            hide-details
            :value="startingExportHeader"
            @change="updateField($event, 'exportHeader')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-menu
            v-model="deliveryDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedStartingAssignmentDeliveryDate"
                label="Delivery Date (Client)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="startingAssignmentDeliveryDate"
              @input="updateField($event, 'deliveryDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            v-model="payPeriodMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedStartingPayPeriod"
                label="Pay Period"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              :value="startingPayPeriod"
              @input="updateField($event, 'payPeriod')"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            outlined
            hide-details
            label="Price Card"
            :items="priceOptions"
            :loading="priceOptionsLoading"
            :value="startingRelatedPrice"
            @change="updateField($event, 'relatedPrice')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            label="Quantity"
            type="number"
            :value="startingQuantity"
            @change="updateField($event, 'quantity')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            outlined
            hide-details
            label="PO"
            :disabled="!hasPOs"
            :items="projectPOs"
            :value="startingPO"
            @change="updateField($event, 'po')"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined
            hide-details
            label="GEO"
            :items="geoOptions"
            :loading="geoOptionsLoading"
            :value="startingGEO"
            @change="updateField($event, 'geo')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined
            hide-details
            label="Media Type"
            :items="mediaTypeOptions"
            :loading="mediaTypeOptionsLoading"
            :value="startingMediaType"
            @change="updateField($event, 'mediaType')"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-autocomplete
            outlined
            hide-details
            label="Client Requester"
            :items="projectRequesters"
            :item-text="fullName"
            :value="startingClientRequester"
            @change="updateField($event, 'clientRequester')"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="editor-new-assignment__right editor-new-assignment__col"
    >
      <FlowStepper :datasIndex="datasIndex" :editMode="editMode" />
    </v-container>
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");
// internal
import { getEnum, getCustomerPriceList } from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";
// components
import FlowStepper from "@/components/Editor/EditorNewAssignmentModal/EditorNewAssignmentItem/FlowStepper.vue";

export default {
  name: "EditorNewAssignment",
  props: {
    datasIndex: {
      type: Number,
      require: false,
    },
    editMode: {
      type: Boolean,
      require: false,
    },
  },
  components: { FlowStepper },
  data() {
    return {
      sampleFlow: ["writer", "editor", "final"],
      deliveryDateMenu: false,
      payPeriodMenu: false,
      geoOptionsLoading: false,
      geoOptions: [],
      mediaTypeOptionsLoading: false,
      mediaTypeOptions: [],
      priceOptionsLoading: false,
      priceOptions: [],
    };
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("editor", [
      "newAssignmentDatas",
      "selectedProject",
      "editAssignmentID",
      "editAssignmentData",
      "projectPOs",
      "projectRequesters",
    ]),
    formattedStartingPayPeriod() {
      return this.startingPayPeriod
        ? `${this.$moment(this.startingPayPeriod).format("MM-YYYY")}`
        : "";
    },
    formattedStartingAssignmentDeliveryDate() {
      return this.formatPickerDate(this.startingAssignmentDeliveryDate);
    },
    startingAssignmentName() {
      return this.getStartingValues("assignmentName");
    },
    startingAssignmentDetails() {
      return this.getStartingValues("assignmentDetails");
    },
    startingAssignmentDeliveryDate() {
      return this.getStartingValues("deliveryDate");
    },
    startingRelatedPrice() {
      return this.getStartingValues("relatedPrice");
    },
    startingQuantity() {
      return this.getStartingValues("quantity");
    },
    startingClientRequester() {
      return this.getStartingValues("clientRequester");
    },
    startingPayPeriod() {
      return this.getStartingValues("payPeriod");
    },
    startingPO() {
      return this.getStartingValues("po");
    },
    startingGEO() {
      return this.getStartingValues("geo");
    },
    startingMediaType() {
      return this.getStartingValues("mediaType");
    },
    startingExportHeader() {
      return this.getStartingValues("exportHeader");
    },
    hasPOs() {
      return this.projectPOs?.length;
    },
  },
  watch: {
    selectedProject() {
      this.getPriceOptions();
    },
  },
  mounted() {
    // geo options
    this.getGeoOptions();

    // media options
    this.getMediaOptions();

    // price options
    this.getPriceOptions();
  },
  methods: {
    ...mapActions("editor", ["setNewAssignmentDatas", "setEditAssignmentData"]),
    fullName(item) {
      return `${item.user.firstName} ${item.user.lastName}`;
    },
    updateField(value, field) {
      //  do pay period calculation
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload[field] = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy[field] = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    getStartingValues(field) {
      if (this.editMode) {
        if (field === "relatedPrice" && this.priceOptions.length) {
          return this.priceOptions.find(
            (el) => el.value == this.editAssignmentData.relatedPrice
          );
        } else {
          return this.editAssignmentData[field];
        }
      } else {
        return this.newAssignmentDatas[this.datasIndex][field];
      }
    },
    getGeoOptions() {
      this.geoOptionsLoading = true;
      getEnum("GEO")
        .then((data) => {
          this.geoOptionsLoading = false;
          this.geoOptions = data;
        })
        .catch((err) => {
          this.geoOptionsLoading = false;
          console.error(err);
        });
    },
    getMediaOptions() {
      this.mediaTypeOptionsLoading = true;
      getEnum("MediaType")
        .then((data) => {
          this.mediaTypeOptionsLoading = false;
          this.mediaTypeOptions = data;
        })
        .catch((err) => {
          this.mediaTypeOptions = false;
          console.error(err);
        });
    },
    getPriceOptions() {
      this.priceOptionsLoading = true;
      getCustomerPriceList()
        .then((data) => {
          const formattedPriceOptions = data
            .filter(
              (el) => el.workTypeCategory === this.selectedProject.workType
            )
            .map((el) => {
              const clientPrice = el.clientPriceCard
                ? ` - ${numeral(el.clientPriceCard).format("$0,0.00")}`
                : "";
              return {
                text: `${el.workTypeName}${clientPrice}`,
                value: el.id,
              };
            });
          this.priceOptions = formattedPriceOptions;
          if (this.editMode) this.getStartingValues("relatedPrice");
          this.priceOptionsLoading = false;
        })
        .catch((err) => {
          this.priceOptionsLoading = false;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-new-assignment {
  border-bottom: 1px solid #e0e0e0;
  &__col {
    &:first-of-type {
      width: 40%;
    }
    &:last-of-type {
      width: 60%;
    }
  }
}
</style>
