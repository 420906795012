<template>
  <v-card outlined class="custom-date-time-picker">
    <v-card-title class="text-center custom-date-time-picker__title">
      <div class="custom-date-time-picker__title--year">
        {{ formattedYear }}
      </div>
      <div class="custom-date-time-picker__title--date">
        {{ formattedDate }}
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <!-- Date Picker -->
        <v-col class="date-picker-container">
          <v-date-picker
            no-title
            v-model="selectedDate"
            @input="updateFormattedDate"
          ></v-date-picker>
        </v-col>
        <!-- Time Picker -->
        <v-col class="time-picker-container">
          <div class="time-scroller">
            <div class="time-scroller-box box-1"></div>
            <div class="time-scroller-box box-2"></div>
            <!-- Hour Scroller -->
            <div 
              class="scroller" 
              ref="hourScroller"
              @scroll="updateSelectedHour"
              @mousedown="startScrollHours" 
              @mousemove="scrollHours" 
              @mouseup="stopScrollHours" 
              @mouseleave="stopScrollHours"
            >
              <div
                v-for="hour in hours"
                :key="hour"
                :class="['scroller-item', { 'selected': hour === selectedHour }]"
              >
                {{ hour }}
              </div>
            </div>
            <!-- Minute Scroller -->
            <div 
              class="scroller" 
              ref="minutesScroller"
              @scroll="updateSelectedMinute"
              @mousedown="startScrollMinutes" 
              @mousemove="scrollMinutes" 
              @mouseup="stopScrollMinutes" 
              @mouseleave="stopScrollMinutes"
            >
              <div
                v-for="minute in minutes"
                :key="minute"
                :class="['scroller-item', { 'selected': minute === selectedMinute }]"
              >
                {{ minute }}
              </div>
            </div>
            <!-- AM/PM Scroller -->
            <div class="not-scroller">
              <div
                v-for="meridian in meridians"
                :key="meridian"
                class="not-scroller-item"
                :class="{ amSelected : amPm === meridian }"
                @click="selectMeridian(meridian)"
              >
                {{ meridian }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "CustomDateTimePicker",
  props: {
    preparedDate: {
      type: String,
      required: false,
    },
    preparedTime: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedHour: String(new Date().getHours() % 12 || 12).padStart(2, "0"),
      selectedMinute: String(new Date().getMinutes()).padStart(2, "0"),
      amPm: new Date().getHours() >= 12 ? "PM" : "AM",
      hours: Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, "0")),
      minutes: Array.from({ length: 60 }, (_, i) => String(i).padStart(2, "0")),
      meridians: ["AM", "PM"],
      isScrolling: false,
      startY: 0,
      scrollTop: 0,
    };
  },
  watch: {
    selectedHour() {
      this.updateTime();
    },
    selectedMinute() {
      this.updateTime();
    },
    amPm() {
      this.updateTime();
    },
  },
  computed: {
    formattedDate() {
      const date = new Date(this.selectedDate);
      const dateFormatter = new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      });
      const formattedDate = dateFormatter.format(date);

      return `${formattedDate} | ${this.selectedHour}:${this.selectedMinute} ${this.amPm}`;
    },
    formattedYear() {
      const date = new Date(this.selectedDate);
      const yearFormatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      });
      return yearFormatter.format(date);
    },
  },
  methods: {
    selectMeridian(meridian) {
      this.amPm = meridian;
    },
    startScrollHours(event) {
      this.isScrolling = true;
      this.startY = event.pageY - event.currentTarget.offsetTop;
      this.scrollTop = event.currentTarget.scrollTop;
    },
    scrollHours(event) {
      if (!this.isScrolling) return;
      const y = event.pageY - event.currentTarget.offsetTop;
      const walk = (y - this.startY) * 2;
      event.currentTarget.scrollTop = this.scrollTop - walk;
    },
    stopScrollHours() {
      this.isScrolling = false;
    },
    startScrollMinutes(event) {
      this.isScrolling = true;
      this.startY = event.pageY - event.currentTarget.offsetTop;
      this.scrollTop = event.currentTarget.scrollTop;
    },
    scrollMinutes(event) {
      if (!this.isScrolling) return;
      const y = event.pageY - event.currentTarget.offsetTop;
      const walk = (y - this.startY) * 2;
      event.currentTarget.scrollTop = this.scrollTop - walk;
    },
    stopScrollMinutes() {
      this.isScrolling = false;
    },
    updateSelectedHour() {
      const scroller = this.$refs.hourScroller;
      const scrollerBox = scroller.querySelector('.scroller-item');

      const boxTop = scroller.getBoundingClientRect().top + 12; // 12px from .time-scroller-box CSS
      const items = scroller.querySelectorAll('.scroller-item');

      let closestHour = null;
      let closestDistance = Infinity;

      items.forEach((item) => {
        const itemRect = item.getBoundingClientRect();
        const distance = Math.abs(itemRect.top - boxTop);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestHour = item.textContent.trim();
        }
      });

      this.selectedHour = closestHour;
    },
    updateSelectedMinute() {
      const scroller = this.$refs.minutesScroller;
      const scrollerBox = scroller.querySelector('.scroller-item');

      const boxTop = scroller.getBoundingClientRect().top + 12; // 12px from .time-scroller-box CSS
      const items = scroller.querySelectorAll('.scroller-item');

      let closestMinute = null;
      let closestDistance = Infinity;

      items.forEach((item) => {
        const itemRect = item.getBoundingClientRect();
        const distance = Math.abs(itemRect.top - boxTop);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestMinute = item.textContent.trim();
        }
      });

      this.selectedMinute = closestMinute;
    },
    updateFormattedDate() {
      this.$emit("onDateSelected", this.selectedDate);
    },
    updateTime() {
      this.$emit("onTimeSelected", `${this.selectedHour}:${this.selectedMinute} ${this.amPm}`);
    },
    scrollToSelectedTime() {
      // Multiple attempts to scroll
      this.scrollHourToTop();
      this.scrollMinuteToTop();
    },
    scrollHourToTop() {
      if (!this.$refs.hourScroller) return;

      const hourItems = this.$refs.hourScroller.querySelectorAll('.scroller-item');
      const targetHourItem = Array.from(hourItems).find(item => item.textContent.trim() === this.selectedHour);
      
      if (targetHourItem) {
        const itemHeight = targetHourItem.offsetHeight;
        const itemIndex = Array.from(hourItems).indexOf(targetHourItem);
        this.$refs.hourScroller.scrollTop = itemHeight * itemIndex;
      }
    },
    scrollMinuteToTop() {
      if (!this.$refs.minutesScroller) return;

      const minuteItems = this.$refs.minutesScroller.querySelectorAll('.scroller-item');
      const targetMinuteItem = Array.from(minuteItems).find(item => item.textContent.trim() === this.selectedMinute);
      
      if (targetMinuteItem) {
        const itemHeight = targetMinuteItem.offsetHeight;
        const itemIndex = Array.from(minuteItems).indexOf(targetMinuteItem);
        this.$refs.minutesScroller.scrollTop = itemHeight * itemIndex;
      }
    }
  },
  mounted() {
    if (this.preparedDate) {
    this.selectedDate = this.preparedDate;
  }
  if (this.preparedTime) {
    const [time] = this.preparedTime.split(" ");
    const [hour, minute] = time.split(":");
    
    // Convert 24-hour format to 12-hour format
    let hour12 = parseInt(hour) % 12 || 12;
    this.selectedHour = hour12.toString().padStart(2, '0');
    this.selectedMinute = minute.padStart(2, '0');
    this.amPm = parseInt(hour) >= 12 ? "PM" : "AM";
    console.log(this.selectedHour, this.selectedMinute)
    // Use multiple methods to ensure scrolling
    this.$nextTick(() => {
      // Delay to allow full render
      setTimeout(() => {
        this.scrollToSelectedTime();
      }, 200);
    });
  }
}
};
</script>

<style scoped lang="scss">
.custom-date-time-picker {
  max-width: 500px;
  margin: auto;
}
.custom-date-time-picker__title {
  background-color: #505864;
  min-width: 483px;
  min-height: 88px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 19px 25px 19px;
  border-radius: 10px 10px 0px 0px;
  &--year {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #B3B3B3;
    margin-bottom: 13px;
  }
  &--date {
    font-family: Roboto;
    font-size: 36px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF;
  }
}
.date-picker-container {
  width: 60%;
}
.time-picker-container {
  min-width: 160px;
}
.time-scroller {
  display: flex;
  justify-content: space-around;
  position: relative;
  &-box {
    position: absolute;
    top: 10px;
    left: 6px;
    font-weight: bold;
    background-color: #505864;
    color: #fff;
    border-radius: 4px;
    margin: 1px;
    width: 48px;
    height: 34px;
  }
  & .box-2 {
    left: 50%;
    transform: translateX(-50%);
  }
}
.scroller {
  height: 280px;
  overflow-y: scroll;
  width: 60px;
  text-align: center;
  border-radius: 4px;
  padding: 8px 0;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  padding-bottom: 240px;
  background-color: transparent;
  z-index: 10;
}
.not-scroller {
  height: 280px;
  overflow-y: none;
  width: 60px;
  text-align: center;
  border-radius: 4px;
  padding: 8px 0;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
}
.scroller::-webkit-scrollbar {
  display: none;
}

.scroller-item {
  padding: 6px 2px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, font-weight 0.3s;
}

.not-scroller-item {
  padding: 6px 2px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, font-weight 0.3s;
}

.scroller-item.selected {
  font-weight: bold;
  color: #fff;
}

.not-scroller-item.amSelected {
  background-color: #505864;
  padding: 6px 2px;
  color: #fff;
  border-radius: 4px;
  margin: 1px;
  width: 48px;
  height: 34px;
}
</style>