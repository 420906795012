<template>
  <div>
    <v-app-bar class="app-bar" app color="primary" dark>
      <div class="d-flex align-center">
        <v-expand-x-transition>
          <v-app-bar-nav-icon
            v-if="showMenuButton && !profileLock"
            @click.stop="drawer = !drawer"
          />
        </v-expand-x-transition>
        <v-toolbar-title>
          <span :style="{ fontWeight: 700 }">Cue</span
          ><span :style="{ fontWeight: 200 }">Hub</span>
        </v-toolbar-title>
        <h2
          v-if="$route.name !== 'Home' && $route.name !== 'Login'"
          class="ml-10"
        >
          {{ $route.name }}
        </h2>
      </div>
      <NavBarUser />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      class="nav-drawer-container"
      id="nav-drawer-container"
    >
      <v-list class="list" nav dense>
        <!-- ///////// DATA TOOLS /////////// -->
        <v-list-group v-if="isCueUI" :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="v-subheader">
                <v-chip class="font-weight-bold white elevation-2">
                  MyCue
                  {{ isAdminUI || isManagerUI ? "Manager" : "Staff" }}
                </v-chip></v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!--  -->
          <v-list-item
            v-if="isAdminUI || isManagerUI"
            @click="handleListSelect(`${routesConst.requestedAssignments}`)"
          >
            <v-list-item-title> Requested Assignments </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isAdminUI || isManagerUI"
            @click="handleListSelect(`${routesConst.spreadsheetUpload}`)"
          >
            <v-list-item-title> Spreadsheet Upload </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isAdminUI || isManagerUI"
            @click="handleListSelect(`${routesConst.dataUpload}`)"
          >
            <v-list-item-title> Data Upload </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isAdminUI || isManagerUI"
            @click="handleListSelect(`${routesConst.fileSplitter}`)"
          >
            <v-list-item-title> File Splitter </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isAdminUI || isManagerUI"
            @click="handleListSelect(`${routesConst.pmDashboard}`)"
          >
            <v-list-item-title> PM Dashboard </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="(isCueUI && !isVendor)"
            @click="handleListSelect(`${routesConst.assignmentDashboard}`)"
          >
            <v-list-item-title> Assignment Dashboard </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isFCReviewerView"
            @click="handleListSelect(`${routesConst.ledDashboard}`)"
          >
            <v-list-item-title> Lead Editor Dashboard </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isAdminUI || isManagerUI"
            @click="handleListSelect(`${routesConst.issueTracker}`)"
          >
            <v-list-item-title> Issue Tracker </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="(isCueUI && !isVendor)"
            @click="handleListSelect(`${routesConst.calendar}`)"
          >
            <v-list-item-title> Availability Calendar </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            v-if="isCueUI"
            @click="handleListSelect(`${routesConst.paymentRequests}`)"
          >
            <v-list-item-title> Payment Request Form </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- //////////// CUE ONLY /////////////// -->
        <v-list-group v-if="isDubEditorView" :value="false" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="v-subheader">
                <v-chip class="font-weight-bold white elevation-2"
                  >MyCue Editor</v-chip
                ></v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.editor}`)">
            <v-list-item-title> DubEditor </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- ///////// ADMIN /////////// -->
        <v-list-group v-if="isAdminUI" :value="false" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="v-subheader">
                <v-chip class="font-weight-bold white elevation-2"
                  >MyCue Admin</v-chip
                ></v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.clients}`)">
            <v-list-item-title> Clients </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            @click="handleListSelect(`${routesConst.clientRequesters}`)"
          >
            <v-list-item-title> Client Requesters </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            @click="handleListSelect(`${routesConst.purchaseOrders}`)"
          >
            <v-list-item-title> Purchase Orders </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.projects}`)">
            <v-list-item-title> Projects </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            @click="handleListSelect(`${routesConst.customerPriceList}`)"
          >
            <v-list-item-title> Price List </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.invoiceBot}`)">
            <v-list-item-title> Invoice Bot </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.staff}`)">
            <v-list-item-title> Staff </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.cueHardware}`)">
            <v-list-item-title> Cue Hardware </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.cueSoftware}`)">
            <v-list-item-title> Cue Software </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.settings}`)">
            <v-list-item-title> Settings </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- ///////// CLIENT TOOLS /////////// -->
        <v-list-group
          v-if="isAdminUI || isClientUI"
          :value="isClientUI"
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="v-subheader">
                <v-chip class="font-weight-bold white elevation-2"
                  >MyCue Client</v-chip
                ></v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.myCue}`)">
            <v-list-item-title> Client Dashboard </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.request}`)">
            <v-list-item-title> Client Request Form </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import * as d3 from "d3";
// internal
import { ROUTES } from "@/utils/constants";
// components
import NavBarUser from "@/components/ui/NavBarUser";

export default {
  components: { NavBarUser },
  data: function () {
    return {
      drawer: false,
    };
  },
  methods: {
    handleListSelect(value) {
      this.drawer = false;
      this.$router.push(value);
    },
  },
  computed: {
    ...mapGetters("auth", [
      "user",
      "isAdmin",
      "isDubEditor",
      "profileLock",
      "isManager",
    ]),
    showMenuButton() {
      return Object.keys(this.user ?? {}).length;
    },
    isAdminUI() {
      return this.isAdmin;
    },
    isManagerUI() {
      return this.isManager;
    },
    isClientUI() {
      return this.user?.user?.userRole === "CLIENT";
    },
    isVendor() {
      return this.user?.adminDetails?.vendor;
    },
    isCueUI() {
      return this.isAdmin || this.user?.user?.userRole !== "CLIENT";
    },
    isVendor() {
      return this.user?.adminDetails?.vendor;
    },
    isFCReviewerView() {
      return (
        this.isAdmin || (this.isCueUI && this.user?.adminDetails?.leadEditor)
      );
    },
    isDubEditorView() {
      return this.isAdmin || this.isDubEditor;
    },
    routesConst() {
      return ROUTES;
    },
  },
  mounted() {
    // bit of a hack here as CSS selectors we're playing nice.
    // this forces the list to be flex with main list at top and secondary list at bottom
    const a = d3
      .select("#nav-drawer-container")
      .select(".v-navigation-drawer__content");

    a.style("display", "flex");
    a.style("flex-flow", "column");
    a.style("justify-content", "space-between");
  },
};
</script>

<style lang="scss" scoped>
#nav-drawer-container {
  height: 100vh !important;
}
.v-list {
  .v-list-item {
    padding: 0px 20px !important;
  }
}
::v-deep {
  .v-list-item__icon {
    margin: 0 !important;
    align-self: unset;
    color: rgba(0, 0, 0, 0.6);
    i {
      font-size: 30px;
    }
  }
}
</style>
