<template>
  <v-card class="grey lighten-2 pt-1">
    <EditorNewAssignmentItem
      v-if="this.editAssignmentData"
      :editMode="true"
      :editorStartData="editorStartData"
    />
    <v-card-actions class="px-4 pb-4">
      <v-btn
        block
        color="secondary"
        :loading="assignmentsProcessing"
        @click="handleSaveButton"
        >save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { updateAssignmentWithData, getSortField } from "@/utils/editorUtils";
// components
import EditorNewAssignmentItem from "@/components/Editor/EditorNewAssignmentModal/EditorNewAssignmentItem/EditorNewAssignmentItem";

export default {
  name: "EditorEditAssignmentModal",
  components: { EditorNewAssignmentItem },
  data() {
    return {
      editorStartData: null,
      assignmentsProcessing: false,
    };
  },
  computed: {
    ...mapGetters("editor", [
      "editAssignmentID",
      "selectedProject",
      "editAssignmentData",
      "editAssignmentModalOpen",
    ]),
    ...mapGetters("auth", ["user", "isAdmin"]),
  },
  watch: {
    editAssignmentID(newValue) {
      this.loadDataToEditObject(newValue);
    },
    editAssignmentModalOpen(newValue) {
      if (newValue === true) {
        this.loadDataToEditObject(this.editAssignmentID);
      } else if (newValue === false) {
        this.editorStartData = null;
        this.setEditAssignmentData(null);
      }
    },
  },
  mounted() {
    this.loadDataToEditObject(this.editAssignmentID);
  },
  methods: {
    ...mapActions("editor", [
      "setEditAssignmentData",
      "setTableAssignments",
      "setEditAssignmentModalOpen",
    ]),
    handleSaveButton() {
      this.assignmentsProcessing = true;
      updateAssignmentWithData({
        id: this.editAssignmentID,
        project: this.selectedProject,
        assignment: this.editAssignmentData,
        originalData: this.editorStartData,
      })
        .then(() => {
          // get data for table refresh
          return this.selectedProject.getAssignmentDatas({
            isAdmin: this.isAdmin,
            userEmail: this.user.user.email,
          });
        })
        .then((d) => {
          // do table refresh
          d.forEach((el) => {
            el.sortDate = getSortField({
              row: el,
              isAdmin: this.isAdmin,
              userEmail: this.user.user.email,
            });
          });
          this.setTableAssignments(d);
          this.assignmentsProcessing = false;
          this.setEditAssignmentModalOpen(false);
        })
        .catch((err) => {
          this.assignmentsProcessing = false;
          console.error(err);
        });
    },
    loadDataToEditObject(id) {
      this.selectedProject
        .getSingleAssignment(id)
        .then((d) => {
          this.editorStartData = d;
          this.setEditAssignmentData(d);
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style lang="scss" scoped></style>
