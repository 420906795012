<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ softwareInfo.serviceType }}</div>
        <h2>{{ softwareInfo.serviceName }}</h2>
        <v-chip :disabled="!softwareInfo.planType" class="my-5">
          {{ softwareInfo.planType ? softwareInfo.planType : "No Plan Type" }}
        </v-chip>
        <div>
          <!-- Plan Name -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Plan Name</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              softwareInfo.planName
            }}</v-col>
          </v-row>

          <!-- Account Owner -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Account Owner</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              softwareInfo.accountEmail
            }}</v-col>
          </v-row>

          <!-- User Agreement -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >User Agreement</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                v-if="softwareInfo.userAgreement"
                color="accent"
                :loading="userAgreementLoading"
                @click="downloadUserAgreement"
              >
                Download<v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-icon v-else>mdi-file-document-remove-outline</v-icon>
            </v-col>
          </v-row>

          <!-- Users -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Users</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <ul>
                <li v-for="(user, index) in softwareInfo.staff" :key="index">
                  {{ `${user.firstName} ${user.lastName}` }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center mb-3">
          <h3 class="pb-2">Usage Info</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">User Amount (Max)</th>
                  <th class="text-center">User Amount (Current)</th>
                  <th class="text-center">Device Amount (Max)</th>
                  <th class="text-center">Device Amount (Current)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ softwareInfo.maxUserAmount }}
                  </td>
                  <td>
                    {{ softwareInfo.currentUserAmount }}
                  </td>
                  <td>
                    {{ softwareInfo.deviceAmountMax }}
                  </td>
                  <td>
                    {{ softwareInfo.deviceAmount }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="table-bg text-center">
          <h3 class="pb-2">Subscription Info</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Type</th>
                  <th class="text-center">Amount (Monthly)</th>
                  <th class="text-center">Start Date</th>
                  <th class="text-center">End Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ softwareInfo.subscriptionType }}
                  </td>
                  <td>
                    {{
                      softwareInfo.subscriptionAmount
                        ? `$${softwareInfo.subscriptionAmount.toFixed(2)}`
                        : ""
                    }}
                  </td>
                  <td>
                    {{ formatDate(softwareInfo.subscriptionStartDate) }}
                  </td>
                  <td>{{ formatDate(softwareInfo.subscriptionEndDate) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// library
import { saveAs } from "file-saver";
// internal
import { getFile } from "@/utils/newDbUtils";

export default {
  name: "CueSoftwareTableExpansion",
  props: {
    softwareInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userAgreementLoading: false,
    };
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment.unix(date).format("MM-DD-YYYY") : "";
    },
    downloadUserAgreement() {
      this.userAgreementLoading = true;
      getFile({ url: this.softwareInfo.userAgreement }).then(async (string) => {
        const base64Response = await fetch(`data:image/jpg;base64,${string}`),
          blob = await base64Response.blob();
        saveAs(blob, `${this.softwareInfo.serviceName}_User Agreement.jpg`);
        this.userAgreementLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
