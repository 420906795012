//
import { DubEditor } from "dub-editor";
import { db, auth } from "@/providers/firebase";
import {
  DOCUMENTS_COLLECTION_NAME,
  ASSIGNMENTS_COLLECTION_NAME,
  PROJECTS_COLLECTION_NAME,
  EDITOR_AUTOSAVE_INTERVAL,
  BLANK_DOCUMENT_CONTENTS,
  BLANK_DOCUMENT_STRING,
} from "@/utils/editorUtils";

const dubConfig = {
  db, // this is the firebase DB
  userEmail: auth.currentUser?.email ?? "marc@companycue.com", // current user email
  documentsCollectionName: DOCUMENTS_COLLECTION_NAME,
  assignmentsCollectionName: ASSIGNMENTS_COLLECTION_NAME,
  projectsCollectionName: PROJECTS_COLLECTION_NAME,
  editorAutosaveInterval: EDITOR_AUTOSAVE_INTERVAL,
  blankDocumentContents: BLANK_DOCUMENT_CONTENTS,
  blankDocumentString: BLANK_DOCUMENT_STRING,
};

const dub = new DubEditor(dubConfig);

export { dub };
