<template>
  <div class="staff my-10 px-2">
    <div class="d-flex align-end mb-4" :style="{ position: 'relative' }">
      <!-- left -->

      <!-- new staff -->
      <v-btn
        v-if="!laptopSmaller && isAdmin"
        height="38px"
        color="secondary"
        :loading="showTableLoader"
        @click="handleStaffForm({})"
        >New</v-btn
      >
      <div v-if="laptopSmaller && isAdmin" class="text-center">
        <span class="overline"> New </span>
        <div>
          <v-btn
            min-width="42px"
            height="38px"
            class="px-0"
            color="secondary"
            :loading="showTableLoader"
            @click="handleStaffForm({})"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- filters -->
      <div class="d-flex" :class="laptopSmaller ? 'align-end' : 'align-center'">
        <v-badge
          v-if="!laptopSmaller"
          bordered
          color="accent"
          overlap
          :value="filtersBadge"
          :content="filtersBadge"
          ><v-btn
            height="38px"
            color="#494949"
            class="ml-3"
            dark
            :loading="showTableLoader"
            @click="filtersDialog = true"
            >Add Filters</v-btn
          ></v-badge
        >
        <div v-else class="text-center ml-3">
          <span class="overline"> Add </span>
          <div>
            <v-badge
              bordered
              color="accent"
              overlap
              :value="filtersBadge"
              :content="filtersBadge"
            >
              <v-btn
                min-width="42px"
                height="38px"
                color="#494949"
                class="px-0"
                :loading="showTableLoader"
                @click="filtersDialog = true"
              >
                <v-icon color="white">mdi-filter-plus</v-icon>
              </v-btn></v-badge
            >
          </div>
        </div>
        <v-btn
          v-if="!laptopSmaller"
          height="38px"
          color="secondary"
          class="ml-3"
          :disabled="!filtersBadge"
          :loading="showTableLoader"
          @click="clearFilters(false)"
          >Clear Filters</v-btn
        >
        <div v-else class="ml-3 text-center">
          <span class="overline"> Clear </span>
          <div>
            <v-btn
              min-width="42px"
              height="38px"
              class="px-0"
              color="secondary"
              :disabled="!filtersBadge"
              :loading="showTableLoader"
              @click="clearFilters(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- total data -->
        <transition>
          <v-chip
            v-if="!laptopSmaller"
            v-show="tableData"
            class="font-weight-bold accent elevation-2 ml-3"
          >
            {{ `TOTAL: ${totalInfo}` }}
          </v-chip>
          <div v-else v-show="tableData" class="text-center ml-3 mb-1">
            <span class="overline"> Total </span>
            <div>
              <v-chip class="font-weight-bold accent elevation-2">
                {{ totalInfo }}
              </v-chip>
            </div>
          </div>
        </transition>

        <v-dialog
          content-class="filters"
          transition="dialog-top-transition"
          max-width="1500px"
          v-model="filtersDialog"
          hide-overlay
          persistent
          no-click-animation
        >
          <v-card
            class="d-flex justify-space-around flex-wrap px-6 pt-md-2 pt-lg-4 pb-md-4 pb-lg-6"
          >
            <!--  -->
            <div class="text-center">
              <span class="overline"> Active Staff </span>
              <div class="d-flex align-end justify-center">
                <v-checkbox
                  class="mt-2 pt-0"
                  v-model="filterActiveStaff"
                  @change="handleFilterQuery('ActiveStaff', $event)"
                ></v-checkbox>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> Status </span>
              <div>
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  multiple
                  dense
                  v-model="filterStatus"
                  @change="handleFilterQuery('Status', $event)"
                >
                  <v-btn
                    v-for="(status, i) in statusOptions"
                    :key="i"
                    height="38px"
                    >{{ status }}</v-btn
                  >
                </v-btn-toggle>
              </div>
            </div>

            <!--  -->
            <div class="text-center">
              <span class="overline"> Start Date(s) </span>
              <div class="d-flex align-end">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  solo-inverted
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterStartDateRange"
                      class="staff__date-selector selector"
                      solo
                      dense
                      readonly
                      hide-details
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="clearFilters(true)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterStartDate"
                    range
                    @input="handleFilterQuery('StartDate', $event)"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> Skills </span>
              <div>
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  multiple
                  dense
                  v-model="filterSkill"
                  @change="handleFilterQuery('Skill', $event)"
                >
                  <v-tooltip
                    v-for="(skill, i) in skills"
                    :key="i"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        min-width="38px"
                        height="38px"
                        class="px-0"
                        ><v-icon
                          :color="
                            filterSkill.includes(i) ? 'accent' : '#000000de'
                          "
                          >{{ `mdi-${skill.icon}` }}</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ skill.name }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> General Expertise </span>
              <div>
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  multiple
                  dense
                  v-model="filterExpertise"
                  @change="handleFilterQuery('Expertise', $event)"
                >
                  <v-tooltip
                    v-for="(item, i) in expertise"
                    :key="i"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        min-width="38px"
                        height="38px"
                        class="px-0"
                        ><v-icon
                          :color="
                            filterExpertise.includes(i) ? 'accent' : '#000000de'
                          "
                          >{{ `mdi-${item.icon}` }}</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> Native Language </span>
              <div>
                <v-autocomplete
                  class="staff__language-selector selector"
                  :items="languages"
                  v-model="filterLanguage"
                  item-text="value"
                  solo
                  dense
                  hide-details
                  @input="handleFilterQuery('Language', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!--  -->
            <div class="ml-2 text-center">
              <span class="overline"> Location (Country) </span>
              <div class="d-flex align-end">
                <v-autocomplete
                  class="staff__country-selector selector selector-width-2l"
                  :items="countries"
                  v-model="filterCountry"
                  solo
                  dense
                  hide-details
                  :loading="dropdownsLoading"
                  @change="handleFilterQuery('Country', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!-- <div class="ml-2 text-center">
              <span class="overline"> Lead Editor </span>
              <div class="d-flex align-end justify-center">
                <v-checkbox
                  class="mt-2 pt-0"
                  v-model="filterLeadEditors"
                  @change="handleFilterQuery('LeadEditors', $event)"
                ></v-checkbox>
              </div>
            </div>

            <div class="ml-4 text-center">
              <span class="overline"> Project Coordinator </span>
              <div class="d-flex align-end justify-center">
                <v-checkbox
                  class="mt-2 pt-0"
                  v-model="filterPCs"
                  @change="handleFilterQuery('PCs', $event)"
                ></v-checkbox>
              </div>
            </div> -->

            <!--  -->
            <div class="ml-2 text-center">
              <span class="overline"> Role </span>
              <div class="d-flex align-end justify-center w-100">
                <v-autocomplete
                  class="selector-width-2l"
                  solo
                  dense
                  hide-details
                  :items="rolesList"
                  v-model="filterRole"
                ></v-autocomplete>
              </div>
            </div>

            <!--  -->
            <div v-if="filterRole === 'STAFF'"
              class="ml-2 text-center">
              <span class="overline"> Operational Role </span>
              <div class="d-flex align-end justify-center w-100">
                <v-autocomplete
                  class="selector-width-2l"
                  multiple
                  small-chips
                  solo
                  dense
                  hide-details
                  :items="operationalRolesList"
                  v-model="operationalRoles"
                ></v-autocomplete>
              </div>
            </div>

            <!--  -->
            <div class="ml-2 text-center">
              <span class="overline"> Search </span>
              <div>
                <v-text-field
                  v-model="search"
                  class="staff__search-selector selector staff__search-selector-2l"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  dense
                  hide-details
                  clearable
                  @click:prepend-inner="applySearch(search)"
                  @click:clear="applySearch('')"
                  @keydown.enter="applySearch(search)"
                />
              </div>
            </div>

            <v-card-actions class="ml-auto mt-5 pa-0 align-end">
              <v-btn
                color="secondary"
                @click="clearFilters(false)"
                :disabled="!filtersBadge"
                >Clear Filters</v-btn
              >
              <v-btn color="#494949" dark @click="filtersDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          class="staff__modal"
          max-width="1200"
          v-model="staffModal"
          persistent
        >
          <StaffForm
            :isOperating="staffModal"
            :itemToEdit="itemToEdit"
            :profileView="false"
            @close-form="staffModal = false"
            @refresh-data="handleRefresh"
          />
        </v-dialog>
      </div>

      <!-- right -->
      <div class="ml-auto">
        <v-text-field
          v-model="search"
          class="staff__search-selector selector"
          append-outer-icon="mdi-magnify"
          solo
          dense
          hide-details
          clearable
          :loading="showTableLoader"
          :disabled="showTableLoader"
          @click:append-outer="applySearch(search)"
          @click:clear="applySearch('')"
          @keydown.enter="applySearch(search)"
        />
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <StaffTable
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :filterStartDate="filterStartDate"
        :filterSearch="filterSearch"
        :filterLanguage="filterLanguage ? filterLanguage : ''"
        :filterStatus="filterStatus"
        :filterActiveStaff="filterActiveStaff"
        :filterCountry="filterCountry ? filterCountry : ''"
        :filterSkill="filterSkill"
        :filterExpertise="filterExpertise"
        :filterLeadEditors="filterLeadEditors"
        :filterDubEditor="filterDubEditor"
        :filterRole="filterRole"
        :filterPCs="filterPCs"
        @call-edit-form="handleStaffForm"
        @refresh-total-info="setTotalInfo"
      />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import _ from "lodash";
// internal
import { getAllStaff, getEnum } from "@/utils/newDbUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
// components
import StaffTable from "@/components/Staff/StaffTable";
import StaffForm from "@/components/Staff/StaffForm";

export default {
  name: "Staff",
  components: {
    StaffTable,
    StaffForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      filterStartDate: [],
      filterSearch: "",
      search: "",
      expandMenu: false,
      dropdownsLoading: true,
      filterActiveStaff: false,
      statusOptions: [],
      filterStatus: [0],
      languages: [],
      filterLanguage: "",
      countries: [],
      filterCountry: "",
      skills: [
        { name: "Copywriting", icon: "fountain-pen-tip" },
        { name: "Copyediting", icon: "text-box-edit" },
        { name: "Translation", icon: "translate-variant" },
        { name: "Editing Translations", icon: "text-box-edit-outline" },
        { name: "Curation", icon: "table-filter" },
        { name: "Design", icon: "pencil-ruler" },
        { name: "Metadata", icon: "database" },
        { name: "Project Management", icon: "account-cog" },
      ],
      filterSkill: [],
      expertise: [
        { name: "Music", icon: "music" },
        { name: "TV & Films", icon: "video-vintage" },
        { name: "Books", icon: "book-open-page-variant" },
        { name: "Mobile/Video Games", icon: "controller" },
        { name: "Podcasts", icon: "microphone" },
        { name: "Mobile Apps", icon: "tablet-cellphone" },
        { name: "Lifestyle", icon: "creation" },
        { name: "Sports", icon: "soccer" },
      ],
      filterExpertise: [],
      filterLeadEditors: false,
      filterDubEditor: false,
      filterPCs: false,
      filtersDialog: false,
      staffModal: false,
      itemToEdit: {},
      totalInfo: "",
      operationalRolesList: [
        "Lead Editor",
        "DubEditor Admin"
      ],
      filterRole: "",
      rolesList: [
        "ADMIN",
        "MANAGER",
        "STAFF",
        "VENDOR",
        "CANDIDATE",
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin"]),
    filtersBadge() {
      return Object.entries(this.$route.query).filter((el) => el[1] !== null)
        .length || this.filterRole || this.filterDubEditor || this.filterLeadEditors;
    },
    filterStartDateRange: {
      get: function () {
        return this.filterStartDate
          .map((date) => this.formatPickerDate(date))
          .join(" - ");
      },
      set: function (newVal) {
        return (
          this.formatPickerDate(newVal)
            ? [...this.formatPickerDate(newVal)]
            : []
        ).join(" - ");
      },
    },
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
    operationalRoles: {
      get() {
        const roles = [];
        return roles;
      },
      set(value) {
        if (value.includes("DubEditor Admin")) {
          this.filterDubEditor = true;
        } else {
          this.filterDubEditor = false;
        }
        if (value.includes("Lead Editor")) {
          this.filterLeadEditors = true;
        } else {
          this.filterLeadEditors = false;
        }
      },
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.staff}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    this.handleDropdowns();
  },
  methods: {
    setTotalInfo(newTotal) {
      this.totalInfo = newTotal;
    },
    applySearch(search) {
      this.filterSearch = search;
    },
    getTableData() {
      this.showTableLoader = true;
      getAllStaff().then((data) => {
        this.tableData = data?.status === 500 ? [] : data;
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.staff}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters(dateOnly) {
      let query = this.$route.query;

      if (dateOnly) {
        query = _.omit(query, ["StartDate"]);
      } else {
        this.filterStatus = [0];
        this.filterSkill = this.filterExpertise = [];
        this.filterLeadEditors =
          this.filterDubEditor =
          this.filterPCs =
          this.filterActiveStaff =
            false;
        this.filterLanguage =
          this.filterRole =
          this.filterCountry =
          this.filterSearch =
          this.search =
            "";
      }

      this.filterStartDate = [];

      this.$router.push({ path: `/${ROUTES.staff}` }).catch(() => {}); // Avoided redundant navigation error handler
    },
    getLanguages() {
      return new Promise((resolve, reject) => {
        getEnum("Language").then((languages) =>
          resolve(
            (this.languages = languages.map((el) => {
              return { value: el, disabled: false };
            }))
          )
        );
      });
    },
    getStatuses() {
      return new Promise((resolve, reject) => {
        getEnum("UserStatus").then((statuses) =>
          resolve((this.statusOptions = statuses))
        );
      });
    },
    getContries() {
      return new Promise((resolve, reject) => {
        getEnum("Country").then((countries) =>
          resolve((this.countries = countries))
        );
      });
    },
    handleDropdowns() {
      Promise.all([
        this.getLanguages(),
        this.getStatuses(),
        this.getContries(),
      ]).then(() => (this.dropdownsLoading = false));
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach((param) => {
          if (param === "StartDate") {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [currentQuery[param]]
                : currentQuery[param];
          } else if (
            param === "ActiveStaff" ||
            param === "LeadEditors" ||
            param === "PCs"
          ) {
            this[`filter${param}`] = currentQuery[param] === "true";
          } else if (
            param === "Skill" ||
            param === "Expertise" ||
            param === "Status"
          ) {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [Number(currentQuery[param])]
                : currentQuery[param].map((x) => Number(x));
          } else {
            this[`filter${param}`] = currentQuery[param];
            if (param === "Search") this.search = currentQuery[param];
          }
        });
    },
    handleStaffForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.staffModal = true;
    },
    handleRefresh() {
      this.staffModal = false;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.staff {
  max-width: 1500px;
  margin: 0 auto;

  &__date-selector {
    width: 220px;
  }

  &__language-selector {
    width: 200px;
  }

  &__country-selector {
    width: 280px;
  }

  &__search-selector {
    width: 450px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
  .v-dialog--active {
    position: relative;
  }
  .filters {
    position: absolute;
    top: 20px;
  }
  .v-input--selection-controls__input {
    margin-right: 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

.selector-width-2l {
  width: 200px;
}

.staff__search-selector-2l {
  width: 290px !important;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
