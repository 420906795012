<template>
  <div>
    <v-progress-circular v-if="updateProcessing" indeterminate :size="24" />
    <v-select
      v-else
      :key="itemUpdate"
      class="caption status-selector"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="roleMode ? roleBasedOptions : options"
      item-text="value"
      dense
      hide-details
      outlined
      :disabled="
        roleMode ? !allowedToUpdateStatuses().includes(currentStatus) : false
      "
      :value="currentStatus"
      :loading="optionsLoading"
      @change="(selected) => onChange({ item, selected })"
      @click.native.stop.prevent
    />
  </div>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  processAssignment,
  getAssignments,
  getTasks,
  processTask,
} from "@/utils/newDbUtils";

export default {
  name: "ADStatusSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionsLoading: {
      type: Boolean,
      required: true,
    },
    roleMode: {
      type: String,
      required: true,
    },
    writerStatuses: {
      type: Array,
      required: true,
    },
    editorStatuses: {
      type: Array,
      required: true,
    },
    reviewerStatuses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentStatus: null,
      updateProcessing: false,
      itemUpdate: 0,
    };
  },
  computed: {
    statusColor() {
      return this.options.find((el) => el.value === this.currentStatus)?.color;
    },
    roleBasedOptions() {
      return this.options
        .map((el) => ({
          ...el,
          disabled: !this.allowedToSelectStatuses().includes(el.value),
        }))
        .sort((a, b) => Number(a.disabled) - Number(b.disabled));
    },
  },
  watch: {
    value(newValue) {
      this.currentStatus = newValue;
    },
  },
  mounted() {
    this.currentStatus = this.value;
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    onChange({ item, selected }) {
      if (item.project.id === 115
        && item.assignmentDetails.requestType === "Adaptation"
        && selected === "Delivered"
        && (!item.assignmentDetails.wordCount || !item.pricing.workTypes[0].quantity)
      ) {
        this.itemUpdate += 1;
        this.$emit("show-warning-modal", true);
        return;
      }
      this.currentStatus = selected;
      this.updateProcessing = true;
      processAssignment({
        data: {
          assignmentStatus: selected,
        },
        id: item.id,
        patch: true,
      })
        .then((resp) => {
          if (resp.status > 204) {
            this.updateProcessing = false;
            this.handleFlash({ response: resp, show: true });
            console.error(resp);
          } else {
            this.$emit("refresh-table-data", item.index, selected);
            // handle task status after assignment status change
            if (selected.endsWith("Complete")) {
              getAssignments({ query: { search: item.cueTaskId } })
                .then((assgnResp) => {
                  const assgnsWithCueIdField = assgnResp.content.filter(
                    (el) => el.cueTaskId === item.cueTaskId
                  );
                  if (
                    assgnsWithCueIdField.every(
                      (el) => el.assignmentDetails.assignmentStatus === selected
                    )
                  ) {
                    getTasks({ query: { search: item.cueTaskId } })
                      .then((taskResp) => {
                        const task = taskResp.content.find(
                          (el) => el.cueTaskId === item.cueTaskId
                        );
                        if (task) {
                          processTask({
                            data: {
                              taskStatus: selected,
                            },
                            id: task.id,
                            patch: true,
                          }).then((resp) =>
                            this.handleFlash({ response: resp, show: true })
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                        this.handleFlash({ response: err, show: true });
                      });
                  }
                  this.updateProcessing = false;
                })
                .catch((err) => {
                  console.error(err);
                  this.updateProcessing = false;
                  this.handleFlash({ response: err, show: true });
                });
            } else {
              this.updateProcessing = false;
              this.handleFlash({ response: resp, show: true });
            }
          }
        })
        .catch((err) => {
          this.updateProcessing = false;
          this.handleFlash({ response: err, show: true });
          console.error(err);
        });
    },
    allowedToSelectStatuses() {
      let allowedStatuses = [...this[`${this.roleMode}Statuses`]];
      if (this.roleMode === "writer") {
        if (this.currentStatus === "Assigned") allowedStatuses.splice(1, 1);
        if (this.currentStatus === "Rejected") allowedStatuses.splice(0, 1);
      }
      return allowedStatuses;
    },
    allowedToUpdateStatuses() {
      const allowedStatuses = this[`${this.roleMode}Statuses`].filter(
        (el) => el != this[`${this.roleMode}Statuses`].slice(-1)
      );
      return this.roleMode !== "reviewer"
        ? allowedStatuses
        : this[`${this.roleMode}Statuses`];
    },
  },
};
</script>

<style lang="scss" scoped>
.status-selector {
  width: 120px;
  margin: 0 auto;
}
</style>
