<template>
  <v-slide-x-transition>
    <div v-if="userEmail" class="text-center">
      <v-btn icon large class="mr-2" @click="slackFormModal = true"
        ><v-icon>mdi-lifebuoy</v-icon></v-btn
      >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small :loading="userRefresh" v-bind="attrs" v-on="on">
            {{ userEmail }}
            <v-icon id="down-icon">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="handleItemSelect(item.value)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-overlay id="reset-overlay" v-model="pwChangeDialog">
        <v-form ref="form" class="theme--dark v-card v-sheet">
          <v-card-text @click.stop>
            <v-text-field
              label="Enter a new password"
              v-model="newPassword"
              type="password"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="ma-1"
              color="white"
              plain
              @click.stop="pwChangeDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              class="ma-1"
              color="secondary"
              plain
              @click.stop="handleResetSend"
              >Reset</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-overlay>
      <v-dialog
        max-width="1200"
        v-model="slackFormModal"
        @click:outside="slackFormModal = false"
        @keydown.esc="slackFormModal = false"
      >
        <SlackInquiryForm
          :inquiryItem="{}"
          :mode="'bug'"
          @close-slack-form="slackFormModal = false"
        />
      </v-dialog>
      <v-dialog max-width="1200" v-model="profileModal" persistent>
        <StaffForm
          :isOperating="profileModal"
          :itemToEdit="user"
          :profileView="true"
          @close-form="handleProfileModal(false)"
          @refresh-data="handleUserRefresh"
        />
      </v-dialog>
    </div>
  </v-slide-x-transition>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { changePassword, getAllStaff, toTitleCase } from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
// components
import SlackInquiryForm from "./SlackInquiryForm";
import StaffForm from "@/components/Staff/StaffForm";

export default {
  name: "NavBarUser",
  components: {
    SlackInquiryForm,
    StaffForm,
  },
  data() {
    return {
      pwChangeDialog: false,
      userRefresh: false,
      slackFormModal: false,
      newPassword: "",
      itemToEdit: {},
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  methods: {
    ...mapActions("auth", [
      "setUser",
      "handleProfileModal",
      "setIsAdmin",
      "setIsManager",
      "setIsDubEditor",
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    handleItemSelect(value) {
      if (value === "logout") this.doLogout();
      else if (value === "changePW") this.pwChangeDialog = true;
      else if (value === "profile") this.handleProfileModal(true);
    },
    doLogout() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      this.setUser(null);
      this.setIsAdmin(false);
      this.setIsDubEditor(false);
      this.$router.push("login");
    },
    handleResetSend() {
      if (this.$refs.form.validate()) {
        changePassword({
          id: this.user.user.id,
          password: this.newPassword,
        }).then((resp) => {
          this.handleFlash({ response: resp, show: true });
          this.pwChangeDialog = false;
          this.newPassword = "";
          this.doLogout();
        });
      }
    },
    handleUserRefresh(handleProfileChange, userData, modifiedKeys) {
      this.handleProfileModal(false);
      this.userRefresh = true;
      if (handleProfileChange) {
        this.profileUpdateNotifying(userData, modifiedKeys);
        getAllStaff({ id: this.user.user.id }).then((resp) => {
          this.setUser(resp);
          this.userRefresh = false;
        });
      }
    },
    profileUpdateNotifying(userData, modifiedKeys) {
      const keys = modifiedKeys
        .map(
          (el) =>
            `${toTitleCase(Object.keys(el)[0])}: ${
              Array.isArray(Object.values(el)[0])
                ? Object.values(el)[0].join(", ")
                : Object.values(el)[0] ?? ""
            }`
        )
        .join("\n");
      if (keys.length)
        publishMessage({
          user: `*${userData.user.firstName} ${userData.user.lastName}*`,
          data: {
            isActive: userData.adminDetails.activeStaff,
            keys,
          },
          mode: "profileUpdate",
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "profileModal"]),
    userEmail() {
      return this.user?.user?.email;
    },
    items() {
      const itemsList = [
        { title: "Profile", value: "profile" },
        { title: "Reset Password", value: "changePW" },
        { title: "Logout", value: "logout" },
      ];
      if (this.user?.user?.userRole === "CLIENT") itemsList.shift();
      return itemsList;
    },
  },
};
</script>

<style lang="scss" scoped>
#reset-overlay {
  position: fixed;
  height: 100vh;
}

::v-deep {
  .v-btn__content {
    line-height: 1;
  }
}

#down-icon {
  margin-top: 1px;
}
</style>
