import {
  BLANK_NEW_ASSIGNMENT_DATAS,
  BLANK_NEW_PROJECT_DATA,
} from "@/utils/editorUtils";

export const editorModule = {
  state: () => ({
    // modals
    newAssignmentModalOpen: false,
    editAssignmentModalOpen: false,
    projectModalOpen: false,
    // assignments
    tableAssignments: null,
    tableAssignmentsNeedsRefresh: false,
    openAssignment: null,
    editAssignmentID: null,
    editAssignmentData: null,
    newAssignmentDatas: BLANK_NEW_ASSIGNMENT_DATAS,
    assignmentComments: [],
    // projects
    allProjects: null,
    dbProjects: null,
    selectedProject: null,
    editProjectData: BLANK_NEW_PROJECT_DATA,
    projectPOs: null,
    projectRequesters: null,
    // editor status
    flowStage: 0,
    // staff
    staffLoading: true,
    staff: [],
  }),
  mutations: {
    SET_ALL_PROJECTS(state, data) {
      state.allProjects = data;
    },
    SET_STAFF(state, data) {
      state.staff = data;
      state.staffLoading = false;
    },
    SET_DB_PROJECTS(state, data) {
      state.dbProjects = data;
    },
    SET_SELECTED_PROJECT(state, project) {
      state.selectedProject = project;
    },
    SET_TABLE_ASSIGNMENTS(state, data) {
      state.tableAssignments = data;
    },
    SET_TABLE_ASSIGNMENTS_NEEDS_REFRESH(state, needsRefresh) {
      state.tableAssignmentsNeedsRefresh = needsRefresh;
    },
    SET_OPEN_ASSIGNMENT(state, id) {
      state.openAssignment = id;
    },
    SET_FLOW_STAGE(state, stage) {
      state.flowStage = stage;
    },
    SET_EDIT_ASSIGNMENT_MODAL_OPEN(state, editAssignmentModalOpen) {
      state.editAssignmentModalOpen = editAssignmentModalOpen;
    },
    SET_EDIT_ASSIGNMENT_ID(state, editAssignmentID) {
      state.editAssignmentID = editAssignmentID;
    },
    SET_EDIT_ASSIGNMENT_DATA(state, editAssignmentData) {
      state.editAssignmentData = editAssignmentData;
    },
    SET_NEW_ASSIGNMENT_MODAL_OPEN(state, assignmentModalOpen) {
      state.newAssignmentModalOpen = assignmentModalOpen;
    },
    SET_ASSIGNMENT_COMMENTS(state, comments) {
      state.assignmentComments = comments;
    },
    SET_PROJECT_MODAL_OPEN(state, projectModalOpen) {
      state.projectModalOpen = projectModalOpen;
    },
    SET_NEW_ASSIGNMENT_DATAS(state, newDatas) {
      state.newAssignmentDatas = newDatas;
    },
    RESET_NEW_ASSIGNMENT_DATAS(state) {
      state.newAssignmentDatas = [...BLANK_NEW_ASSIGNMENT_DATAS];
    },
    SET_EDIT_PROJECT_DATA(state, newData) {
      state.editProjectData = newData;
    },
    RESET_EDIT_PROJECT_DATA(state) {
      state.editProjectData = BLANK_NEW_PROJECT_DATA;
    },
    SET_PROJECT_POS(state, poData) {
      state.projectPOs = poData;
    },
    SET_PROJECT_REQUESTERS(state, requesters) {
      state.projectRequesters = requesters;
    },
  },
  actions: {
    setAllProjects(context, data) {
      context.commit("SET_ALL_PROJECTS", data);
    },
    setStaff(context, data) {
      context.commit("SET_STAFF", data);
    },
    setDbProjects(context, data) {
      context.commit("SET_DB_PROJECTS", data);
    },
    setSelectedProject(context, project) {
      context.commit("SET_SELECTED_PROJECT", project);
    },
    setTableAssignments(context, data) {
      context.commit("SET_TABLE_ASSIGNMENTS", data);
    },
    setTableAssignmentsNeedsRefresh(context, needsRefresh) {
      context.commit("SET_TABLE_ASSIGNMENTS_NEEDS_REFRESH", needsRefresh);
    },
    setOpenAssignment(context, id) {
      context.commit("SET_OPEN_ASSIGNMENT", id);
    },
    setFlowStage(context, stage) {
      context.commit("SET_FLOW_STAGE", stage);
    },
    setEditAssignmentID(context, editAssignmentID) {
      context.commit("SET_EDIT_ASSIGNMENT_ID", editAssignmentID);
    },
    setEditAssignmentData(context, editAssignmentData) {
      context.commit("SET_EDIT_ASSIGNMENT_DATA", editAssignmentData);
    },
    setEditAssignmentModalOpen(context, editAssignmentModalOpen) {
      context.commit("SET_EDIT_ASSIGNMENT_MODAL_OPEN", editAssignmentModalOpen);
    },
    setAssignmentComments(context, comments) {
      context.commit("SET_ASSIGNMENT_COMMENTS", comments);
    },
    setProjectModalOpen(context, projectModalOpen) {
      if (!projectModalOpen) {
        context.commit("RESET_EDIT_PROJECT_DATA");
      }
      context.commit("SET_PROJECT_MODAL_OPEN", projectModalOpen);
    },
    setNewAssignmentModalOpen(context, assignmentModalOpen) {
      if (!assignmentModalOpen) {
        context.commit("RESET_NEW_ASSIGNMENT_DATAS");
      }
      context.commit("SET_NEW_ASSIGNMENT_MODAL_OPEN", assignmentModalOpen);
    },
    setNewAssignmentDatas(context, newDatas) {
      context.commit("SET_NEW_ASSIGNMENT_DATAS", newDatas);
    },
    resetNewAssignmentDatas(context) {
      context.commit("RESET_NEW_ASSIGNMENT_DATAS");
    },
    setEditProjectData(context, newData) {
      context.commit("SET_EDIT_PROJECT_DATA", newData);
    },
    setProjectPOs(context, poData) {
      context.commit("SET_PROJECT_POS", poData);
    },
    setProjectRequesters(context, requesters) {
      context.commit("SET_PROJECT_REQUESTERS", requesters);
    },
  },
  getters: {
    allProjects: (state) => {
      return state.allProjects;
    },
    staff: (state) => {
      return state.staff;
    },
    staffLoading: (state) => {
      return state.staffLoading;
    },
    dbProjects: (state) => {
      return state.dbProjects;
    },
    selectedProject: (state) => {
      return state.selectedProject;
    },
    tableAssignments: (state) => {
      return state.tableAssignments;
    },
    tableAssignmentsNeedsRefresh: (state) => {
      return state.tableAssignmentsNeedsRefresh;
    },
    openAssignment: (state) => {
      return state.openAssignment;
    },
    flowStage: (state) => {
      return state.flowStage;
    },
    editAssignmentID: (state) => {
      return state.editAssignmentID;
    },
    editAssignmentData: (state) => {
      return state.editAssignmentData;
    },
    editAssignmentModalOpen: (state) => {
      return state.editAssignmentModalOpen;
    },
    newAssignmentModalOpen: (state) => {
      return state.newAssignmentModalOpen;
    },
    assignmentComments: (state) => {
      return state.assignmentComments;
    },
    projectModalOpen: (state) => {
      return state.projectModalOpen;
    },
    newAssignmentDatas: (state) => {
      return state.newAssignmentDatas;
    },
    editProjectData: (state) => {
      return state.editProjectData;
    },
    projectPOs: (state) => {
      return state.projectPOs;
    },
    projectRequesters: (state) => {
      return state.projectRequesters;
    },
  },
};
