//////////////////////
// CONSTANTS
//////////////////////

// vuex
import store from "@/store/index";
// libraries
import axios from "axios";
import _ from "lodash";
import moment from "moment";
// internal
import {
  getFCWorkType,
  getFCWordCount,
  getFCRequestType,
  isAdaptation,
} from "./spreadsheetUploadUtils";
import { publishMessage } from "./slackUtils";

const au =
  process.env.VUE_APP_NODE_ENV === "development"
    ? `QB-USER-TOKEN ${process.env.VUE_APP_QB_TOK}`
    : `QB-USER-TOKEN ${process.env.VUE_APP_QB_TOK}`;

export const realmID = "paulparreira.quickbase.com";

export const appIDs = {
  cueProjectManager: {
    app: "bn5u4eryg",
    assignments: "bn5u4er2b",
    invoices: "bn6yyfnqi",
    clients: "bn5u4es2b",
    purchaseOrders: "bpazp87m6",
    projects: "bn5u4erz4",
    clientRequesters: "bpegdcrp8",
    priceList: "bn5u4esz9",
  },
  iTunesProjectManager: {
    app: "bn2mu9k35",
    assignments: "bn2mu9k44",
    marcTest: "bqyfs9stw",
    purchaseOrders: "bpbntqxcz",
    projects: "bn2mu9k4x",
    clientRequesters: "bpeg8cpcy",
    issueTracker: "bsermrq9d",
    pmDashboard: "bsgnwgeeh",
    priceList: "bn2mu9k6y",
  },
  cueStaff: {
    app: "bixvfy9e2",
    contacts: "bixvfy9gt",
    calendar: "br4uq3q2i",
  },
  cueProjectTools: {
    app: "bsndx667w",
    hardware: "bsndxy29c",
    software: "bsnd5t8by",
  },
  masterAssignments: {
    assignments: "bn3rmeead",
  },
};

export const leadPM = "anitaxu@companycue.com";

export const invoiceReportLookup = {
  THIS: 92,
  LAST: 93,
  NEXT: 94,
};

///////////////////////
// GENERAL
///////////////////////
export const cleanRecordsData = (tableData, fieldData, isCamelCase) => {
  const retVal = tableData.map((record) => {
    const cleanedRecord = {};
    fieldData.forEach((field) => {
      if (isCamelCase) {
        cleanedRecord[encodeToCamelCase(field.label)] = record[field.id].value;
      } else {
        cleanedRecord[field.label] = record[field.id].value;
      }
    });
    return cleanedRecord;
  });

  return retVal;
};

export const encodeToCamelCase = (str) => {
  return str.replace(/\s(.)/g, ($1) => $1.toUpperCase()).replace(/\s/g, "");
};

export const decodeFromCamelCase = (str) => {
  return str
    .replace(/([A-Z])/g, ($1) => " " + $1.toLowerCase())
    .replace(/^./, ($1) => $1.toUpperCase());
};

export const cleanFieldsData = (fieldData, isCamelCase) => {
  if (isCamelCase) {
    return fieldData.map((el) => {
      return {
        text: el.label,
        value: encodeToCamelCase(el.label),
      };
    });
  } else {
    return fieldData.map((el) => el.label);
  }
};

//////////////////////
// STAFF
//////////////////////
export const getStaffNameFieldID = (getWithInitials) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://api.quickbase.com/v1/fields?tableId=${appIDs.cueStaff.contacts}`,
        {
          headers: {
            Authorization: au,
            "QB-Realm-Hostname": realmID,
          },
        }
      )
      .then((data) => {
        let filterConditions = getWithInitials
          ? ["Name", "Initials"]
          : ["Name", "Name"];
        let rowsIDs = data.data
          .filter(
            (el) =>
              el.label === filterConditions[0] ||
              el.label === filterConditions[1]
          )
          .map((el) => el.id);
        resolve(rowsIDs);
      })
      .catch((err) => reject(err));
  });
};

export const getFCStaffDatasFromID = (fieldsIDs, selectedGeo) => {
  fieldsIDs.push(225, 226, 273);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      from: appIDs.cueStaff.contacts,
      select: fieldsIDs,
      // where: "{9.CT.'Active'}", // only select active users
      where: selectedGeo
        ? `{225.EX.${selectedGeo}}OR{226.EX.${selectedGeo}}` // only select those with geos that matches selected geo
        : "{225.XEX.''}OR{226.XEX.''}", // only select those with geos
    });
    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const getFCStaffDatas = () => {
  return new Promise((resolve, reject) => {
    getStaffNameFieldID(true)
      .then((fieldsIDs) => {
        return getFCStaffDatasFromID(fieldsIDs);
      })
      .then((staffDatas) => {
        const namesList = staffDatas.data.data.map((el) => {
          return {
            name: el[7].value,
            geos: [el[225].value, el[226].value],
            initials: el[292].value,
            slackID: el[273].value,
          };
        });
        resolve(namesList.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => reject(err));
  });
};

export const getStaffNamesFromFragment = (fragment) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select: [7, 18],
      from: appIDs.cueStaff.contacts,
      where: `{7.CT.'${fragment}'}`,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((resp) =>
        resolve(
          cleanRecordsData(resp.data.data, resp.data.fields).map((el) => {
            return {
              name: el.Name,
              email: el.Email,
            };
          })
        )
      )
      .catch((err) => reject(err));
  });
};

export const getEmployeesData = async ({ namesOnly, email } = {}) => {
  let fields = [7];
  const getQuery = () => {
    if (email) return `{18.EX.'${email}'}OR{32.EX.'${email}'}`;
    else return "";
  };

  if (!namesOnly)
    fields.push(
      3,
      17,
      18,
      132,
      136,
      14,
      13,
      130,
      9,
      273,
      272,
      31,
      32,
      70,
      69,
      159,
      146,
      147,
      148,
      151,
      152,
      153,
      131,
      15,
      197,
      162,
      163,
      35,
      36,
      20,
      296,
      232,
      313,
      314,
      19,
      319,
      233,
      235,
      236,
      237,
      225,
      226,
      326,
      320,
      328,
      329,
      331,
      332,
      333,
      334,
      335,
      336,
      311,
      312,
      307,
      308,
      309,
      310,
      195,
      292,
      158,
      338,
      341,
      342,
      343,
      344,
      345,
      346,
      347,
      348,
      349,
      350,
      351,
      352,
      353,
      354,
      355,
      356,
      363,
      364,
      365,
      366,
      367,
      368,
      369,
      370,
      371,
      372,
      373,
      374,
      375,
      376,
      377,
      378,
      391,
      392,
      393,
      394,
      395,
      396,
      397,
      398,
      330,
      410,
      411,
      412,
      413,
      414,
      415,
      402,
      403,
      404,
      405,
      406,
      407,
      408,
      409,
      337,
      399,
      400,
      401,
      21
    );
  const data = JSON.stringify({
    select: fields,
    from: appIDs.cueStaff.contacts,
    where: getQuery(),
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records/query",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return namesOnly
    ? response.data.data
        .map((el) => {
          return el[7].value;
        })
        .sort()
    : {
        data: cleanRecordsData(response.data.data, response.data.fields, true),
        fields: cleanFieldsData(response.data.fields, true),
      };
};

export const getFile = (url) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `https://api.quickbase.com/v1${url}`,
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const deleteFile = (url) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      url: `https://api.quickbase.com/v1${url}`,
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
      },
    };

    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const contactsFieldsLookup = {
  Name: { field: "Name", default: "" },
  Country: { field: "Country", default: "" },
  StartDate: { field: "Start Date", default: "" },
  EndDate: { field: "End Date", default: "" },
  Pronouns: { field: "Pronouns", default: "" },
  Photo: { field: "Photo", default: null },
  PreferredName: { field: "Preferred Name", default: "" },
  CueEmail: { field: "Cue Email", default: "" },
  PersonalWebsite: { field: "Personal Website", default: "" },
  LinkedInProfile: { field: "LinkedIn Profile", default: "" },
  PortfolioFile: { field: "Portfolio file", default: null },
  Language1: { field: "Language 1", default: "" },
  Language2: { field: "Language 2", default: "" },
  Language3: { field: "Language 3", default: "" },
  Language1Level: { field: "Language1Level", default: "Native" },
  Language2Level: { field: "Language2Level", default: "" },
  Language3Level: { field: "Language3Level", default: "" },
  Bio: { field: "Bio", default: "" },
  Email: { field: "Email", default: "" },
  Gmail: { field: "Gmail", default: "" },
  StreetAddress: { field: "Street Address", default: "" },
  PhoneNumber: { field: "Phone Number", default: "" },
  LegalName: { field: "Legal Name", default: "" },
  TaxResidency: { field: "Tax Residency", default: "" },
  NDA: { field: "NDA", default: null },
  Contract: { field: "Contract", default: null },
  PaymentType: { field: "Payment Type", default: "" },
  PayPalEmail: { field: "PayPal Email", default: "" },
  Currency: { field: "Currency", default: "" },
  ["SSN/TaxID"]: { field: "SSN/Tax ID", default: "" },
  ["W-9(U.S.Only)"]: { field: "W-9 (U.S. Only)", default: null },
  SlackStaffWorkspaceID: { field: "Slack Staff Workspace ID", default: "" },
  SlackFCWorkspaceID: { field: "Slack FC Workspace ID", default: "" },
  ["SlackCo.CueWorkspaceID(PMOnly)"]: {
    field: "Slack Co.Cue Workspace ID (PM only)",
    default: "",
  },
  Status: { field: "Status", default: "" },
  Smartling: { field: "Smartling", default: false },
  ["Smartling(Apple)"]: { field: "Smartling (Apple)", default: false },
  TryRating: { field: "TryRating", default: false },
  LeadEditor: { field: "Lead Editor", default: false },
  ProjectCoordinator: { field: "Project Coordinator", default: false },
  AppleCopyPortal: { field: "Apple Copy Portal", default: false },
  Dropbox: { field: "Dropbox", default: false },
  Trello: { field: "Trello", default: false },
  SmartCat: { field: "SmartCat", default: false },
  Box: { field: "Box", default: false },
  ["ShakespeareApps:WriterRole"]: {
    field: "Shakespeare Apps: Writer Role",
    default: false,
  },
  ["ShakespeareApps:EditorRole"]: {
    field: "Shakespeare Apps: Editor Role",
    default: false,
  },
  ["ShakespeareMusic:WriterRole"]: {
    field: "Shakespeare Music: Writer Role",
    default: false,
  },
  ["ShakespeareMusic:EditorRole"]: {
    field: "Shakespeare Music: Editor Role",
    default: false,
  },
  ["ShakespeareVideo:WriterRole"]: {
    field: "Shakespeare Video: Writer Role",
    default: false,
  },
  ["ShakespeareVideo:EditorRole"]: {
    field: "Shakespeare Video: Editor Role",
    default: false,
  },
  GEO1: { field: "GEO 1", default: "" },
  GEO2: { field: "GEO 2", default: "" },
  Skill1: { field: "Skill 1", default: "" },
  Skill1YearsOfExperience: {
    field: "Skill 1 Years of Experience",
    default: "",
  },
  Skill2: { field: "Skill 2", default: "" },
  Skill2YearsOfExperience: {
    field: "Skill 2 Years of Experience",
    default: "",
  },
  Skill3: { field: "Skill 3", default: "" },
  Skill3YearsOfExperience: {
    field: "Skill 3 Years of Experience",
    default: "",
  },
  Skill4: { field: "Skill 4", default: "" },
  Skill4YearsOfExperience: {
    field: "Skill 4 Years of Experience",
    default: "",
  },
  Skill5: { field: "Skill 5", default: "" },
  Skill5YearsOfExperience: {
    field: "Skill 5 Years of Experience",
    default: "",
  },
  Skill6: { field: "Skill 6", default: "" },
  Skill6YearsOfExperience: {
    field: "Skill 6 Years of Experience",
    default: "",
  },
  Skill7: { field: "Skill 7", default: "" },
  Skill7YearsOfExperience: {
    field: "Skill 7 Years of Experience",
    default: "",
  },
  Skill8: { field: "Skill 8", default: "" },
  Skill8YearsOfExperience: {
    field: "Skill 8 Years of Experience",
    default: "",
  },
  MusicExpertiseList: { field: "Music Expertise List", default: [] },
  MusicYearsOfExperience: { field: "Music Years of Experience", default: "" },
  MusicSpecialty: { field: "Music Specialty", default: [] },
  ["Film&TVExpertiseList"]: { field: "Film & TV Expertise List", default: [] },
  ["Film&TVYearsOfExperience"]: {
    field: "Film & TV Years of Experience",
    default: "",
  },
  ["Film&TVSpecialty"]: { field: "Film & TV Specialty", default: [] },
  BooksExpertiseList: { field: "Books Expertise List", default: [] },
  BooksYearsOfExperience: { field: "Books Years of Experience", default: "" },
  BooksSpecialty: { field: "Books Specialty", default: [] },
  ["Mobile/VideoGamesExpertiseList"]: {
    field: "Mobile/Video Games Expertise List",
    default: [],
  },
  ["Mobile/VideoGamesYearsOfExperience"]: {
    field: "Mobile/Video Games Years of Experience",
    default: "",
  },
  ["Mobile/VideoGamesSpecialty"]: {
    field: "Mobile/Video Games Specialty",
    default: [],
  },
  PodcastsExpertiseList: { field: "Podcasts Expertise List", default: [] },
  PodcastsYearsOfExperience: {
    field: "Podcasts Years of Experience",
    default: "",
  },
  PodcastsSpecialty: { field: "Podcasts Specialty", default: [] },
  MobileAppsExpertiseList: { field: "Mobile Apps Expertise List", default: [] },
  MobileAppsYearsOfExperience: {
    field: "Mobile Apps Years of Experience",
    default: "",
  },
  MobileAppsSpecialty: { field: "Mobile Apps Specialty", default: [] },
  LifestyleExpertiseList: { field: "Lifestyle Expertise List", default: [] },
  LifestyleYearsOfExperience: {
    field: "Lifestyle Years of Experience",
    default: "",
  },
  LifestyleSpecialty: { field: "Lifestyle Specialty", default: [] },
  SportsExpertiseList: { field: "Sports Expertise List", default: [] },
  SportsYearsOfExperience: { field: "Sports Years of Experience", default: "" },
  SportsSpecialty: { field: "Sports Specialty", default: [] },
  MusicStreamingServices: { field: "Music Streaming Services", default: [] },
  ["Film/TVStreamingServices"]: {
    field: "Film/TV Streaming Services",
    default: [],
  },
  ProductivityTools: { field: "Productivity Tools", default: [] },
  CATTools: { field: "CAT Tools", default: [] },
  OS1: { field: "OS 1", default: "" },
  OS2: { field: "OS 2", default: "" },
  OS3: { field: "OS 3", default: "" },
  OS4: { field: "OS 4", default: "" },
  OS1VersionYear: { field: "OS 1 Version Year", default: "" },
  OS2VersionYear: { field: "OS 2 Version Year", default: "" },
  OS3VersionYear: { field: "OS 3 Version Year", default: "" },
  OS4VersionYear: { field: "OS 4 Version Year", default: "" },
  DesktopComputer: { field: "Desktop Computer", default: [] },
  Laptop: { field: "Laptop", default: [] },
  Phone: { field: "Phone", default: [] },
  Tablet: { field: "Tablet", default: [] },
  StreamingTVDevice: { field: "Streaming TV Device", default: [] },
  Watch: { field: "Watch", default: [] },
  ["VR/ARDevice"]: { field: "VR/AR Device", default: [] },
  // Firebase fields for reset (default value only)
  cueHub: { default: true },
  admin: { default: false },
  dubEditor: { default: false },
};

export const processContactRow = async (data) => {
  const fields = await getFieldLabelIDPairsFromDB(appIDs.cueStaff.contacts),
    formatted = () => {
      let retVal = {};

      Object.keys(contactsFieldsLookup).forEach((key) => {
        if (contactsFieldsLookup[key].hasOwnProperty("field"))
          if (data[key] !== null)
            retVal[
              key === "Country" ? 17 : fields[contactsFieldsLookup[key].field] // workaround for QuickBase returns wrong field ID
            ] = {
              value: data[key],
            };
      });

      return retVal;
    };

  try {
    const resp = await doRecordUpload([formatted()], appIDs.cueStaff.contacts);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getReportData = async ({ reportID, tableID }) => {
  const config = {
    method: "post",
    url: `https://api.quickbase.com/v1/reports/${reportID}/run?tableId=${tableID}`,
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data: "",
  };

  try {
    const resp = await axios(config);
    const data = resp.data.data;
    const fields = resp.data.fields;
    return cleanRecordsData(data, fields, true);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addAvailabilityRecord = (datas, defaultCapacity) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      to: defaultCapacity ? appIDs.cueStaff.contacts : appIDs.cueStaff.calendar,
      data: datas,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getAvailabilityRecords = ({
  employeeName,
  mm,
  yyyy,
  startingFrom,
} = {}) => {
  const whereCondition = () => {
    if (employeeName) {
      return startingFrom
        ? `{6.EX.${employeeName}}AND{7.OAF.${startingFrom}}OR{8.OAF.${startingFrom}}`
        : `{6.EX.${employeeName}}`;
    } else if (mm && yyyy) {
      return `{7.SW.'${mm}'}AND{7.CT.'${yyyy}'}OR{8.SW.'${mm}'}AND{8.CT.'${yyyy}'}`;
    } else if (startingFrom) {
      return `{7.OAF.${startingFrom}}OR{8.OAF.${startingFrom}}`;
    } else {
      return "";
    }
  };
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select: [3, 6, 7, 8, 9, 16, 17],
      from: appIDs.cueStaff.calendar,
      where: whereCondition(),
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(cleanRecordsData(response.data.data, response.data.fields))
      )
      .catch((err) => reject(err));
  });
};

export const getWorkloadByEmployee = ({
  employeeName,
  writing,
  editing,
  isShakespeare,
}) => {
  let fieldsIDs = [131],
    whereCondition;
  if (writing) {
    fieldsIDs.push(13);
    whereCondition = `{8.EX.${employeeName}}AND{13.OAF.'01-01-2021'}`;
  } else if (editing) {
    fieldsIDs.push(194);
    whereCondition = `{177.EX.${employeeName}}AND{194.OAF.'01-01-2021'}`;
  }
  if (isShakespeare) (whereCondition += "AND{62.XEX.''}"), (fieldsIDs[0] = 140);
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select: fieldsIDs,
      from: appIDs.iTunesProjectManager.assignments,
      where: whereCondition,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(cleanRecordsData(response.data.data, response.data.fields))
      )
      .catch((err) => reject(err));
  });
};

export const getDefaultCapacityByEmployee = (employeeName) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select: [277, 278],
      from: appIDs.cueStaff.contacts,
      where: `{7.EX.${employeeName}}`,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(cleanRecordsData(response.data.data, response.data.fields))
      )
      .catch((err) => reject(err));
  });
};

//////////////////////
// CUE PROJECT TOOLS
//////////////////////
export const getHardware = (employeeName) => {
  let select = [7, 41, 9, 19];
  if (!employeeName)
    select.push(
      24,
      10,
      11,
      38,
      12,
      14,
      15,
      16,
      17,
      18,
      20,
      32,
      29,
      40,
      23,
      26,
      3,
      27,
      42
    );
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select,
      from: appIDs.cueProjectTools.hardware,
      where: employeeName ? `{24.EX.${employeeName}}AND{11.EX.true}` : "",
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(
          employeeName
            ? cleanRecordsData(response.data.data, response.data.fields, true)
            : {
                data: cleanRecordsData(
                  response.data.data,
                  response.data.fields,
                  true
                ),
                fields: cleanFieldsData(response.data.fields, true),
              }
        )
      )
      .catch((error) => reject(error));
  });
};

export const hardwareFieldsLookup = {
  ["RecordID#"]: { field: "Record ID#", default: null },
  DeviceBrand: { field: "Device Brand", default: "" },
  DeviceCategory: { field: "Device Category", default: "" },
  DeviceModel: { field: "Device Model", default: "" },
  DeviceSerialNumber: { field: "Device Serial Number", default: "" },
  HardwareUserAgreement: { field: "Hardware User Agreement", default: null },
  Notes: { field: "Notes", default: "" },
  OnHand: { field: "On Hand", default: false },
  OnHandEndDate: { field: "On Hand End Date", default: "" },
  OnHandStartDate: { field: "On Hand Start Date", default: "" },
  Project1: { field: "Project 1", default: "" },
  RelatedProject: { field: "Related Project", default: "" },
  PurchaseDate: { field: "Purchase Date", default: "" },
  PurchasePrice: { field: "Purchase Price", default: "" },
  PurchaseReceipt: { field: "Purchase Receipt", default: null },
  PurchasedBy: { field: "Purchased By", default: "" },
  ReimbursementAmount: { field: "Reimbursement Amount", default: "" },
  ReimbursementDate: { field: "Reimbursement Date", default: "" },
  ReimbursementRecord: { field: "Reimbursement Record", default: "" },
  User: { field: "User", default: "" },
  History: { field: "History", default: "" },
};

export const processProjectToolsRow = async ({
  tableID,
  fieldsLookup,
  data,
}) => {
  const fields = await getFieldLabelIDPairsFromDB(tableID),
    formatted = () => {
      let retVal = {};

      Object.keys(fieldsLookup).forEach((key) => {
        if (fieldsLookup[key].hasOwnProperty("field"))
          if (data[key] !== null)
            retVal[fields[fieldsLookup[key].field]] = {
              value: data[key],
            };
      });

      return retVal;
    };

  try {
    const resp = await doRecordUpload([formatted()], tableID);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getSoftware = (employeeName, today) => {
  return new Promise((resolve, reject) => {
    let select = [6, 27];
    if (!employeeName)
      select.push(3, 9, 20, 10, 21, 11, 24, 19, 15, 12, 13, 16, 25, 18, 31);
    const data = JSON.stringify({
      select,
      from: appIDs.cueProjectTools.software,
      where: employeeName
        ? `{28.CT.${employeeName}}AND({13.OAF.${today}}OR{13.EX.''})`
        : "",
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(
          employeeName
            ? cleanRecordsData(response.data.data, response.data.fields, true)
            : {
                data: cleanRecordsData(
                  response.data.data,
                  response.data.fields,
                  true
                ),
                fields: cleanFieldsData(response.data.fields, true),
              }
        )
      )
      .catch((error) => reject(error));
  });
};

export const softwareFieldsLookup = {
  ["RecordID#"]: { field: "Record ID#", default: null },
  ServiceName: { field: "Service Name", default: "" },
  ServiceType: { field: "Service Type", default: "" },
  PlanType: { field: "Plan Type", default: "" },
  ["UserAmount(Max)"]: { field: "User Amount (Max)", default: "" },
  ["UserAmount(Current)"]: { field: "User Amount (Current)", default: "" },
  ["DeviceAmount(Max)"]: { field: "Device Amount (Max)", default: "" },
  ["DeviceAmount(Current)"]: { field: "Device Amount (Current)", default: "" },
  SubscriptionType: { field: "Subscription Type", default: "" },
  ["SubscriptionAmount(Monthly)"]: {
    field: "Subscription Amount (Monthly)",
    default: "",
  },
  SubscriptionStartDate: { field: "Subscription Start Date", default: "" },
  SubscriptionEndDate: { field: "Subscription End Date", default: "" },
  AccountOwner: { field: "Account Owner", default: "" },
  UserAgreement: { field: "User Agreement", default: null },
  ["Users(non-relational)"]: { field: "Users (non-relational)", default: [] },
};

//////////////////////
// ITUNES
//////////////////////
export const getFieldInfosFromDB = async ({ fieldID, tableID }) => {
  return new Promise((resolve, reject) => {
    const baseUrl = "https://api.quickbase.com/v1/fields";
    const tableUrl = `?tableId=${tableID}`;
    let url = baseUrl.concat(tableUrl);

    if (fieldID) {
      url = baseUrl.concat(`/${fieldID}`, tableUrl);
    }

    axios
      .get(url, {
        headers: {
          Authorization: au,
          "QB-Realm-Hostname": realmID,
        },
      })
      .then((data) => resolve(fieldID ? data.data.properties.choices : data))
      .catch((err) => reject(err));
  });
};

export const getiTunesFCPONumber = (project) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      from: appIDs.iTunesProjectManager.purchaseOrders,
      select: [3],
      where: project
        ? `{20.CT.${project}}AND{8.EX.true}`
        : "{20.CT.'iTunes FC'}AND{8.EX.true}",
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const addITunesAssignmentRowsToQB = (
  formValues,
  tableRows,
  staffAssignments,
  staffDatas
) => {
  let poNumber = "";
  return new Promise((resolve, reject) => {
    return getiTunesFCPONumber()
      .then((poData) => {
        poNumber = poData.data[0][3].value;
        return getFieldInfosFromDB({
          tableID: appIDs.iTunesProjectManager.assignments,
        });
      })
      .then((fieldInfos) => {
        const fieldIDLookup = _.keyBy(
            fieldInfos.data.map((el) => {
              return {
                id: el.id,
                name: el.label,
              };
            }),
            (el) => el.name
          ),
          promiseList = [],
          shakespeare = store.getters["spreadsheetUpload/shakespeare"],
          shakespeareLoc = store.getters["spreadsheetUpload/shakespeareLoc"],
          backstageCopywriting =
            store.getters["spreadsheetUpload/backstageCopywriting"],
          incrsTableRows = [...tableRows],
          geosWithIndividualDeadlines = formValues.individualGeosDeadlines.map(
            (geo) => Object.keys(geo)[0]
          );

        if (backstageCopywriting || shakespeare)
          Object.entries(staffAssignments).forEach((el) => {
            const assgn = el[0],
              geos = Object.keys(el[1]),
              parent = tableRows.find(
                (tableRow) =>
                  tableRow[shakespeare ? "task_id" : "task_name"] === assgn
              );
            geos.forEach((geo) => {
              if (staffAssignments[assgn][geo]?.incrementals?.length) {
                const incrsArray = staffAssignments[assgn][geo].incrementals;
                incrsArray.forEach((inc) => {
                  if (shakespeare) {
                    incrsTableRows.push({
                      ...parent,
                      index: incrsArray.indexOf(inc),
                    });
                  } else {
                    const incrRow = { ...parent };
                    Object.assign(incrRow, {
                      geos: parent.geos.filter(
                        (parentGeo) => parentGeo === geo
                      ),
                      index: incrsArray.indexOf(inc),
                    });
                    incrsTableRows.push(incrRow);
                  }
                });
              }
            });
          });

        // form the Slack notification data
        let notification = {
          keyword: tableRows[0].keyword,
          payPeriod: formValues.payPeriod,
          mediaType: shakespeare
            ? formValues.mediaType
            : tableRows[0].media_type,
          requestType: tableRows[0].request_type,
          geos: [],
          fileLocation: formValues.fileLocation,
          writingDueDate: formValues.writingDueDate,
          writingDue: formValues.writingDue,
          editingDueDate: formValues.editingDueDate,
          editingDue: formValues.editingDue,
          reviewingDueDate: formValues.reviewingDueDate,
          reviewingDue: formValues.reviewingDue,
          assignmentName: formValues.assignmentTitle,
          deliveryDate: formValues.deliveryDate,
          wordCount: tableRows[0].word_count,
        };
        const getGeoIndex = (geo, array) =>
          array.findIndex((el) => el.hasOwnProperty(geo));

        (backstageCopywriting || shakespeare
          ? incrsTableRows
          : tableRows
        ).forEach((tableRow) => {
          (shakespeare ? [tableRow.geo] : tableRow.geos).forEach((geoVal) => {
            const geo = shakespeareLoc ? geoVal.geo : geoVal,
              geoIndividualData =
                formValues.individualGeosDeadlines[
                  getGeoIndex(geo, formValues.individualGeosDeadlines)
                ]?.[geo];
            promiseList.push(
              addAssignmentRow({
                to: appIDs.iTunesProjectManager.assignments,
                datas: [
                  {
                    ...fieldIDLookup["Related Project"],
                    value:
                      shakespeare || backstageCopywriting
                        ? formValues.relatedProject.id
                        : 1,
                  },
                  {
                    ...fieldIDLookup["Pay Period"],
                    value: formValues.payPeriod,
                  },
                  {
                    ...fieldIDLookup["Email Subject Line"],
                    value: shakespeare
                      ? tableRow.task_name
                      : tableRow.subject_line,
                  },
                  {
                    ...fieldIDLookup["Request Date"],
                    value: moment(tableRow.request_date).format("YYYY-MM-DD"),
                  },
                  {
                    ...fieldIDLookup["Request Time"],
                    value: shakespeare ? formValues.requestTime : "",
                  },
                  {
                    ...fieldIDLookup["Related Client Requester"],
                    value:
                      shakespeare || shakespeareLoc
                        ? formValues.clientRequester
                        : tableRow.client_requester,
                  },
                  {
                    ...fieldIDLookup["Task ID"],
                    value: tableRow.task_id,
                  },
                  {
                    ...fieldIDLookup["Assignment ID"],
                    value: shakespeare
                      ? ""
                      : staffAssignments[tableRow["task_name"]][geo][
                          "assignmentId"
                        ] ?? "",
                  },
                  {
                    ...fieldIDLookup["Content ID"],
                    value:
                      shakespeare || shakespeareLoc
                        ? tableRow.content_id ?? tableRow.video_content_id
                        : "",
                  },
                  {
                    ...fieldIDLookup["Assignment Title"],
                    value: formValues.assignmentTitle,
                  },
                  {
                    ...fieldIDLookup["Assignment Detail"],
                    value: tableRow.task_name,
                  },
                  {
                    ...fieldIDLookup["Media Type"],
                    value: shakespeare
                      ? formValues.mediaType
                      : tableRow.media_type,
                  },
                  {
                    ...fieldIDLookup["GEO"],
                    value: geo,
                  },
                  {
                    ...fieldIDLookup["Source GEO"],
                    value: shakespeare
                      ? ""
                      : backstageCopywriting
                      ? isAdaptation({
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                        ? isAdaptation({
                            locale: geo,
                            getSourceLocale: true,
                          })
                        : ""
                      : tableRow.source_geo,
                  },
                  {
                    ...fieldIDLookup["Keyword"],
                    value: tableRow.keyword,
                  },
                  {
                    ...fieldIDLookup["Request Type"],
                    value: backstageCopywriting
                      ? getFCRequestType({
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : tableRow.request_type,
                  },
                  {
                    ...fieldIDLookup["Word Count"],
                    value:
                      shakespeare ||
                      backstageCopywriting ||
                      tableRow.request_type === "Adaptation"
                        ? ""
                        : tableRow.word_count,
                  },
                  {
                    ...fieldIDLookup["Work Type 1 Reference"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType1Reference"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType1Reference"
                          ]
                        : ""
                      : backstageCopywriting ||
                        tableRow.request_type === "Adaptation"
                      ? getFCWorkType({
                          workType:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType1Reference"
                            ],
                          workType1: true,
                          locale: geo,
                          allLocales: tableRow.geos,
                          adaptationRequest:
                            tableRow.request_type === "Adaptation",
                        })
                      : getFCWorkType({
                          locale: geo,
                          sourceLocale: tableRow.source_geo,
                          localizationMode: true,
                        }),
                  },
                  {
                    ...fieldIDLookup["Work Type 1 Quantity"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType1Quantity"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType1Quantity"
                          ]
                        : ""
                      : backstageCopywriting
                      ? getFCWordCount({
                          wordCount:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType1Quantity"
                            ],
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : tableRow.request_type === "Adaptation"
                      ? ""
                      : tableRow.word_count,
                  },
                  {
                    ...fieldIDLookup["Work Type 2 Reference"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType2Reference"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType2Reference"
                          ]
                        : ""
                      : backstageCopywriting ||
                        tableRow.request_type === "Adaptation"
                      ? getFCWorkType({
                          workType:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType2Reference"
                            ],
                          workType1: false,
                          locale: geo,
                          allLocales: tableRow.geos,
                          adaptationRequest:
                            tableRow.request_type === "Adaptation",
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 2 Quantity"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType2Quantity"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType2Quantity"
                          ]
                        : ""
                      : backstageCopywriting
                      ? getFCWordCount({
                          wordCount:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType2Quantity"
                            ],
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 3 Reference"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType3Reference"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType3Reference"
                          ]
                        : ""
                      : backstageCopywriting ||
                        tableRow.request_type === "Adaptation"
                      ? getFCWorkType({
                          workType:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType3Reference"
                            ],
                          workType1: false,
                          locale: geo,
                          allLocales: tableRow.geos,
                          adaptationRequest:
                            tableRow.request_type === "Adaptation",
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 3 Quantity"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType3Quantity"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType3Quantity"
                          ]
                        : ""
                      : backstageCopywriting
                      ? getFCWordCount({
                          wordCount:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType3Quantity"
                            ],
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 4 Reference"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType4Reference"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType4Reference"
                          ]
                        : ""
                      : backstageCopywriting ||
                        tableRow.request_type === "Adaptation"
                      ? getFCWorkType({
                          workType:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType4Reference"
                            ],
                          workType1: false,
                          locale: geo,
                          allLocales: tableRow.geos,
                          adaptationRequest:
                            tableRow.request_type === "Adaptation",
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 4 Quantity"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType4Quantity"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType4Quantity"
                          ]
                        : ""
                      : backstageCopywriting
                      ? getFCWordCount({
                          wordCount:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType4Quantity"
                            ],
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 5 Reference"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType5Reference"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType5Reference"
                          ]
                        : ""
                      : backstageCopywriting ||
                        tableRow.request_type === "Adaptation"
                      ? getFCWorkType({
                          workType:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType5Reference"
                            ],
                          workType1: false,
                          locale: geo,
                          allLocales: tableRow.geos,
                          adaptationRequest:
                            tableRow.request_type === "Adaptation",
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 5 Quantity"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType5Quantity"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType5Quantity"
                          ]
                        : ""
                      : backstageCopywriting
                      ? getFCWordCount({
                          wordCount:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType5Quantity"
                            ],
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 6 Reference"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType6Reference"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType6Reference"
                          ]
                        : ""
                      : backstageCopywriting ||
                        tableRow.request_type === "Adaptation"
                      ? getFCWorkType({
                          workType:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType6Reference"
                            ],
                          workType1: false,
                          locale: geo,
                          allLocales: tableRow.geos,
                          adaptationRequest:
                            tableRow.request_type === "Adaptation",
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Work Type 6 Quantity"],
                    value: shakespeare
                      ? staffAssignments[tableRow["task_id"]][geo][
                          "workType6Quantity"
                        ]
                        ? staffAssignments[tableRow["task_id"]][geo][
                            "workType6Quantity"
                          ]
                        : ""
                      : backstageCopywriting
                      ? getFCWordCount({
                          wordCount:
                            staffAssignments[tableRow["task_name"]][geo][
                              "workType6Quantity"
                            ],
                          locale: geo,
                          allLocales: tableRow.geos,
                        })
                      : "",
                  },
                  {
                    ...fieldIDLookup["Writer"],
                    value:
                      staffAssignments[
                        tableRow[shakespeare ? "task_id" : "task_name"]
                      ][geo]["writer"],
                  },
                  {
                    ...fieldIDLookup["Writing Due Date"],
                    value:
                      geoIndividualData?.writingDueDate ??
                      formValues.writingDueDate,
                  },
                  {
                    ...fieldIDLookup["Writing Due (Time of Day)"],
                    value:
                      geoIndividualData?.writingDue ?? formValues.writingDue,
                  },
                  {
                    ...fieldIDLookup["Editor"],
                    value:
                      staffAssignments[
                        tableRow[shakespeare ? "task_id" : "task_name"]
                      ][geo]["editor"],
                  },
                  {
                    ...fieldIDLookup["Editing Due Date"],
                    value: staffAssignments[
                      tableRow[shakespeare ? "task_id" : "task_name"]
                    ][geo]["editor"]
                      ? geoIndividualData?.editingDueDate ??
                        formValues.editingDueDate ??
                        ""
                      : "",
                  },
                  {
                    ...fieldIDLookup["Editing Due (Time of Day)"],
                    value: staffAssignments[
                      tableRow[shakespeare ? "task_id" : "task_name"]
                    ][geo]["editor"]
                      ? geoIndividualData?.editingDue ?? formValues.editingDue
                      : "",
                  },
                  {
                    ...fieldIDLookup["Reviewer"],
                    value:
                      staffAssignments[
                        tableRow[shakespeare ? "task_id" : "task_name"]
                      ][geo]["reviewer"],
                  },
                  {
                    ...fieldIDLookup["Reviewing Due Date"],
                    value: staffAssignments[
                      tableRow[shakespeare ? "task_id" : "task_name"]
                    ][geo]["reviewer"]
                      ? geoIndividualData?.reviewingDueDate ??
                        formValues.reviewingDueDate ??
                        ""
                      : "",
                  },
                  {
                    ...fieldIDLookup["Reviewing Due (Time of Day)"],
                    value: staffAssignments[
                      tableRow[shakespeare ? "task_id" : "task_name"]
                    ][geo]["reviewer"]
                      ? geoIndividualData?.reviewingDue ??
                        formValues.reviewingDue
                      : "",
                  },
                  {
                    ...fieldIDLookup["Delivery Date"],
                    value: formValues.deliveryDate,
                  },
                  {
                    ...fieldIDLookup["Delivery Due (Time of Day)"],
                    value: formValues.deliveryDue,
                  },
                  {
                    ...fieldIDLookup["Related Purchase Order"],
                    value:
                      shakespeare || backstageCopywriting
                        ? formValues.po
                        : poNumber,
                  },
                  {
                    ...fieldIDLookup["File Location"],
                    value: formValues.fileLocation,
                  },
                  {
                    ...fieldIDLookup["Link"],
                    value: shakespeare ? "" : tableRow.link ?? "",
                  },
                  {
                    ...fieldIDLookup["Urgent"],
                    value: formValues.urgent ? "yes" : "no",
                  },
                  {
                    ...fieldIDLookup["Override"],
                    value: formValues.override ? "yes" : "no",
                  },
                  {
                    ...fieldIDLookup["Writer Overloaded"],
                    value: staffAssignments[
                      tableRow[shakespeare ? "task_id" : "task_name"]
                    ][geo]["writerOverloaded"]
                      ? "yes"
                      : "no",
                  },
                  {
                    ...fieldIDLookup["Editor Overloaded"],
                    value: staffAssignments[
                      tableRow[shakespeare ? "task_id" : "task_name"]
                    ][geo]["editorOverloaded"]
                      ? "yes"
                      : "no",
                  },
                  {
                    ...fieldIDLookup["Assignment Status"],
                    value: "Assigned",
                  },
                  {
                    ...fieldIDLookup["Created By"],
                    value: store.getters["auth/user"].Name,
                  },
                  {
                    ...fieldIDLookup["Note"],
                    value: formValues.note,
                  },
                ],
              })
            );

            // process the GEO-related data for Slack notification
            const getPersonName = (role) =>
                staffAssignments[
                  tableRow[shakespeare ? "task_id" : "task_name"]
                ][geo][role],
              getPersonSlackID = (role) =>
                staffDatas.find((person) => person.name === getPersonName(role))
                  ?.slackID,
              [writer, editor, reviewer] = [
                getPersonSlackID("writer")
                  ? `<@${getPersonSlackID("writer")}>`
                  : getPersonName("writer"),
                getPersonSlackID("editor")
                  ? `<@${getPersonSlackID("editor")}>`
                  : getPersonName("editor"),
                getPersonSlackID("reviewer")
                  ? `<@${getPersonSlackID("reviewer")}>`
                  : getPersonName("reviewer"),
              ],
              geos = notification.geos.map((el) => {
                return Object.keys(el)[0];
              });

            if (geos.includes(geo)) {
              const geoStaffChecker = (staffGroup, staffMember) => {
                if (
                  notification.geos[getGeoIndex(geo, notification.geos)][
                    geo
                  ].hasOwnProperty(staffGroup)
                ) {
                  if (
                    !notification.geos[getGeoIndex(geo, notification.geos)][
                      geo
                    ][staffGroup].includes(staffMember)
                  )
                    notification.geos[getGeoIndex(geo, notification.geos)][geo][
                      staffGroup
                    ].push(staffMember);
                } else {
                  Object.assign(
                    notification.geos[getGeoIndex(geo, notification.geos)][geo],
                    {
                      [staffGroup]: [staffMember],
                    }
                  );
                }
              };
              if (writer) geoStaffChecker("writers", writer);
              if (editor) geoStaffChecker("editors", editor);
              if (reviewer) geoStaffChecker("reviewers", reviewer);
            } else {
              let geoObj = { [geo]: {} };
              if (writer) Object.assign(geoObj[geo], { writers: [writer] });
              if (editor) Object.assign(geoObj[geo], { editors: [editor] });
              if (reviewer)
                Object.assign(geoObj[geo], { reviewers: [reviewer] });
              if (Object.keys(geoObj[geo]).length)
                notification.geos.push(geoObj);
            }
          });
        });
        // add assignments details
        Object.entries(staffAssignments).forEach((el) => {
          const assgnDetail = shakespeare
              ? staffAssignments[el[0]].task_name
              : el[0],
            geos = shakespeare
              ? [Object.keys(el[1])[0]]
              : backstageCopywriting
              ? Object.keys(el[1])
              : Object.keys(el[1]).slice(0, -1);
          geos.forEach((geo) => {
            notification.geos[getGeoIndex(geo, notification.geos)][
              geo
            ].hasOwnProperty("assignments")
              ? notification.geos[getGeoIndex(geo, notification.geos)][
                  geo
                ].assignments.push(assgnDetail)
              : Object.assign(
                  notification.geos[getGeoIndex(geo, notification.geos)][geo],
                  {
                    assignments: [assgnDetail],
                  }
                );
          });
        });
        // add individual deadlines
        const geosDeadlines = [...formValues.individualGeosDeadlines];
        geosWithIndividualDeadlines.forEach((geo) => {
          Object.assign(
            notification.geos[getGeoIndex(geo, notification.geos)][geo],
            {
              writingDueDate:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .writingDueDate,
              writingDue:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo].writingDue,
              editingDueDate:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .editingDueDate,
              editingDue:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo].editingDue,
              reviewingDueDate:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .reviewingDueDate,
              reviewingDue:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .reviewingDue,
            }
          );
        });

        Promise.all(promiseList)
          .then((res) => {
            const createdRecords = _.flatMap(
              res.map((el) => el.metadata.createdRecordIds)
            ).length;
            if (createdRecords > 0) {
              publishMessage({
                mode: "fcNotification",
                item: notification,
              });
              resolve("success");
            } else {
              const errMsg = _.flatMap(_.values(res[0].metadata.lineErrors))[0];
              resolve(errMsg);
            }
          })
          .catch((err) => reject(err));
      });
  });
};

export const getAssignments = async ({
  personEmail,
  cueID,
  payPeriod,
  maxRows,
  skipRows,
  personName,
  earningsMode,
  cpmMode,
  ledMode,
}) => {
  const options = {};
  if (maxRows) {
    options.top = maxRows;
  }

  if (skipRows) {
    options.skip = skipRows;
  }

  const filter = () => {
    if (personEmail || (personName && cpmMode)) {
      return `{${cpmMode ? 8 : 9}.EX.'${
        cpmMode ? personName : personEmail
      }'}OR{${cpmMode ? 135 : 198}.EX.'${cpmMode ? personName : personEmail}'}${
        cpmMode ? "" : `OR{283.EX.'${personEmail}'}`
      }AND{16.EX.'${payPeriod}'}`;
    } else if (cueID) {
      return `{307.EX.'${cueID}'}`;
    } else if (earningsMode) {
      return `{6.EX.'${personName}'}AND{14.EX.'${payPeriod}'}`;
    } else if (ledMode) {
      return `{164.EX.'Editorial Supervision'}AND({219.EX.'Pilar Blanco'}OR{219.EX.'Ines Santos'}OR{219.EX.'Company Cue, Inc.'})AND{16.EX.'${payPeriod}'}${
        personName ? `AND{8.EX.'${personName}'}` : ""
      }`;
    } else {
      return `{16.EX.'${payPeriod}'}`;
    }
  };

  let fields = earningsMode
    ? [17, 31, 11]
    : [3, 44, 61, 12, 8, 13, 45, 177, 194, 204, 279, 277, 278, 110, 316];
  if (!cueID && !earningsMode) {
    fields.push(16, 163, 140, 9, 60, 38, 21, 132, 98, 131, 149, 31);
    cpmMode
      ? fields.push(135, 144, 7, 173, 19, 14, 136, 137, 240, 244)
      : fields.push(
          218,
          134,
          162,
          219,
          164,
          159,
          145,
          141,
          142,
          153,
          143,
          346,
          355,
          350,
          354,
          198,
          181,
          283,
          200,
          297,
          301,
          302,
          307,
          315,
          169,
          321,
          1,
          323
        );
    if (ledMode) fields.push(317, 318, 319, 320);
  }

  const data = JSON.stringify({
    select: fields,
    from: appIDs[
      earningsMode
        ? "masterAssignments"
        : cpmMode
        ? "cueProjectManager"
        : "iTunesProjectManager"
    ].assignments,
    where: filter(),
    options: {
      ...options,
    },
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records/query",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return {
    data: cleanRecordsData(response.data.data, response.data.fields, true),
    fields: cleanFieldsData(response.data.fields, true),
  };
};

export const updateDBRowField = async ({ rid, status, note, cpmMode }) => {
  // note to client id = 316 (FC only)
  // status id = 110
  // rid = 3

  let postData = [
    {
      3: { value: rid },
    },
  ];

  if (status) {
    Object.assign(postData[0], { 110: { value: status } });
  } else if (note) {
    Object.assign(postData[0], { 316: { value: note } });
  }

  const data = JSON.stringify({
    to: appIDs[cpmMode ? "cueProjectManager" : "iTunesProjectManager"]
      .assignments,
    data: postData,
    fieldsToReturn: cpmMode ? [3, 110] : [3, 110, 316],
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return response.data;
};

export const updateMultipleDBRowStatus = async ({ rows, status, cpmMode }) => {
  const promises = rows.map((row) => {
    return updateDBRowField({ rid: row["RecordID#"], status, cpmMode });
  });

  return Promise.all(promises);
};

export const getBugReports = async (rid) => {
  const filter = rid ? `{7.EX.'${rid}'}` : "";

  const data = JSON.stringify({
    select: [
      1, 3, 7, 8, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 28, 29, 30, 32, 35,
    ],
    from: appIDs.iTunesProjectManager.issueTracker,
    where: filter,
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records/query",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return {
    data: cleanRecordsData(response.data.data, response.data.fields, true),
    fields: cleanFieldsData(response.data.fields, true),
  };
};

export const updateBugReport = async ({
  issue,
  relatedAssgn,
  status,
  type,
}) => {
  const postData = [
    {
      32: { value: issue.LastUpdatedBy },
      35: { value: issue.LastUpdated },
    },
  ];
  if (status) {
    Object.assign(postData[0], { 8: { value: status } });
  } else if (type) {
    Object.assign(postData[0], { 14: { value: type } });
  } else {
    Object.assign(postData[0], {
      7: { value: relatedAssgn },
      8: { value: issue.IssueStatus },
      14: { value: issue.IssueType },
    });
  }
  if (issue["RecordID#"])
    Object.assign(postData[0], { 3: { value: issue["RecordID#"] } });

  const data = JSON.stringify({
    to: appIDs.iTunesProjectManager.issueTracker,
    data: postData,
    fieldsToReturn: [1, 3, 7, 8, 14, 32, 35],
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return response.data;
};

export const updateBugReports = async ({ issues, relatedAssgn, status }) => {
  const promises = issues.map((issue) => {
    return updateBugReport({ issue, relatedAssgn, status });
  });

  return Promise.all(promises);
};

export const getProjectID = async (projectName) => {
  const data = JSON.stringify({
    select: [3],
    from: appIDs.iTunesProjectManager.projects,
    where: `{16.EX.${projectName}}`,
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records/query",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return cleanRecordsData(response.data.data, response.data.fields, true)[0];
};

//////////////////////
// ASSIGNMENTS
//////////////////////
export const getFieldLabelIDPairsFromDB = (table) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`https://api.quickbase.com/v1/fields?tableId=${table}`, {
        headers: {
          Authorization: au,
          "QB-Realm-Hostname": realmID,
        },
      })
      .then((data) => {
        const retVal = [];
        data.data.forEach((el) => {
          retVal[el.label] = el.id;
        });
        resolve(retVal);
      })
      .catch((err) => reject(err));
  });
};

export const addAssignmentRow = (props) => {
  return new Promise((resolve, reject) => {
    const rowData = {};

    props.datas.forEach((el) => {
      rowData[el.id.toString()] = { value: el.value };
    });

    const data = JSON.stringify({
      to: props.to,
      data: [rowData],
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
        "Retry-After": 2,
      },
      data,
    };

    axios(config)
      .then((response) =>
        // if has line errors then something went wrong
        resolve(response.data)
      )
      .catch((error) => reject(error));
  });
};

const formatAssignmentForUpload = (data, fields) => {
  // this matches the fields in the data object to the column they'll be uploaded to in quickbase
  const lookup = {
    assignmentDetails: "Assignment Detail",
    assignmentName: "Assignment Title",
    clientRequester: "Related Client Requester",
    deliveryDate: "Delivery Date",
    payPeriod: "Pay Period",
    personPrimary: "Person - Primary",
    personPrimaryDueDate: "Primary Due Date",
    personSecondary: "Person - Reviewer",
    personSecondaryDueDate: "Reviewer Due Date",
    po: "Related Purchase Order",
    projectName: "Project Name",
    quantity: "Quantity",
    relatedPrice: "Related Price",
    assignmentStatus: "Assignment Status",
    qbID: "Record ID#",
    sourceGeo: "Source GEO",
    GEO: "GEO",
    geo: "GEO",
    mediaType: "Media Type",
    workType: "Work Type Category",
    requestDate: "Request Date",
    relatedProject: "Related Project",
    emailSubjectLine: "Email Subject Line",
    requestType: "Request Type",
    keyword: "Keyword",
    deliveryDue: "Delivery Due (Time of Day)",
    urgent: "Urgent",
    override: "Override",
    writerOverloaded: "Writer Overloaded",
    editorOverloaded: "Editor Overloaded",
    wordCount: "Word Count",
    taskID: "Task ID",
    assignmentID: "Assignment ID",
    contentID: "Content ID",
    writer: "Writer",
    writingDueDate: "Writing Due Date",
    writingDue: "Writing Due (Time of Day)",
    editor: "Editor",
    editingDueDate: "Editing Due Date",
    editingDue: "Editing Due (Time of Day)",
    reviewer: "Reviewer",
    reviewingDueDate: "Reviewing Due Date",
    reviewingDue: "Reviewing Due (Time of Day)",
    fileLocation: "File Location",
    link: "Link",
    workType1Reference: "Work Type 1 Reference",
    workType1Quantity: "Work Type 1 Quantity",
    workType2Reference: "Work Type 2 Reference",
    workType2Quantity: "Work Type 2 Quantity",
    workType3Reference: "Work Type 3 Reference",
    workType3Quantity: "Work Type 3 Quantity",
    workType4Reference: "Work Type 4 Reference",
    workType4Quantity: "Work Type 4 Quantity",
    workType5Reference: "Work Type 5 Reference",
    workType5Quantity: "Work Type 5 Quantity",
    workType6Reference: "Work Type 6 Reference",
    workType6Quantity: "Work Type 6 Quantity",
    createdBy: "Created By",
    note: "Note",
    lastUpdated: "Last Updated",
    spellingTypos: "Spelling/Typos",
    grammarSyntax: "Grammar/Syntax",
    factualError: "Factual Error",
    styleToV: "Style/ToV",
  };

  const retVal = {};
  Object.keys(lookup).forEach((key) => {
    if (data[key] !== undefined) {
      retVal[fields[lookup[key]]] = {
        value: data[key],
      };
    }
  });

  return retVal;
};

export const doRecordUpload = (datas, table) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      to: table,
      data: datas,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        // if has line errors then something went wrong
        resolve(response.data)
      )
      .catch((error) => reject(error));
  });
};

export const addAssignmentRowsFromEditor = async (datas, table, slackMute) => {
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const fields = await getFieldLabelIDPairsFromDB(table);
  const formatted = datas.map((data) => {
    return formatAssignmentForUpload(data, fields);
  });

  const retVal = [];
  for (let i = 0; i < formatted.length; i++) {
    try {
      if (i !== 0) {
        await timeout(1000);
      }
      const resp = await doRecordUpload([formatted[i]], table);
      retVal.push({ status: "fulfilled", value: resp });
      if (!slackMute) {
        // process the GEO-related data for Slack notification
        const [writer, editor, reviewer] = [
          datas[i].writerSlackID
            ? `<@${datas[i].writerSlackID}>`
            : datas[i].writer,
          datas[i].editorSlackID
            ? `<@${datas[i].editorSlackID}>`
            : datas[i].editor,
          datas[i].reviewerSlackID
            ? `<@${datas[i].reviewerSlackID}>`
            : datas[i].reviewer,
        ];
        let geoObj = {
          [datas[i].geo]: {
            assignments: [datas[i].assignmentDetails],
          },
        };
        if (writer) Object.assign(geoObj[datas[i].geo], { writers: [writer] });
        if (editor) Object.assign(geoObj[datas[i].geo], { editors: [editor] });
        if (reviewer)
          Object.assign(geoObj[datas[i].geo], { reviewers: [reviewer] });
        publishMessage({
          mode: "fcNotification",
          item: {
            ...datas[i],
            geos: [geoObj],
          },
        });
      }
    } catch (err) {
      retVal.push({ status: "rejected", value: err });
    }
  }
  return retVal;
};

export const updateAssignmentRowFromEditor = ({ data }) => {
  return new Promise((resolve, reject) => {
    return getFieldLabelIDPairsFromDB(appIDs.cueProjectManager.assignments)
      .then((fields) => {
        const formatted = formatAssignmentForUpload(data, fields);

        return doRecordUpload(
          [formatted],
          appIDs.cueProjectManager.assignments
        );
      })
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
};

export const getRecordIDField = (tableID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`https://api.quickbase.com/v1/fields?tableId=${tableID}`, {
        headers: {
          Authorization: au,
          "QB-Realm-Hostname": realmID,
        },
      })
      .then((data) => {
        const recordIDNumber = data.data
          .filter((el) => el.label === "Record ID#")
          .map((el) => el.id);
        resolve(recordIDNumber);
      })
      .catch((err) => reject(err));
  });
};

export const deleteRowFromQB = ({ id, tableID }) => {
  return new Promise((resolve, reject) => {
    getRecordIDField(tableID).then((recordIDNumber) => {
      const data = JSON.stringify({
        from: tableID,
        where: `{${recordIDNumber}.EX.${id}}`,
      });

      const config = {
        method: "delete",
        url: "https://api.quickbase.com/v1/records",
        headers: {
          Authorization: au,
          "QB-Realm-Hostname": realmID,
          "Content-Type": "application/json",
        },
        data,
      };

      axios(config)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  });
};

export const getAssignmentsFromClient = async ({ client, monthSelector }) => {
  const fieldIDs = [
    52, 13, 12, 30, 11, 24, 20, 18, 19, 30, 6, 3, 74, 87, 88, 78, 91, 76, 102,
  ];
  const tableIDLookup = {
    THIS: 82,
    LAST: 83,
    NEXT: 89,
  };
  const tableId = tableIDLookup[monthSelector];
  const data = JSON.stringify({
    select: fieldIDs,
    from: appIDs.cueProjectManager.invoices,
    where: `{52.EX.'${client}'}AND{${tableId}.EX.'true'}AND{30.GT.0}`,
    options: {
      skip: 0,
    },
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records/query",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const skipSize = 100;

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        if (
          response.data.metadata.numRecords ===
          response.data.metadata.totalRecords
        ) {
          // all records are captured in one call
          resolve(response.data);
        } else {
          let cumulativeData = [];
          const promises = [];
          cumulativeData = [...response.data.data];

          // do as many 200 record page calls as needed to get all the data
          const numCalls = Math.ceil(
            response.data.metadata.totalRecords / skipSize
          );

          // might want to put a check here to prevent too many API Calls

          for (let i = 1; i < numCalls; i++) {
            const data = JSON.stringify({
              select: fieldIDs,
              from: appIDs.cueProjectManager.invoices,
              where: `{52.EX.'${client}'}AND{${tableId}.EX.'true'}AND{30.GT.0}`,
              options: {
                skip: 100 * i,
              },
            });
            const newConfig = {
              method: "post",
              url: "https://api.quickbase.com/v1/records/query",
              headers: {
                Authorization: au,
                "QB-Realm-Hostname": realmID,
                "Content-Type": "application/json",
              },
              data,
            };
            const newPromise = axios(newConfig);
            promises.push(newPromise);
          }
          Promise.all(promises).then((values) => {
            values.forEach((d) => {
              cumulativeData = [...cumulativeData, ...d.data.data];
            });
            resolve({
              data: cumulativeData,
              fields: response.data.fields,
            });
          });
        }
      })
      .catch((error) => reject(error));
  });
};

export const getGeoChoices = () => {
  return new Promise((resolve, reject) => {
    const data = "";

    const config = {
      method: "get",
      url: `https://api.quickbase.com/v1/fields/44?tableId=${appIDs.cueProjectManager.assignments}`,
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) => resolve(response.data.properties.choices))
      .catch((error) => reject(error));
  });
};

export const getMediaTypeChoices = ({
  instance,
  shakespeareProjectID,
} = {}) => {
  return new Promise((resolve, reject) => {
    const getShakespeareMediaField = () => {
        if (shakespeareProjectID === 3) {
          return 291;
        } else if (shakespeareProjectID === 1) {
          return 292;
        }
      },
      data = "",
      config = {
        method: "get",
        url: `https://api.quickbase.com/v1/fields/${
          shakespeareProjectID ? getShakespeareMediaField() : 98
        }?tableId=${
          instance
            ? appIDs.iTunesProjectManager.assignments
            : appIDs.cueProjectManager.assignments
        }`,
        headers: {
          Authorization: au,
          "QB-Realm-Hostname": realmID,
          "Content-Type": "application/json",
        },
        data,
      };

    axios(config)
      .then((response) => resolve(response.data.properties.choices.sort()))
      .catch((error) => reject(error));
  });
};

export const getWorkTypeChoices = () => {
  return new Promise((resolve, reject) => {
    const data = "";

    const config = {
      method: "get",
      url: `https://api.quickbase.com/v1/fields/149?tableId=${appIDs.cueProjectManager.assignments}`,
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) => resolve(response.data.properties.choices))
      .catch((error) => reject(error));
  });
};

export const updateAccepted = async ({ recordID, value, role, cpmMode }) => {
  // RID = 3
  // Writer (FC) / Primary (CPM) Accepted = 21
  // Editor Accepted (FC) = 181
  // Reviewer Accepted (CPM) = 137

  let dataObj = {
    3: {
      value: recordID,
    },
  };

  if (role === "EDITOR") {
    dataObj[181] = { value };
  } else if (role === "REVIEWER") {
    dataObj[137] = { value };
  } else {
    dataObj[21] = { value };
  }

  const data = JSON.stringify({
    to: appIDs[cpmMode ? "cueProjectManager" : "iTunesProjectManager"]
      .assignments,
    data: [dataObj],
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  try {
    await axios(config);
    return;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const getTasks = async ({ cueID, month, year }) => {
  const data = JSON.stringify({
    select: [
      3, 11, 12, 14, 15, 16, 17, 18, 19, 20, 24, 25, 26, 27, 29, 32, 35, 36, 37,
      39,
    ],
    from: appIDs.iTunesProjectManager.pmDashboard,
    where: cueID
      ? `{11.EX.'${cueID}'}`
      : `{20.CT.'${month}-'}AND{20.CT.'-${year}'}`,
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records/query",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return {
    data: cleanRecordsData(response.data.data, response.data.fields, true),
    fields: cleanFieldsData(response.data.fields, true),
  };
};

export const processTask = async ({
  task,
  status,
  priority,
  DRI,
  QC,
  archiving,
}) => {
  let postData = [
    {
      24: { value: task.LastUpdatedBy },
      25: { value: task.LastUpdated },
    },
  ];
  if (status) {
    Object.assign(postData[0], { 19: { value: status } });
  } else if (priority) {
    Object.assign(postData[0], { 26: { value: priority } });
  } else if (DRI) {
    Object.assign(postData[0], { 18: { value: DRI } });
  } else if (QC !== undefined) {
    let qcData = {
      36: { value: QC ? "yes" : "no" },
      37: { value: `${task.LastUpdated} by ${task.LastUpdatedBy}` },
    };
    if (QC && archiving) Object.assign(qcData, { 19: { value: "Archived" } });
    Object.assign(postData[0], qcData);
  } else {
    Object.assign(postData[0], {
      11: { value: task.CueTaskID },
      14: { value: task.RequestDate },
      29: { value: task.RequestTime },
      15: { value: task.TaskID },
      16: { value: task.ClientRequester },
      17: { value: task.MediaType },
      18: { value: task.DRI },
      19: { value: task.TaskStatus },
      20: { value: task.DeliveryDate },
      32: { value: task.DeliveryTime },
      26: { value: task.Priority },
      27: { value: task.Task },
      39: { value: task.RelatedProject },
    });
  }
  if (task["RecordID#"])
    Object.assign(postData[0], { 3: { value: task["RecordID#"] } });

  const data = JSON.stringify({
    to: appIDs.iTunesProjectManager.pmDashboard,
    data: postData,
    fieldsToReturn: [
      3, 11, 14, 15, 16, 17, 18, 19, 20, 24, 25, 26, 27, 29, 32, 36, 37, 39,
    ],
  });

  const config = {
    method: "post",
    url: "https://api.quickbase.com/v1/records",
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);
  return response.data.data[0];
};

//////////////////////
// INVOICE
//////////////////////
export const getInvoiceRowsForPayPeriod = async () => {
  const config = {
    method: "post",
    url: `https://api.quickbase.com/v1/reports/90/run?tableId=${appIDs.cueProjectManager.invoices}`,
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
  };

  const getURLString = (page, step) => {
    return `https://api.quickbase.com/v1/reports/90/run?tableId=${
      appIDs.cueProjectManager.invoices
    }&skip=${page * 200}&top=200`;
  };

  let firstData = await axios({ ...config, url: getURLString(0) });

  firstData = firstData.data;

  let retVal = [...firstData.data];
  const pages = Math.floor(
    firstData.metadata.totalRecords / firstData.metadata.numRecords
  );
  if (pages === 0) {
  } else {
    for (let i = 1; i <= pages; i++) {
      let newPage = await axios({ ...config, url: getURLString(i) });
      newPage = newPage.data;
      retVal = [...retVal, ...newPage.data];
    }
  }

  return {
    fields: firstData.fields,
    data: retVal,
  };
};

export const getAllRowsFromReport = async (props) => {
  const { tableID, reportID } = props;

  const config = {
    method: "post",
    url: `https://api.quickbase.com/v1/reports/${reportID}/run?tableId=${tableID}`,
    headers: {
      Authorization: au,
      "QB-Realm-Hostname": realmID,
      "Content-Type": "application/json",
    },
  };

  const getURLString = (page, step) => {
    return `https://api.quickbase.com/v1/reports/${reportID}/run?tableId=${tableID}&skip=${
      page * 200
    }&top=200`;
  };

  let firstData = await axios({ ...config, url: getURLString(0) });

  firstData = firstData.data;

  let retVal = [...firstData.data];
  const pages = Math.floor(
    firstData.metadata.totalRecords / firstData.metadata.numRecords
  );
  if (pages === 0) {
  } else {
    for (let i = 1; i <= pages; i++) {
      let newPage = await axios({ ...config, url: getURLString(i) });
      newPage = newPage.data;
      retVal = [...retVal, ...newPage.data];
    }
  }

  return {
    fields: firstData.fields,
    data: retVal,
  };
};

//////////////////////
// CLIENTS
//////////////////////
export const getAllClientNames = () => {
  const CLIENT_NAME_FID = 6;

  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select: [CLIENT_NAME_FID],
      from: `${appIDs.cueProjectManager.clients}`,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) => {
        const retval = response.data.data
          .map((el) => el[CLIENT_NAME_FID].value)
          .sort();
        resolve(retval);
      })
      .catch((error) => reject(error));
  });
};

//////////////////////
// PROJECTS
//////////////////////
export const getAllProjectNames = (instance) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      from: instance
        ? appIDs.iTunesProjectManager.projects
        : appIDs.cueProjectManager.projects,
      select: instance ? [16, 3] : [16],
      where: instance ? "{157.EX.true}" : "",
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    const clean = (d) => {
      return d.map((el) => el["16"].value).sort();
    };

    const namesAndIDs = (d) => {
      return d
        .map((el) => ({
          ["name"]: el["16"].value,
          ["id"]: el["3"].value,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    };

    axios(config)
      .then((response) =>
        resolve(
          instance ? namesAndIDs(response.data.data) : clean(response.data.data)
        )
      )
      .catch((error) => reject(error));
  });
};

//////////////////////
// PURCHASE ORDERS
//////////////////////
export const getAllPOs = (props) => {
  return new Promise((resolve, reject) => {
    let queryParts = [];

    if (props.client) {
      queryParts.push(`{'10'.EX.'${props.client}'}`);
    }

    if (props.project) {
      queryParts.push(`{'12'.EX.'${props.project}'}`);
    }

    const data = JSON.stringify({
      from: appIDs.cueProjectManager.purchaseOrders,
      where: queryParts.join("AND"),
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(cleanRecordsData(response.data.data, response.data.fields))
      )
      .catch((err) => reject(err));
  });
};

//////////////////////
// CLIENT REQUESTERS
//////////////////////
export const getClientRequestersForProject = ({ project, instance }) => {
  const queryConditions = {
    select: [6],
    where: "",
  };
  if (project) {
    queryConditions.where = `{10.EX.${project}}`;
  } else if (instance) {
    queryConditions.select.push(13);
    queryConditions.where = "{13.EX.true}";
  }
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      from: instance
        ? appIDs.iTunesProjectManager.clientRequesters
        : appIDs.cueProjectManager.clientRequesters,
      select: queryConditions.select,
      where: queryConditions.where,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    const clean = (d) => {
      return d.map((el) => el["6"].value).sort();
    };

    axios(config)
      .then((response) => resolve(clean(response.data.data)))
      .catch((error) => reject(error));
  });
};

//////////////////////
// PRICES
//////////////////////
export const getPriceCodeWithWorkTypeCategory = ({
  workTypeCategory,
  iTunes,
  getAll,
}) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({
      select: [3, 8, 15],
      from: appIDs[iTunes ? "iTunesProjectManager" : "cueProjectManager"]
        .priceList,
      where: getAll ? "" : `{${iTunes ? 8 : 29}.EX.${workTypeCategory}}`,
    });

    const config = {
      method: "post",
      url: "https://api.quickbase.com/v1/records/query",
      headers: {
        Authorization: au,
        "QB-Realm-Hostname": realmID,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then((response) =>
        resolve(cleanRecordsData(response.data.data, response.data.fields))
      )
      .catch((error) => reject(error));
  });
};
