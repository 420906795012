<template>
  <div class="po my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new po -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="dropdownsLoading"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog
          max-width="1200"
          :value="poModal"
          @click:outside="poModal = false"
          @keydown.esc="poModal = false"
        >
          <PurchaseOrdersForm
            :clientsList="clients"
            :projectsList="projects"
            :itemToEdit="itemToEdit"
            @po-table-refresh="handleRefresh"
          />
        </v-dialog>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilters(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- client filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Client </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="po__main-selector po__main-selector-2 selector"
              :class="{ laptop }"
              item-text="name"
              :items="clients"
              v-model="filterClient"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Client', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- project filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Project </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="po__main-selector po__main-selector-2 selector"
              :class="{ laptop }"
              item-text="name"
              :items="projects"
              v-model="filterProject"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Project', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- project manager filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Project Manager </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="po__main-selector po__main-selector-2 selector"
              :class="{ laptop }"
              :item-text="fullName"
              :items="projectManagers"
              v-model="filterProjectManager"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('ProjectManager', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- active date filter -->
        <transition>
          <div v-show="!expandMenu" class="ml-lg-3 ml-md-2 text-center">
            <span class="overline"> Active Date(s) </span>
            <div class="d-flex align-end">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                solo-inverted
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterActiveDateRange"
                    class="po__date-selector po__date-selector-2 selector"
                    solo
                    dense
                    readonly
                    hide-details
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearFilters(true, 'ActiveDate')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterActiveDate"
                  range
                  @input="handleFilterQuery('ActiveDate', $event)"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
        </transition>

        <!-- end date filter -->
        <transition>
          <div v-show="!expandMenu" class="ml-lg-3 ml-md-2 text-center">
            <span class="overline"> End Date(s) </span>
            <div class="d-flex align-end">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                solo-inverted
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterEndDateRange"
                    class="po__date-selector po__date-selector-2 selector"
                    solo
                    dense
                    readonly
                    hide-details
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearFilters(true, 'EndDate')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterEndDate"
                  range
                  @input="handleFilterQuery('EndDate', $event)"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
        </transition>

        <!-- active filter -->
        <transition>
          <div v-show="!expandMenu" class="ml-lg-3 ml-md-2 text-center">
            <span class="overline"> Active </span>
            <div class="d-flex align-end justify-center">
              <v-checkbox
                class="filter-checkbox mt-2 pt-0"
                v-model="filterActive"
                @change="handleFilterQuery('Active', $event)"
              ></v-checkbox>
            </div>
          </div>
        </transition>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="po__search-selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader || dropdownsLoading"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <PurchaseOrdersTable
        v-if="tableData && !showTableLoader && !dropdownsLoading"
        :tableData="tableData"
        :filterSearch="filterSearch"
        :filterClient="filterClient ? filterClient : ''"
        :filterProjectManager="filterProjectManager ? filterProjectManager : ''"
        :filterProject="filterProject ? filterProject : ''"
        :filterActiveDate="filterActiveDate"
        :filterEndDate="filterEndDate"
        :filterActive="filterActive"
        @call-edit-form="handleForm"
      />
    </v-card>
  </div>
</template>

<script>
// libraries
import _ from "lodash";
// internal
import {
  getPurchaseOrders,
  getClients,
  getAllProjects,
  getAllStaff
} from "@/utils/newDbUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
// components
import PurchaseOrdersTable from "@/components/PurchaseOrders/PurchaseOrdersTable";
import PurchaseOrdersForm from "@/components/PurchaseOrders/PurchaseOrdersForm";

export default {
  name: "PurchaseOrders",
  components: {
    PurchaseOrdersTable,
    PurchaseOrdersForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      filterSearch: "",
      expandMenu: false,
      dropdownsLoading: true,
      clients: [],
      filterClient: "",
      projects: [],
      projectManagers: [],
      filterProject: "",
      filterProjectManager: "",
      filterActiveDate: [],
      filterEndDate: [],
      filterActive: true,
      poModal: false,
      itemToEdit: {},
    };
  },
  computed: {
    filterActiveDateRange: {
      get: function () {
        return this.filterActiveDate
          .map((date) => this.formatPickerDate(date))
          .join(" - ");
      },
      set: function (newVal) {
        return (
          this.formatPickerDate(newVal)
            ? [...this.formatPickerDate(newVal)]
            : []
        ).join(" - ");
      },
    },
    filterEndDateRange: {
      get: function () {
        return this.filterEndDate
          .map((date) => this.formatPickerDate(date))
          .join(" - ");
      },
      set: function (newVal) {
        return (
          this.formatPickerDate(newVal)
            ? [...this.formatPickerDate(newVal)]
            : []
        ).join(" - ");
      },
    },
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.purchaseOrders}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  async mounted() {
    this.handleQuery();
    this.getTableData();
    this.handleDropdowns();
    const staff = await getAllStaff();
    this.projectManagers = staff.filter(
      (el) =>
        el.user.userRole === "ADMIN" || el.user.userRole === "MANAGER"
    ) ?? [];
  },
  methods: {
    getTableData() {
      this.showTableLoader = true;
      getPurchaseOrders().then((data) => {
        this.tableData = data?.status === 500 ? [] : data;
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.purchaseOrders}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters(dateOnly, date) {
      let query = this.$route.query;

      if (dateOnly) {
        query = _.omit(query, [date]);
      } else {
        this.filterClient = this.filterProject = this.filterSearch = "";
        this.filterProjectManager = "";
        this.filterActiveDate = false;
        this.filterActiveDate = this.filterActiveDate = [];
      }

      this[`filter${date}`] = [];

      this.$router.push({ path: `/${ROUTES.purchaseOrders}` }).catch(() => {}); // Avoided redundant navigation error handler
    },
    handleDropdowns() {
      Promise.all([getClients(), getAllProjects()])
        .then((resp) => {
          [this.clients, this.projects] = resp;
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.dropdownsLoading = false;
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach((param) => {
          if (param === "ActiveDate" || param === "EndDate") {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [currentQuery[param]]
                : currentQuery[param];
          } else if (param === "Active") {
            this[`filter${param}`] = currentQuery[param] === "true";
          } else {
            this[`filter${param}`] = currentQuery[param];
          }
        });
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.poModal = true;
    },
    handleRefresh() {
      this.poModal = false;
      this.getTableData();
    },
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.po {
  max-width: 1500px;
  margin: 0 auto;

  &__main-selector {
    width: 300px;
    &.laptop {
      width: 200px;
    }
  }

  &__date-selector {
    width: 220px;
  }

  &__search-selector {
    width: 340px;
  }
}

.po__main-selector-2 {
  width: 180px !important;
}

.po__date-selector-2 {
  width: 180px !important;
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
  .filter-checkbox .v-input--selection-controls__input {
    margin-right: 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>