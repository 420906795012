<template>
  <div :class="{'payment-requests': !user.adminDetails.vendor}">
    <v-container class="typeform-selector d-flex" v-if="!showInvitationMessage || !showInvitationVendor">
      <v-btn 
        v-if="!user.adminDetails.vendor"
        height="56px" 
        color="primary" 
        @click="handleTypeform('uwO7SBB0')">
        Staff Hours
      </v-btn>
      <v-btn
        v-if="!user.adminDetails.vendor"
        height="56px"
        color="primary"
        class="ml-4"
        @click="handleInvitationWindow('reimbursement')"
      >
        Reimbursement
      </v-btn>
      <v-btn
        v-if="user.user.userRole !== 'STAFF' || user.adminDetails.vendor"
        height="56px"
        color="primary"
        class="ml-4"
        @click="handleInvitationWindow('vendor')"
      >
        SUBMIT YOUR INVOICE
      </v-btn>
    </v-container>
    <v-card :style="{ opacity: showTypeform ? '100%' : '0' }" v-if="!showInvitationMessage && !showInvitationVendor">
      <div ref="typeform__embed" class="typeform__embed"></div>
      <v-snackbar
        v-model="typeformProcessing"
        min-width="fit-content"
        elevation="12"
        timeout="-1"
        color="warning"
        rounded="pill"
        content-class="text-center"
        bottom
      >
        <v-icon left>mdi-timer-sand</v-icon>Processing your answers...
      </v-snackbar>
    </v-card>
    <div v-if="showInvitationMessage" class="invitation">
      <p class="invitation__title">Hi, {{ user.user.firstName }} {{ user.user.lastName }}!</p>
      <p class="invitation__subtitle">Welcome to the Co. Cue Reimbursement Form.</p>
      <p class="invitation__subtitle">Please make sure to provide the reimbursement amount in USD ($).</p>
      <div class="mt-10">
        <v-btn
          class="invitation__btn ma-2"
          x-large
          @click="requestReimbursement"
        >
          Start
        </v-btn>
      </div>
    </div>
    <div v-if="showInvitationVendor" class="invitation">
      <p class="invitation__title">Hi, {{ user.user.firstName }} {{ user.user.lastName }}!</p>
      <p class="invitation__subtitle">Welcome to the Co. Cue Payment Request.</p>
      <p class="invitation__subtitle">Please make sure to provide the invoice amount in USD ($).</p>
      <div class="mt-10">
        <v-btn
          class="invitation__btn ma-2"
          x-large
          @click="requestVendor"
        >
          Start
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import * as typeformEmbed from "@typeform/embed";
// internal
import {
  processAssignment,
  queryTypeformData,
  getCustomerPriceList,
  getAllProjects,
  getAllStaff,
  getLimitedStaffData
} from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
import { BLANK_NEW_ASSIGNMENT_DATAS } from "@/utils/assignmentUtils";

export default {
  name: "PaymentRequests",
  data() {
    return {
      showInvitationMessage: false,
      showInvitationVendor: false,
      showTypeform: false,
      staff: [],
      typeformProcessing: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleInvitationWindow(type) {
      if (type === "vendor") {
        this.showInvitationVendor = true;
      } else {
        this.showInvitationMessage = true;
      }
    },
    requestReimbursement() {
      this.$router.push({ name: 'Reimbursement Request Form' });
      this.showInvitationMessage = false;
    },
    requestVendor() {
      this.$router.push({ name: 'Vendor Invoice Form' });
      this.showInvitationVendor = false;
    },
    handleTypeform(typeform_id) {
      this.showTypeform = true;
      const hoursMode = typeform_id === "uwO7SBB0";
      const vendorForm = typeform_id === "osT4dudQ";
      const element = this.$refs["typeform__embed"];
      typeformEmbed.makeWidget(
        element,
        `https://form.typeform.com/to/${typeform_id}`,
        {
          hideFooter: true,
          hideHeaders: true,
          onSubmit: (event) => {
            this.typeformProcessing = true;
            setTimeout(() => {
              queryTypeformData({
                typeform_id,
                response_id: event.response_id,
              }).then(async (resp) => {
                // process form answers
                const form = resp.items.find(
                  (el) => el.response_id === event.response_id
                );
                const getKeyValue = (key) => {
                  const retVal = form.answers.find(
                    (el) =>
                      el.field.ref === key ||
                      el.field.ref === `${key}1` ||
                      el.field.ref === `${key}2`
                  );
                  if (retVal) {
                    const dataType = retVal.type;
                    if (dataType === "choice") return retVal.choice.label;
                    else if (dataType === "choices")
                      return retVal.choices.labels;
                    else return retVal[dataType];
                  } else return null;
                };
                const data = {
                  requestType: getKeyValue("requestType"),
                  requestType1: getKeyValue("requestType1"),
                  requestType2: getKeyValue("requestType2"),
                  quantity: getKeyValue("quantity"),
                  geo: getKeyValue("geo"),
                  requestDate: getKeyValue("requestDate"),
                  deliveryDate: getKeyValue("deliveryDate"),
                  mediaType: getKeyValue("mediaType"),
                  payPeriod: getKeyValue("payPeriod"),
                  assignmentDetail: getKeyValue("assignmentDetail"),
                  document: getKeyValue("document"),
                  projectManager: getKeyValue("projectManager"),
                  project: getKeyValue("project"),
                  workCategory: getKeyValue("workCategory"),
                  employmentType: getKeyValue("employmentType"),
                  user: `${this.user.user.firstName} ${this.user.user.lastName}`,
                  formId: typeform_id,
                };
                if (vendorForm) {
                  const resp = await getLimitedStaffData();
                  this.staff = resp.data;
                }
                // format data for assignment POST
                const projectManager = this.staff.find(
                  (el) =>
                    data.projectManager.includes(el?.firstName) &&
                    data.projectManager.includes(el?.lastName)
                );
                const projectsList =
                  hoursMode || data.employmentType === "Freelancer"
                    ? null
                    : await getAllProjects();
                const priceList = hoursMode
                  ? null
                  : await getCustomerPriceList();
                const getWorkType = () => {
                  const businessExpenses = priceList?.find(
                    (el) => el.workTypeName === "Business Expenses"
                  );
                  return businessExpenses ? businessExpenses.id : null;
                };
                const getRelatedProject = () => {
                  const administrative = projectsList?.find(
                    (el) => el.name === "Administrative"
                  );
                  return administrative ?? null;
                };
                // handle document
                const doc = data.document
                  ? await queryTypeformData({ file_url: data.document })
                  : null;
                // handle assignment
                const assignment = {
                  assignmentDetails: {
                    assignmentTitle: hoursMode
                      ? data.requestType
                      : vendorForm
                      ? `Vendor Invoice`
                      : `Reimbursement Request - ${data.employmentType.concat(
                          " | "
                        )} - ${data.workCategory}`,
                    geo: data.geo,
                    assignmentDetail: hoursMode
                      ? data.assignmentDetail
                      : vendorForm
                      ? ``
                      : `${data.project} - ${
                          data.requestType1 ?? data.requestType2.join(", ")
                        } - ${data.assignmentDetail}`,
                    mediaType: data.mediaType ?? null,
                    primaryAccepted: true,
                    assignmentStatus: "Requested",
                  },
                  schedule: {
                    requestDate: hoursMode
                      ? data.requestDate.substring(0, 10)
                      : BLANK_NEW_ASSIGNMENT_DATAS[0].schedule.requestDate,
                    deliveryDate: data.deliveryDate.substring(0, 10),
                    primaryDueDate: data.deliveryDate.substring(0, 10),
                    payPeriod: this.$moment(data.payPeriod, "MMMM YYYY").format(
                      "YYYY-MM"
                    ),
                  },
                  document: hoursMode ? null : doc,
                  pricing: {
                    workTypes: [
                      {
                        customerPriceListId: hoursMode ? null : getWorkType(),
                        quantity: Number(data.quantity)
                          ? Number(data.quantity)
                          : null,
                        orderPosition: 1
                      },
                    ],
                  },
                  clientId: hoursMode
                    ? null
                    : getRelatedProject()?.client?.id ?? null,
                  projectId: hoursMode ? null : getRelatedProject()?.id ?? null,
                  primaryId: this.user.user.id,
                  projectManagerId: hoursMode
                    ? null
                    : vendorForm
                    ? projectManager?.id
                    : getRelatedProject()?.projectManager?.user?.id ?? null,
                };
                typeform_id === 'uwO7SBB0' ? assignment.schedule.primaryDueTime = '05:00 PM' : null;
                // POST assignment
                processAssignment({ data: assignment }).then((resp) => {
                  if (resp.status === 201) {
                    // POST Slack message
                    if (!hoursMode)
                      Object.assign(data, {
                        pmSlackTag: projectManager?.adminDetails?.slackIdPm
                          ? `<@${projectManager.adminDetails.slackIdPm}>`
                          : `*${data.projectManager}*`,
                      });
                    publishMessage({
                      mode: hoursMode 
                        ? "hours" 
                        : vendorForm
                        ? "vendor"
                        : "reimbursement",
                      data,
                    }).then(() => {
                      if (hoursMode) {
                        this.typeformProcessing = false;
                        this.handleFlash({
                          response: { data: resp },
                          show: true,
                        });
                      } else {
                        if (!vendorForm) {
                          publishMessage({
                            mode: "reimbursementConfirmation",
                            data: {
                              userSlackTag: this.user.adminDetails.slackIdStaff,
                            },
                          }).then(() => {
                            this.typeformProcessing = false;
                            this.handleFlash({
                              response: { data: resp },
                              show: true,
                            });
                          });
                        } else {
                          this.typeformProcessing = false;
                          this.handleFlash({
                            response: { data: resp },
                            show: true,
                          })
                        }
                      }
                    });
                  }
                });
              });
            }, 3000);
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-requests {
  max-width: 1400px;
  margin: 0 auto;
}
.container {
  margin-left: 0 !important;
}
.typeform {
  &__embed {
    height: 900px;
  }
}
.invitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  &__title {
    font-size: 30px;
    font-weight: 400;
    line-height: 41.41px;
  }
  &__subtitle {
    font-size: 30px;
    font-weight: 400;
    line-height: 41.41px;
  }
  & button {
    background-color: #1976D2 !important;
    border-radius: 4px !important;
    color: #fff;
  }
}
</style>