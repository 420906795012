<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            outlined
            label="Work Type"
            v-model="priceList.workTypeName"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Work Type Category"
            :items="workTypeCategories"
            v-model="priceList.workTypeCategory"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Units"
            :items="units"
            v-model="priceList.units"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            type="number"
            outlined
            prepend-inner-icon="mdi-currency-usd"
            label="Primary Price Card (Internal)"
            v-model="priceList.primaryPriceCard"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            type="number"
            outlined
            prepend-inner-icon="mdi-currency-usd"
            label="Secondary Price Card (Internal)"
            v-model="priceList.secondaryPriceCard"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            type="number"
            outlined
            prepend-inner-icon="mdi-currency-usd"
            label="Price Card (Client)"
            v-model="priceList.clientPriceCard"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Note"
            v-model="priceList.note"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deletePriceList">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="priceListHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="priceListHandling"
        :disabled="deleteConfirmation"
        @click="handlePriceList"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  processCustomerPriceList,
  deleteCustomerPriceList,
} from "@/utils/newDbUtils";

export default {
  name: "CustomerPriceListForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    units: {
      type: Array,
      required: true,
    },
    workTypeCategories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      priceList: {
        clientPriceCard: null,
        editorPriceCard: null,
        note: null,
        primaryPriceCard: null,
        secondaryPriceCard: null,
        units: null,
        workTypeCategory: null,
        workTypeName: null,
      },
      valid: true,
      priceListHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.dataViewsHandler();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else this.$refs.form.reset();
    },
    handleEditMode() {
      this.priceList = { ...this.itemToEdit };
    },
    handlePriceList() {
      if (this.$refs.form.validate()) {
        this.priceListHandling = true;
        processCustomerPriceList({
          data: { ...this.priceList },
          id: this.itemToEdit?.id,
        }).then((resp) => {
          this.priceListHandling = false;
          this.$emit("price-list-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      }
    },
    deletePriceList() {
      this.deleteConfirmation = false;
      this.priceListHandling = true;
      deleteCustomerPriceList(this.itemToEdit?.id).then((resp) => {
        this.priceListHandling = false;
        this.$emit("price-list-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
