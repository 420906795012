export const projectsModule = {
    state: () => ({
        requestTypes: [],
        customerPriceList: [],
        geosList: [],
    }),
    mutations: {
        mSetRequestTypes(state, requestTypes) {
            state.requestTypes = requestTypes
        },
        mSetCustomerPriceList(state, customerPriceList) {
            state.customerPriceList = customerPriceList
        },
        mSetGeosList(state, geosList) {
            state.geosList = geosList
        }
    },
    actions: {
        aSetRequestTypes({ commit }, requestTypes) {
            commit('mSetRequestTypes', requestTypes)
        },
        aSetCustomerPriceList({ commit }, customerPriceList) {
            commit('mSetCustomerPriceList', customerPriceList)
        },
        aSetGeosList({ commit }, geosList) {
            commit('mSetGeosList', geosList)
        }
    },
    getters: {
        gGetRequestTypes(state) {
            return state.requestTypes
        },
        gGetCustomerPriceList(state) {
            return state.customerPriceList
        },
        gGetGeosList(state) {
            return state.geosList
        }
    },
  };
  