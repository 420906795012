<template>
  <div class="source-table">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-if="
                !xContent &&
                !shakespeare &&
                !shakespeareLoc &&
                !cueLoc &&
                !cueCopywriting
              "
              class="text-center px-0"
            >
              File Found
            </th>
            <th class="text-center px-2">Assignment Detail</th>
            <th class="text-center px-2">Task ID</th>
            <th v-if="shakespeare || shakespeareLoc" class="text-center px-0">
              Content ID
            </th>
            <th
              v-if="!shakespeare && !backstageCopywriting && !cueCopywriting"
              class="text-center px-2"
            >
              Source GEO
            </th>
            <th class="text-center px-2">
              GEO{{ shakespeare || cueCopywriting ? "" : "s" }}
            </th>
            <th v-if="!shakespeare && !shakespeareLoc" class="text-center px-2">
              Related Client Requester
            </th>
            <th
              v-if="!shakespeare && !cueLoc && !cueCopywriting && !shakespeareLoc"
              class="text-center px-2"
            >
              Email Subject Line
            </th>
            <th
              v-if="!shakespeare && !backstageCopywriting && !cueCopywriting"
              class="text-center px-2"
            >
              Word Count
            </th>
            <th v-if="!shakespeare" class="text-center px-2">Media Type</th>
            <th v-if="!cueLoc && !cueCopywriting" class="text-center px-2">
              Keyword
            </th>
            <th class="text-center px-2">Request Type</th>
            <th class="text-center px-2">
              {{ cueLoc || cueCopywriting ? "Requested" : "Request Date" }}
            </th>
            <th
              v-if="
                !shakespeare && !shakespeareLoc && !cueLoc && !cueCopywriting
              "
              class="text-center px-2"
            >
              Delivery Date
            </th>
            <th
              v-if="!shakespeare && !cueLoc && !cueCopywriting"
              class="text-center px-0"
            >
              Link
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) in tableRows" :key="'table-row' + i">
            <td
              v-if="
                !xContent &&
                !shakespeare &&
                !shakespeareLoc &&
                !cueLoc &&
                !cueCopywriting
              "
              class="text-center px-0"
            >
              <v-icon v-if="isFileFound(row.task_name)" color="green darken-2">
                mdi-check-bold
              </v-icon>
              <v-icon v-if="!isFileFound(row.task_name)" color="red darken-2">
                mdi-close
              </v-icon>
            </td>
            <td :class="row.video_content_id ? 'px-0' : 'px-2'">
              {{ row.task_name }}
            </td>
            <td
              class="text-center"
              :class="row.video_content_id ? 'px-0' : 'px-2'"
            >
              <!-- handling the Shakespeare data format preservation for Cue Copywriting -->
              {{ row.task_id === row.task_name ? "" : row.task_id }}
            </td>
            <td v-if="shakespeare || shakespeareLoc" class="text-center px-0">
              {{ row.content_id ? row.content_id : row.video_content_id }}
            </td>
            <td
              v-if="!shakespeare && !backstageCopywriting && !cueCopywriting"
              class="text-center px-2"
            >
              <v-chip small>
                {{ row.source_geo }}
              </v-chip>
            </td>
            <td
              v-if="shakespeare || cueCopywriting"
              class="text-center"
              :class="row.video_content_id ? 'px-0' : 'px-2'"
            >
              {{ row.geo }}
            </td>
            <td v-else class="text-center px-2">
              <v-chip
                small
                v-for="g in row.geos"
                :key="
                  shakespeare ? i + g : shakespeareLoc || cueLoc ? g.geo : g
                "
              >
                {{ shakespeareLoc || cueLoc ? g.geo : g }}
              </v-chip>
            </td>
            <td v-if="!shakespeare && !shakespeareLoc" class="text-center px-2">
              {{ row.client_requester }}
            </td>
            <td v-if="!shakespeareLoc && !shakespeare && !cueLoc && !cueCopywriting" class="px-2">
              {{ row.subject_line }}
            </td>
            <td
              v-if="!shakespeare && !backstageCopywriting && !cueCopywriting"
              class="text-center px-2"
            >
              {{ row.word_count }}
              <v-btn
                v-if="!cueLoc"
                icon
                :color="row.word_count ? 'grey lighten-1' : 'secondary'"
                @click="openUpdateForm(row, i)"
              >
                <v-icon v-if="row.word_count">mdi-pen-remove</v-icon>
                <v-icon v-else>mdi-pen-plus</v-icon>
              </v-btn>
            </td>
            <td v-if="!shakespeare" class="text-center px-2">
              {{ row.media_type }}
            </td>
            <td v-if="!cueLoc && !cueCopywriting" class="text-center px-2">
              {{ row.keyword }}
            </td>
            <td class="text-center px-2">
              {{ row.request_type }}
            </td>
            <td class="text-center px-2">
              {{
                formatDate(row.request_date) +
                ((cueLoc || cueCopywriting) && formValues.requestTime
                  ? ` ${formValues.requestTime}`
                  : "")
              }}
            </td>
            <td
              v-if="
                !shakespeare && !shakespeareLoc && !cueLoc && !cueCopywriting
              "
              class="text-center px-2"
            >
              {{ formatDate(row.due_date) }}
            </td>
            <td
              v-if="!shakespeare && !cueLoc && !cueCopywriting"
              class="text-center px-0"
            >
              <v-btn
                v-if="row.link"
                icon
                color="primary"
                :href="row.link"
                target="_blank"
              >
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
              <v-btn
                icon
                :color="row.link ? 'grey lighten-1' : 'secondary'"
                @click="openUpdateForm(row, i)"
              >
                <v-icon v-if="row.link">mdi-link-variant-remove</v-icon>
                <v-icon v-else>mdi-link-variant-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="updateTableRows" width="600px">
      <v-form ref="updateTableRowsForm" class="v-card v-sheet theme--light">
        <v-card-title class="flex-column align-start py-4">
          <span class="text-h5"
            >{{ backstageCopywriting ? "" : "Word Count / " }}Link update</span
          >
          <span>{{ taskName }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" class="pb-0">
                <v-text-field
                  v-if="!backstageCopywriting"
                  outlined
                  label="Word Count"
                  type="number"
                  v-model="wordCount"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" :sm="backstageCopywriting ? 12 : 9" class="pb-0">
                <v-text-field outlined label="Link" v-model="link" /> </v-col
            ></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 py-4">
          <v-btn width="30%" color="secondary" @click="handleTableRowsUpdate()">
            Update
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import _ from "lodash";

export default {
  name: "SourceTable",
  data() {
    return {
      updateTableRows: false,
      assignmentIndex: null,
      taskName: "",
      wordCount: null,
      link: null,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "tableRows",
      "files",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
      "backstageCopywriting",
      "cueLoc",
      "cueCopywriting",
      "formValues",
    ]),
    filenames() {
      return this.files.map((el) => el.name.split(".xlsx")[0]);
    },
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["updateStaffAssignmentWordCount"]),
    isFileFound(taskName) {
      return this.filenames.includes(taskName);
    },
    formatDate(d) {
      return this.shakespeare ||
        this.shakespeareLoc ||
        this.cueLoc ||
        this.cueCopywriting
        ? this.$moment(d).format("MM-DD-YYYY")
        : d?.replace(/\./g, "/");
    },
    openUpdateForm(row, i) {
      this.updateTableRows = true;
      this.assignmentIndex = i;
      this.taskName = row.task_name;
      this.wordCount = row.word_count ? row.word_count : null;
      this.link = row.link ? row.link : null;
    },
    handleTableRowsUpdate() {
      if (this.$refs.updateTableRowsForm.validate()) {
        this.tableRows[this.assignmentIndex].word_count = Number(
          this.wordCount
        );
        this.updateStaffAssignmentWordCount({
          taskName: this.taskName,
          newWordCount: Number(this.wordCount),
        });
        this.tableRows[this.assignmentIndex].link = this.link;
        this.updateTableRows = false;
      }
    },
  },
};
</script>
