<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-checkbox v-else v-model="qc" @click.native.stop.prevent="handleChange" />
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { processTask } from "@/utils/newDbUtils";

export default {
  name: "PMDashboardValidationSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    leadPMEmail: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      qc: null,
      isUpdating: false,
    };
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleChange() {
      this.isUpdating = true;
      const validationMoment = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      let emitParams = [
        "refresh-table-data",
        this.item.index,
        "qc",
        this.qc,
        `${validationMoment} by ${this.user.user.firstName} ${this.user.user.lastName}`,
      ];
      processTask({
        data: {
          qc: this.qc,
          validation: `${validationMoment} by ${this.user.user.firstName} ${this.user.user.lastName}`,
        },
        id: this.item.id,
        patch: true,
      })
        .then((qcResp) => {
          if (
            this.qc &&
            this.item.taskStatus === "Delivered" &&
            this.user.user.email === this.leadPMEmail
          ) {
            emitParams.push(true);
            processTask({
              data: {
                taskStatus: "Archived",
              },
              id: this.item.id,
              patch: true,
            })
              .then((statusResp) => {
                this.$emit(...emitParams);
                this.isUpdating = false;
                this.handleFlash({ response: statusResp, show: true });
              })
              .catch((err) => {
                this.isUpdating = false;
                this.handleFlash({ response: err, show: true });
                console.error(err);
              });
          } else {
            this.item.taskStatus === "Archived"
              ? this.$emit("get-table-data")
              : this.$emit(...emitParams);
            this.isUpdating = false;
            this.handleFlash({ response: qcResp, show: true });
          }
        })
        .catch((err) => {
          this.isUpdating = false;
          this.handleFlash({ response: err, show: true });
          console.error(err);
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.qc = this.value;
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 21px 0;
}
::v-deep {
  .v-input--checkbox .v-input__control .v-input__slot {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 8px;
    & .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
</style>
