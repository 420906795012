<template>
  <v-container class="py-0 px-0">
    <v-row class="py-0">
      <v-col>
        <v-list class="py-0">
          <v-list-item
            class="d-flex align-center list-item relative"
          >
            <v-checkbox class="mt-0 pt-0 absolute absoluteTop ml--5" v-model="checkedAll" @change="handleCheckAll"/>
            <v-list-item-content class="ml-3" v-if="checkTheValueColumn">
              <v-list-item-title class="ml-14 font-weight-bold mt-1">Key</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content 
              :class="gGetFieldName === 'GEO' ? 'ml-2' : 'ml-5'"
              :style="{maxWidth: checkTheMediaType ? '180px' : ''}">
              <v-list-item-title class="ml-14 font-weight-bold mt-1">Value</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content 
              :style="{maxWidth: checkTheMediaType ? '180px' : ''}"
              :class="checkTheMediaType ? 'ml-7' : 'ml-3'">
              <v-list-item-title class="ml-14 font-weight-bold mt-1">Status</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content :class="gGetFieldName === 'GEO' ? 'ml-1' : 'ml-3'" v-if="checkTheUsageColumn">
              <v-list-item-title class="ml-14 font-weight-bold mt-1">Usage</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="ml-3" v-if="gGetFieldName === 'Media Type'">
              <v-list-item-title class="ml-14 font-weight-bold mt-1">Dependent Genres</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <draggable v-model="enumsList"
            item-key="id"
            @start="drag = true"
            @end="drag = false">
          <v-list-item
              v-for="(item, index) in enumsList"
              :key="item.id"
              class="d-flex align-center list-item relative"
            >
            <div class="d-flex align-center">
              <v-list-item-icon class="handle mt-6 mr-0">
                <v-icon>mdi-drag</v-icon>
              </v-list-item-icon>
              <v-checkbox v-model="item.checked" class="mt-5"/>
            </div>
              
              <v-list-item-content class="ml-3 hover"
                v-if="checkTheValueColumn"
                @click="handleSelect(item)">
                <v-list-item-title>{{ item.key }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="ml-3 hover"
                :style="{maxWidth: checkTheMediaType ? '180px' : ''}"
                @click="handleSelect(item)">
                <v-list-item-title class="ml-3">{{ item.value }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="ml-3 hover"
                :style="{maxWidth: checkTheMediaType ? '180px' : ''}"
                @click="handleSelect(item)">
                <v-list-item-title class="ml-6">
                  <v-chip
                    v-if="item.status === 'ACTIVE'"
                    color="#1976D2"
                    outlined
                  >
                    {{ item.status }}
                  </v-chip>
                  <v-chip
                    v-else
                    color="#000000"
                    outlined
                  >
                    {{ item.status }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="ml-4 hover"
                v-if="checkTheUsageColumn"
                @click="handleSelect(item)">
                <v-list-item-title class="ml-7">
                  <v-chip
                    color="#1976D2"
                    outlined
                  >
                    {{ item.usage }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="ml-3" v-if="gGetFieldName === 'Media Type'">
                <v-list-item-title class="ml-5 mt-1">
                  <div class="chip-container">
                    <v-chip
                      v-for="genre in item.genres"
                      :key="genre"
                      class="ml-1"
                    >
                      {{ genre }}
                    </v-chip>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </draggable>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import { updateEnum, getSpecificEnum } from '@/utils/newDbUtils';

export default {
  name: 'OptionsTable',
  components: {
    draggable
  },
  data() {
    return {
      checkedAll: false,
      drag: false
    }
  },
  computed: {
    ...mapGetters("settings", ["gGetEnumsList", "gGetEnumsListClone", "gGetFieldName"]),
    checkTheValueColumn() {
      const noValue = [
        'Keyword', 'Media Type', 'Genre'
      ];
      return !noValue.includes(this.gGetFieldName);  
    },
    checkTheUsageColumn() {
      const noValue = [
        'Keyword',
        'Media Type',
        'Request Type',
      ];
      return !noValue.includes(this.gGetFieldName);
    },
    checkTheMediaType() {
      return this.gGetFieldName === 'Media Type';
    },
    enumsList: {
      get() {
        if (this.gGetFieldName && this.gGetFieldName === 'Media Type') {
          this.gGetEnumsList.forEach(item => {
            if (item.genres && item.genres.length) {
              item.genres.sort((a, b) => a.localeCompare(b));
            }
          })
        }
        return this.gGetEnumsList;
      },
      async set(value) {
        value.forEach((item, index) => {
          item.displayOrder = index;
        })
        const changes = [];
        const oldMap = new Map(this.gGetEnumsListClone.map(item => [item.id, item]));
        value.forEach(newItem => {
          const oldItem = oldMap.get(newItem.id);
          if (oldItem) {
            for (const key in newItem) {
              if (newItem[key] !== oldItem[key]) {
                changes.push({
                  id: newItem.id,
                  oldItem,
                  newItem
                });
                break;
              }
            }
          }
        });
        if (changes.length) {
          const fieldType = this.enumRequestType();
          const updatePromises = changes.map(item => updateEnum({type: fieldType, id: item.id, data: item.newItem}));
          try {
            await Promise.all(updatePromises)
              .then(async (res) => {
                const result = await getSpecificEnum(this.enumRequestType());
                this.aSetEnumsList(result.data);
                this.loading = false;
              })
          } catch (error) {
            console.error('Error deleting items:', error);
            this.loading = false;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("settings", ["aSetEnumsList", "aUpdateEnumsList"]),
    handleCheckAll() {
      if (this.checkedAll) {
        this.gGetEnumsList.forEach(item => item.checked = true);
      } else {
        this.gGetEnumsList.forEach(item => item.checked = false);
      }
    },
    enumRequestType() {
      switch (this.gGetFieldName) {
        case "GEO":
          return "geos";
        case "Request Type":
          return "request-types";
        case "Media Type":
          return "media-types";
        case "Keyword":
          return "keywords";
        case "Genre":
          return "genre";
        default:
          return null;
      }
    },
    handleSelect(item) {
      this.$emit('update:item', item)
    }
  }
};
</script>

<style>
.handle {
  cursor: move;
}
.list-item {
  border-bottom: 1px solid #e0e0e0;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  left: 50px;
  top: 16px;
}
.absoluteTop {
  position: absolute;
  left: 50px;
  top: 14px;
}
.hover:hover {
  cursor: pointer;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.v-chip {
  white-space: normal;
}

.ml--5 {
  margin-left: -9px;
}
</style>
