import { render, staticRenderFns } from "./POViz.vue?vue&type=template&id=38dd3172&scoped=true&"
import script from "./POViz.vue?vue&type=script&lang=js&"
export * from "./POViz.vue?vue&type=script&lang=js&"
import style0 from "./POViz.vue?vue&type=style&index=0&id=38dd3172&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38dd3172",
  null
  
)

export default component.exports