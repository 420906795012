// libraries
import _ from "lodash";
// internal
import {
  getTableDataFromSource
} from "../utils/dataUploadUtils";

export const dataUploadModule = {
  state: () => ({
    uploadedSourceFile: null,
    tableRows: null
  }),
  mutations: {
    ADD_SOURCE_FILE(state, file) {
      state.uploadedSourceFile = file;
    },
    REMOVE_SOURCE_FILE(state) {
      state.uploadedSourceFile = null;
    },
    CHANGE_TABLE_ROWS(state, rows) {
      state.tableRows = rows;
    },
  },
  actions: {
    addSourceFile(context, file) {
      context.commit("ADD_SOURCE_FILE", file);
      getTableDataFromSource(file).then((data) => {
        context.commit("CHANGE_TABLE_ROWS", data);
      });
    },
    removeSourceFile(context) {
      context.commit("REMOVE_SOURCE_FILE");
      context.commit("CHANGE_TABLE_ROWS", null);
    }
  },
  getters: {
    uploadedSourceFile: (state) => {
      return state.uploadedSourceFile;
    },
    tableRows: (state) => {
      return state.tableRows;
    }
  },
};
