import { render, staticRenderFns } from "./CustomerPriceListTable.vue?vue&type=template&id=fcc88260&scoped=true&"
import script from "./CustomerPriceListTable.vue?vue&type=script&lang=js&"
export * from "./CustomerPriceListTable.vue?vue&type=script&lang=js&"
import style0 from "./CustomerPriceListTable.vue?vue&type=style&index=0&id=fcc88260&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcc88260",
  null
  
)

export default component.exports