var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"po-vizualization-chart-container d-flex flex-column"},[_c('v-container',[_c('v-row',{staticClass:"d-flex flex-row justify-center"},[_c('v-col',{staticClass:"d-flex flex-column justify-start align-center",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"overline"},[_vm._v("Amount in PO")]),_c('div',{staticClass:"font-weight-black",style:({ fontSize: '1.4em' })},[_vm._v(" "+_vm._s(_vm.amountInPoString)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-start align-center",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"overline"},[_vm._v("Total Amount Billed")]),_c('div',{staticClass:"font-weight-black",style:({ fontSize: '1.4em', color: _vm.chartColors.pink })},[_vm._v(" "+_vm._s(_vm.amountUsedString)+" ")])]),_c('v-col',{staticClass:"d-flex flex-column justify-start align-center",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"overline"},[_vm._v("Total Amount Remaining")]),_c('div',{staticClass:"font-weight-black",style:({ fontSize: '1.4em', color: '#666666' })},[_vm._v(" "+_vm._s(_vm.amountRemainingString)+" ")])])],1)],1),_c('svg',{ref:"svg",attrs:{"id":"svg-po-vizualization"}},[(_vm.domLoaded)?_c('g',{style:({
        transform: `translate(${_vm.margin.left}px,${_vm.margin.top}px)`,
      })},[_c('g',[_c('rect',{attrs:{"x":"0","y":_vm.height / 2 - _vm.barHeight / 2,"width":_vm.xScale(_vm.amountInPO),"height":_vm.barHeight,"fill":"#666666"}}),_c('rect',{attrs:{"x":"0","y":_vm.height / 2 - _vm.barHeight / 2,"width":_vm.xScale(_vm.billedAmount),"height":_vm.barHeight,"fill":_vm.chartColors.pink}}),(_vm.billedIsGreaterThanAmount)?_c('rect',{attrs:{"x":_vm.xScale(_vm.amountInPO),"y":_vm.height / 2 - _vm.barHeight / 2,"width":_vm.xScale(_vm.billedAmount) - _vm.xScale(_vm.amountInPO),"height":_vm.barHeight,"fill":_vm.texture.url()}}):_vm._e()]),_c('g',[_c('g',{style:({
            transform: `translate(${_vm.chartLabelXOffset}px,${-5}px)`,
          })},[(_vm.amountInPO)?_c('foreignObject',{staticClass:"chart-label-fo",attrs:{"height":_vm.barHeight,"width":"170px"}},[_c('div',{staticClass:"chart-label-div d-flex flex-column justify-end aling-end"},[_c('span',{class:{
                  shadow: _vm.billedIsGreaterThanAmount,
                  'ml-2': _vm.billedPercentString === '0.0',
                }},[_c('span',{staticClass:"billed-label text-uppercase font-weight-medium mr-1"},[_vm._v("billed")]),_c('span',{staticClass:"billed-amount font-weight-bold"},[_vm._v(_vm._s(`${_vm.billedPercentString}%`))])])])]):_vm._e()])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }