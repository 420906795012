<template>
  <v-chip
    class="flow-chip my-2 mr-1 px-2"
    :outlined="!stageIsComplete"
    :style="{
      backgroundColor: backgroundColor,
      border: `1px solid ${textColor}`,
    }"
    label
    @click.stop="handleChipClick"
  >
    <div class="d-flex flex-column">
      <div
        class="step-name font-weight-bold"
        :style="{
          color: textColor,
        }"
      >
        {{ flowStep.label }}
      </div>
      <div
        class="person-name"
        :style="{
          color: textColor,
        }"
      >
        {{ getName(flowStep.person) }}
      </div>
    </div>
    <div
      v-if="hasDueDate"
      class="date-section ml-2 rounded-sm d-flex flex-column align-center"
      :class="{
        complete: stageIsComplete,
      }"
      :style="{ border: `1px solid ${textColor}`, color: textColor }"
    >
      <div class="month">{{ dueDateMonth }}</div>
      <div class="day">{{ dueDateDay }}</div>
    </div>
  </v-chip>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditorTableFlowChip",
  props: {
    flowStep: {
      type: Object,
      require: true,
    },
    assignment: {
      type: Object,
      require: true,
    },
    index: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      calendarColorClassComplete: "accent--text text--lighten-2",
      calendarColorClassIncomplete: "accent--text text--lighten-1",
      borderColorComplete: "#90D6CE ",
      borderColorIncomplete: "#75BAB3",
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin"]),
    isUserChip() {
      return (
        !this.isAdmin && this.user.user.email === this.flowStep.person.email
      );
    },
    stageIsComplete() {
      return this.flowStep.isComplete;
    },
    textColor() {
      if (!this.isUserChip) {
        if (this.stageIsComplete) {
          return "white";
        } else {
          return "#3FA198";
        }
      } else {
        if (this.stageIsComplete) {
          return "white";
        } else {
          return "#EE7975";
        }
      }
    },
    borderColor() {
      return this.flowStep.isComplete
        ? this.borderColorComplete
        : this.borderColorIncomplete;
    },

    backgroundColor() {
      if (!this.isUserChip) {
        if (this.stageIsComplete) {
          return "#3FA198";
        } else {
          return "transparent";
        }
      } else {
        if (this.stageIsComplete) {
          return "#EE7975";
        } else {
          return "transparent";
        }
      }
    },
    hasDueDate() {
      return (
        this.flowStep.stepDueDate !== undefined &&
        this.flowStep.stepDueDate !== ""
      );
    },
    dueDateMonth() {
      if (!this.hasDueDate) return "";
      return this.$moment(this.flowStep.stepDueDate).format("MMM");
    },
    dueDateDay() {
      if (!this.hasDueDate) return "";
      return this.$moment(this.flowStep.stepDueDate).format("DD");
    },
  },
  methods: {
    ...mapActions("editor", ["setOpenAssignment", "setFlowStage"]),
    handleChipClick() {
      this.setFlowStage(this.index);
      this.setOpenAssignment(this.assignment.id);
    },
    getName(personObj) {
      return personObj.name ?? personObj;
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-chip {
  height: 45px;
  .step-name {
    line-height: 15px;
  }

  .person-name {
    font-size: 12px;
    line-height: 15px;
  }

  .date-section {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;

    .month {
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
      letter-spacing: 1.5px;
      padding-left: 1.5px;
      text-transform: uppercase;
    }

    .day {
      font-size: 13px;
      line-height: 15px;
      font-weight: 700;
    }
  }
}
</style>
