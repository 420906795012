<template>
  <div>
    <v-container>
      <v-row
        v-for="(fragment, i) in editProjectData.fragmentSchema"
        :key="`fragment-row-${i}`"
      >
        <v-col cols="12" sm="5">
          <v-text-field
            filled
            dense
            hide-details
            label="Fragment Name"
            :value="fragment.fragmentName"
            @change="(value) => handleInput(value, i, 'fragmentName')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2">
          <v-text-field
            filled
            dense
            hide-details
            label="Max"
            :value="fragment.maxCount"
            @change="handleInput($event, i, 'maxCount')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            filled
            dense
            hide-details
            label="Min"
            :value="fragment.minCount"
            @change="handleInput($event, i, 'minCount')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            filled
            dense
            hide-details
            label="Count"
            :items="['words', 'char']"
            :value="fragment.isCountWords"
            @change="handleInput($event, i, 'isCountWords')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" class="remove-button">
          <v-icon @click="() => handleRemoveFragment(i)">
            mdi-minus-circle</v-icon
          >
        </v-col>
      </v-row>
    </v-container>
    <div
      class="add-new-fragment overline grey--text lighten-1"
      @click="addNewFragment"
    >
      <v-icon color="grey lighten-1">mdi-plus</v-icon>
      add new fragment
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { BLANK_NEW_FRAGMENT_DATA } from "@/utils/editorUtils";

export default {
  name: "FragmentsEditor",
  computed: {
    ...mapGetters("editor", ["editProjectData"]),
    fragments() {
      return this.editProjectData.fragmentSchema;
    },
  },
  methods: {
    ...mapActions("editor", ["setEditProjectData"]),
    addNewFragment() {
      // create copy of current editProjectData
      const newEditProjectData = { ...this.editProjectData };

      // create copy of current fragments
      const newFragments = [...newEditProjectData.fragmentSchema];

      // add new fragment
      newFragments.push({
        fragmentIndex: newFragments.length,
        ...BLANK_NEW_FRAGMENT_DATA,
      });

      // update newEditProjectData fragments
      newEditProjectData.fragmentSchema = newFragments;

      // update editProjectData
      this.setEditProjectData(newEditProjectData);
    },
    handleInput(value, index, field) {
      // update editProjectData to include value at index of field for fragment
      const newEditProjectData = _.cloneDeep(this.editProjectData);
      const newFragments = _.cloneDeep(newEditProjectData.fragmentSchema);

      let parsedValue;
      if (field === "minCount" || field === "maxCount") {
        parsedValue = parseInt(value);
      } else if (field === "isCountWords") {
        parsedValue = value === "words" ? true : false;
      } else {
        parsedValue = value;
      }

      newFragments[index][field] = parsedValue;
      newEditProjectData.fragmentSchema = newFragments;
      this.setEditProjectData(newEditProjectData);
    },
    handleRemoveFragment(index) {
      // create copy of current editProjectData
      const newEditProjectData = { ...this.editProjectData };

      // create copy of current fragments
      const newFragments = [...newEditProjectData.fragmentSchema];

      // remove fragment
      newFragments.splice(index, 1);

      // update newEditProjectData fragments
      newEditProjectData.fragmentSchema = newFragments;

      // update editProjectData
      this.setEditProjectData(newEditProjectData);
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-button {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.add-new-fragment {
  cursor: pointer;
}
</style>
