<template>
  <v-card class="text-editor-card grey lighten-3 d-flex flex-column">
    <!-- title -->
    <v-container class="text-editor-card__top-bar">
      <v-card-title
        class="text-editor-card__title d-flex flex-column align-start justify-start pa-0"
      >
        <h2>{{ cardTitle }}</h2>
        <div
          class="stage-complete-tag overline accent white--text px-3 mt-2 rounded"
        >
          {{ stageIsComplete ? "STAGE COMPLETE" : " " }}
        </div>
      </v-card-title>
      <EditorFlowStageSelector
        v-if="assignmentData"
        :flow="assignmentData.flow"
      />
    </v-container>

    <!-- Tabs -->
    <v-container>
      <v-tabs background-color="transparent" v-model="tab" class="flex-grow-0">
        <v-tab href="#editor-panel" background-color="transparent">
          EDITOR
        </v-tab>
        <v-tab href="#details"> DETAILS </v-tab>
        <v-tab href="#styleguide"> STYLE GUIDE </v-tab>
        <v-tab href="#comments">
          <v-badge
            v-if="numComments > 0"
            color="secondary"
            :content="numComments"
            inline
          >
            COMMENTS
          </v-badge>
          <span v-if="numComments === 0">COMMENTS</span>
        </v-tab>
      </v-tabs>
    </v-container>

    <v-tabs-items
      v-model="tab"
      background-color="transparent"
      class="tabs-items flex-grow-1"
    >
      <!-- editor tab -->
      <v-tab-item
        class="tab-item"
        value="editor-panel"
        :transition="false"
        background-color="transparent"
      >
        <div v-if="assignmentData">
          <v-container
            v-for="(fragment, i) in assignmentData.fragments"
            :key="`fragment-${i}`"
          >
            <EditorFragmentEditor
              :fragment="fragment"
              :flow="assignmentData.flow"
            />
          </v-container>
        </div>
      </v-tab-item>

      <!-- assignment details tab -->
      <v-tab-item class="tab-item px-2" value="details" :transition="false">
        <v-container v-if="assignmentData">
          {{ assignmentData.assignmentDetails }}
        </v-container>
      </v-tab-item>

      <!-- styleguide tab -->
      <v-tab-item class="tab-item px-2" value="styleguide" :transition="false">
        <EditorStyleGuide />
      </v-tab-item>

      <!-- comments tab -->
      <v-tab-item class="tab-item px-2" value="comments" :transition="false">
        <EditorCommentsView
          v-if="assignmentData"
          :assignment="assignmentData"
        />
      </v-tab-item>
    </v-tabs-items>

    <!-- footer -->
    <v-container class="text-editor-card__bottom-bar">
      <v-spacer />
      <EditorCompleteStageButton :assignment="assignmentData" />
    </v-container>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
import _ from "lodash";
// internal
import { dub } from "@/providers/dubEditor";

// components
import EditorFlowStageSelector from "@/components/Editor/EditorTextEditor/EditorFlowStageSelector";
import EditorCompleteStageButton from "@/components/Editor/EditorTextEditor/EditorCompleteStageButton";
import EditorFragmentEditor from "@/components/Editor/EditorTextEditor/EditorFragmentEditor.vue";
import EditorStyleGuide from "@/components/Editor/EditorTextEditor/EditorStyleGuide.vue";
import EditorCommentsView from "@/components/Editor/EditorCommentsView/EditorCommentsView.vue";

export default {
  name: "EditorTextEditor",
  components: {
    EditorFlowStageSelector,
    EditorCompleteStageButton,
    EditorFragmentEditor,
    EditorStyleGuide,
    EditorCommentsView,
  },
  props: {},
  data() {
    return {
      assignmentData: null,
      tab: "editor-panel",
    };
  },
  computed: {
    ...mapGetters("editor", [
      "openAssignment",
      "flowStage",
      "assignmentComments",
    ]),
    cardTitle() {
      if (!this.assignmentData) return null;
      return this.assignmentData.assignmentName;
    },
    stageIsComplete() {
      if (!this.assignmentData) return false;
      return this.assignmentData.flow[this.flowStage].isComplete;
    },
    numComments() {
      if (!this.assignmentData) return 0;
      return this.assignmentComments.length;
    },
  },
  watch: {
    openAssignment(newValue) {
      if (!newValue) return;
      this.loadAssignment();
    },
  },
  mounted() {
    this.loadAssignment();
  },
  methods: {
    ...mapActions("editor", ["setAssignmentComments"]),
    loadAssignment() {
      //load the assignment from ID
      dub
        .getAssignment(this.openAssignment)
        .then((assignmentData) => {
          // load the data for this particular assignment
          this.assignmentData = assignmentData;

          return this.assignmentData.getComments();
        })
        .then((comments) => {
          this.setAssignmentComments(comments);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor-card {
  height: 90vh;
  max-height: 90vh;
  // display: flex;
  // flex-flow: column;

  &__top-bar {
    flex: 0 0 auto;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    display: grid;
    grid-template-columns: 2fr 1fr auto;
    min-height: 100px;

    .stage-complete-tag {
      vertical-align: middle;
      transform: translateY(3px);
    }
  }

  .tabs-items {
    background-color: transparent !important;
    overflow-y: auto;
  }

  .tab-item {
    margin-top: 15px;
  }

  &__bottom-bar {
    // position: absolute;
    // bottom: 0;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
}
</style>
