<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            outlined
            label="Client"
            v-model="client.name"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Client Status"
            :items="statuses"
            v-model="client.clientStatus"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Vertical"
            :items="verticals"
            v-model="client.vertical"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field outlined label="Contact" v-model="client.contact" />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Contact Email"
            v-model="client.contactEmail"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Contact Phone"
            v-model="client.contactPhone"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Physical address"
            v-model="client.physicalAddress"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Territory"
            :items="territories"
            v-model="client.territory"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-file-input
            outlined
            label="Contract"
            accept=".pdf"
            truncate-length="50"
            v-model="contractBlob"
            :loading="contractLoading"
            :rules="[rules.isPDF]"
            @change="convertContract($event)"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Quickbooks Customer Name"
            v-model="client.quickbooksCustomerName"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Notes"
            v-model="client.notes"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteClient">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="clientHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="clientHandling"
        :disabled="deleteConfirmation"
        @click="handleClient"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  getEnum,
  getFile,
  processClient,
  deleteClient,
} from "@/utils/newDbUtils";

export default {
  name: "ClientsForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    verticals: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      territoriesLoading: true,
      territories: [],
      client: {
        clientStatus: null,
        contact: null,
        contactEmail: null,
        contactPhone: null,
        contract: null,
        name: null,
        notes: null,
        physicalAddress: null,
        quickbooksCustomerName: null,
        territory: null,
        vertical: null,
      },
      contractLoading: false,
      contractBlob: null,
      valid: true,
      clientHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.getTerritories();
    this.dataViewsHandler();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else this.$refs.form.reset();
    },
    getTerritories() {
      getEnum("Territory").then((territories) => {
        this.territories = territories;
        this.territoriesLoading = false;
      });
    },
    handleEditMode() {
      this.client = { ...this.itemToEdit };
      if (this.client.contract) this.handleContract();
    },
    handleContract() {
      this.contractLoading = true;
      getFile({
        table: "client",
        file: "contract",
        entityId: this.itemToEdit?.id,
      }).then(async (resp) => {
        const base64Response = await fetch(
          `data:application/pdf;base64,${resp}`
        );
        const blob = await base64Response.blob();
        let newBlob = new Blob([blob], {
          type: "application/pdf",
        });
        newBlob.name = `${this.client.name}_Contract.pdf`;
        this.client.contract = ""; // indicates to backend the document don't needs to be updated
        this.contractBlob = newBlob;
        this.contractLoading = false;
      });
    },
    convertContract(file) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.client.contract = reader.result?.split(",")[1];
        };
        reader.readAsDataURL(file);
      } else {
        this.client.contract = null;
      }
    },
    handleClient() {
      if (this.$refs.form.validate()) {
        this.clientHandling = true;
        processClient({
          data: { ...this.client },
          id: this.itemToEdit?.id,
        }).then((resp) => {
          this.clientHandling = false;
          this.$emit("clients-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      }
    },
    deleteClient() {
      this.deleteConfirmation = false;
      this.clientHandling = true;
      deleteClient(this.itemToEdit.id).then((resp) => {
        this.clientHandling = false;
        this.$emit("clients-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
