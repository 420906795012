// imports
const Excel = require("exceljs");
import { saveAs } from "file-saver";
import moment from "moment";

// invoice creation
export const generateInvoice = async ({
  assignments,
  person,
  total,
  filters,
}) => {
  const workbook = new Excel.Workbook(),
    font = {
      name: "Arial",
      size: 10,
    },
    invoiceWorksheet = workbook.addWorksheet("Invoice"),
    headerStyler = (row, fill, size) => {
      invoiceWorksheet.getRow(row).font = { ...font, bold: true, size };
      invoiceWorksheet.getRow(row).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: fill },
      };
      invoiceWorksheet.getRow(row).alignment = { vertical: "middle" };
    },
    cellDyer = (address, color) => {
      invoiceWorksheet.getCell(address).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
    };

  // add assignments data
  invoiceWorksheet.columns = [
    {
      header: "Due Date",
      key: "due_date",
      width: 10,
      style: { font },
    },
    {
      header: "Project Name ",
      key: "project",
      width: 30,
      style: { font },
    },
    {
      header: "Assignment Title",
      key: "title",
      width: 50,
      style: { font },
    },
    {
      header: "Assignment Detail",
      key: "detail",
      width: 60,
      style: { font },
    },
    { header: "Qty", key: "qty", width: 8, style: { font } },
    {
      header: "Total Price",
      key: "price",
      width: 12,
      style: { font },
    },
  ];
  headerStyler(1, "cfe2f3", 11);

  assignments
    .sort((a, b) => (a.groupDate > b.groupDate ? 1 : -1))
    .forEach((assgn) => {
      const assgnRow = invoiceWorksheet.addRow({
        due_date: moment(assgn.dueGroup ?? assgn.schedule.deliveryDate).format(
          "MM-DD-YYYY"
        ),
        project: assgn.project?.name,
        title: assgn.assignmentDetails?.assignmentTitle,
        detail: assgn.assignmentDetails?.assignmentDetail,
        qty: assgn.pricing?.workTypes?.reduce((a, b) => a + b.quantity, 0),
        price: `$${
          assgn.primary?.email === person.user.email
            ? assgn.pricing.primaryTotal.toFixed(2)
            : assgn.secondary?.email === person.user.email
            ? assgn.pricing.secondaryTotal.toFixed(2)
            : "00.00"
        }`,
      });
      if (assgnRow._number % 2)
        invoiceWorksheet.getRow(assgnRow._number).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "efefef" },
        };
    });

  // add general data
  const rows = [
    {},
    {
      due_date: `${person.user.firstName} ${person.user.lastName}`,
      title: [
        ...new Set(
          assignments.map((el) =>
            moment(el.schedule.payPeriod).format("MMMM YYYY")
          )
        ),
      ].join(" / "),
    },
    {
      due_date: "Payable to",
      title: "Pay Period(s)",
      customStyle: { background: "9fc5e8", fontSize: 11 },
    },
    {},
    { due_date: "USA" },
    { due_date: "Croton on Hudson, NY 10520" },
    {
      due_date: "144 Old Post Road",
      title: moment(new Date()).format("MM-DD-YYYY"),
    },
    {
      due_date: "Company Cue, Inc.",
      title: "Report Date",
      customStyle: { background: "6fa8dc", fontSize: 11 },
    },
  ];
  rows.forEach((row) => {
    invoiceWorksheet.insertRow(1, row);
    if (row.hasOwnProperty("customStyle"))
      headerStyler(1, row.customStyle.background, row.customStyle.fontSize);
  });
  // highlight Cue
  invoiceWorksheet.getCell("A1").font = {
    ...font,
    size: 14,
    bold: true,
  };
  // add total
  const totalCell = invoiceWorksheet.addRow({
    price: `$${total}`,
  });
  cellDyer(totalCell._cells[5]._address, "c3f3ee");

  // add notes with filters
  const filtersPairs = Object.entries(filters).filter(
    (el) => el[0] !== "ppm" && el[0] !== "ppy"
  );
  if (filtersPairs.length) {
    let rowsValues = [{}, { due_date: "NOTES:" }];
    filtersPairs.forEach((pair) => {
      if (pair[0].includes("Date")) {
        rowsValues.push({
          due_date: `Filtered by ${pair[0].slice(
            0,
            pair[0].indexOf("Date")
          )} ${pair[0].slice(pair[0].indexOf("Date"))} - ${moment(
            pair[1]
          ).format("MM-DD-YYYY")}`,
        });
      } else if (pair[0].includes("Project")) {
        rowsValues.push({
          due_date: `Filtered by Project - ${pair[1]}`,
        });
      } else if (pair[0].includes("Shakespeare")) {
        if (pair[1] !== undefined)
          rowsValues.push({ due_date: `Filtered by Platform - ${pair[0]}` });
      } else {
        if (pair[1])
          rowsValues.push({
            due_date: `Filtered by ${pair[0]} - ${pair[1]}`,
          });
      }
    });
    if (rowsValues.length > 2) {
      const notesRows = invoiceWorksheet.addRows(rowsValues);
      // highlight Notes
      invoiceWorksheet.getCell(`A${notesRows[1]._number}`).font = {
        ...font,
        bold: true,
      };
    }
  }

  // save and download file
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Co.Cue_Invoice_Report_${moment(new Date()).format("MM.DD.YYYY")}.xlsx`
      )
    )
    .catch((err) => console.error("Error writing Excel export", err));
};
