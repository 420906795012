<template>
  <div class="po-vizualization-chart-container d-flex flex-column">
    <v-container>
      <v-row class="d-flex flex-row justify-center">
        <v-col
          cols="12"
          sm="4"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="overline">Amount in PO</div>
          <div class="font-weight-black" :style="{ fontSize: '1.4em' }">
            {{ amountInPoString }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="overline">Total Amount Billed</div>
          <div
            class="font-weight-black"
            :style="{ fontSize: '1.4em', color: chartColors.pink }"
          >
            {{ amountUsedString }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="overline">Total Amount Remaining</div>
          <div
            class="font-weight-black"
            :style="{ fontSize: '1.4em', color: '#666666' }"
          >
            {{ amountRemainingString }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <svg id="svg-po-vizualization" ref="svg">
      <g
        v-if="domLoaded"
        :style="{
          transform: `translate(${margin.left}px,${margin.top}px)`,
        }"
      >
        <g>
          <!-- background fill -->
          <rect
            x="0"
            :y="height / 2 - barHeight / 2"
            :width="xScale(amountInPO)"
            :height="barHeight"
            fill="#666666"
          />
          <!-- data fill -->
          <rect
            x="0"
            :y="height / 2 - barHeight / 2"
            :width="xScale(billedAmount)"
            :height="barHeight"
            :fill="chartColors.pink"
          />
          <!-- overflow fill -->
          <rect
            v-if="billedIsGreaterThanAmount"
            :x="xScale(amountInPO)"
            :y="height / 2 - barHeight / 2"
            :width="xScale(billedAmount) - xScale(amountInPO)"
            :height="barHeight"
            :fill="texture.url()"
          />
        </g>
        <g>
          <g
            :style="{
              transform: `translate(${chartLabelXOffset}px,${-5}px)`,
            }"
          >
            <foreignObject
              v-if="amountInPO"
              :height="barHeight"
              width="170px"
              class="chart-label-fo"
            >
              <div
                class="chart-label-div d-flex flex-column justify-end aling-end"
              >
                <span
                  :class="{
                    shadow: billedIsGreaterThanAmount,
                    'ml-2': billedPercentString === '0.0',
                  }"
                >
                  <span
                    class="billed-label text-uppercase font-weight-medium mr-1"
                    >billed</span
                  >
                  <span class="billed-amount font-weight-bold">{{
                    `${billedPercentString}%`
                  }}</span>
                </span>
              </div>
            </foreignObject>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
// libraries
import * as d3 from "d3";
import textures from "textures";
const numeral = require("numeral");
// internal
import colors from "@/styles/colors.scss";

export default {
  name: "POVisualization",
  props: {
    data: Object,
  },
  data() {
    return {
      colors,
      margin: { top: 0, right: 20, bottom: 50, left: 20 },
      domLoaded: false,
      width: 0,
      height: 0,
      barHeight: 50,
      texture: null,
      chartColors: {
        darkerGray: "#BDBDBD",
        gray: "#E0E0E0",
        pink: colors.cuePink,
      },
    };
  },
  methods: {
    onResize() {
      this.width =
        +d3.select("#svg-po-vizualization").style("width").replace("px", "") -
        this.margin.left -
        this.margin.right;
      this.height =
        +d3.select("#svg-po-vizualization").style("height").replace("px", "") -
        this.margin.top -
        this.margin.bottom;
    },
    formatDollarAmount(value) {
      return numeral(value).format("$0,0.00");
    },
  },
  computed: {
    amountInPO() {
      return +this.data.totalAmount;
    },
    billedAmount() {
      return +this.data.totalBilledToPurchaseOrder;
    },
    amountInPoString() {
      return this.formatDollarAmount(+this.data.totalAmount);
    },
    amountUsedString() {
      return this.formatDollarAmount(+this.data.totalBilledToPurchaseOrder);
    },
    amountRemainingString() {
      return this.formatDollarAmount(this.data.activePurchaseOrderRemaining !== null ? +this.data.activePurchaseOrderRemaining : this.data.totalAmount);
    },
    billedIsGreaterThanAmount() {
      return this.billedAmount > this.amountInPO;
    },
    billedPercent() {
      return +this.data.activePurchaseOrderUsedPercentage;
    },
    billedPercentString() {
      return numeral(this.billedPercent).format("0.00");
    },
    xScale() {
      const maxValue = Math.max(this.amountInPO, this.billedAmount);
      return d3.scaleLinear().domain([0, maxValue]).range([0, this.width]);
    },
    chartLabelXOffset() {
      if (this.billedPercent < 25) return 20;
      return (
        this.xScale(this.billedAmount) - (this.billedPercent > 0.2 ? 135 : 0)
      );
    },
  },
  mounted() {
    this.domLoaded = true;
    const svg = d3.select(this.$refs.svg);
    this.texture = textures.lines().thicker(1.2).stroke("white");

    svg.call(this.texture);
    this.onResize();

    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.po-vizualization-chart-container {
  width: 100%;
  height: 200px;

  #svg-po-vizualization {
    width: 100%;
    flex: 1 1 auto;

    .chart-label-fo {
      .chart-label-div {
        height: 100%;
        color: #fff;

        .billed-label {
          font-size: 12px;
        }

        .billed-amount {
          font-size: 1.4em;
        }
      }
    }
  }
}
.shadow {
  text-shadow: 1px 1px 5px #000;
}
</style>
