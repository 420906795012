<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Device Category"
            :items="categories"
            v-model="hardware.deviceCategory"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Device Brand"
            :items="brands"
            :loading="dropdownsLoading"
            v-model="hardware.deviceBrand"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Device Model"
            v-model="hardware.model"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Device Serial Number"
            v-model="hardware.serialNumber"
            @change="handleEmptyString($event, 'serialNumber')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="User"
            :items="employees"
            v-model="staff"
            :item-text="fullName"
            return-object
            :disabled="isEditMode && !handToNewUser"
            :rules="hardware.onHand ? [rules.required] : []"
            @change="handleUser($event)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-file-input
            outlined
            label="Hardware User Agreement"
            accept=".pdf"
            truncate-length="50"
            v-model="hardwareAgreementBlob"
            :loading="hardwareAgreementLoading"
            :rules="
              hardware.onHand || (itemToEdit?.onHand && !hardware.onHand)
                ? [rules.required, rules.isPDF]
                : [rules.isPDF]
            "
            @change="convertFile($event, 'hardwareAgreement')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :sm="isEditMode ? 2 : 4"
          class="pb-0 d-flex align-center justify-center"
        >
          <v-checkbox
            class="mt-0 pt-0"
            label="On Hand"
            prepend-icon="mdi-account-arrow-down"
            v-model="hardware.onHand"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" :sm="isEditMode ? 2 : 4" class="pb-0">
          <v-menu
            v-model="onHandStartDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedOnHandStartDate"
                label="On Hand Start Date"
                prepend-icon="mdi-account-arrow-left"
                clearable
                :rules="
                  hardware.onHand || (itemToEdit?.onHand && !hardware.onHand)
                    ? [rules.required]
                    : []
                "
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="onHandStartDateMenu = false"
              v-model="onHandStartDateNotTimestamp"
              @change="handleDate($event, 'onHandStartDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" :sm="isEditMode ? 2 : 4" class="pb-0">
          <v-menu
            v-model="onHandEndDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedOnHandEndDate"
                label="On Hand End Date"
                prepend-icon="mdi-account-arrow-right"
                clearable
                :rules="
                  (itemToEdit?.onHand && !hardware.onHand) || handToNewUser
                    ? [rules.required]
                    : []
                "
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="onHandEndDateMenu = false"
              v-model="onHandEndDateNotTimestamp"
              @change="handleDate($event, 'onHandEndDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="isEditMode"
          cols="12"
          sm="3"
          class="pb-0 d-flex align-center justify-center"
        >
          <v-checkbox
            class="mt-0 pt-0"
            label="Hand to a New User"
            prepend-icon="mdi-account-arrow-down-outline"
            :disabled="!hardware.onHand"
            v-model="handToNewUser"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="newOnHandStartDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedNewOnHandStartDate"
                label="New On Hand Start Date"
                prepend-icon="mdi-account-arrow-left-outline"
                clearable
                :disabled="!handToNewUser"
                :rules="handToNewUser ? [rules.required] : []"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="newOnHandStartDateMenu = false"
              v-model="newOnHandStartDateNotTimestamp"
              @change="handleDate($event, 'newStartDate')"
            ></v-date-picker> </v-menu
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="purchaseDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedPurchaseDate"
                :rules="[rules.required]"
                label="Purchase Date"
                prepend-icon="mdi-cash-fast"
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="purchaseDateMenu = false"
              v-model="purchaseDateNotTimestamp"
              @change="handleDate($event, 'purchaseDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Purchase Price"
            prepend-inner-icon="mdi-currency-usd"
            v-model="hardware.purchasePrice"
            @change="handleEmptyString($event, 'purchasePrice')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-file-input
            outlined
            label="Purchase Receipt"
            accept=".pdf"
            truncate-length="20"
            v-model="purchaseReceiptBlob"
            :loading="purchaseReceiptLoading"
            :rules="[rules.isPDF]"
            @change="convertFile($event, 'purchaseReceipt')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-autocomplete
            outlined
            label="Purchased By"
            :items="purchasers"
            :loading="dropdownsLoading"
            v-model="hardware.purchasedBy"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-menu
            v-model="reimbursementDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedReimbursementDate"
                label="Reimbursement Date"
                hint="Must correspond to Reimbursement Record Delivery Date"
                persistent-hint
                prepend-icon="mdi-cash-refund"
                clearable
                :disabled="hardware.purchasedBy !== 'User'"
                :rules="hardware.reimbursementRecord ? [rules.required] : []"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="reimbursementDateMenu = false"
              v-model="reimbursementDateNotTimestamp"
              @change="handleDate($event, 'reimbursementDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Reimbursement Amount"
            prepend-inner-icon="mdi-currency-usd"
            :disabled="hardware.purchasedBy !== 'User'"
            v-model="hardware.reimbursementAmount"
            @change="handleEmptyString($event, 'reimbursementAmount')"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Reimbursement Record ID"
            prepend-inner-icon="mdi-link-variant"
            :disabled="hardware.purchasedBy !== 'User'"
            v-model="hardware.reimbursementId"
            @change="handleEmptyString($event, 'reimbursementId')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-autocomplete
            outlined
            label="Projects"
            :items="allProjects"
            item-text="name"
            return-object
            multiple
            :loading="dropdownsLoading"
            v-model="relatedProjects"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Notes"
            v-model="hardware.notes"
            @change="handleEmptyString($event, 'notes')"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteHardware">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="hardwareHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="hardwareHandling"
        :disabled="deleteConfirmation"
        @click="handleHardware"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  getEnum,
  getFile,
  toTitleCase,
  getAllProjects,
  processProjectTool,
  deleteProjectTool,
} from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "CueHardwareForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dropdownsLoading: true,
      brands: [],
      hardwareAgreementLoading: false,
      hardwareAgreementBlob: null,
      onHandStartDateMenu: false,
      onHandEndDateMenu: false,
      purchaseDateMenu: false,
      purchaseReceiptLoading: false,
      purchaseReceiptBlob: null,
      purchasers: [],
      reimbursementDateMenu: false,
      allProjects: [],
      relatedProjects: [],
      staff: {
        firstName: null,
        lastName: null,
      },
      hardware: {
        deviceCategory: null,
        deviceBrand: null,
        model: null,
        serialNumber: null,
        hardwareAgreement: null,
        onHand: false,
        onHandStartDate: null,
        onHandEndDate: null,
        purchaseDate: null,
        purchasePrice: null,
        purchaseReceipt: null,
        purchasedBy: null,
        reimbursementDate: null,
        reimbursementAmount: null,
        reimbursementId: null,
        projects: [],
        notes: null,
        firstName: null,
        lastName: null,
        newStartDate: null,
      },
      handToNewUser: false,
      newOnHandStartDateMenu: false,
      newOnHandStartDateNotTimestamp: null,
      onHandStartDateNotTimestamp: null,
      onHandEndDateNotTimestamp: null,
      purchaseDateNotTimestamp: null,
      reimbursementDateNotTimestamp: null,
      valid: true,
      hardwareHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    formattedOnHandStartDate: {
      get() {
        return this.formatPickerDate(this.onHandStartDateNotTimestamp);
      },
      set() {
        this.onHandStartDateNotTimestamp = null;
      },
    },
    formattedOnHandEndDate: {
      get() {
        return this.formatPickerDate(this.onHandEndDateNotTimestamp);
      },
      set() {
        this.onHandEndDateNotTimestamp = null;
      },
    },
    formattedPurchaseDate: {
      get() {
        return this.formatPickerDate(this.purchaseDateNotTimestamp);
      },
      set() {
        this.purchaseDateNotTimestamp = null;
      },
    },
    formattedReimbursementDate: {
      get() {
        return this.formatPickerDate(this.reimbursementDateNotTimestamp);
      },
      set() {
        this.reimbursementDateNotTimestamp = null;
      },
    },
    formattedNewOnHandStartDate: {
      get() {
        return this.formatPickerDate(this.newOnHandStartDateNotTimestamp);
      },
      set() {
        this.newOnHandStartDateNotTimestamp = null;
      },
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
    purchasedBy(newVal) {
      if (!newVal !== "User")
        this.reimbursementDate =
          this.reimbursementAmount =
          this.reimbursementId =
            null;
    },
    relatedProjects(newVal) {
      this.hardware.projects = newVal.map((el) => el.id);
    },
  },
  mounted() {
    this.handleDropdowns();
    this.dataViewsHandler();
  },
  mixins: [timeUnits],
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    handleUser(user) {
      this.hardware.firstName = user.firstName;
      this.hardware.lastName = user.lastName;
    },
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else this.$refs.form.reset();
    },
    handleDropdowns() {
      Promise.all([
        this.getBrands(),
        this.getPurchasers(),
        this.getProjects(),
      ]).then(() => (this.dropdownsLoading = false));
    },
    getBrands() {
      getEnum("DeviceBrand").then((brands) => (this.brands = brands));
    },
    getPurchasers() {
      getEnum("PurchasedBy").then(
        (purchasers) => (this.purchasers = purchasers)
      );
    },
    getProjects() {
      getAllProjects().then((projects) => (this.allProjects = projects));
    },
    handleEditMode() {
      this.hardware = { ...this.itemToEdit };
      this.staff = {
        firstName: this.itemToEdit.firstName,
        lastName: this.itemToEdit.lastName,
      };
      this.relatedProjects = this.itemToEdit.projects
        ? [...this.itemToEdit.projects]
        : [];

      // handle timestamp for standardized formatting
      Object.keys(this.hardware).forEach((key) => {
        if (key.includes("Date"))
          this[`${key}NotTimestamp`] = this.itemToEdit[key]
            ? this.$moment.unix(this.itemToEdit[key]).format("YYYY-MM-DD")
            : "";
      });

      this.handleFile("hardwareAgreement", this.hardware.hardwareAgreement);
      this.handleFile("purchaseReceipt", this.hardware.purchaseReceipt);
    },
    handleFile(file, url) {
      if (url) {
        this[`${file}Loading`] = true;
        getFile({ url }).then(async (resp) => {
          const base64Response = await fetch(
            `data:application/pdf;base64,${resp}`
          );
          const blob = await base64Response.blob();
          let newBlob = new Blob([blob], {
            type: "application/pdf",
          });
          newBlob.name = `${
            file === "hardwareAgreement"
              ? `${this.hardware.firstName} ${this.hardware.lastName}_`
              : ""
          }${this.hardware.deviceBrand}_${this.hardware.model}_${
            this.hardware.deviceCategory
          }_${toTitleCase(file)}.pdf`;
          this.hardware[file] = ""; // indicates to backend the document don't needs to be updated
          this[`${file}Blob`] = newBlob;
          this[`${file}Loading`] = false;
        });
      } else {
        this[`${file}Blob`] = null;
      }
    },
    convertFile(file, field) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.hardware[field] = reader.result?.split(",")[1];
        };
        reader.readAsDataURL(file);
      } else {
        this.hardware[field] = null;
      }
    },
    handleHardware() {
      if (this.$refs.form.validate()) {
        this.hardwareHandling = true;
        processProjectTool({
          tool: "hardware",
          data: { ...this.hardware },
          id: this.itemToEdit?.id,
        }).then((resp) => {
          this.hardwareHandling = false;
          this.$emit("hardware-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      }
    },
    deleteHardware() {
      this.deleteConfirmation = false;
      this.hardwareHandling = true;
      deleteProjectTool({
        tool: "hardware",
        id: this.itemToEdit?.id,
      }).then((resp) => {
        this.hardwareHandling = false;
        this.$emit("hardware-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
    // data formatting for backend
    handleEmptyString(value, key) {
      if (value === "") this.hardware[key] = null;
    },
    handleDate(value, key) {
      this.hardware[key] = value ? this.$moment(value).format("X") : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
