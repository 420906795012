import { render, staticRenderFns } from "./CustomerPriceList.vue?vue&type=template&id=093f50ab&scoped=true&"
import script from "./CustomerPriceList.vue?vue&type=script&lang=js&"
export * from "./CustomerPriceList.vue?vue&type=script&lang=js&"
import style0 from "./CustomerPriceList.vue?vue&type=style&index=0&id=093f50ab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093f50ab",
  null
  
)

export default component.exports