<template>
  <div class="source-dropzone d-flex align-center pb-7">
    <v-btn
      class="mr-2"
      icon
      @click="removeFiles"
      :disabled="!fileInputFiles.length"
    >
      <v-icon v-if="fileInputFiles.length">mdi-close</v-icon>
      <v-icon v-else>mdi-paperclip</v-icon>
    </v-btn>
    <vue-dropzone
      ref="vueDropzone"
      id="dropzone"
      class="flex-grow-1"
      :options="dropzoneOptions"
      :duplicateCheck="true"
      @vdropzone-file-added="handleFiles"
    ></vue-dropzone>
  </div>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// components
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "SourceDropzone",
  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      fileInputFiles: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        dictDefaultMessage: "Drag or Import Assignment Files",
      },
    };
  },
  watch: {
    fileInputFiles(newVal) {
      this.setFiles(newVal);
    },
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["setFiles"]),
    handleFiles(file) {
      this.fileInputFiles.push(file);
    },
    removeFiles() {
      this.$refs.vueDropzone.removeAllFiles();
      this.$refs.vueDropzone.removeAllFiles(true);
      this.fileInputFiles = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#dropzone {
  min-height: 56px;
  padding: 18px 12px 0;
  background: rgba(0, 0, 0, 0.06);
  border: none;
  border-style: solid;
  border-width: 0 0 thin 0;
  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }
}
::v-deep {
  .dz-default.dz-message {
    text-align: left;
    margin: 0;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
  }
  .dz-preview {
    margin: 0 4px 4px 4px;
    width: auto;
    min-height: 32px;
    & .dz-details {
      position: unset;
      max-width: unset;
      border-radius: 4px;
      background-color: #3fa198;
      padding: 5px 12px;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      & .dz-filename {
        white-space: unset;
        & span {
          padding: 0;
        }
      }
    }
  }
  .dz-started .dz-message {
    display: block;
    transform: translate(-133px, -6px) scale(0.75);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .dz-preview .dz-image,
  .dz-preview .dz-progress,
  .dz-preview .dz-error-message,
  .dz-preview .dz-success-mark,
  .dz-preview .dz-error-mark,
  .dz-preview .dz-details .dz-size {
    display: none;
  }
}
</style>
