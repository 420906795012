<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="id"
    sort-by="serviceName"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- subscription amount cell override -->
    <template v-slot:[`item.subscriptionAmount`]="{ value }">
      <span>{{ value ? `$${value.toFixed(2)}` : "" }}</span>
    </template>

    <!-- subscription end date cell override -->
    <template v-slot:[`item.subscriptionEndDate`]="{ value }">
      <span>{{ formatDate(value) }}</span>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <CueSoftwareTableExpansion :softwareInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import CueSoftwareTableExpansion from "@/components/CueSoftware/CueSoftwareTableExpansion";

export default {
  name: "CueSoftwareTable",
  components: {
    CueSoftwareTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterService: {
      type: String,
      required: false,
    },
    filterType: {
      type: String,
      required: true,
    },
    filterPlan: {
      type: String,
      required: true,
    },
    filterSubscription: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Service Name", value: "serviceName" },
        { text: "Service Type", value: "serviceType" },
        { text: "Plan Name", value: "planName" },
        { text: "Plan Type", value: "planType" },
        { text: "User Amount (Max)", value: "maxUserAmount" },
        { text: "User Amount (Current)", value: "currentUserAmount" },
        { text: "Subscription Type", value: "subscriptionType" },
        { text: "Subscription Amount (Monthly)", value: "subscriptionAmount" },
        { text: "Subscription End Date", value: "subscriptionEndDate" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      return this.tableData.length ? Object.keys(this.tableData[0]) : [];
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterService) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.serviceName === this.filterService
        );
      }

      if (this.filterType) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.serviceType === this.filterType
        );
      }

      if (this.filterPlan) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.planType === this.filterPlan
        );
      }

      if (this.filterSubscription) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.subscriptionType === this.filterSubscription
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment.unix(date).format("MM-DD-YYYY") : "";
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
