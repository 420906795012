<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="user[id]"
    sort-by="user[firstName]"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- Name cell override -->
    <template v-slot:[`item.user`]="{ value }">
      <span>{{ `${value.firstName ?? ""} ${value.lastName ?? ""}` }}</span>
    </template>

    <!-- Projects cell override -->
    <template v-slot:[`item.projects`]="{ value }">
      <span>{{ value.map((project) => project.name).join(", ") }}</span>
    </template>

    <!-- Email cell override -->
    <template v-slot:[`item.user[email]`]="{ value }">
      <v-btn
        fab
        x-small
        outlined
        elevation="1"
        color="accent"
        :disabled="!value"
        @click.stop="sendEmail(value)"
        ><v-icon>mdi-email-plus-outline</v-icon></v-btn
      >
    </template>

    <!-- Status cell override -->
    <template v-slot:[`item.active`]="{ value }">
      <v-icon v-if="value">mdi-check</v-icon>
    </template>

    <!-- Shakespeare AM cell override -->
    <template v-slot:[`item.shakespeareAM`]="{ value }">
      <v-icon v-if="value">mdi-check</v-icon>
    </template>

    <!-- Shakespeare FC cell override -->
    <template v-slot:[`item.shakespeareFC`]="{ value }">
      <v-icon v-if="value">mdi-check</v-icon>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ClientRequestersTableExpansion :clientRequesterInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import ClientRequestersTableExpansion from "@/components/ClientRequesters/ClientRequestersTableExpansion";

export default {
  name: "ClientRequestersTable",
  components: {
    ClientRequestersTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterStatus: {
      type: Array,
      required: false,
    },
    filterProject: {
      type: String,
      required: true,
    },
    filterShakespeare: {
      type: Number,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "user" },
        { text: "Projects", value: "projects" },
        { text: "Email", value: "user[email]" },
        { text: "Active", value: "active" },
        { text: "AM", value: "shakespeareAM" },
        { text: "FC", value: "shakespeareFC" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      if (this.tableData.length) {
        const fields = Object.entries(this.tableData[0]).reduce(
          (fields, entry) => {
            const nestedKey = entry[1]
              ? Object.keys(entry[1]).map(
                  (key) => `${entry[0]}${isNaN(key) ? `.${key}` : ""}`
                )
              : [];
            return [...fields, ...nestedKey];
          },
          []
        );
        return fields;
      } else {
        return [];
      }
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterStatus.length === 1) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.filterStatus.includes(0) ? el.active : !el.active
        );
      } else if (!this.filterStatus.length) {
        dateFilteredData = [];
      }

      if (this.filterProject) {
        dateFilteredData = dateFilteredData.filter((el) =>
          el.projects
            .map((project) => project?.name)
            .includes(this.filterProject)
        );
      }

      if (this.filterShakespeare !== undefined) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.filterShakespeare === 0 ? el.shakespeareAM : el.shakespeareFC
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    sendEmail(email) {
      window.open(`mailto:${email}`);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
