<template>
  <div class="flow-stepper">
    <v-simple-table id="stepper-table">
      <thead>
        <tr>
          <th>Flow Step</th>
          <th>Person</th>
          <th>Due Date</th>
          <th>Due Time</th>
          <th></th>
        </tr>
      </thead>
      <tbody ref="table-body">
        <tr
          v-for="(item, i) in thisAssignmentSteps"
          :key="i"
          class="flow-table-row"
        >
          <td class="px-2">
            <v-text-field
              class="step-field"
              dense
              filled
              solo-inverted
              hide-details
              :value="item.label"
              @change="handleStepChange($event, i)"
            ></v-text-field>
          </td>
          <td class="px-2">
            <v-autocomplete
              class="name-field"
              dense
              solo-inverted
              hide-details
              :value="item.person"
              :items="selectedProject.staffPool"
              :disabled="staffLoading"
              :loading="staffLoading"
              @input="handlePersonChange($event, i)"
            ></v-autocomplete>
          </td>
          <td class="px-2">
            <v-menu
              :close-on-content-click="false"
              :nudge-left="45"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="date-field"
                  :value="formatStepDueDate(item.stepDueDate)"
                  solo-inverted
                  dense
                  readonly
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :value="item.stepDueDate"
                @input="handleStepDueDateChange($event, i)"
              ></v-date-picker>
            </v-menu>
          </td>
          <td class="px-2">
            <v-select
              class="time-field"
              :items="hoursList"
              item-text="time"
              :value="item.stepDueTime"
              solo-inverted
              dense
              hide-details
              clearable
              @input="handleStepDueTimeChange($event, i)"
            ></v-select>
          </td>
          <td class="px-2">
            <v-icon @click="handleRemoveStep(i)">mdi-minus</v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="add-step d-flex align-center pl-4">
      <v-btn
        x-small
        outlined
        color="accent"
        @click="handleNewStep(thisAssignmentSteps.length)"
      >
        <v-icon class="btn-icon" small> mdi-plus </v-icon>
        add step</v-btn
      >
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
// internal
import { timeUnits } from "@/utils/mixins";

export default {
  name: "FlowStepper",
  props: {
    datasIndex: {
      type: Number,
      require: false,
    },
    editMode: {
      type: Boolean,
      require: false,
    },
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("editor", [
      "newAssignmentDatas",
      "editAssignmentData",
      "selectedProject",
      "staffLoading",
    ]),
    thisAssignmentSteps() {
      if (this.editMode) {
        return this.editAssignmentData.flow;
      } else {
        return this.newAssignmentDatas[this.datasIndex].flow;
      }
    },
  },
  methods: {
    ...mapActions("editor", ["setNewAssignmentDatas", "setEditAssignmentData"]),
    handleStepChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow[index].label = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow[index].label = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handlePersonChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        const newAssignees = _.cloneDeep(newPayload.assignees);
        newPayload.flow[index].person = value;
        newAssignees[index] = value?.email;
        newPayload.assignees = newAssignees;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        const newAssignees = _.cloneDeep(indexCopy.assignees);
        indexCopy.flow[index].person = value;
        newAssignees[index] = value?.email;
        indexCopy.assignees = newAssignees;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleStepDueDateChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow[index].stepDueDate = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow[index].stepDueDate = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleStepDueTimeChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow[index].stepDueTime = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow[index].stepDueTime = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleNewStep(i) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow.push({
          label: "",
          person: "",
          stepDueDate: "",
          stepDueTime: "",
          index: i,
          isComplete: false,
        });
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow.push({
          label: "",
          person: "",
          stepDueDate: "",
          stepDueTime: "",
          index: i,
          isComplete: false,
        });
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleRemoveStep(i) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow.splice(i, 1);
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow.splice(i, 1);
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    formatStepDueDate(date) {
      return this.formatPickerDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-stepper {
  #stepper-table {
    background-color: transparent;
    .flow-table-row {
      .step-field {
        width: 100px;
      }
      .date-field {
        width: 140px;
      }
      .time-field {
        width: 160px;
      }
    }
  }
  .add-step {
    font-size: 14px;
    padding-top: 10px;
    .btn-icon {
      transform: translateY(0.09em);
    }
  }
}
</style>
