<template>
    <div class="container">
        <v-card class="card d-flex flex-column grey lighten-2">
            <div v-if="!loading">
                <RequestAnAssignmentForm
                    ref="requestAnAssignmentForm"
                    v-for="(nativeForm, i) in gGetDefaultNativeForms"
                    :key="i"
                    :nativeForm="nativeForm"
                    :index="i"
                />
            </div>
            <v-skeleton-loader
                v-if="loading"
                type="list-item-three-line@5"
            ></v-skeleton-loader>
            <v-card-actions class="d-flex justify-end pr-4 pb-4">
            <v-btn
                depressed
                class="mx-3 grey lighten-2"
                :loading="loading"
                @click="cancelHandle"
            >
                Cancel
            </v-btn>
            <v-btn
                outlined
                color="blue darken-2"
                :loading="loading"
                @click="aDuplicateLastForm"
            >
                Duplicate
            </v-btn>
            <v-btn
                color="blue darken-2"
                class="request-btn mx-3"
                :loading="loading"
                @click="handleForm"
                >REQUEST</v-btn
            >
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getProjectsForClientForm, processClientRequestForm } from "@/utils/newDbUtils";
import RequestAnAssignmentForm from "@/components/PaymentRequestForm/RequestAnAssignmentForm";
import { publishMessage } from "@/utils/slackUtils";
export default {
    name: "PaymentRequestForm",
    components: {
        RequestAnAssignmentForm
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters("nativeForms", ["gGetClientFormData", "gGetDefaultNativeForms"]),
        ...mapGetters("auth", ["user"]),
    },
    methods: {
        ...mapActions("flashMessage", ["handleFlash"]),
        ...mapActions("assignment", ["setCommonData"]),
        ...mapActions("nativeForms", ["aSetDefaultAssignmentValues", "aDuplicateLastForm", "aClearNativeFormsList", "aSetClientFormData"]),
        handleData() {
            this.loading = true;
            if (!this.gGetClientFormData) {
                this.$router.push({ name: 'Client Request Form' })
                this.loading = false
                return
            }
            let dropdowns = [
                getProjectsForClientForm(this.gGetClientFormData.id)
            ];
            Promise.all(dropdowns)
            .then((resp) => {
                this.setCommonData({ field: "projectsList", data: resp[0].data });
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        cancelHandle() {
            this.aClearNativeFormsList();
            this.$router.push({ name: 'Client Request Form' })
        },
        confirmFormValid() {
            let validator = [];
            this.$refs.requestAnAssignmentForm.forEach((el) =>
                validator.push(el.validateForm())
            );
            return validator.every((value) => value === true);
        },
        groupedObjects(objects) {
            return objects.reduce((groups, obj) => {
                const compositeKey = `${obj.assignmentDetails.assignmentDetail}_${obj.assignmentDetails.wordCount}_${obj.assignmentDetails.assignmentTitle}_${obj.project.id}_${obj.schedule.deliveryDate}_${obj.assignmentDetails.link}_${obj.assignmentDetails.relatedText}`;
                if (!groups[compositeKey]) {
                groups[compositeKey] = [];
                }
                groups[compositeKey].push(obj);
                return groups;
            }, {});
        },
        async handleForm() {
            if (!this.confirmFormValid()) {
                this.handleFlash({
                    response: { 
                        status: 400,
                        data: {
                            message: "Please fill out all required fields.",
                        }
                    },
                    show: true,
                });
                return;
            }
            this.loading = true;

            try {
                let assignmentList = await Promise.all(this.gGetDefaultNativeForms.map(async (nativeForm) => {
                    let targetLangs = nativeForm.targetLanguages.map((lang) => lang.id);
                    const assignment = {
                        assignmentTitle: nativeForm.assignmentTitle,
                        clientId: +nativeForm.relatedProject.client.id,
                        projectId: +nativeForm.relatedProject.id,
                        clientRequesterId: this.user.user.id,
                        requestTypeId: +nativeForm.typeOfWork.id,
                        deliveryDate: nativeForm.deliveryDate,
                        relatedFiles: [],
                        relatedText: nativeForm.relatedText ? nativeForm.relatedText : "",
                        relatedLink: nativeForm.relatedLink ? nativeForm.relatedLink : "",
                        wordCount: +nativeForm.wordCount ? +nativeForm.wordCount : 0,
                        sourceLanguageId: +nativeForm.sourceLanguages.id,
                        targetLanguageIds: targetLangs,
                        noteToManager: nativeForm.noteToManager ? nativeForm.noteToManager : "",
                        purchaseOrderId: nativeForm.purchaseOrder?.id ? +nativeForm.purchaseOrder.id : null
                    };

                    if (nativeForm.relatedFiles.length) {
                        const fileReadPromises = nativeForm.relatedFiles.map((file, index) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onloadend = () => {
                                    const fileItem = reader.result?.split(",")[1];
                                    if (fileItem) {
                                    const fullFileName = file.name;
                                    const fileExtension = fullFileName.split('.').pop();
                                    const fileName = fullFileName.replace(`.${fileExtension}`, '');

                                    const fileObject = {
                                        id: 0,
                                        documentData: fileItem,
                                        fileName: fileName,
                                        fileExtension: fileExtension,
                                        toDelete: false
                                    };

                                    resolve(fileObject);
                                    } else {
                                    console.error(`File ${index} read failed.`);
                                    reject('Failed to read file.');
                                    }
                                };
                                reader.onerror = error => {
                                    console.error(`File ${index} encountered error:`, error);
                                    reject(error);
                                };
                            });
                        });

                        assignment.relatedFiles = await Promise.all(fileReadPromises);
                    }
                    return assignment;
                }));
                processClientRequestForm(assignmentList)
                    .then(resp => {
                        const assignments = resp?.data || [];
                        const groupedAssignments = this.groupedObjects(assignments);
                        Object.keys(groupedAssignments).forEach(compositeKey => {
                            const group = groupedAssignments[compositeKey];
                            const firstAssignment = group[0];
                            const geosList = group.map(assignment => assignment.assignmentDetails.geo);
                                publishMessage({
                                mode: "request",
                                data: {
                                    project: firstAssignment.project.name,
                                    clientRequester:`${this.user.user.firstName} ${this.user.user.lastName}`,
                                    newClientRequester:`${this.user.user.firstName} ${this.user.user.lastName}`,
                                    requestType: firstAssignment.assignmentDetails.requestType,
                                    assignmentTitle: firstAssignment.assignmentDetails.assignmentTitle,
                                    wordCount: firstAssignment.assignmentDetails.wordCount,
                                    geos: geosList,
                                    deliveryDate: firstAssignment.schedule.deliveryDate,
                                    note: firstAssignment.assignmentDetails.noteToManager,
                                    relatedText: firstAssignment.assignmentDetails.relatedText,
                                    link: firstAssignment.assignmentDetails.link,
                                    assignmentDetail: firstAssignment?.assignmentDetails?.assignmentDetail,
                                    files: firstAssignment?.documents,
                                    channel: firstAssignment.project.slackChannelIdAdmin,
                                    noteToManager: firstAssignment.assignmentDetails.noteToManager,
                                    assignmentDetail: firstAssignment.assignmentDetails.assignmentDetail
                                },
                            });
                        });
                        this.loading = false;
                        this.handleFlash({ response: resp, show: true });
                        this.aClearNativeFormsList();
                        this.$router.push({ name: 'Client Request Form' })
                    });
            } catch (error) {
                console.error('Error preparing assignments:', error);
                this.loading = false;
                this.handleFlash({
                    response: { data: "Something went wrong" },
                    show: true,
                });
            }
        }
    },
    mounted() {
        this.handleData();
        this.aSetDefaultAssignmentValues();
    },
    beforeRouteLeave (to, from, next) {
        this.aClearNativeFormsList();
        this.aSetClientFormData(null);
        next();
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
        min-width: 800px;
        & .request-btn {
            color: #fff !important;
        }
    }
}
</style>