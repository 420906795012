<template>
  <div class="data-upload">
    <v-progress-linear
        v-if="thinking"
        class="thinking-progress"
        indeterminate
      />
    <v-card
      class="data-upload__source-section pa-5 mt-5"
    >
      <h2>Data File</h2>
      <SourceInput :cleanInput="removeFile"/>
      <div
        class="float-right d-flex"
      >
        <v-checkbox
          class="mt-0 mr-3"
          v-model="doubleCheck"
          label="I have double-checked the data."
        ></v-checkbox>
        <v-btn
          :disabled="!doubleCheck || !uploadedSourceFile || !staff.length || !priceList || !clients || thinking"
          class="import-button mr-3"
          color="secondary"
          :loading="thinking"
          @click="handleAddToDB">
            ADD TO DB
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import {
  getAllProjects,
  getAllStaff,
  uploadExcelData,
  getCustomerPriceList
} from "@/utils/newDbUtils";
// libraries
import _ from "lodash";
// components
import SourceInput from "@/components/DataUpload/SourceInput";

export default {
  components: { SourceInput },
  data() {
    return {
      doubleCheck: false,
      projects: [],
      staff: [],
      clients: [],
      priceList: [],
      removeFile: 0,
      thinking: true
    }
  },
  computed: {
    ...mapGetters("dataUpload", [
      "uploadedSourceFile",
      "tableRows"
    ]),
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    dateFormatter(date) {
      return this.$moment(date).format("YYYY-MM-DD")
    },
    timeFormatter(time) {
      return this.$moment(time, "h:mm A").format("hh:mm A")
    },
    findPurchaseOrderId(poNum) {
      let result = null;
      this.projects.forEach((el) => {
        el.purchaseOrders.forEach((item) => {
          if (item.purchaseOrderNumber === poNum) {
            result = item.id
          }
        })
      })
      return result;
    },
    findClientRequesterId(project, user) {
      if (project.clientRequesters.find(item => item.user.firstName + ' ' + item.user.lastName === user)) {
        return project.clientRequesters.find(item => item.user.firstName + ' ' + item.user.lastName === user).user.id
      } else {
        return null;
      }
    },
    findPriceListId(workTypeName) {
      const result = this.priceList.find((el) => el.workTypeName === workTypeName);
      return result.id;
    },
    getProjects() {
      this.thinking = true;
      getAllProjects()
        .then((data) => {
          this.projects = data;
          this.thinking = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getStaff() {
      this.thinking = true;
      getAllStaff()
        .then((data) => {
          this.staff = data.filter(el => el.adminDetails.activeStaff === true);
          this.thinking = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPriceList() {
      this.thinking = true;
      getCustomerPriceList()
        .then((data) => {
          this.priceList = data;
          this.thinking = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleAddToDB() {
      this.thinking = true;
      const fileToSend = [];
      this.tableRows.forEach((row) => {
        const excelRow = {
          projectId: this.projects.find(el => el.name === row.task_name) ? this.projects.find(el => el.name === row.task_name).id : null,
          schedule: {
            deliveryDate: row.deliveryDate ? this.dateFormatter(row.deliveryDate) : null,
            deliveryTime: row.deliveryDue ? this.timeFormatter(row.deliveryDue) : null,
            payPeriod: row.payPeriod ? this.$moment(row.payPeriod).format("YYYY-MM") : null,
            primaryDueDate: row.writingDueDate ? this.dateFormatter(row.writingDueDate) : null,
            primaryDueTime: row.writingDue ? this.timeFormatter(row.writingDue) : null,
            secondaryDueDate: row.editingDueDate ? this.dateFormatter(row.editingDueDate) : null,
            secondaryDueTime: row.editingDue ? this.timeFormatter(row.editingDue) : null,
            qcDueDate: row.reviewingDueDate ? this.dateFormatter(row.reviewingDueDate) : null,
            qcDueTime: row.reviewingDue ? this.timeFormatter(row.reviewingDue) : null,
            requestDate: row.request_date ? this.dateFormatter(row.request_date) : null,
            requestTime: row.requestTime ? this.timeFormatter(row.requestTime) : null
          },
          assignmentDetails: {
            assignmentDetail: row.assignmentDetail ? row.assignmentDetail : null,
            assignmentId: row.assignmentId ? row.assignmentId : null,
            assignmentStatus: row.status ? row.status : null,
            assignmentTitle: row.assignmentTitle ? row.assignmentTitle : null,
            contentId: row.contentId ? row.contentId : null,
            emailSubjectLine: row.emailSubjectLine ? row.emailSubjectLine : null,
            fileLocation: row.fileLocation ? row.fileLocation : null,
            genre: row.genre ? row.genre : null,
            geo: row.geo ? row.geo : null,
            keyword: row.keyword ? row.keyword : null,
            link: row.link ? row.link : null,
            note: row.note ? row.note : null,
            requestType: row.request_type ? row.request_type : null,
            sourceGeo: row.source_geo ? row.source_geo : null,
            taskId: row.task_id ? row.task_id : null,
            wordCount: row.word_count ? row.word_count : null,
            mediaType: row.media_type ? row.media_type : null,
            urgent: false,
          },
          task: {
            taskId: row.task_id ? row.task_id : null,
            id: null,
          },
          clientId: this.projects.find(el => el.name === row.task_name).client ? this.projects.find(el => el.name === row.task_name).id : null,
          clientRequesterId: this.projects.find(el => el.name === row.task_name) ? this.findClientRequesterId(this.projects.find(el => el.name === row.task_name), row.client_requester) : null,
          projectManagerId: this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.projectManager) ? this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.projectManager).user.id : null,
          primaryId: this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.writer) ? this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.writer).user.id : null,
          secondaryId: this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.editor) ? this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.editor).user.id : null,
          qcId: this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.reviewer) ? this.staff.find(el => el.user.firstName + ' ' + el.user.lastName === row.reviewer).user.id : null,
          pricing: {
            purchaseOrderId: row.purchaseOrderId ? this.findPurchaseOrderId(row.purchaseOrderId) : null,
            workTypes: [
              {
                customerPriceListId: row.workType1 ? this.findPriceListId(row.workType1) : null,
                quantity: row.workType1Quantity ? row.workType1Quantity : null,
                orderPosition: 1
              },
              {
                customerPriceListId: row.workType2 ? this.findPriceListId(row.workType2) : null,
                quantity: row.workType2Quantity ? row.workType2Quantity : null,
                orderPosition: 2
              },
              {
                customerPriceListId: row.workType3 ? this.findPriceListId(row.workType3) : null,
                quantity: row.workType3Quantity ? row.workType3Quantity : null,
                orderPosition: 3
              },
              {
                customerPriceListId: row.workType4 ? this.findPriceListId(row.workType4) : null,
                quantity: row.workType4Quantity ? row.workType4Quantity : null,
                orderPosition: 4
              },
              {
                customerPriceListId: row.workType5 ? this.findPriceListId(row.workType5) : null,
                quantity: row.workType5Quantity ? row.workType5Quantity : null,
                orderPosition: 5
              },
              {
                customerPriceListId: row.workType6 ? this.findPriceListId(row.workType6) : null,
                quantity: row.workType6Quantity ? row.workType6Quantity : null,
                orderPosition: 6
              },
            ]
          }
        }
        fileToSend.push(excelRow);
      })
      uploadExcelData(fileToSend).then(result => {
          this.handleFlash({
              response: result,
              show: true,
            });
          this.removeFile++;
          this.doubleCheck = false;
          this.thinking = false;
      })
    },
  },
  async mounted() {
    await this.getProjects();
    await this.getStaff();
    await this.getPriceList();
  },
};
</script>

<style lang="scss" scoped>
.data-upload {
  max-width: 1200px;
  margin: 0 auto;

  &__source-section {
    height: 210px;
    transition: max-height 0.15s ease-out;
    &.active {
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }
  }

  & .import-button {
    width: 120px;
  }

  & .notification {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
