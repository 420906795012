<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ clientRequesterInfo.client?.name }}</div>
        <h2>
          {{
            `${clientRequesterInfo.user.firstName ?? ""} ${
              clientRequesterInfo.user.lastName ?? ""
            }`
          }}
        </h2>
        <v-chip class="my-5">
          {{ clientRequesterInfo.active ? "Active" : "Inactive" }}
        </v-chip>
        <div>
          <!-- Email -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Email</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientRequesterInfo.user.email
            }}</v-col>
          </v-row>

          <!-- Time Zone -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Time Zone</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientRequesterInfo.timeZone
            }}</v-col>
          </v-row>

          <!-- Location -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Location</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientRequesterInfo.location
            }}</v-col>
          </v-row>

          <!-- Projects -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Projects</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientRequesterInfo.projects
                .map((project) => project.name)
                .join(", ")
            }}</v-col>
          </v-row>

          <!-- Notes -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Additional Info</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientRequesterInfo.additionInfo
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12">
            <h3 class="pb-2">Access & Platforms</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">CueHub</th>
                    <th class="d-flex justify-center align-center">
                      <v-img
                        :src="SHK"
                        class="flex-grow-0 mr-4"
                        width="30px"
                        height="26px"
                        alt="Shakespeare"
                      />AM
                    </th>
                    <th class="text-center">FC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon v-if="clientRequesterInfo.user.cueHubAccess"
                        >mdi-check</v-icon
                      >
                    </td>
                    <td>
                      <v-icon v-if="clientRequesterInfo.shakespeareAM"
                        >mdi-check</v-icon
                      >
                    </td>
                    <td>
                      <v-icon v-if="clientRequesterInfo.shakespeareFC"
                        >mdi-check</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="table-bg text-center pa-0 mt-6">
          <v-col cols="12">
            <h3 class="pb-2">Related Data</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Client</th>
                    <th class="text-center">Projects</th>
                    <th class="text-center">Purchase Orders</th>
                    <th class="text-center">Assignments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${getDashboardPath('clients')}?Search=${
                          clientRequesterInfo.client?.name
                        }`"
                        :disabled="!clientRequesterInfo.client?.name"
                        target="_blank"
                      >
                        <v-icon>mdi-folder-account-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${getDashboardPath('projects')}?Client=${
                          clientRequesterInfo.client?.name
                        }&Search=${clientRequesterInfo.user?.firstName} ${
                          clientRequesterInfo.user?.lastName
                        }`"
                        :disabled="!clientRequesterInfo.projects.length"
                        target="_blank"
                      >
                        <v-icon>mdi-folder-multiple-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${getDashboardPath('purchaseOrders')}?Client=${
                          clientRequesterInfo.client?.name
                        }&Search=${clientRequesterInfo.user?.firstName} ${
                          clientRequesterInfo.user?.lastName
                        }`"
                        :disabled="!clientRequesterInfo.purchaseOrders.length"
                        target="_blank"
                      >
                        <v-icon>mdi-octagram-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${getDashboardPath(
                          'assignmentDashboard'
                        )}?Search=${clientRequesterInfo.client?.name ?? ''}`"
                        :disabled="
                          !clientRequesterInfo.client?.assignmentsCount
                        "
                        target="_blank"
                      >
                        <v-icon>mdi-clipboard-list-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// internal
import { DOMAIN, ROUTES } from "@/utils/constants";
import SHK from "@/assets/SHK.svg";

export default {
  name: "ClientRequestersTableExpansion",
  props: {
    clientRequesterInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { SHK };
  },
  methods: {
    getDashboardPath(table) {
      return `${DOMAIN}/${ROUTES[table]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
