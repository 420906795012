<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Purchase Order Number"
            v-model="po.purchaseOrderNumber"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            type="number"
            outlined
            prepend-inner-icon="mdi-currency-usd"
            label="Total Amount"
            v-model="po.totalAmount"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-menu
            v-model="activeDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedActiveDate"
                label="Active Date"
                prepend-icon="mdi-calendar-start"
                outlined
                readonly
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="activeDateMenu = false"
              v-model="po.activeDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="End Date"
                prepend-icon="mdi-calendar-end"
                outlined
                readonly
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="endDateMenu = false"
              v-model="po.endDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
          <v-checkbox
            prepend-icon="mdi-cash-fast"
            label="Pre-Paid"
            v-model="po.prePaid"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-file-input
            outlined
            label="Document Proving"
            accept=".pdf"
            truncate-length="50"
            v-model="documentProvingBlob"
            :loading="documentProvingLoading"
            :rules="[rules.isPDF]"
            @change="convertDoc($event)"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Currency"
            item-text="name"
            return-object
            :loading="currenciesLoading"
            :items="currencies"
            v-model="po.currency"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Client"
            item-text="name"
            return-object
            :items="clientsList"
            v-model="relatedClient"
            :rules="[rules.requiredObject]"
            @change="handleRelatedClient($event, true)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Projects"
            item-text="name"
            return-object
            :disabled="!relatedClient"
            :items="relatedProjects"
            v-model="relatedProject"
            :rules="[rules.requiredObject]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-autocomplete
            outlined
            multiple
            chips
            deletable-chips
            label="Client Requesters"
            return-object
            :disabled="!relatedClient"
            :items="relatedClientRequesters"
            :item-text="fullName"
            :search-input.sync="crSearchInput"
            v-model="clientRequesters"
            :rules="[rules.requiredArray]"
            @change="handleCRs"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Notes"
            v-model="po.notes"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deletePo"> Yes </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="poHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="poHandling"
        :disabled="deleteConfirmation"
        @click="handlePo"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  getEnum,
  getFile,
  processPurchaseOrder,
  deletePurchaseOrder,
} from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "PurchaseOrdersForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    clientsList: {
      type: Array,
      required: true,
    },
    projectsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currenciesLoading: true,
      activeDateMenu: false,
      endDateMenu: false,
      relatedClient: {},
      relatedClientRequesters: [],
      crSearchInput: "",
      clientRequesters: [],
      relatedProjects: [],
      relatedProject: {},
      currencies: [],
      po: {
        activeDate: null,
        clientId: null,
        clientRequesterIds: [],
        currency: null,
        documentProving: null,
        endDate: null,
        notes: null,
        prePaid: false,
        projectId: null,
        purchaseOrderNumber: null,
        totalAmount: null,
      },
      documentProvingLoading: false,
      documentProvingBlob: null,
      valid: true,
      poHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
        requiredArray: (value) => !!value.length || "Required",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
      },
    };
  },
  mixins: [timeUnits],
  computed: {
    isEditMode() {
      return !!this.itemToEdit?.id;
    },
    formattedActiveDate: {
      get() {
        return this.formatPickerDate(this.po.activeDate);
      },
      set() {
        this.po.activeDate = "";
      },
    },
    formattedEndDate: {
      get() {
        return this.formatPickerDate(this.po.endDate);
      },
      set() {
        this.po.endDate = "";
      },
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
    relatedProject(newVal) {
      this.handleId("project", newVal);
    },
    relatedClientRequester(newVal) {
      this.handleId("clientRequester", newVal);
    },
  },
  mounted() {
    this.getCurrenciesList();
    this.dataViewsHandler();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
    handleId(entity, data) {
      this.po[`${entity}Id`] =
        (entity === "clientRequester" ? data?.user?.id : data?.id) ?? null;
    },
    handleRelatedClient(client, resetRelatedData) {
      this.handleId("client", client);
      this.relatedClientRequesters = this.relatedClient.clientRequesters?.sort(
        (a, b) => a.user.firstName.localeCompare(b.user.firstName)
      );
      this.relatedProjects = this.relatedClient.projects?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      this.clientRequesters = resetRelatedData
        ? []
        : this.relatedClientRequesters.filter((el) => {
            const crIds = this.itemToEdit.clientRequesters?.map(
              (item) => item.user.id
            );
            if (crIds.includes(el.user.id)) return el;
          });
      this.relatedProject = resetRelatedData
        ? {}
        : this.relatedProjects.find(
            (el) => el.id === this.itemToEdit.project.id
          );
    },
    getCurrenciesList() {
      getEnum("Currency").then((currencies) => {
        this.currencies = currencies;
        this.currenciesLoading = false;
      });
    },
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else this.$refs.form.reset();
    },
    handleEditMode() {
      this.po = { ...this.itemToEdit };
      this.relatedClient = this.clientsList.find(
        (el) => el.id === this.itemToEdit.client?.id
      );
      this.handleRelatedClient(this.itemToEdit.client, false);
      if (this.po.documentProving) this.handleDoc();
      else this.documentProvingBlob = null;
    },
    handleCRs() {
      this.po.clientRequesterIds = this.clientRequesters.map(
        (el) => el.user.id
      );
      this.crSearchInput = "";
    },
    handleDoc() {
      this.documentProvingLoading = true;
      getFile({
        table: "purchase-order",
        file: "document-proving",
        entityId: this.itemToEdit?.id,
      }).then(async (resp) => {
        const base64Response = await fetch(
          `data:application/pdf;base64,${resp}`
        );
        const blob = await base64Response.blob();
        let newBlob = new Blob([blob], {
          type: "application/pdf",
        });
        newBlob.name = `${this.po.purchaseOrderNumber}_Document Proving.pdf`;
        this.po.documentProving = ""; // indicates to backend the document don't needs to be updated
        this.documentProvingBlob = newBlob;
        this.documentProvingLoading = false;
      });
    },
    convertDoc(file) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.po.documentProving = reader.result?.split(",")[1];
        };
        reader.readAsDataURL(file);
      } else {
        this.po.documentProving = null;
      }
    },
    handlePo() {
      if (this.$refs.form.validate()) {
        this.poHandling = true;
        processPurchaseOrder({
          data: { ...this.po },
          id: this.itemToEdit?.id,
        }).then((resp) => {
          this.poHandling = false;
          this.$emit("po-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      }
    },
    deletePo() {
      this.deleteConfirmation = false;
      this.poHandling = true;
      deletePurchaseOrder(this.itemToEdit?.id).then((resp) => {
        this.poHandling = false;
        this.$emit("po-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
