<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    item-key="id"
    :header-props="{ sortIcon: null }"
    show-expand
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    :options.sync="options"
    :server-items-length="serverItemsLength"
    :class="{ 'table-updating': isTableUpdating, laptop, laptopSmaller }"
    :loading="isTableUpdating"
    @click:row="$emit('call-edit-form', $event)"
    @update:page="handleTableOptions"
    @update:sort-by="handleTableOptions('sort-by')"
    @update:sort-desc="handleTableOptions('sort-desc')"
    @update:items-per-page="handleTableOptions"
  >
    <!-- grouping override -->
    <template v-slot:[`group.header`]="{ group, headers }">
      <td class="pa-0 group-header-row" :colspan="headers.length">
        <h2 class="mx-4 font-weight-bold d-inline">
          {{ setGroupHeader(group) }}
        </h2>
        <v-chip
          v-if="filterGeo || (!isAdmin && !isManager)"
          class="font-weight-bold white"
          :color="handleTotalWeekHrs(group) > 25 ? 'secondary' : ''"
        >
          {{ `Total HRS: ${handleTotalWeekHrs(group)}` }}
        </v-chip>
      </td>
    </template>

    <!-- Work Type 1 Quantity cell override -->
    <template v-slot:[`item.pricing[workTypes]`]="{ value }">
      <span>
        {{ value[0].quantity }}
      </span>
    </template>

    <!-- Writer cell override -->
    <template v-slot:[`item.primary`]="{ value }">
      <span>
        {{ `${value?.firstName} ${value?.lastName}` }}
      </span>
    </template>

    <!-- Request Date cell override -->
    <template v-slot:[`item.schedule[requestDate]`]="{ value }">
      <span>{{ formatPickerDate(value) }}</span>
    </template>

    <!-- Detail cell override -->
    <template v-slot:[`item.assignmentDetails[assignmentDetail]`]="{ value }">
      <v-tooltip right color="primary" :disabled="value.length <= 40">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ ellipseText(value, 40) }}</span>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
    </template>

    <!-- GEO cell override -->
    <template v-slot:[`item.assignmentDetails[geo]`]="{ value }">
      <v-chip v-if="value">{{ value }}</v-chip>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <LEDTableExpansion 
          @call-edit-form="$emit('call-edit-form', $event)"
          :assignmentInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { customBreakpoint, timeUnits } from "@/utils/mixins";
// components
import LEDTableExpansion from "./LEDTableExpansion.vue";

export default {
  name: "LEDTable",
  components: {
    LEDTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    totalHours: {
      type: Object,
      required: false
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
    isTableUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterGeo: {
      type: String,
      required: true,
    },
    filterDeliveryDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [true],
        groupBy: ["groupWeek"],
        groupDesc: [true],
        mustSort: false,
        multiSort: false,
      },
    };
  },
  mixins: [customBreakpoint, timeUnits],
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    headers() {
      return [
        { text: "Request Date", value: "schedule[requestDate]" },
        {
          text: "Assignment Title",
          value: "assignmentDetails[assignmentTitle]",
        },
        {
          text: "Assignment Detail",
          value: "assignmentDetails[assignmentDetail]",
        },
        { text: "Media Type", value: "assignmentDetails[mediaType]" },
        { text: "Word Count", value: "assignmentDetails[wordCount]" },
        {
          text: "Work Type Quantity",
          value: "pricing[workTypes]",
          sortable: !this.isAdmin && !this.isManager,
        },
        { text: "Primary", value: "primary", sortable: false },
        { text: "GEO", value: "assignmentDetails[geo]" },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  methods: {
    ellipseText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    handleTotalWeekHrs(week) {
      let totalHrs = 0;
      const datesArray = week.split(', ');
      let firstDayKeys = Object.keys(this.totalHours);
      const commonValues = datesArray.filter(value => firstDayKeys.includes(value));
      if (commonValues.length > 0) {
        const commonItem = commonValues[0];
        totalHrs = this.totalHours[commonItem];
      }
      return totalHrs;
    },
    setGroupHeader(week) {
      return this.filterDeliveryDate
        ? `Day: ${this.$moment(this.filterDeliveryDate).format("MMM DD, YYYY")}`
        : `Week: ${this.$moment(week.split(", ")[0]).format(
            "MMM DD, YYYY"
          )} - ${this.$moment(week.split(", ")[4]).format("MMM DD, YYYY")}`;
    },
    handleTableOptions(updatedProp) {
      if (updatedProp === "sort-by" && this.options.sortBy[0] === undefined)
        this.options.sortBy = [];
      else if (
        updatedProp === "sort-desc" &&
        this.options.sortDesc[0] === undefined
      )
        this.options.sortDesc = [];
      else this.$emit("refresh-request", this.options);
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
  cursor: default;
}

::v-deep {
  tbody > tr {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}

.d-inline {
  vertical-align: middle;
}

.table-updating {
  pointer-events: none;
  opacity: 0.5;
}
</style>
