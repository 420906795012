<template>
  <div class="invoice-bot">
    <v-card>
      <div class="invoice-bot__button-container d-flex justify-end">
        <v-btn :loading="!invoiceRows" color="primary" @click="handleCSVButton"
          >Create Importable CSV</v-btn
        >
        <v-btn v-if="!$route.query?.code" color="primary" @click="handleLogInToQuickBooks"
          >Connect To Quickbooks</v-btn
        >
        <v-btn v-else :loading="!invoiceRows || reportLoading" color="primary" @click="handleImportToQuickBooks"
          >Import To Quickbooks</v-btn
        >
        <v-btn color="secondary" @click="handleNavToEmails"
          >Client Emails</v-btn
        >
      </div>
      <v-alert
        v-model="failAlert"
        class="fail-alert"
        type="warning"
        dismissible
        >{{ alertMessage }}</v-alert
      >
      <ReportSelector :selected="selectedReport" @change="handleReportChange" />
      <PayPeriodSelector v-if="selectedReport === 'ANOTHER'" />
      <InvoiceReport />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
//moment
import moment from "moment";
// internal
import { getInvoiceEmails } from "@/utils/dbUtils";
import { doCSVGenerate, createQuickBooksRedirectLink, sendQuickBooksInvoices } from "@/utils/invoiceBotUtils";
import { ROUTES } from "@/utils/constants";
import { getAllStaff } from "@/utils/newDbUtils";
// components
import InvoiceReport from "@/components/InvoiceBot/InvoiceReport";
import PayPeriodSelector from "@/components/InvoiceBot/PayPeriodSelector";
import ReportSelector from "@/components/ui/ReportSelector";

export default {
  components: {
    InvoiceReport,
    PayPeriodSelector,
    ReportSelector,
  },
  data() {
    return {
      reportLoading: false,
      allStaff: [],
      failAlert: false,
      alertMessage: "",
    }
  },
  methods: {
    ...mapActions("invoiceBot", ["setClientEmails", "setSelectedReport"]),
    ...mapActions("flashMessage", ["handleFlash"]),
    handleNavToEmails() {
      this.$router.push(`/${ROUTES.clientEmails}`);
    },
    handleCSVButton() {
      doCSVGenerate({
        rows: this.invoiceRows,
        clientEmails: this.clientEmails,
      });
    },
    handleReportChange(v) {
      this.setSelectedReport(v);
    },
    async handleLogInToQuickBooks() {
      const response = await createQuickBooksRedirectLink();
      if (response) window.location = response.data;
    },
    findPMId(pmFullName) {
      if (this.allStaff.length) {
        if (pmFullName) {
          const nameParts = pmFullName.split(' ');
          const firstName = nameParts[0];
          const lastName = nameParts.slice(1).join(' ');
          const pm = this.allStaff.find(el => el.user?.firstName === firstName && el.user?.lastName === lastName);
          return pm.user?.id;
        } else {
          return null;
        }
      }
    },
    async handleImportToQuickBooks() {
      this.reportLoading = true;
      const data = {
        authCode: this.$route.query.code,
        realmId: this.$route.query.realmId,
        parameters: []
      };
      Object.keys(this.selectedInvoices).forEach((el) => {
        this.selectedInvoices[el].forEach((item) => {
          item.invoices.forEach((obj) => {
            const dateObject = moment(obj.payPeriod, 'MMMM YYYY');
            const currentMonth = dateObject.month() + 1;
            const currentYear = dateObject.year();
            const match = obj.workTypePrice.match(/(.+?)\s+([\d.]+)$/);
            const category = match[1];
            const priceCard = parseFloat(match[2]);

            data.parameters.push({
              ppm: currentMonth,
              ppy: currentYear,
              quickbooksCustomerName: obj.quickBooksCustomerName,
              purchaseOrderNumber: obj.purchaseOrderNumber,
              workTypeCategory: category,
              clientPriceCard: priceCard,
              assignmentDetails: obj.assignmentDetails,
              projectManagerId: this.findPMId(obj.projectManagerFullName)
            })
          })
        })
      })
      if (data.parameters.length === 0) {
        this.failAlert = true;
        this.alertMessage = "Please select at least one invoice.";
        this.reportLoading = false;
        return
      }
      
      await sendQuickBooksInvoices(data)
        .then((response) => {
          this.handleFlash({ response: response, show: true });
          this.$router.push(`/${ROUTES.invoiceBot}`);
        })
        .catch((err) => {
          this.handleFlash({ response: err, show: true });
          this.$router.push(`/${ROUTES.invoiceBot}`);
          console.log(err);
        });
      this.reportLoading = false;
    }
  },
  computed: {
    ...mapGetters("invoiceBot", [
      "clientEmails",
      "clientEmailsLoaded",
      "selectedReport",
      "invoiceRows",
      "payPeriodYear",
      "payPeriodMonth",
      "selectedPM",
      "selectedInvoices"
    ]),
  },
  async mounted() {
    if (!this.clientEmailsLoaded)
      getInvoiceEmails().then((emailData) => this.setClientEmails(emailData));
    this.allStaff = await getAllStaff();
  },
};
</script>

<style lang="scss" scoped>
.invoice-bot {
  max-width: 1400px;
  margin: 0 auto;

  &__button-container {
    padding: 10px;

    > * {
      margin-left: 10px;
    }
  }
}
</style>
