<template>
  <div class="mycue">
    <v-container class="client-selector" v-if="isAdminView">
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="12">
          <v-autocomplete
            :items="clients"
            item-text="name"
            return-object
            :loading="clientsLoading"
            :value="myCueSelectedClient"
            @change="handleClientSelect"
            label="Client"
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>

    <v-alert
      v-if="showAlert"
      :type="emailAlertDatas[emailAlert].type"
      transition="slide-y-transition"
    >
      Email to Company Cue project manager sent
    </v-alert>

    <v-card v-if="myCueSelectedClient">
      <POView />
      <MyCueAssignmentsTable />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { getClients } from "@/utils/newDbUtils";
// components
import MyCueAssignmentsTable from "@/components/MyCue/MyCueAssignmentsTable";
import POView from "@/components/MyCue/POView";

export default {
  name: "MyCue",
  components: { MyCueAssignmentsTable, POView },
  data() {
    return {
      clientsLoading: true,
      clients: [],
      showAlert: false,
      emailAlertDatas: {
        SUCCESS: {
          type: "success",
          msg: "Email to Company Cue project manager sent!",
        },
        FAIL: {
          type: "error",
          msg: "Problem sending email. Please email your project manager directly.",
        },
      },
    };
  },
  watch: {
    emailAlert(newValue) {
      if (newValue === "SUCCESS" || newValue === "FAIL") {
        this.showAlert = true;
        setTimeout(() => {
          this.setEmailAlert(null);
        }, 4000);
      } else {
        this.showAlert = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    ...mapGetters("myCue", ["myCueSelectedClient", "emailAlert"]),
    isAdminView() {
      return this.isAdmin || this.isManager;
    },
  },
  methods: {
    ...mapActions("myCue", ["setMyCueSelectedClient", "setEmailAlert"]),
    handleClientSelect(value) {
      this.setMyCueSelectedClient(value);
    },
  },
  mounted() {
    if (!this.isAdmin && !this.isManager && this.user?.client?.id)
      this.setMyCueSelectedClient(this.user.client);
    else
      getClients().then((names) => {
        this.clients = names;
        this.clientsLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.mycue {
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
</style>
