export const staffModule = {
    state: () => ({
      staff: [],
    }),
    mutations: {
      SET_STAFF(state, data) {
        state.staff = data;
      },
    },
    actions: {
      aSetStaff({ commit }, data) {
        commit("SET_STAFF", data);
      },
    },
    getters: {
      staffList: (state) => state.staff,
    },
  };
  