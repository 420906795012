<template>
  <div class="client-requesters my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new client -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="projectsLoading"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog
          max-width="1200"
          :value="requesterModal"
          @click:outside="requesterModal = false"
          @keydown.esc="requesterModal = false"
        >
          <ClientRequestersForm
            :projectsList="projects"
            :clientsList="clients"
            :itemToEdit="itemToEdit"
            @requesters-table-refresh="handleRefresh"
          />
        </v-dialog>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilters"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- project filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Project </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="selector"
              item-text="name"
              :items="projects"
              v-model="filterProject"
              solo
              dense
              hide-details
              :loading="projectsLoading"
              @input="handleFilterQuery('Project', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- status filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Status </span>
          <div>
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              multiple
              dense
              v-model="filterStatus"
              @change="handleFilterQuery('Status', $event)"
            >
              <v-btn height="38px">Active</v-btn>
              <v-btn height="38px">Inactive</v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <!-- Shakespeare filter -->
        <div class="ml-lg-3 ml-md-2 d-flex align-center flex-column">
          <v-img
            :src="SHK"
            class="flex-grow-0"
            max-width="36px"
            width="36px"
            height="32px"
            alt="Shakespeare"
          />
          <div>
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              dense
              v-model="filterShakespeare"
              @change="handleFilterQuery('Shakespeare', $event)"
            >
              <v-btn height="38px">AM</v-btn>
              <v-btn height="38px">FC</v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="client-requesters__search-selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader || projectsLoading"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <ClientRequestersTable
        v-if="tableData && !showTableLoader && !projectsLoading"
        :tableData="tableData"
        :filterSearch="filterSearch"
        :filterProject="filterProject ? filterProject : ''"
        :filterStatus="filterStatus"
        :filterShakespeare="filterShakespeare"
        @call-edit-form="handleForm"
      />
    </v-card>
  </div>
</template>

<script>
// libraries
import _ from "lodash";
// internal
import { getClientRequesters, getAllProjects } from "@/utils/newDbUtils";
import { customBreakpoint } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
import SHK from "@/assets/SHK.svg";
// components
import ClientRequestersTable from "@/components/ClientRequesters/ClientRequestersTable";
import ClientRequestersForm from "@/components/ClientRequesters/ClientRequestersForm";

export default {
  name: "ClientRequesters",
  components: {
    ClientRequestersTable,
    ClientRequestersForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      filterSearch: "",
      expandMenu: false,
      projectsLoading: true,
      projects: [],
      clients: [],
      filterProject: "",
      filterStatus: [0, 1],
      SHK,
      filterShakespeare: undefined,
      requesterModal: false,
      itemToEdit: {},
    };
  },
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.clientRequesters}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    this.getProjects();
  },
  methods: {
    getTableData() {
      this.showTableLoader = true;
      getClientRequesters().then((data) => {
        this.tableData = data?.status === 500 ? [] : data;
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.clientRequesters}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters() {
      this.filterStatus = [0, 1];
      this.filterShakespeare = undefined;
      this.filterProject = this.filterSearch = "";

      this.$router
        .push({ path: `/${ROUTES.clientRequesters}` })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    getProjects() {
      getAllProjects().then((resp) => {
        this.projects = resp;
        this.projectsLoading = false;
      });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach((param) => {
          if (param === "Status") {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [Number(currentQuery[param])]
                : currentQuery[param].map((x) => Number(x));
          } else {
            this[`filter${param}`] =
              param === "Shakespeare"
                ? Number(currentQuery[param])
                : currentQuery[param];
          }
        });
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.requesterModal = true;
    },
    handleRefresh() {
      this.requesterModal = false;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.client-requesters {
  max-width: 1500px;
  margin: 0 auto;

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector {
    width: 300px;
  }
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
