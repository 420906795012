<template>
  <div class="po-view mt-5">
    <div
      class="po-view__header-and-controls d-flex justify-space-between align-center px-4 py-3"
    >
      <div class="custom-card-header">Purchase Orders</div>
    </div>

    <div v-if="!purchaseOrdersLoading" class="po-view__po-selector">
      <v-tabs v-model="selectedPOIndex">
        <v-tab v-for="(po, i) in activePOs" :key="`tab-${i}`">{{
          po.purchaseOrderNumber
        }}</v-tab>
      </v-tabs>
    </div>

    <v-container
      v-if="selectedPOInfo && !isAllPOView && !purchaseOrdersLoading"
      width="100%"
      height="100%"
      class="po-view__details-container elevation-2 grey lighten-4 black--text rounded-lg pa-0 overflow-hidden"
    >
      <v-row class="po-view__details-container__row">
        <v-col cols="12" sm="3" class="grey lighten-3 pl-8 pt-4">
          <div class="po-text-info-container">
            <div
              class="po-text-info d-flex flex-column justify-space-between font-weight-bold mb-2"
            >
              <div class="po-text-info__top text-uppercase">Requestors</div>
              <div
                v-for="cr in selectedPOInfo.clientRequesters"
                :key="cr.user.id"
                class="po-text-info__bottom"
              >
                {{ `${cr.user.firstName} ${cr.user.lastName}` }}
              </div>
            </div>
            <div
              class="po-text-info d-flex flex-column justify-space-between font-weight-bold mb-2"
            >
              <div class="po-text-info__top text-uppercase">Project Name</div>
              <div class="po-text-info__bottom">
                {{ selectedPOInfo.project.name }}
              </div>
            </div>
            <div
              class="po-text-info d-flex flex-column justify-space-between font-weight-bold mb-2"
            >
              <div class="po-text-info__top text-uppercase">PO Start Date</div>
              <div class="po-text-info__bottom">
                {{ formatDateForInfoCard(selectedPOInfo.activeDate) }}
              </div>
            </div>
            <div
              class="po-text-info d-flex flex-column justify-space-between font-weight-bold mb-2"
            >
              <div class="po-text-info__top text-uppercase">PO End Date</div>
              <div class="po-text-info__bottom">
                {{ formatDateForInfoCard(selectedPOInfo.endDate) }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="po-visualization-column" cols="12" sm="9">
          <POViz :data="selectedPOInfo" />
        </v-col>
      </v-row>
    </v-container>
    <v-skeleton-loader
      v-if="purchaseOrdersLoading"
      type="list-item-three-line"
      height="48px"
    ></v-skeleton-loader>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { getPurchaseOrders } from "@/utils/newDbUtils";
// components
import POViz from "@/components/MyCue/POViz";

export default {
  name: "POView",
  components: { POViz },
  data() {
    return {
      purchaseOrdersLoading: false,
      poData: null,
      selectedPOIndex: null,
    };
  },
  watch: {
    myCueSelectedClient() {
      this.handlePurchaseOrders();
    },
    activePOs(newValue) {
      if (newValue === null) this.setSelectedPO(null);
      else this.selectedPOIndex = newValue[0];
    },
    selectedPOIndex(newValue) {
      this.setSelectedPO(this.activePOs[newValue]);
    },
  },
  methods: {
    ...mapActions("myCue", ["setSelectedPO"]),
    formatDateForInfoCard(unformattedDateString) {
      return unformattedDateString
        ? this.$moment(unformattedDateString).format("LL")
        : "";
    },
    handlePurchaseOrders() {
      if (this.user.user.userRole === "CLIENT") {
        this.poData = [...this.user.purchaseOrders].map((el) => ({
          ...el,
          clientRequester: this.user,
        }));
        if (this.poData.length) this.selectedPOIndex = 0;
      } else {
        this.purchaseOrdersLoading = true;
        getPurchaseOrders().then((result) => {
          this.poData = result.filter(
            (el) => el.client?.id === this.myCueSelectedClient.id
          );
          if (this.poData.length) this.selectedPOIndex = 0;
          this.purchaseOrdersLoading = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myCue", ["myCueSelectedClient"]),
    activePOs() {
      if (!this.poData || !this.poData.length) return null;
      const filtered = this.poData.filter((el) => el.isActive);
      if (!filtered.length) return null;

      return [
        {
          purchaseOrderNumber: "All",
        },
        ...filtered,
      ];
    },
    selectedPOInfo() {
      return this.activePOs ? this.activePOs[this.selectedPOIndex] : null;
    },
    isAllPOView() {
      return this.activePOs ? this.selectedPOIndex === 0 : false;
    },
  },
  mounted() {
    this.handlePurchaseOrders();
  },
};
</script>

<style lang="scss" scoped>
.po-view {
  &__details-container {
    max-width: calc(100% - 20px);
    position: relative;
    &__row {
      height: 100%;
    }
    .po-text-info-container {
      height: 100%;
      .po-text-info {
        &__top {
          font-size: 10px;
          color: #757575;
        }
        &__bottom {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
