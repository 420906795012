<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="First Name"
            v-model="clientRequester.firstName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Last Name"
            v-model="clientRequester.lastName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Email"
            v-model="clientRequester.email"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Location"
            :items="countries"
            :loading="countriesLoading"
            v-model="clientRequester.location"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Time Zone"
            :items="allTimeZones"
            v-model="clientRequester.timeZone"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
          <v-checkbox
            prepend-icon="mdi-account-badge-outline"
            label="Active"
            v-model="clientRequester.active"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
          <v-checkbox label="AM" v-model="clientRequester.shakespeareAM">
            <template v-slot:prepend>
              <v-img
                :src="SHK"
                class="flex-grow-0"
                width="26px"
                height="24px"
                alt="Shakespeare"
              />
            </template>
          </v-checkbox>
          <v-checkbox
            class="ml-4"
            label="FC"
            v-model="clientRequester.shakespeareFC"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex justify-center align-center pb-0">
          <v-checkbox
            class="mr-4"
            prepend-icon="mdi-account-key-outline"
            label="CueHub Access"
            :persistent-hint="
              clientRequester.cueHubAccess &&
              (!isEditMode || !itemToEdit?.user?.cueHubAccess)
            "
            hint="Password will be sent to email."
            v-model="clientRequester.cueHubAccess"
          ></v-checkbox>
          <v-tooltip v-model="resetSucess" bottom color="success">
            <template v-slot:activator="{ attrs }">
              <v-btn
                small
                :disabled="
                  !clientRequester.cueHubAccess ||
                  !isEditMode ||
                  !itemToEdit?.user?.cueHubAccess
                "
                :loading="resetLoading"
                @click="sendPasswordReset"
                v-bind="attrs"
              >
                <v-icon class="mr-2">mdi-lock-reset</v-icon>Reset Pw
              </v-btn>
            </template>
            <span>Password reset sent!</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Client"
            item-text="name"
            return-object
            :items="clientsList"
            v-model="relatedClient"
            :loading="clientsLoading"
            :rules="[rules.requiredObject]"
            @change="handleRelatedClient($event, true)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="8" class="pb-0">
          <v-autocomplete
            outlined
            label="Projects"
            item-text="name"
            return-object
            multiple
            :items="clientProjects"
            v-model="relatedProjects"
            :loading="clientsLoading"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Additional Info"
            v-model="clientRequester.additionInfo"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteRequester">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="clientRequesterHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="clientRequesterHandling"
        :disabled="deleteConfirmation"
        @click="handleClientRequester"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  getEnum,
  getClients,
  processClientRequester,
  deleteClientRequester,
  resetPassword,
} from "@/utils/newDbUtils";
import SHK from "@/assets/SHK.svg";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "ClientRequestersForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    projectsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      clientsLoading: true,
      clientsList: [],
      relatedClient: {},
      clientProjects: [],
      relatedProjects: [],
      countriesLoading: true,
      countries: [],
      clientRequester: {
        active: false,
        additionInfo: null,
        clientId: null,
        cueHubAccess: false,
        email: null,
        firstName: null,
        lastName: null,
        projects: [],
        shakespeareAM: false,
        shakespeareFC: false,
        location: null,
        timeZone: null,
      },
      SHK,
      resetLoading: false,
      resetSucess: false,
      valid: true,
      clientRequesterHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
    relatedProjects(newVal) {
      this.clientRequester.projects = newVal.map((el) => el.id);
    },
  },
  mounted() {
    this.getContries();
    this.getClientsList();
    this.dataViewsHandler();
  },
  mixins: [timeUnits],
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    getContries() {
      getEnum("Country").then((countries) => {
        this.countries = countries;
        this.countriesLoading = false;
      });
    },
    getClientsList() {
      getClients().then((clients) => {
        this.clientsList = clients;
        if (this.isEditMode) {
          this.relatedClient = this.clientsList.find(
            (el) => el.id === this.itemToEdit.client.id
          );
          this.handleRelatedClient(this.itemToEdit.client, false);
        }
        this.clientsLoading = false;
      });
    },
    handleRelatedClient(client, resetProjects) {
      this.clientRequester.clientId = client?.id ?? null;
      this.clientProjects = this.relatedClient.projects?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      this.relatedProjects = resetProjects
        ? []
        : this.clientProjects.filter(
            (el) => !!this.itemToEdit.projects.find((item) => item.id === el.id)
          );
    },
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else this.$refs.form.reset();
    },
    handleEditMode() {
      this.clientRequester = { ...this.itemToEdit };
      Object.keys(this.itemToEdit.user).forEach(
        (key) => (this.clientRequester[key] = this.itemToEdit.user[key])
      );
      if (this.clientsList.length) {
        this.relatedClient = this.clientsList.find(
          (el) => el.id === this.itemToEdit.client.id
        );
        this.handleRelatedClient(this.itemToEdit.client, false);
      }
    },
    handleClientRequester() {
      if (this.$refs.form.validate()) {
        this.clientRequesterHandling = true;
        processClientRequester({
          data: { ...this.clientRequester },
          id: this.itemToEdit?.user?.id,
        }).then((resp) => {
          this.clientRequesterHandling = false;
          this.$emit("requesters-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      }
    },
    deleteRequester() {
      this.deleteConfirmation = false;
      this.clientRequesterHandling = true;
      deleteClientRequester(this.itemToEdit.user.id).then((resp) => {
        this.clientRequesterHandling = false;
        this.$emit("requesters-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
    sendPasswordReset() {
      this.resetLoading = true;
      resetPassword(this.itemToEdit.user.id).then(() => {
        this.resetLoading = false;
        this.resetSucess = true;
        setTimeout(() => {
          this.resetSucess = false;
        }, 3000);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
