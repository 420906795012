<template>
  <v-container class="pb-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">
          {{ assignmentInfo.assignmentDetails.assignmentTitle }}
        </div>
        <h2>{{ assignmentInfo.assignmentDetails.assignmentDetail }}</h2>
        <v-chip class="my-5">
          {{ assignmentInfo.assignmentDetails.geo }}
        </v-chip>
        <div>
          <!-- related project -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Project</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.project?.name
            }}</v-col>
          </v-row>

          <!-- related purchase order -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Purchase Order</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.pricing.purchaseOrder?.purchaseOrderNumber
            }}</v-col>
          </v-row>

          <!-- pay period -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Pay Period</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.schedule.payPeriod
                ? `${$moment(assignmentInfo.schedule.payPeriod).format(
                    "MMMM YYYY"
                  )}`
                : ""
            }}</v-col>
          </v-row>

          <!-- delivery date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Delivery Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              formatDate(assignmentInfo.schedule.deliveryDate)
            }}</v-col>
          </v-row>

          <!-- related client requester -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Client Requester</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              `${assignmentInfo.clientRequester?.firstName ?? ""} ${
                assignmentInfo.clientRequester?.lastName ?? ""
              }`
            }}</v-col>
          </v-row>

          <!-- request type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.requestType
            }}</v-col>
          </v-row>

          <!-- media type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Media Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.mediaType
            }}</v-col>
          </v-row>

          <!-- keyword -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Keyword</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.keyword
            }}</v-col>
          </v-row>

          <!-- word count -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Word Count</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.wordCount
            }}</v-col>
          </v-row>

          <!-- comments -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Comments</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.note
            }}</v-col>
          </v-row>

          <!-- created -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Created</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              `${formatDate(assignmentInfo.createdDate, true)} by ${
                assignmentInfo.createdBy.firstName
              } ${assignmentInfo.createdBy.lastName}`
            }}</v-col>
          </v-row>

          <!-- last updated -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Last Updated</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.updatedBy || assignmentInfo.lastModifiedDate
                ? `${formatDate(assignmentInfo.lastModifiedDate, true)}
                ${
                  assignmentInfo.updatedBy
                    ? ` by ${assignmentInfo.updatedBy.firstName} ${assignmentInfo.updatedBy.lastName}`
                    : ""
                }`
                : ""
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Assignment Steps</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Person</th>
                  <th class="text-center">Spelling/Typos</th>
                  <th class="text-center">Grammar/Syntax</th>
                  <th class="text-center">Factual Error</th>
                  <th class="text-center">Style/ToV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      `${assignmentInfo.primary?.firstName ?? ""} ${
                        assignmentInfo.primary?.lastName ?? ""
                      }`
                    }}
                  </td>
                  <td>
                    <!--  -->
                    <v-icon
                      v-if="assignmentInfo.leadEditorDetails?.spellingOrTypos"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <!--  -->
                    <v-icon
                      v-if="assignmentInfo.leadEditorDetails?.grammarOrSyntax"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <!--  -->
                    <v-icon
                      v-if="assignmentInfo.leadEditorDetails?.factualError"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <!--  -->
                    <v-icon v-if="assignmentInfo.leadEditorDetails?.styleOrTov"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="mt-6 table-bg text-center">
          <h3 class="pb-2">Assignment Work Types</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Work Type</th>
                  <th class="text-center">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    :style="{
                      textDecoration: isAdmin ? 'underline' : 'none',
                    }"
                    @click="checkPrice"
                  >
                    {{
                      assignmentInfo.pricing?.workTypes[0]?.customerPriceList
                        ?.workTypeName
                    }}
                  </td>
                  <td>{{ assignmentInfo.pricing?.workTypes[0]?.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <!-- bottom controls -->
    <v-row v-if="isAdmin || isManager" class="d-flex justify-end px-3">
      <v-btn
        class="secondary"
        @click="$emit('call-edit-form', assignmentInfo)"
        >Edit</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { DOMAIN, ROUTES } from "@/utils/constants";

export default {
  name: "LEDTableExpansion",
  props: {
    assignmentInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
  },
  methods: {
    formatDate(date, withTime) {
      return date
        ? `${this.$moment(
            withTime
              ? new Date(date).toLocaleString("en-US", {
                  timeZone: "America/New_York",
                })
              : date
          ).format(`MM-DD-YYYY${withTime ? " hh:mm A" : ""}`)}`
        : "";
    },
    checkPrice() {
      if (this.isAdmin)
        window.open(
          `${DOMAIN}/${ROUTES.customerPriceList}?WorkTypeCategory=${this.assignmentInfo.pricing?.workTypes[0]?.customerPriceList?.workTypeCategory}&Search=${this.assignmentInfo.pricing?.workTypes[0]?.customerPriceList?.workTypeName}`,
          "_blank"
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
