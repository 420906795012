<template>
  <div class="staff-table">
    <v-expansion-panels multiple>
      <StaffExpansionPanel
        v-for="groupItem in sourceGroup"
        :key="groupItem"
        :groupItem="groupItem"
        :data="
          shakespeare || cueCopywriting
            ? shakespeareData[groupItem]
            : staffAssignments[groupItem]
        "
        :staffDatas="staffDatas"
        :identifierCount="getIdentifierCount(groupItem)"
        :workType1References="workType1References"
        :workType2References="workType2References"
        :workType3References="workType3References"
        :workType4References="workType4References"
        :workType5References="workType5References"
        :workType6References="workType6References"
      />
    </v-expansion-panels>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// component
import StaffExpansionPanel from "@/components/SpreadsheetUpload/StaffExpansionPanel";

export default {
  name: "StaffTable",
  components: { StaffExpansionPanel },
  props: {
    staffDatas: {
      required: false,
      type: Array,
    },
    tableRows: {
      required: false,
      type: Array,
    },
    workType1References: {
      required: false,
      type: Array,
    },
    workType2References: {
      required: false,
      type: Array,
    },
    workType3References: {
      required: false,
      type: Array,
    },
    workType4References: {
      required: false,
      type: Array,
    },
    workType5References: {
      required: false,
      type: Array,
    },
    workType6References: {
      required: false,
      type: Array,
    },
  },
  methods: {
    getIdentifierCount(groupItem) {
      return this.shakespeare || this.cueCopywriting
        ? Object.keys(this.shakespeareData[groupItem]).length
        : this.backstageCopywriting
        ? 0
        : Number(
            this.tableRows?.find((el) => el.task_name === groupItem)?.word_count
          );
    },
  },
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "staffAssignments",
      "shakespeare",
      "backstageCopywriting",
      "cueCopywriting",
    ]),
    sourceGroup() {
      if (this.shakespeare || this.cueCopywriting) {
        const shakespeareGeos = [];
        Object.values(this.staffAssignments).forEach((el) => {
          if (!shakespeareGeos.includes(Object.keys(el)[0]))
            shakespeareGeos.push(Object.keys(el)[0]);
        });
        return shakespeareGeos;
      } else {
        return this.backstageCopywriting
          ? [Object.keys(this.staffAssignments)[0]]
          : Object.keys(this.staffAssignments);
      }
    },
    shakespeareData() {
      const shakespeareData = {};
      Object.values(this.staffAssignments).forEach((el) => {
        if (shakespeareData.hasOwnProperty(Object.keys(el)[0])) {
          Object.assign(shakespeareData[Object.keys(el)[0]], {
            [el.task_name]: null,
          });
        } else {
          shakespeareData[Object.keys(el)[0]] = { [el.task_name]: null };
        }
      });
      return shakespeareData;
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-table {
  margin-bottom: 30px;
}
</style>
