import Vue from "vue";
import VueRouter from "vue-router";
// components
import Home from "@/views/Home";
import DataUpload from "@/views/DataUpload";
import SpreadsheetUpload from "@/views/SpreadsheetUpload";
import GeneralSplitter from "@/views/GeneralSplitter";
import InvoiceBot from "@/views/InvoiceBot";
import AvailabilityCalendar from "@/views/AvailabilityCalendar";
import Login from "@/views/Login";
import ClientEmails from "@/views/ClientEmails";
import Staff from "@/views/Staff";
import MyCue from "@/views/MyCue";
import Request from "@/views/Request";
import PaymentRequests from "@/views/PaymentRequests";
import PMDashboard from "@/views/PMDashboard";
import AssignmentDashboard from "@/views/AssignmentDashboard";
import LEDDashboard from "@/views/LEDDashboard";
import IssueTracker from "@/views/IssueTracker";
import Editor from "@/views/Editor";
import CueHardware from "@/views/CueHardware";
import CueSoftware from "@/views/CueSoftware";
import Clients from "@/views/Clients";
import ClientRequesters from "@/views/ClientRequesters";
import PurchaseOrders from "@/views/PurchaseOrders";
import CustomerPriceList from "@/views/CustomerPriceList";
import Projects from "@/views/Projects";
import Settings from "@/views/Settings";
import PaymentRequestForm from "@/views/PaymentRequestForm";
import RequestAnAssignment from "@/views/RequestAnAssignment";
import VendorInvoiceForm from "@/views/VendorInvoiceForm";
import NotFound from "@/views/NotFound";
// internal
import { getTypeformIDForCompany } from "@/utils/dbUtils";
import { ROUTES } from "@/utils/constants";
import store from "@/store/index";
import { validateToken, refreshSession } from "@/utils/axiosInstance";

Vue.use(VueRouter);

const permRejectHandler = (next) => {
  if (store.getters["auth/user"] !== null) {
    next({ name: "Home" });
  } else {
    next({ name: "Login" });
  }
};

const adminsOnly = (to, from, next) => {
  if (store.getters["auth/isAdmin"]) {
    next();
  } else {
    permRejectHandler(next);
  }
};

const adminsOrDubOnly = (to, from, next) => {
  if (
    store.getters["auth/isAdmin"] ||
    (store.getters["auth/isDubEditor"] && !store.getters["auth/profileLock"])
  ) {
    next();
  } else {
    permRejectHandler(next);
  }
};

const adminsOrManagers = (to, from, next) => {
  if (
    store.getters["auth/isAdmin"] ||
    (store.getters["auth/isManager"] && !store.getters["auth/profileLock"])
  ) {
    next();
  } else {
    permRejectHandler(next);
  }
};

const cueOnly = (to, from, next) => {
  if (
    store.getters["auth/user"].user.userRole !== "CLIENT" &&
    !store.getters["auth/profileLock"]
  ) {
    next();
  } else {
    permRejectHandler(next);
  }
};

const isVendor = (to, from, next) => {
  if (
    store.getters["auth/user"]?.adminDetails?.vendor
  ) {
    next('/payment-requests');
  } else {
    next();
  }
}

const staffAndVendor = (to, from, next) => {
  if (
    store.getters["auth/user"].user.userRole !== "CLIENT" &&
    !store.getters["auth/profileLock"] && !store.getters["auth/user"].adminDetails.vendor
  ) {
    next();
  } else {
    permRejectHandler(next);
  }
}

const PRforCueAndVendor = (to, from, next) => {
  if (
    (store.getters["auth/user"].user.userRole !== "CLIENT" &&
    !store.getters["auth/profileLock"]) || store.getters["auth/user"].adminDetails.vendor
  ) {
    next();
  } else {
    permRejectHandler(next);
  }
}

const seniorStaffOrFCReviewersOnly = (to, from, next) => {
  if (
    (store.getters["auth/user"].user.userRole !== "CLIENT" &&
      store.getters["auth/user"]?.adminDetails?.leadEditor &&
      !store.getters["auth/profileLock"]) ||
    store.getters["auth/isAdmin"]
  ) {
    next();
  } else {
    permRejectHandler(next);
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: isVendor,
  },
  { path: "/404", component: NotFound },
  { path: "*", redirect: "/404" },
  {
    path: `/${ROUTES.spreadsheetUpload}`,
    name: "Spreadsheet Upload",
    component: SpreadsheetUpload,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.dataUpload}`,
    name: "Data Upload",
    component: DataUpload,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.fileSplitter}`,
    name: "File Splitter",
    component: GeneralSplitter,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.invoiceBot}`,
    name: "Invoice Bot",
    component: InvoiceBot,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.calendar}`,
    name: "Availability Calendar",
    component: AvailabilityCalendar,
    beforeEnter: staffAndVendor,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: `/${ROUTES.clientEmails}`,
    name: "Client Emails",
    component: ClientEmails,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.myCue}`,
    name: "Client Dashboard",
    component: MyCue,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/user"]?.projects?.length) next();
      else adminsOnly(to, from, next);
    },
  },
  {
    path: `/${ROUTES.staff}`,
    name: "Staff",
    component: Staff,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.pmDashboard}`,
    name: "PM Dashboard",
    component: PMDashboard,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.assignmentDashboard}`,
    name: "Assignment Dashboard",
    component: AssignmentDashboard,
    beforeEnter: staffAndVendor,
  },
  {
    path: `/${ROUTES.requestedAssignments}`,
    name: "Requested Assignments",
    component: AssignmentDashboard,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.ledDashboard}`,
    name: "Lead Editor Dashboard",
    component: LEDDashboard,
    beforeEnter: seniorStaffOrFCReviewersOnly,
  },
  {
    path: `/${ROUTES.issueTracker}`,
    name: "Issue Tracker",
    component: IssueTracker,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.editor}`,
    name: "DubEditor",
    component: Editor,
    beforeEnter: adminsOrDubOnly,
  },
  {
    path: `/${ROUTES.cueHardware}`,
    name: "Cue Hardware",
    component: CueHardware,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.cueSoftware}`,
    name: "Cue Software",
    component: CueSoftware,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.clients}`,
    name: "Clients",
    component: Clients,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.clientRequesters}`,
    name: "Client Requesters",
    component: ClientRequesters,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.purchaseOrders}`,
    name: "Purchase Orders",
    component: PurchaseOrders,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.customerPriceList}`,
    name: "Price List",
    component: CustomerPriceList,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.projects}`,
    name: "Projects",
    component: Projects,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.settings}`,
    name: "Settings",
    component: Settings,
    beforeEnter: adminsOnly,
  },
  {
    path: `/${ROUTES.request}`,
    name: "Client Request Form",
    component: Request,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isAdmin"]) {
        next();
        return "skip";
      } else next();
    },
  },
  {
    path: `/${ROUTES.paymentRequests}`,
    name: "Payment Request Form",
    component: PaymentRequests,
    beforeEnter: PRforCueAndVendor
  },
  {
    path: `/${ROUTES.paymentRequestForms}`,
    name: "Reimbursement Request Form",
    component: PaymentRequestForm,
    beforeEnter: PRforCueAndVendor
  },
  {
    path: `/${ROUTES.vendorRequestForms}`,
    name: "Vendor Invoice Form",
    component: VendorInvoiceForm,
    beforeEnter: PRforCueAndVendor
  },
  {
    path: `/${ROUTES.requestanAssignment}`,
    name: "Request an Assignment",
    component: RequestAnAssignment
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (window.location.pathname === "/login" || to.name === "Login") {
    // go on as normal
    next();
  } else {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    validateToken(token).then((resp) => {
      if (resp.status === 200) {
        store._actions["auth/setIsAdmin"][0](
          resp.data.user.userRole === "ADMIN"
        );
        store._actions["auth/setIsManager"][0](
          resp.data.user.userRole === "MANAGER"
        );
        store._actions["auth/setIsDubEditor"][0](
          !!resp.data?.adminDetails?.dubEditorAccess
        );
        store._actions["auth/setUser"][0](resp.data);
        next();
      } else {
        refreshSession(refreshToken).then((resp) => {
          if (resp.status === 200) {
            localStorage.setItem("token", resp.data.accessToken);
            next();
          } else {
            store._actions["auth/setIsAdmin"][0](false);
            store._actions["auth/setIsManager"][0](false);
            store._actions["auth/setIsDubEditor"][0](false);
            store._actions["auth/setUser"][0](null);
            // redirect to login page
            const entry = to.fullPath;
            next({ name: "Login", query: { to: entry } });
            store._actions["flashMessage/handleFlash"][0]({
              response: resp,
              show: true,
            });
          }
        });
      }
    });
  }
});

export default router;
