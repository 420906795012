export const invoiceBotModule = {
  state: () => ({
    clientEmails: [],
    clientEmailsLoaded: false,
    payPeriodMonth: null,
    payPeriodYear: null,
    invoiceRows: null,
    invoiceTablePMs: null,
    selectedReport: "THIS",
    selectedPM: null,
    selectedInvoices: {}
  }),
  mutations: {
    SET_CLIENT_EMAILS(state, clientEmails) {
      state.clientEmails = clientEmails;
      state.clientEmailsLoaded = true;
    },
    SET_PAY_PERIOD_MONTH(state, month) {
      state.payPeriodMonth = month;
    },
    SET_PAY_PERIOD_YEAR(state, year) {
      state.payPeriodYear = year;
    },
    SET_INVOICE_ROWS(state, rows) {
      state.invoiceRows = rows;
    },
    SET_INVOICE_TABLE_HEADER(state, header) {
      state.invoiceTableHeader = header;
    },
    SET_INVOICE_TABLE_PMS(state, pmList) {
      state.invoiceTablePMs = pmList;
    },
    SET_SELECTED_REPORT(state, report) {
      state.selectedReport = report;
    },
    SET_SELECTED_PM(state, pm) {
      state.selectedPM = pm;
    },
    SET_SELECTED_INVOICES(state, payload) {
      if (!state.selectedInvoices[payload.pm]) {
        state.selectedInvoices[payload.pm] = [];
      }
      const filteredInvoices = state.selectedInvoices[payload.pm].find(
        (item) => item.payPeriod === payload.payPeriod
      )
      if (!filteredInvoices) {
        state.selectedInvoices[payload.pm].push({
          payPeriod: payload.payPeriod,
          invoices: payload.invoices
        });
      } else {
        filteredInvoices.invoices = payload.invoices
      }
    },
    CLEAR_SELECTED_INVOICES(state) {
      state.selectedInvoices = [];
    }
  },
  actions: {
    setClientEmails(context, clientEmails) {
      context.commit("SET_CLIENT_EMAILS", clientEmails);
    },
    setPayPeriodMonth(context, month) {
      context.commit("SET_PAY_PERIOD_MONTH", month);
    },
    setPayPeriodYear(context, year) {
      context.commit("SET_PAY_PERIOD_YEAR", year);
    },
    setInvoiceRows(context, rows) {
      context.commit("SET_INVOICE_ROWS", rows);
    },
    setInvoiceTableHeader(context, header) {
      context.commit("SET_INVOICE_TABLE_HEADER", header);
    },
    setInvoiceTablePMs(context, pmList) {
      context.commit("SET_INVOICE_TABLE_PMS", pmList);
    },
    setSelectedReport(context, report) {
      context.commit("SET_SELECTED_REPORT", report);
    },
    setSelectedPM(context, pm) {
      context.commit("SET_SELECTED_PM", pm);
    },
    setSelectedInvoices(context, invoices) {
      context.commit("SET_SELECTED_INVOICES", invoices);
    },
    clearSelectedInvoices(context) {
      context.commit("CLEAR_SELECTED_INVOICES");
    }
  },
  getters: {
    clientEmails: (state) => {
      return state.clientEmails;
    },
    clientEmailsLoaded: (state) => {
      return state.clientEmailsLoaded;
    },
    payPeriodMonth: (state) => {
      return state.payPeriodMonth;
    },
    payPeriodYear: (state) => {
      return state.payPeriodYear;
    },
    invoiceRows: (state) => {
      return state.invoiceRows;
    },
    invoiceTablePMs: (state) => {
      return state.invoiceTablePMs;
    },
    selectedReport: (state) => {
      return state.selectedReport;
    },
    selectedPM: (state) => {
      return state.selectedPM;
    },
    selectedInvoices: (state) => {
      return state.selectedInvoices;
    }
  },
};
