<template>
  <EditorProjectSelectionList v-if="allProjects" />
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// components
import EditorProjectSelectionList from "./EditorProjectSelectionList.vue";

export default {
  name: "EditorAssignmentsTableControls",
  components: { EditorProjectSelectionList },
  computed: {
    ...mapGetters("editor", ["allProjects"]),
  },
};
</script>

<style lang="scss" scoped></style>
