export const pmDashboardModule = {
  state: () => ({
    isEditing: false,
  }),
  mutations: {
    SET_IS_EDITING(state, data) {
      state.isEditing = data;
    },
  },
  actions: {
    aSetIsEditing({ commit }, data) {
      commit("SET_IS_EDITING", data);
    }
  },
  getters: {
    gIsEditing: (state) => state.isEditing
  },
};
  