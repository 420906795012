<template>
  <div class="customer-price-list my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new client -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog
          max-width="1200"
          :value="priceListModal"
          @click:outside="priceListModal = false"
          @keydown.esc="priceListModal = false"
        >
          <CustomerPriceListForm
            :units="units"
            :workTypeCategories="workTypeCategories"
            :itemToEdit="itemToEdit"
            @price-list-table-refresh="handleRefresh"
          />
        </v-dialog>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilters"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- work type category filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Work Type Category </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="selector"
              :items="workTypeCategories"
              v-model="filterWorkTypeCategory"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('WorkTypeCategory', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- units filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Units </span>
          <div class="d-flex align-end">
            <v-autocomplete
              class="selector"
              :items="units"
              v-model="filterUnits"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @change="handleFilterQuery('Units', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="customer-price-list__search-selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <CustomerPriceListTable
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :filterSearch="filterSearch"
        :filterWorkTypeCategory="
          filterWorkTypeCategory ? filterWorkTypeCategory : ''
        "
        :filterUnits="filterUnits ? filterUnits : ''"
        @call-edit-form="handleForm"
      />
    </v-card>
  </div>
</template>

<script>
// libraries
import _ from "lodash";
// internal
import { getCustomerPriceList, getEnum } from "@/utils/newDbUtils";
import { customBreakpoint } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
// components
import CustomerPriceListTable from "@/components/CustomerPriceList/CustomerPriceListTable";
import CustomerPriceListForm from "@/components/CustomerPriceList/CustomerPriceListForm";

export default {
  name: "CustomerPriceList",
  components: {
    CustomerPriceListTable,
    CustomerPriceListForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      filterSearch: "",
      expandMenu: false,
      dropdownsLoading: true,
      workTypeCategories: [],
      filterWorkTypeCategory: "",
      units: [],
      filterUnits: "",
      priceListModal: false,
      itemToEdit: {},
    };
  },
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.customerPriceList}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    this.handleDropdowns();
  },
  methods: {
    getTableData() {
      this.showTableLoader = true;
      getCustomerPriceList().then((data) => {
        this.tableData = data?.status === 500 ? [] : data;
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.customerPriceList}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters() {
      this.filterWorkTypeCategory = this.filterUnits = this.filterSearch = "";

      this.$router
        .push({ path: `/${ROUTES.customerPriceList}` })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    handleDropdowns() {
      Promise.all([getEnum("WorkTypeCategory"), getEnum("Units")])
        .then((resp) => {
          [this.workTypeCategories, this.units] = resp;
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.dropdownsLoading = false;
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach(
          (param) => (this[`filter${param}`] = currentQuery[param])
        );
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.priceListModal = true;
    },
    handleRefresh() {
      this.priceListModal = false;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-price-list {
  max-width: 1500px;
  margin: 0 auto;

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector {
    width: 200px;
  }
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
