<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Service Name"
            v-model="software.serviceName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Service Type"
            :items="types"
            v-model="software.serviceType"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Plan Name"
            v-model="software.planName"
            @change="handleEmptyString($event, 'planName')"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Plan Type"
            :items="plans"
            v-model="software.planType"
            @change="handleEmptyString($event, 'planType')"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="User Amount (Max)"
            v-model="software.maxUserAmount"
            @change="handleEmptyString($event, 'maxUserAmount')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="User Amount (Current)"
            v-model="software.currentUserAmount"
            @change="handleEmptyString($event, 'currentUserAmount')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Device Amount (Max)"
            v-model="software.deviceAmountMax"
            @change="handleEmptyString($event, 'deviceAmountMax')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Device Amount (Current)"
            v-model="software.deviceAmount"
            @change="handleEmptyString($event, 'deviceAmount')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-autocomplete
            outlined
            label="Subscription Type"
            :items="subscriptions"
            v-model="software.subscriptionType"
            @change="handleEmptyString($event, 'subscriptionType')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Subscription Amount (Monthly)"
            prepend-inner-icon="mdi-currency-usd"
            v-model="software.subscriptionAmount"
            @change="handleEmptyString($event, 'subscriptionAmount')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="subscriptionStartDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartDate"
                label="Subscription Start Date"
                prepend-icon="mdi-calendar-check"
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="subscriptionStartDateMenu = false"
              v-model="subscriptionStartDateNotTimestamp"
              @change="handleDate($event, 'subscriptionStartDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="subscriptionEndDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="Subscription End Date"
                prepend-icon="mdi-calendar-remove"
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="subscriptionEndDateMenu = false"
              v-model="subscriptionEndDateNotTimestamp"
              @change="handleDate($event, 'subscriptionEndDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            type="email"
            label="Account Owner"
            v-model="software.accountEmail"
            @change="handleEmptyString($event, 'accountEmail')"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-file-input
            outlined
            label="User Agreement"
            accept=".png, .jpg, .jpeg"
            truncate-length="50"
            v-model="userAgreementBlob"
            :loading="userAgreementLoading"
            :rules="[rules.isImage]"
            @change="convertFile($event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-autocomplete
            outlined
            multiple
            chips
            deletable-chips
            label="Users"
            :item-text="fullName"
            return-object
            :items="employees"
            :loading="employeesLoading"
            v-model="software.staff"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteSoftware">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="softwareHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="softwareHandling"
        :disabled="deleteConfirmation"
        @click="handleSoftware"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import {
  getAllStaff,
  getFile,
  processProjectTool,
  deleteProjectTool,
} from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "CueSoftwareForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    plans: {
      type: Array,
      required: true,
    },
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      employeesLoading: true,
      employees: [],
      subscriptionStartDateMenu: false,
      subscriptionEndDateMenu: false,
      userAgreementBlob: null,
      userAgreementLoading: false,
      software: {
        serviceName: null,
        serviceType: null,
        planName: null,
        planType: null,
        maxUserAmount: null,
        currentUserAmount: null,
        deviceAmountMax: null,
        deviceAmount: null,
        subscriptionType: null,
        subscriptionAmount: null,
        subscriptionStartDate: null,
        subscriptionEndDate: null,
        accountEmail: null,
        userAgreement: null,
        staff: [],
      },
      subscriptionStartDateNotTimestamp: null,
      subscriptionEndDateNotTimestamp: null,
      valid: true,
      softwareHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        isImage: (value) =>
          value?.type == "image/jpeg" ||
          value?.type == "image/jpg" ||
          value?.type == "image/png" ||
          value === null ||
          "Only JPEG/JPG or PNG images are allowed.",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    formattedStartDate: {
      get() {
        return this.formatPickerDate(this.subscriptionStartDateNotTimestamp);
      },
      set() {
        this.subscriptionStartDateNotTimestamp = null;
      },
    },
    formattedEndDate: {
      get() {
        return this.formatPickerDate(this.subscriptionEndDateNotTimestamp);
      },
      set() {
        this.subscriptionEndDateNotTimestamp = null;
      },
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.getEmployees();
    this.dataViewsHandler();
  },
  mixins: [timeUnits],
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    fullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else this.$refs.form.reset();
    },
    getEmployees() {
      getAllStaff({ namesOnly: true }).then((names) => {
        this.employees = names;
        this.employeesLoading = false;
      });
    },
    handleEditMode() {
      this.software = { ...this.itemToEdit };

      // handle timestamp for standardized formatting
      Object.keys(this.software).forEach((key) => {
        if (key.includes("Date"))
          this[`${key}NotTimestamp`] = this.itemToEdit[key]
            ? this.$moment.unix(this.itemToEdit[key]).format("YYYY-MM-DD")
            : "";
      });

      this.handleFile(this.software.userAgreement);
    },
    handleFile(url) {
      if (url) {
        this.userAgreementLoading = true;
        getFile({ url }).then(async (resp) => {
          const base64Response = await fetch(`data:image/jpeg;base64,${resp}`),
            blob = await base64Response.blob();
          let newBlob = new Blob([blob], {
            type: "image/jpeg",
          });
          newBlob.name = `${this.software.serviceName}_User Agreement.jpeg`;
          this.software.userAgreement = ""; // indicates to backend the document don't needs to be updated
          this.userAgreementBlob = newBlob;
          this.userAgreementLoading = false;
        });
      } else {
        this.userAgreementBlob = null;
      }
    },
    convertFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.software.userAgreement = reader.result?.split(",")[1];
        };
        reader.readAsDataURL(file);
      } else {
        this.software.userAgreement = null;
      }
    },
    handleSoftware() {
      if (this.$refs.form.validate()) {
        this.softwareHandling = true;
        processProjectTool({
          tool: "software",
          data: { ...this.software },
          id: this.itemToEdit?.id,
        }).then((resp) => {
          this.softwareHandling = false;
          this.$emit("software-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      }
    },
    deleteSoftware() {
      this.deleteConfirmation = false;
      this.softwareHandling = true;
      deleteProjectTool({
        tool: "software",
        id: this.itemToEdit?.id,
      }).then((resp) => {
        this.softwareHandling = false;
        this.$emit("software-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
    // data formatting for backend
    handleEmptyString(value, key) {
      if (value === "") this.software[key] = null;
    },
    handleDate(value, key) {
      this.software[key] = value ? this.$moment(value).format("X") : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
