<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ clientInfo.vertical }}</div>
        <h2>{{ clientInfo.name }}</h2>
        <v-chip class="my-5">
          {{ clientInfo.clientStatus }}
        </v-chip>
        <div>
          <!-- Quickbooks Customer Name -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Quickbooks Customer Name</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientInfo.quickbooksCustomerName
            }}</v-col>
          </v-row>

          <!-- Contract -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Contract</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :disabled="!clientInfo.contract"
                :loading="contractLoading"
                @click="downloadFile(clientInfo.contract)"
              >
                Download<v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Territory -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Territory</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientInfo.territory
            }}</v-col>
          </v-row>

          <!-- Notes -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Notes</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              clientInfo.notes
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12">
            <h3 class="pb-2">Contact Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Person</th>
                    <th class="text-center">Email</th>
                    <th class="text-center">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ clientInfo.contact }}
                    </td>
                    <td>
                      {{ clientInfo.contactEmail }}
                      <v-btn
                        icon
                        small
                        color="accent"
                        :disabled="!clientInfo.contactEmail"
                        @click="sendEmail"
                        ><v-icon>mdi-email-plus-outline</v-icon></v-btn
                      >
                    </td>
                    <td>
                      {{ clientInfo.contactPhone }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="table-bg text-center pa-0 mt-6">
          <v-col cols="12">
            <h3 class="pb-2">Related Data</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Projects</th>
                    <th class="text-center">Requesters</th>
                    <th class="text-center">Purchase Orders</th>
                    <th class="text-center">Assignments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullProjectsDashboardPath}?Client=${clientInfo.name}`"
                        :disabled="!clientInfo.projects.length"
                        target="_blank"
                      >
                        <v-icon>mdi-folder-account-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullClientRequestersDashboardPath}?Search=${clientInfo.name}`"
                        :disabled="!clientInfo.clientRequesters.length"
                        target="_blank"
                      >
                        <v-icon>mdi-account-alert-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullPosDashboardPath}?Client=${clientInfo.name}`"
                        :disabled="!clientInfo.purchaseOrders.length"
                        target="_blank"
                      >
                        <v-icon>mdi-octagram-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="accent"
                        :href="`${fullAssignmentsDashboardPath}?Search=${clientInfo.name}`"
                        :disabled="!clientInfo.assignmentsCount"
                        target="_blank"
                      >
                        <v-icon>mdi-clipboard-list-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// library
import { saveAs } from "file-saver";
// internal
import { getFile } from "@/utils/newDbUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";

export default {
  name: "ClientsTableExpansion",
  props: {
    clientInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contractLoading: false,
    };
  },
  computed: {
    fullProjectsDashboardPath() {
      return `${DOMAIN}/${ROUTES.projects}`;
    },
    fullPosDashboardPath() {
      return `${DOMAIN}/${ROUTES.purchaseOrders}`;
    },
    fullClientRequestersDashboardPath() {
      return `${DOMAIN}/${ROUTES.clientRequesters}`;
    },
    fullAssignmentsDashboardPath() {
      return `${DOMAIN}/${ROUTES.assignmentDashboard}`;
    },
  },
  methods: {
    downloadFile() {
      this.contractLoading = true;
      getFile({
        table: "client",
        file: "contract",
        entityId: this.clientInfo.id,
      }).then(async (resp) => {
        const base64Response = await fetch(
          `data:application/pdf;base64,${resp}`
        );
        const blob = await base64Response.blob();
        saveAs(blob, `${this.clientInfo.name}_Contract`);
        this.contractLoading = false;
      });
    },
    sendEmail() {
      window.open(`mailto:${this.clientInfo.contactEmail}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
