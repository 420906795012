<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-checkbox
      v-if="role !== 'qc' && !isUpdating"
      :input-value="value"
      @click.native.stop.prevent="handleChange"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { processAssignment } from "@/utils/newDbUtils";

export default {
  name: "ADAcceptedSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    role() {
      if (this.item.primary.email === this.user.user.email) {
        return "primary";
      } else if (this.item.secondary?.email === this.user.user.email) {
        return "secondary";
      } else {
        return "qc";
      }
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleChange() {
      this.isUpdating = true;
      processAssignment({
        data: {
          [`${this.role}Accepted`]: !this.value,
        },
        id: this.item.id,
        patch: true,
      }).then((resp) => {
        if (resp.status <= 204)
          this.$emit(
            "refresh-table-expansion",
            this.item.index,
            !this.value,
            this.role,
            resp
          );
        this.isUpdating = false;
        this.handleFlash({ response: resp, show: true });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 0 auto;
}
::v-deep {
  .v-input--checkbox .v-input__control .v-input__slot {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 8px;
    & .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
</style>
