<template>
  <v-form
      ref="form"
      lazy-validation
      class="form v-card v-sheet theme--light ma-4"
  >
      <v-row class="my-0">
          <v-col
              cols="12"
              class="d-flex align-center mt-0 pb-0 title"
          >
              <v-icon>mdi-format-list-bulleted</v-icon>
              <p class="form__title ml-2 mt-4">New Invoice</p>
          </v-col>
      </v-row>
      <v-row class="my-0">
          <v-col
              cols="12"
              class="my-0 py-0"
          >
              <v-autocomplete
                  outlined
                  label="Invoice Category"
                  :items="invoiceCategoriesList"
                  v-model="invoiceCategory"
                  :rules="[rules.required]"
              ></v-autocomplete>
          </v-col>
      </v-row>
      <v-row class="my-0">
          <v-col
              cols="6"
              class="my-0 py-0"
          >
              <v-text-field
                  outlined
                  type="number"
                  label="Total Invoice Amount (USD)"
                  :prepend-inner-icon="this.invoiceAmount || this.isAmountInputFocused ? 'mdi-currency-usd' : ''"
                  v-model="invoiceAmount"
                  @focus="isAmountInputFocused = true"
                  @blur="isAmountInputFocused = false"
                  :rules="[rules.required, rules.negativeCheck]"
                  />
          </v-col>
          <v-col
              cols="6"
              class="my-0 py-0"
          >
              <v-menu
                  v-model="purchaseDeliveryDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="purchaseDeliveryDate"
                      label="Service Completion Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                  ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="purchaseDeliveryDate"
                      @input="purchaseDeliveryDateMenu = false"
                  ></v-date-picker>
              </v-menu>
          </v-col>
      </v-row>
      <v-row class="my-0">
          <v-col cols="12" class="my-0 py-0">
              <div
                  @dragover.prevent="handleDragOver"
                  @dragleave.prevent="handleDragLeave"
                  @drop.prevent="handleDrop"
                  :class="{ 'dropzone-active': isDragOver }"
                  class="file-input-wrapper"
              >
                  <v-file-input
                      ref="fileInput"
                      class="file-input"
                      chips
                      label="Receipt Copy"
                      prepend-icon=""
                      prepend-inner-icon="mdi-paperclip"
                      outlined
                      multiple
                      v-model="reimbursementFiles"
                      @change="handleFileChange"
                  ></v-file-input>
              </div>
          </v-col>
      </v-row>
      <v-row class="my-0">
            <v-col
                cols="12"
                class="my-0 py-0"
            >
            <v-textarea
                outlined
                label="Note to Manager"
                auto-grow
                rows="1"
                v-model="noteToManager"
            ></v-textarea>
            </v-col>
        </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "ReimbursementForm",
  props: {
      nativeForm: {
          type: Object,
          required: true
      },
      index: {
          type: Number,
          required: true
      }
  },
  data() {
      return {
          purchaseDeliveryDateMenu: false,
          isAmountInputFocused: false,
          invoiceCategoriesList: [
              "Administartive",
              "Consulting",
              "Development",
              "Finance",
              "Accounting",
              "Other"
          ],
          rules: {
              required: (value) => !!value || value === 0 || "Required",
              requiredObject: (value) =>
                  (value ? !!Object.keys(value).length : !!value) || "Required",
              availabile: (specialist) =>
                  !this[`${specialist}Unavailable`] ||
                  `Unavailable on selected due date for ${specialist}.`,
              dateAvailabile: (operation) =>
                  this[`${operation}DueDateAvailable`] ||
                  "Selected date is after delivery date for the client.",
              timeAvailabile: (operation) =>
                  this[`${operation}DueHoursAvailable`] ||
                  "Selected time is after delivery due (time of day) for the client.",
              negativeCheck: (value) => value >= 0 || "Quantity can't be nagative.",
              isPDF: (value) =>
                  value?.type == "application/pdf" ||
                  value === null ||
                  "Only PDF attachments are allowed.",
          },
          isDragOver: false
      }
  },
  computed: {
      ...mapGetters('assignment', ['projectsList']),
      ...mapGetters("nativeForms", ["gGetDefaultNativeForms"]),
      ...mapGetters("auth", ["user"]),
      invoiceCategory: {
          get() {
              return this.gGetDefaultNativeForms[this.index].invoiceCategory
          },
          set(val) {
              this.aSetFieldValue({
                  index: this.index,
                  field: "invoiceCategory",
                  value: val
              })
          }
      },
      purchaseDeliveryDate: {
          get() {
              return this.gGetDefaultNativeForms[this.index].purchaseDeliveryDate
          },
          set(val) {
              this.aSetFieldValue({
                  index: this.index,
                  field: "purchaseDeliveryDate",
                  value: val
              })
          }
      },
      invoiceAmount: {
          get() {
              return this.gGetDefaultNativeForms[this.index].invoiceAmount
          },
          set(val) {
              this.aSetFieldValue({
                  index: this.index,
                  field: "invoiceAmount",
                  value: val
              })
          }
      },
      noteToManager: {
          get() {
              return this.gGetDefaultNativeForms[this.index].noteToManager
          },
          set(val) {
              this.aSetFieldValue({
                  index: this.index,
                  field: "noteToManager",
                  value: val
              })
          }
      },
      reimbursementFiles: {
          get() {
              return this.gGetDefaultNativeForms[this.index].reimbursementFiles
          },
          set(val) {
              this.aSetFieldValue({
                  index: this.index,
                  field: "reimbursementFiles",
                  value: val
              })
          }
      },
      requestTypeList() {
          if (this.reimbursementCategory && this.reimbursementCategory === "Media") {
              return [
                  "Audiobook",
                  "Book",
                  "Music",
                  "Film",
                  "Apps & Games",
                  "Subscription",
              ]
          }
          if (this.reimbursementCategory && this.reimbursementCategory === "Business expenses") {
              return [
                  "Travel Expenses",
                  "Hardware Purchase",
                  "Software Purchase",
                  "Meal & Entertainment",
                  "Office Supply",
                  "Seminars & Training",
                  "Postage & Delivery",
                  "Event"
              ]
          }
      }
  },
  methods: {
      ...mapActions("nativeForms", ["aSetFieldValue"]),
      validateForm() {
          return this.$refs.form.validate();
      },
      handleDragOver() {
          this.isDragOver = true;
      },
      handleDragLeave() {
          this.isDragOver = false;
      },
      handleDrop(event) {
          this.isDragOver = false;
          const files = event.dataTransfer.files;
          this.addFiles(files);
      },
      handleFileChange(files) {
          this.addFiles(files);
      },
      addFiles(files) {
          files = Array.from(files).filter(file => !this.reimbursementFiles.some(existingFile => existingFile.name === file.name));
          this.reimbursementFiles = [...this.reimbursementFiles, ...files];
      }   
  }
}
</script>

<style lang="scss" scoped>
.form {
  .title {
      margin-top: -15px !important;
  }
  padding: 10px;
  .v-text-field__details {
      display: none;
  }
  &__title {
      font-size: 20px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      line-height: 18px;
  }
}

.file-input-wrapper {
  display: inline-block;
  width: 100%;
  height: auto;
}
</style>