<template>
  <div class="invoice-report-table">
    <v-tabs v-model="pmTab">
      <v-tab
        v-for="(pm, i) in invoiceTablePMs"
        :key="`tab-${i}`"
        @change="handleTab(pm)"
        >{{ pm ?? "No PM" }}</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="pmTab">
      <v-tab-item v-for="(pm, i) in invoiceTablePMs" :key="`tab-${i}`">
        <InvoiceReportTableView :selectedPM="selectedPM" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// components
import InvoiceReportTableView from "@/components/InvoiceBot/InvoiceReportTableView.vue";

export default {
  components: {
    InvoiceReportTableView,
  },
  data() {
    return {
      selectedPM: null,
      pmTab: 0,
    };
  },
  computed: {
    ...mapGetters("invoiceBot", ["invoiceTablePMs"]),
  },
  methods: {
    ...mapActions("invoiceBot", ["setSelectedPM"]),
    handleTab(pm) {
      this.selectedPM = pm;
      this.pmTab = this.invoiceTablePMs.indexOf(this.selectedPM);
      this.setSelectedPM(pm);
    },
  },
  mounted() {
    this.selectedPM = this.invoiceTablePMs[0];
    this.setSelectedPM(this.selectedPM);
  },
};
</script>

<style lang="scss" scoped></style>
