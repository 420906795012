import { render, staticRenderFns } from "./ClientRequestersForm.vue?vue&type=template&id=d460487c&scoped=true&"
import script from "./ClientRequestersForm.vue?vue&type=script&lang=js&"
export * from "./ClientRequestersForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d460487c",
  null
  
)

export default component.exports