// libraries
import _ from "lodash";
import { schema } from "prosemirror-schema-basic";
import { EditorState } from "prosemirror-state";
import { DOMSerializer, DOMParser, Node } from "prosemirror-model";
import { exampleSetup, buildMenuItems } from "prosemirror-example-setup";
import moment from "moment";
const Excel = require("exceljs");
var { htmlToText } = require("html-to-text");
// internal
import { processAssignment, deleteAssignment } from "@/utils/newDbUtils";
import store from "@/store/index";

// constants
export const DOCUMENTS_COLLECTION_NAME = "editor-documents";
export const ASSIGNMENTS_COLLECTION_NAME = "editor-assignments";
export const PROJECTS_COLLECTION_NAME = "editor-projects";
export const EDITOR_AUTOSAVE_INTERVAL = 500;
export const BLANK_DOCUMENT_CONTENTS = {
  type: "doc",
  content: [
    {
      type: "paragraph",
    },
  ],
};
export const BLANK_DOCUMENT_STRING =
  "{“type”:“doc”,“content”:[{“type”:“paragraph”}]";

export const BLANK_NEW_ASSIGNMENT_DATAS = [
  {
    assignmentName: null,
    assignmentDetails: null,
    projectID: "",
    deliveryDate: null,
    relatedPrice: null,
    payPeriod: null,
    quantity: null,
    clientRequester: null,
    qbID: "",
    geo: "en-US",
    mediaType: null,
    po: null,
    isDelivered: false,
    id: "",
    exportHeader: "",
    assignees: ["", ""],
    flow: [
      {
        label: "Writer",
        person: "",
        personEmail: "",
        stepDueDate: null,
        stepDueTime: null,
        isComplete: false,
      },
      {
        label: "Editor",
        person: "",
        personEmail: "",
        stepDueDate: null,
        stepDueTime: null,
        isComplete: false,
      },
    ],
  },
];

export const BLANK_NEW_PROJECT_DATA = {
  projectName: "",
  workType: "",
  staffPool: [],
  staffPoolEmails: [],
  styleGuideLink: "",
  fragmentSchema: [],
};

export const BLANK_NEW_FRAGMENT_DATA = {
  fragmentName: "",
  maxCount: null,
  minCount: null,
  isCountWords: true,
};

const assignmentStatusList = [
  "Assigned",
  "Primary Complete",
  "Secondary Complete",
  "QC Complete",
  "Client Reviewed",
];

///////////////////////////////
// local editor
///////////////////////////////
export const initState = (content) => {
  const menuItems = buildMenuItems(schema);
  const plugins = exampleSetup({
    schema,
    menuContent: [[menuItems.toggleStrong, menuItems.toggleEm]],
    history: true,
  });

  if (_.isEmpty(content)) {
    return EditorState.create({
      doc: DOMParser.fromSchema(schema).parse(BLANK_DOCUMENT_STRING),
      plugins: plugins,
    });
  } else {
    const n = Node.fromJSON(schema, content);
    return EditorState.create({
      doc: n,
      plugins: plugins,
    });
  }
};

export const getSortField = ({ row, isAdmin, userEmail }) => {
  if (isAdmin) return row.deliveryDate;

  const match = row.flow.find((flowStep) => {
    return flowStep.person.email === userEmail;
  });

  return match ? match.stepDueDate : row.deliveryDate;
};

//////////////////////////////
// text export
//////////////////////////////
export const saveHtmlAsPlaintext = ({ string, filename }) => {
  let text = htmlToText(string, {
    wordwrap: false,
    ignoreHref: true,
    formatters: {
      // Create a formatter.
      boldFormatter: function (elem, walk, builder, formatOptions) {
        builder.openBlock({
          leadingLineBreaks: 0,
        });
        builder.addInline("\n<b>");
        walk(elem.children, builder);
        builder.addInline("</b>\n");
        builder.closeBlock({
          trailingLineBreaks: 0,
        });
      },
      italicFormatter: function (elem, walk, builder, formatOptions) {
        builder.openBlock({
          leadingLineBreaks: 0,
        });
        builder.addInline(" ");
        builder.addInline("\n<i>");
        walk(elem.children, builder);
        builder.addInline("</i>\n");
        builder.addInline(" ");
        builder.closeBlock({
          trailingLineBreaks: 0,
        });
      },
    },
    tags: {
      // Assign it to `foo` tags.
      b: {
        format: "boldFormatter",
      },
      i: {
        format: "italicFormatter",
      },
    },
  });

  text = text.replaceAll("</b>", "</b> ");
  text = text.replaceAll("<b>", " <b>");
  text = text.replaceAll("</i>", "</i> ");
  text = text.replaceAll("<i>", " <i>");
  text = text.replaceAll("\n ", "\n");

  const blob = new Blob([text]);
  saveAs(blob, `${filename}.txt`);
};

const editTags = (contents) => {
  let retVal = _.cloneDeep(contents);

  retVal = retVal.replace("</em>", "</i>");
  retVal = retVal.replace("</strong>", "</b>");
  retVal = retVal.replace("<em>", "<i>");
  retVal = retVal.replace("<strong>", "<b>");

  return retVal;
};

const assembleFragmentsForExport = ({ assignment, contents }) => {
  let assembled = `<p>${assignment.exportHeader}</p>`;
  contents.forEach((fragment) => {
    const html = `
      <p>${fragment.fragmentName}</p>
      ${fragment.textContent}`;
    assembled = assembled + html;
  });

  return assembled;
};

const getHTMLRepresentationOfObject = (obj) => {
  const tempState = EditorState.create({
    schema,
    doc: Node.fromJSON(schema, obj),
  });
  const div = document.createElement("div");
  const fragment = DOMSerializer.fromSchema(schema).serializeFragment(
    tempState.doc.content
  );

  div.appendChild(fragment);

  const textContent = editTags(div.innerHTML);

  return textContent;
};

export const createSingleAssignmentText = async (assignment) => {
  let contents = await assignment.getContentsOfFinalStage();

  contents = contents.map((el) => {
    // turn the json object into content string
    const textContent = getHTMLRepresentationOfObject(el.lastStageData);

    // marry with existing data
    return {
      ...el,
      textContent,
    };
  });
  const assembled = assembleFragmentsForExport({ assignment, contents });
  return assembled;
};

export const createMultiAssignmentText = async (assignments) => {
  const proms = assignments.map((assignment) => {
    return createSingleAssignmentText(assignment);
  });
  const results = await Promise.all(proms);
  const assembled = results.join("<hr>");
  return assembled;
};

//////////////////////////////
// excel export
//////////////////////////////
export const createExcel = async (assignments) => {
  const contentProms = assignments.map((assignment) => {
    return assignment.getContentsOfFinalStage();
  });

  let contents = await Promise.all(contentProms);

  const pairs = contents.map((fragments) => {
    return getNameValuePairsFromFragments(fragments);
  });

  // get list of fragment names, these are the columns of the excel sheet
  const fragmentNames = assignments.reduce((acc, assignment) => {
    const fragments = assignment.fragments;
    fragments.forEach((fragment) => {
      if (!acc.includes(fragment.fragmentName)) {
        acc.push(fragment.fragmentName);
      }
    });
    return acc;
  }, []);

  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet("Copy Export");
  let cols = [
    {
      header: "",
      key: "exportHeader",
      width: 45,
      style: {
        alignment: {
          wrapText: true,
        },
      },
    },
  ];
  fragmentNames.forEach((el) => {
    cols.push({
      header: el,
      key: el,
      width: 75,
      style: {
        alignment: {
          wrapText: true,
        },
      },
    });
  });

  sheet.columns = cols;

  pairs.forEach((pair) => {
    sheet.addRow(pair);
  });

  const headerRow = sheet.getRow(1);
  headerRow.font = { bold: true };
  headerRow.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFC9F2EE" },
  };

  const fileName = `${assignments[0].projectName}_${moment().format(
    "YYYY-MM-DD"
  )}.xlsx`;

  const buffData = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  blob.lastModifiedDate = new Date();
  blob.name = `${fileName}`;
  saveAs(blob, `${fileName}.xlsx`);
};

const getNameValuePairsFromFragments = (fragments, assignment) => {
  const retVal = {
    exportHeader: fragments[0].exportHeader,
  };
  fragments.forEach((fragment) => {
    retVal[fragment.fragmentName] = getHTMLRepresentationOfObject(
      fragment.lastStageData
    )
      .replace("<p>", "")
      .replace("</p>", "");
  });
  return retVal;
};

//////////////////////////////
// assignment CRUD
//////////////////////////////
const formatDataForBackend = ({ data, project }) => {
  const currentProject = store.getters["editor/dbProjects"].find(
    (el) => el.name === project.projectName
  );
  const backendFormat = {
    assignmentDetails: {
      assignmentId: null,
      assignmentDetail: data.assignmentDetails,
      assignmentTitle: data.assignmentName,
      clientFeedback: false,
      fileLocation: null,
      taskId: null,
      genre: null,
      geo: data.geo,
      sourceGeo: null,
      mediaType: data.mediaType,
      primaryAccepted: false,
      secondaryAccepted: false,
      assignmentStatus: "Assigned",
      wordCount: null,
      contentId: null,
      emailSubjectLine: null,
      keyword: null,
      billed: false,
      clientReviewed: false,
      genre: null,
      note: null,
      requestType: "Copywriting",
      urgent: false,
      link: null,
    },
    pricing: {
      purchaseOrderId: data.po,
      workTypes: [
        { customerPriceListId: data.relatedPrice, quantity: +data.quantity, orderPosition: data.orderPosition },
      ],
    },
    document: null,
    schedule: {
      deliveryDate: data.deliveryDate,
      deliveryTime: null,
      payPeriod: data.payPeriod,
      primaryDueDate: data.flow[0]?.stepDueDate ?? null,
      primaryDueTime: data.flow[0]?.stepDueTime ?? null,
      requestDate: moment(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).format("YYYY-MM-DD"),
      secondaryDueDate: data.flow[1]?.stepDueDate ?? null,
      secondaryDueTime: data.flow[1]?.stepDueTime ?? null,
      qcDueDate: null,
      qcDueTime: null,
    },
    qcId: null,
    projectId: currentProject.id,
    clientId: currentProject.client.id,
    clientRequesterId:
      currentProject.clientRequesters.find(
        (el) =>
          data.clientRequester?.includes(el.user.firstName) &&
          data.clientRequester?.includes(el.user.lastName)
      )?.user.id ?? null,
    projectManagerId: currentProject.projectManager?.id ?? null,
    primaryId:
      store.getters["editor/staff"].find(
        (el) =>
          data.flow[0]?.person.name?.includes(el.user.firstName) &&
          data.flow[0]?.person.name?.includes(el.user.lastName)
      )?.user.id ?? null,
    secondaryId:
      store.getters["editor/staff"].find(
        (el) =>
          data.flow[1]?.person.name?.includes(el.user.firstName) &&
          data.flow[1]?.person.name?.includes(el.user.lastName)
      )?.user.id ?? null,
  };
  return {
    ...data,
    ...backendFormat,
  };
};

export const createNewAssignmentsWithDatas = async ({ datas, project }) => {
  // create datas object
  // const firebasePromises = datas.map((el) => {
  //   return project.createNewAssignment({
  //     ...el,
  //   });
  // });
  // const firebaseResp = await Promise.all(firebasePromises);
  // return {
  //   firebaseResp,
  //   // rejected,
  // };
  /////////////////////////////////////
  //  restore below when done testing
  /////////////////////////////////////
  const dbUploadDatas = datas.map((data) => ({
    ...formatDataForBackend({ data, project }),
  }));
  let backendResponse = [];
  for (let i = 0; i < dbUploadDatas.length; i++) {
    try {
      const resp = await processAssignment({ data: dbUploadDatas[i] });
      backendResponse.push({
        status: resp.status > 204 ? "rejected" : "fulfilled",
        value: resp,
      });
      if (resp.status > 204)
        store._actions["flashMessage/handleFlash"][0]({
          response: resp,
          show: true,
        });
    } catch (err) {
      store._actions["flashMessage/handleFlash"][0]({
        response: err,
        show: true,
      });
      backendResponse.push({ status: "rejected", value: err });
    }
  }
  // create an object that marries the backend response with the original data
  const allInfos = datas.map((el, i) => {
    return { ...el, ...backendResponse[i] };
  });
  // create the subset of those that are fulfilled
  const fulfilled = allInfos
    .filter((el) => el.status === "fulfilled")
    .map((el) => {
      return {
        ..._.omit(el, ["value", "status"]),
        qbID: `${el.value.data.id}`,
      };
    });
  // create the subset of those that are rejected
  const rejected = allInfos.filter((el) => el.status === "rejected");
  // create firebase entries for the assignments that now have qb entries
  const firebasePromises = fulfilled.map((el) => {
    return project.createNewAssignment({
      ...el,
    });
  });
  // wait for all the firebase promises to resolve
  const firebaseResp = await Promise.all(firebasePromises);
  if (!rejected.length)
    store._actions["flashMessage/handleFlash"][0]({
      response: { status: 201 },
      show: true,
    });
  return {
    firebaseResp,
    rejected,
  };
};

export const updateAssignmentWithData = async ({
  id,
  project,
  assignment,
  originalData,
}) => {
  try {
    // update database
    // RETURN AFTER EDIT
    const response = await processAssignment({
      data: formatDataForBackend({ data: assignment, project }),
      id: assignment.qbID,
    });
    // update firebase
    if (response.status <= 204)
      await project.updateAssignmentWithData({ id, assignment, originalData });
    store._actions["flashMessage/handleFlash"][0]({
      response,
      show: true,
    });
    return;
  } catch (err) {
    console.error(err);
    store._actions["flashMessage/handleFlash"][0]({
      response: err,
      show: true,
    });
  }
};

export const deleteAssignmentFromEditor = ({ id, firebaseID, project }) => {
  return new Promise((resolve, reject) => {
    deleteAssignment(id)
      .then((resp) => {
        return resp.status <= 204
          ? project.deleteAssignment(firebaseID)
          : Promise.reject(resp);
      })
      .then(() => {
        store._actions["flashMessage/handleFlash"][0]({
          response: { status: 200 },
          show: true,
        });
        resolve();
      })
      .catch((err) => {
        store._actions["flashMessage/handleFlash"][0]({
          response: err,
          show: true,
        });
        reject(err);
      });
  });
};

export const handleAssignmentChangeDeliveryStatus = ({
  assignment,
  deliveryStatus,
}) => {
  return new Promise((resolve, reject) => {
    let updatedStatus;

    if (deliveryStatus) {
      updatedStatus = "Delivered";
    } else {
      const lastCompletedStep = _.sum(
        assignment.flow.map((el) => el.isComplete)
      );
      updatedStatus = assignmentStatusList[lastCompletedStep];
    }

    processAssignment({
      data: {
        assignmentStatus: updatedStatus,
      },
      id: assignment.qbID,
      patch: true,
    })
      .then((resp) => {
        return resp.status <= 204
          ? assignment.setIsDelivered(deliveryStatus)
          : Promise.reject(resp);
      })
      .then(() => {
        store._actions["flashMessage/handleFlash"][0]({
          response: { status: 201 },
          show: true,
        });
        resolve();
      })
      .catch((err) => {
        store._actions["flashMessage/handleFlash"][0]({
          response: err,
          show: true,
        });
        reject(err);
      });
  });
};

export const handleAssignmentChangeFlowStep = ({
  assignment,
  flowStage,
  isComplete,
}) => {
  return new Promise((resolve, reject) => {
    const stepCount = assignment.flow.length;
    if (isComplete) {
      // simply set what's needed
      const newStatus = assignmentStatusList[flowStage + 1];
      processAssignment({
        data: {
          assignmentStatus: newStatus,
        },
        id: assignment.qbID,
        patch: true,
      })
        .then((resp) => {
          return resp.status <= 204
            ? assignment.setFlowStageCompletion({
                flowStage,
                isComplete,
                isLastStep: flowStage === stepCount - 1,
                newStatus: newStatus,
              })
            : Promise.reject(resp);
        })
        .then(() => {
          store._actions["flashMessage/handleFlash"][0]({
            response: { status: 201 },
            show: true,
          });
          resolve();
        })
        .catch((err) => {
          store._actions["flashMessage/handleFlash"][0]({
            response: err,
            show: true,
          });
          reject(err);
        });
    } else {
      // here we need to account for possibly setting multiple steps to false
      const newStatus = assignmentStatusList[flowStage];
      const rangeToSet = _.range(flowStage, stepCount);

      processAssignment({
        data: {
          assignmentStatus: newStatus,
        },
        id: assignment.qbID,
        patch: true,
      })
        .then((resp) => {
          return resp.status <= 204
            ? assignment.setFlowStageCompletion({
                flowStage: rangeToSet,
                isComplete: false,
                newStatus: newStatus,
              })
            : Promise.reject(resp);
        })
        .then(() => {
          store._actions["flashMessage/handleFlash"][0]({
            response: { status: 201 },
            show: true,
          });
          resolve();
        })
        .catch((err) => {
          store._actions["flashMessage/handleFlash"][0]({
            response: err,
            show: true,
          });
          reject(err);
        });
    }
  });
};
