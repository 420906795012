import { db } from "@/providers/firebase";
import store from "@/store/index";

const EMAILS_COLLECTION_NAME = "client-invoice-contacts";
const TYPEFORM_COLLECTION_NAME = "typeforms";

////////////////////////////////
// INVOICE EMAILS
////////////////////////////////

export const getInvoiceEmails = () => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .get()
      .then((querySnapshot) => {
        const retVal = [];
        querySnapshot.forEach((doc) => {
          retVal.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        resolve(retVal);
      })
      .catch((err) => reject(err));
  });
};

export const addInvoiceEmail = ({
  client,
  primary_email,
  secondary_emails,
}) => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .add({
        client,
        primary_email,
        secondary_emails: secondary_emails || "",
      })
      .then(() => {
        return getInvoiceEmails();
      })
      .then((newEmailData) => resolve(newEmailData))
      .catch((err) => reject(err));
  });
};

export const updateInvoiceEmail = ({
  id,
  client,
  primary_email,
  secondary_emails,
}) => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .doc(id)
      .update({
        client,
        primary_email,
        secondary_emails: secondary_emails || "",
      })
      .then(() => {
        return getInvoiceEmails();
      })
      .then((newEmailData) => resolve(newEmailData))
      .catch((err) => reject(err));
  });
};

export const deleteInvoiceEmail = (idToDelete) => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .doc(idToDelete)
      .delete()
      .then(() => {
        return getInvoiceEmails();
      })
      .then((newEmailData) => resolve(newEmailData))
      .catch((err) => reject(err));
  });
};

////////////////////////////////
// TYPEFORMS
////////////////////////////////

export const getTypeformDataset = () => {
  if (store.getters["auth/user"] !== null)
    return new Promise((resolve, reject) => {
      db.collection(TYPEFORM_COLLECTION_NAME)
        .get()
        .then((querySnapshot) => {
          const retVal = [];
          querySnapshot.forEach((doc) => {
            retVal.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          resolve(retVal);
        })
        .catch((err) => reject(err));
    });
};

export const getTypeformIDForCompany = (company) => {
  if (store.getters["auth/user"] !== null)
    return new Promise((resolve, reject) => {
      db.collection(TYPEFORM_COLLECTION_NAME)
        .where("company", "==", company)
        .get()
        .then((querySnapshot) => {
          let retVal = null;

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              retVal = doc.data().typeform_id;
            });
          }

          resolve(retVal);
        })
        .catch((err) => reject(err));
    });
};

export const addTypeformID = ({
  company,
  typeform_id,
  slack_channel_id,
  dropbox_link,
}) => {
  if (store.getters["auth/user"] !== null)
    return new Promise((resolve, reject) => {
      db.collection(TYPEFORM_COLLECTION_NAME)
        .add({
          company,
          typeform_id,
          slack_channel_id,
          dropbox_link,
        })
        .then(() => {
          return getTypeformDataset();
        })
        .then((newTypeformData) => resolve(newTypeformData))
        .catch((err) => reject(err));
    });
};

export const updateTypeformID = ({
  id,
  company,
  typeform_id,
  slack_channel_id,
  dropbox_link,
}) => {
  if (store.getters["auth/user"] !== null)
    return new Promise((resolve, reject) => {
      db.collection(TYPEFORM_COLLECTION_NAME)
        .doc(id)
        .update({
          company,
          typeform_id,
          slack_channel_id,
          dropbox_link,
        })
        .then(() => {
          return getTypeformDataset();
        })
        .then((newTypeformData) => resolve(newTypeformData))
        .catch((err) => reject(err));
    });
};

export const deleteTypeformID = (idToDelete) => {
  if (store.getters["auth/user"] !== null)
    return new Promise((resolve, reject) => {
      db.collection(TYPEFORM_COLLECTION_NAME)
        .doc(idToDelete)
        .delete()
        .then(() => {
          return getTypeformDataset();
        })
        .then((newTypeformData) => resolve(newTypeformData))
        .catch((err) => reject(err));
    });
};
