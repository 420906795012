<template>
  <v-form ref="form" v-model="valid" class="v-card v-sheet theme--light">
    <v-card-title class="align-start pb-2">
      <span class="text-h5 pt-1 mr-2">Individual deadlines for</span>
      <v-autocomplete
        class="geo-field mr-2"
        dense
        solo-inverted
        label="GEO"
        :disabled="editMode"
        :items="geos"
        v-model="currentGeo"
        :rules="[rules.required]"
      ></v-autocomplete>
      <v-btn
        v-if="editMode"
        min-width="40px"
        width="40px"
        height="40px"
        class="ml-auto"
        color="secondary"
        @click="handleDelete"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <!-- writing -->
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-menu
            v-model="writerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="writingDueDateField"
                filled
                v-model="writingDueDate"
                label="Writer Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required, rules.dateAvailabile('writing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="writingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="writerMenu = false"
              @change="handleDueData($event, 'writing')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            filled
            v-model="writingDue"
            :items="writingDueHours"
            item-text="time"
            label="Writer Due Time (ET)"
            :rules="[rules.required, rules.timeAvailabile('writing')]"
            @change="handleDueData($event, 'writing', true)"
          >
          </v-select> </v-col
      ></v-row>
      <!-- editing -->
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-menu
            v-model="editorMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="editingDueDateField"
                filled
                clearable
                v-model="editingDueDate"
                label="Editor Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="editingDueDate ? [rules.dateAvailabile('editing')] : []"
                @click:clear="handleDueData('', 'editing')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="editorMenu = false"
              @change="handleDueData($event, 'editing')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            filled
            clearable
            v-model="editingDue"
            :items="editingDueHours"
            item-text="time"
            label="Editor Due Time (ET)"
            :rules="editingDue ? [rules.timeAvailabile('editing')] : []"
            @change="handleDueData($event, 'editing', true)"
            @click:clear="handleDueData('', 'editing', true)"
          >
          </v-select> </v-col
      ></v-row>
      <!-- reviewing -->
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-menu
            v-model="reviewerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="reviewingDueDateField"
                filled
                clearable
                v-model="reviewingDueDate"
                label="Reviewer Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="
                  reviewingDueDate ? [rules.dateAvailabile('reviewing')] : []
                "
                @click:clear="handleDueData('', 'reviewing')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reviewingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="reviewerMenu = false"
              @change="handleDueData($event, 'reviewing')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            filled
            clearable
            v-model="reviewingDue"
            :items="reviewingDueHours"
            item-text="time"
            label="Reviewer Due Time (ET)"
            :rules="reviewingDue ? [rules.timeAvailabile('reviewing')] : []"
            @change="handleDueData($event, 'reviewing', true)"
            @click:clear="handleDueData('', 'reviewing', true)"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end px-6 py-4">
      <v-btn width="20%" color="secondary" @click="handleSave"> Save </v-btn>
      <v-btn
        width="20%"
        color="secondary"
        @click="$emit('close-geo-deadlines-form')"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { timeUnits } from "@/utils/mixins";

export default {
  name: "GEODeadlinesForm",
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    itemToEditIndex: {
      type: Number,
      required: false,
    },
    allGeos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      currentGeo: "",
      writerMenu: false,
      writingDueDate: "",
      writingDue: "",
      editorMenu: false,
      editingDueDate: "",
      editingDue: "",
      reviewerMenu: false,
      reviewingDueDate: "",
      reviewingDue: "",
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        dateAvailabile: (operation) =>
          this[`${operation}DueDateAvailable`] ||
          "Selected date is after delivery date for the client.",
        timeAvailabile: (operation) =>
          this[`${operation}DueHoursAvailable`] ||
          "Selected time is after delivery due (time of day) for the client.",
      },
    };
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("spreadsheetUpload", [
      "formValues",
      "shakespeare",
      "tableRows",
    ]),
    geos() {
      const geosWithIndividualDeadlines =
        this.formValues.individualGeosDeadlines.map(
          (geo) => Object.keys(geo)[0]
        );

      const geosList =
        geosWithIndividualDeadlines.length && !this.editMode
          ? this.allGeos.filter(
              (el) => !geosWithIndividualDeadlines.includes(el)
            )
          : [...new Set(this.allGeos)];

      return geosList;
    },
    itemToEditGeo() {
      return this.editMode
        ? Object.keys(
            this.formValues.individualGeosDeadlines[this.itemToEditIndex]
          )[0]
        : null;
    },
    writingDueHours() {
      return this.handleDueHoursAvailability("writing");
    },
    editingDueHours() {
      return this.handleDueHoursAvailability("editing");
    },
    reviewingDueHours() {
      return this.handleDueHoursAvailability("reviewing");
    },
  },
  watch: {
    itemToEditIndex(newVal) {
      if (newVal !== null) {
        const reset = newVal < 0;
        let source;
        if (!reset)
          source =
            this.formValues.individualGeosDeadlines[this.itemToEditIndex][
              this.itemToEditGeo
            ];
        this.currentGeo = reset ? "" : this.itemToEditGeo;
        this.writingDueDate = reset ? "" : source.writingDueDate;
        this.writingDue = reset ? "" : source.writingDue;
        this.editingDueDate = reset ? "" : source.editingDueDate;
        this.editingDue = reset ? "" : source.editingDue;
        this.reviewingDueDate = reset ? "" : source.reviewingDueDate;
        this.reviewingDue = reset ? "" : source.reviewingDue;
        if (source)
          setTimeout(() => {
            this.$refs.form.validate();
            this.handleStaffDuesValidation();
          }, 100);
      }
    },
    writingDueHours(newVal) {
      this.handleDueHoursValidation("writing", newVal);
    },
    editingDueHours(newVal) {
      this.handleDueHoursValidation("editing", newVal);
    },
    reviewingDueHours(newVal) {
      this.handleDueHoursValidation("reviewing", newVal);
    },
  },
  methods: {
    ...mapActions("spreadsheetUpload", ["setFormValue"]),
    handleSave() {
      if (this.$refs.form.validate()) {
        let individualGeosDeadlines = [
          ...this.formValues.individualGeosDeadlines,
        ];
        const individualGeosDeadlinesItem = {
          writingDueDate: this.writingDueDate,
          writingDue: this.writingDue,
          editingDueDate: this.editingDueDate,
          editingDue: this.editingDue,
          reviewingDueDate: this.reviewingDueDate,
          reviewingDue: this.reviewingDue,
        };
        if (this.editMode) {
          individualGeosDeadlines[this.itemToEditIndex][this.itemToEditGeo] =
            individualGeosDeadlinesItem;
        } else {
          individualGeosDeadlines.push({
            [this.currentGeo]: individualGeosDeadlinesItem,
          });
        }
        this.setFormValue({
          key: "individualGeosDeadlines",
          value: individualGeosDeadlines,
        });
        this.$emit("close-geo-deadlines-form");
      }
    },
    handleDelete() {
      let individualGeosDeadlines = [
        ...this.formValues.individualGeosDeadlines,
      ];
      individualGeosDeadlines.splice(this.itemToEditIndex, 1);
      this.setFormValue({
        key: "individualGeosDeadlines",
        value: individualGeosDeadlines,
      });
      this.$emit("close-geo-deadlines-form");
    },
    handleDueData(value, flow, timeMode) {
      if (value)
        timeMode
          ? this.handleDueHoursValidation(flow, this[`${flow}DueHours`])
          : this.handleStaffDuesValidation();
    },
    handleDueHoursValidation(operation, updatedArray) {
      this[`${operation}DueHoursAvailable`] = !updatedArray.find(
        (el) => el.time === this[`${operation}Due`]
      )?.disabled;
    },
    handleDueHoursAvailability(operation) {
      if (
        this.formValues.deliveryDate &&
        this[`${operation}DueDate`] === this.formValues.deliveryDate &&
        !this.formValues.override
      ) {
        return this.hoursList.map((el) => {
          if (
            this.hoursList.indexOf(el) >
            this.hoursList.findIndex(
              (el) => el.time === this.formValues.deliveryDue
            )
          ) {
            return Object.assign({}, { time: el.time, disabled: true });
          } else {
            return Object.assign({}, { time: el.time, disabled: false });
          }
        });
      } else if (
        this.$moment(this[`${operation}DueDate`]).isAfter(
          this.formValues.deliveryDate
        ) &&
        !this.formValues.override
      ) {
        return this.hoursList.map((el) => {
          return Object.assign({}, { time: el.time, disabled: true });
        });
      } else {
        return this.hoursList;
      }
    },
    handleStaffDuesValidation() {
      ["writing", "editing", "reviewing"].forEach((operation) => {
        if (!this.formValues.override) {
          if (this.formValues.deliveryDate && this[`${operation}DueDate`]) {
            this[`${operation}DueDateAvailable`] = this.$moment(
              this[`${operation}DueDate`]
            ).isSameOrBefore(this.formValues.deliveryDate);
            this.$refs[`${operation}DueDateField`].validate(true);
          }
        } else {
          this[`${operation}DueDateAvailable`] = true;
          this.$refs[`${operation}DueDateField`].validate(true);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.geo-field {
  width: 145px;
  max-width: 145px;
}
</style>
