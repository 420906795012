export const BLANK_REIMBURSEMENT_FORM_VALUES = {
    relatedProject: null,
    reimbursementCategory: null,
    requestType: null,
    reimbursementDescription: null,
    purchaseDeliveryDate: null,
    reimbursementAmount: null,
    reimbursementFiles: [],
}

export const BLANK_ASSIGNMENT_FORM_VALUES = {
    assignmentTitle: null,
    relatedProject: null,
    typeOfWork: null,
    deliveryDate: null,
    relatedFiles: [],
    relatedLink: null,
    relatedText: null,
    wordCount: null,
    sourceLanguages: null,
    targetLanguages: [],
    noteToManager: null,
    purchaseOrder: null,
}