<template>
  <div class="invoice-report">
    <div class="invoice-report__loader" v-if="!invoiceRows">
      <v-progress-linear indeterminate />
    </div>

    <div class="invoice-report__main" v-if="invoiceRows">
      <InvoiceReportTable />
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { getInvoiceReport } from "@/utils/newDbUtils";
// components
import { v4 as uuid } from 'uuid';
import InvoiceReportTable from "@/components/InvoiceBot/InvoiceReportTable";

export default {
  components: { InvoiceReportTable },
  data() {
    return {
      payPeriodMonth: {
        LAST: new Date().getMonth(),
        THIS: new Date().getMonth() + 1,
        NEXT: new Date().getMonth() + 2,
      },
    };
  },
  watch: {
    selectedReport(newValue) {
      this.setInvoiceRows(null);
      this.handleInvoiceReport(newValue);
    },
  },
  methods: {
    ...mapActions("invoiceBot", ["setInvoiceRows", "setInvoiceTablePMs"]),
    getInvoiceTablePMs(rows) {
      return [...new Set(rows.map((el) => el.projectManagerFullName))].sort();
    },
    handleInvoiceReport(report) {
      getInvoiceReport(this.payPeriodMonth[report], report).then((data) => {
        const parsedData = data.reduce((acc, value) => {
          // remove internal records with zero totals
          if (value.totalAmount !== 0) {
            // parse pay period array from backend
            return [
              ...acc,
              {
                ...value,
                payPeriod: `${this.$moment(value.payPeriod[1], "M").format(
                  "MMMM"
                )} ${value.payPeriod[0]}`,
                id: value.quickBooksCustomerName + value.quantity + value.payPeriod[0] + value.payPeriod[1] + value.projectManagerFullName + value.assignmentTitle + value.purchaseOrderNumber + value.workTypePrice + uuid(),
              },
            ];
          } else {
            return acc;
          }
        }, []);
        this.setInvoiceRows(parsedData);
        this.setInvoiceTablePMs(this.getInvoiceTablePMs(parsedData));
      });
    },
  },
  computed: {
    ...mapGetters("invoiceBot", ["invoiceRows", "selectedReport"]),
  },
  mounted() {
    this.handleInvoiceReport(this.selectedReport);
  },
};
</script>

<style lang="scss" scoped></style>
