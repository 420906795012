<template>
  <v-card class="d-flex flex-column grey lighten-2">
    <v-form
      ref="form"
      class="ad-new-assignment v-card v-sheet theme--light ma-4">
      <v-row class="my-0 ml-3">
        <v-col
            cols="12"
            class="d-flex align-center mt-0 pb-0 title"
        >
            <v-icon>mdi-format-list-bulleted</v-icon>
            <p class="form__title ml-2 mt-4">
              <span v-if="!gGetDataToUpdate && !checkTheValueColumn">Add new value to</span>
              <span v-else-if="!gGetDataToUpdate">Add new key-value to</span>
              <span v-else>Edit</span>
              {{ gGetFieldName }}
              <span v-if="gGetDataToUpdate">key-value</span>
            </p>
        </v-col>
      </v-row>
      <v-row class="my-0 mx-3">
        <v-col
            cols="6"
            class="d-flex align-center mt-0 pb-0"
            v-if="checkTheValueColumn"
        >
            <v-text-field
              outlined
              label="Key"
              v-model="key"
              class="mt-0 pt-0"
              required
            ></v-text-field>
        </v-col>
        <v-col
            :cols="checkTheValueColumn ? 6 : 12"
            class="d-flex align-center mt-0 pb-0"
        >
            <v-text-field
              outlined
              label="Value"
              v-model="value"
              class="mt-0 pt-0"
              required
            ></v-text-field>
        </v-col>
        <v-col cols="12"
          v-if="gGetFieldName === 'Media Type'">
          <v-autocomplete
            :disabled="loading"
            :loading="loading"
            v-model="genreValues"
            :items="autocompleteValues"
            label="Related Field Values"
            outlined
            chips
            multiple
            item-text="value"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>

    </v-form>
    <v-card-actions class="d-flex justify-end pr-4 pb-4">
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        :loading="loading"
        @click="handleCancel">
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-2"
        class="request-btn mx-3 white--text"
        :loading="loading"
        @click.prevent="createEnum"
        >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { createNewEnum, getSpecificEnum, updateEnum } from '@/utils/newDbUtils';
export default {
  name: "NewFieldValue",
  data() {
    return {
      loading: false,
      autocompleteValues: [],
    }
  },
  watch: {
    gGetFieldName: {
      immediate: true,
      async handler() {
        if (this.gGetFieldName && this.gGetFieldName === 'Media Type') {
          let result = await getSpecificEnum("genre");
          if (result) {
            result.data.forEach(element => {
              this.autocompleteValues.push(element);
            });
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("settings", ["gGetFieldName", "gGetNewField", "gGetEnumsList", "gGetDataToUpdate", "gGetNewFieldAutocomplete"]),
    checkTheValueColumn() {
      const noValue = [
        'Keyword', 'Media Type', 'Genre'
      ];
      return !noValue.includes(this.gGetFieldName);  
    },
    genreValues: {
      get() {
        if (this.gGetFieldName === 'Media Type' && this.gGetNewField.genres?.length) {
          let genreList = [];
          this.gGetNewField.genres.forEach(element => {
            genreList.push(this.autocompleteValues.find(el => el.value === element))
          })
          return genreList;
        }
        return this.gGetNewFieldAutocomplete;
      },
      set(value) {
        this.aSetNewFieldAutocomplete(value);
      }
    },
    key: {
      get() {
        return this.gGetNewField.key;
      },
      set(value) {
        this.aSetNewField({key: "key", value: value});
      }
    },
    value: {
      get() {
        return this.gGetNewField.value;
      },
      set(value) {
        this.aSetNewField({key: "value", value: value});
      }
    },
    enumRequestType() {
      switch (this.gGetFieldName) {
        case "GEO":
          return "geos";
        case "Request Type":
          return "request-types";
        case "Media Type":
          return "media-types";
        case "Keyword":
          return "keywords";
        case "Genre":
          return "genre";
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    ...mapActions("settings", ["aSetNewField", "aSetEnumsList", "aClearNewField", "aSetDataToUpdate", "aSetNewFieldAutocomplete"]),
    handleCancel() {
      this.$emit('cancel')
    },
    async createEnum() {
      if (this.gGetDataToUpdate) {
        try {
          this.loading = true;
          const genresString = [];
          if (this.gGetNewFieldAutocomplete.length) {
            this.gGetNewFieldAutocomplete.forEach(element => {
              if (typeof element === "string") {
                let genre = this.autocompleteValues.find(el => el.value === element);
                genresString.push(genre);
                this.gGetNewFieldAutocomplete.slice(this.gGetNewFieldAutocomplete.indexOf(element), 1);
              } else {
                genresString.push(element);
              }
            })
          }
          const response = await updateEnum({
            id: this.gGetNewField.id,
            type: this.enumRequestType,
            data: {
              value: this.gGetNewField.value ? this.gGetNewField.value : null,
              key: this.gGetNewField.key ? this.gGetNewField.key : this.gGetNewField.value,
              displayOrder: this.gGetEnumsList.length,
              status: this.gGetNewField.status,
              genreIds: genresString.length
                ? genresString.map(el => el.id)
                : null,
          }});
          const result = await getSpecificEnum(this.enumRequestType);
          this.aSetEnumsList(result.data);
          this.loading = false;
          this.handleFlash({ response: { status: 200 }, show: true });
          this.aClearNewField();
          this.handleCancel();
        } catch (error) {
          console.log(error);
        }
        this.aSetDataToUpdate(false);
      } else {
        try {
          this.loading = true;
          const response = await createNewEnum({
            type: this.enumRequestType,
            data: {
              value: this.gGetNewField.value ? this.gGetNewField.value : null,
              key: this.gGetNewField.key ? this.gGetNewField.key : this.gGetNewField.value,
              displayOrder: this.gGetEnumsList.length,
              status: "ACTIVE",
              genreIds: this.gGetNewFieldAutocomplete.length
                ? this.gGetNewFieldAutocomplete.map(el => el.id)
                : null,
          }});
          const result = await getSpecificEnum(this.enumRequestType);
          this.aSetEnumsList(result.data);
          this.loading = false;
          this.handleFlash({ response: { status: 200 }, show: true });
          this.aClearNewField();
          this.handleCancel();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  .title {
      margin-top: -15px !important;
  }
  padding: 10px;
  .v-text-field__details {
      display: none;
  }
  &__title {
      font-size: 20px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      line-height: 18px;
  }
}
</style>
