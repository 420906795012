<template>
  <div class="invoice-report-table-view">
    <div class="d-flex justify-end">
      <v-text-field
        class="search-field mb-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </div>
    <v-data-table
      :headers="headers"
      :header-props="{ sortIcon: null }"
      :items="formattedData"
      :search="search"
      :items-per-page="30"
      :footer-props="{
        'items-per-page-options': [10, 30, 50],
      }"
      v-model="selectedRow"
      show-select
      @current-items="handleFilter"
    >
      <template slot="body.append">
        <tr>
          <th></th>
          <th colspan="3">Pay Period Total</th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-center">{{ sumInvoices }}</th>
          <th class="text-center">{{ sumQuantity }}</th>
          <th class="text-center">{{ sumTotal }}</th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");

export default {
  props: {
    selectedPM: String,
  },
  data() {
    return {
      headers: [
        { text: "Pay Period", value: "payPeriod" },
        { text: "QuickBooks Customer Name", value: "quickBooksCustomerName" },
        {
          text: "Related Purchase Order Number",
          value: "purchaseOrderNumber",
        },
        { text: "Work Type Price", value: "workTypePrice" },
        { text: "Assignment Details", value: "assignmentDetails" },
        { text: "Project Manager Name", value: "projectManagerFullName" },
        { text: "Number of Invoices", value: "numberOfInvoices" },
        { text: "Quantity", value: "quantity" },
        { text: "Client Total", value: "totalAmount" },
      ],
      search: "",
      currentItems: null,
    };
  },
  methods: {
    ...mapActions("invoiceBot", ["setSelectedInvoices", "clearSelectedInvoices"]),
    handleFilter(e) {
      this.currentItems = e;
    },
  },
  computed: {
    ...mapGetters("invoiceBot", ["invoiceTablePMs", "invoiceRows", "selectedInvoices"]),
    selectedRow: {
      get() {
        if (!this.selectedInvoices[this.selectedPM]) {
          return []
        } else {
          const invoices = this.selectedInvoices[this.selectedPM].find(el => el.payPeriod === this.formattedData[0].payPeriod)
          if (invoices) {
            return invoices.invoices
          };
        }
        return [];
      },
      set(v) {
        this.setSelectedInvoices({payPeriod: this.formattedData[0].payPeriod, pm: this.selectedPM, invoices: v});
      }
    },
    rowsForPM() {
      return this.invoiceRows.filter(
        (el) => el.projectManagerFullName === this.selectedPM
      );
    },
    formattedData() {
      return this.rowsForPM.map((el) => ({
        ...el,
        totalAmount: numeral(el.totalAmount).format("$0.00"),
      }));
    },
    sumInvoices() {
      return this.currentItems
        ? _.sumBy(this.currentItems, "numberOfInvoices")
        : 0;
    },
    sumQuantity() {
      return this.currentItems ? _.sumBy(this.currentItems, "quantity") : 0;
    },
    sumTotal() {
      return this.currentItems
        ? numeral(
            _.sum(
              this.currentItems.map((el) =>
                parseFloat(el.totalAmount.replace("$", ""))
              )
            )
          ).format("$0,0.00")
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 300px;
}
</style>
