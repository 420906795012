<template>
  <div class="request">
    <div class="client-selector" v-if="isAdminView">
      <v-container class="client-selector__container d-flex">
        <v-row>
          <v-col class="d-flex selector-column" cols="12" sm="12">
            <v-autocomplete
              :items="clientNames"
              label="Client"
              v-model="selectedCompany"
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-card v-if="gGetClientFormData">
      <div class="invitation">
        <p class="invitation__title">Hi, {{ user.user.firstName }} {{ user.user.lastName }}!</p>
        <p class="invitation__subtitle">Welcome to the Co. Cue assignment request form.</p>
        <p class="invitation__subtitle">Please fill it out and one of our project managers will get back to you shortly.</p>
        <p class="invitation__subtitle"> Don't hesitate to reach out to request@companycue.com with any additional questions.</p>
        <div class="mt-10">
          <v-btn
            class="invitation__btn ma-2"
            x-large
            @click="handleRequest"
          >
            Start
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { getClients } from "@/utils/newDbUtils";
import { ROUTES } from "@/utils/constants";
// components
import Typeform from "../components/Typeform/Typeform.vue";

export default {
  name: "Request",
  data() {
    return {
      allTypeformInfo: null,
      clientNames: [],
      selectedCompany: null,
      selectedTypeform: null,
    };
  },
  components: { Typeform },
  watch: {
    selectedCompany(newValue) {
      if (newValue && this.gGetClientsList.length) {
        this.gGetClientsList.forEach((el) => {
          if (el.name === newValue) {
            this.aSetClientFormData(el);
          }
        })
      }
    },
  },
  methods: {
    ...mapActions("nativeForms", ["aSetClientsList", "aSetClientFormData"]),
    handleRequest() {
      this.$router.push({ name: "Request an Assignment" });
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    ...mapGetters("nativeForms", ["gGetClientFormData", "gGetClientsList"]),
    isAdminView() {
      return this.isAdmin || this.isManager;
    },
  },
  mounted() {
    getClients().then((clients) => {
      this.aSetClientsList(clients);
      this.clientNames = clients.map((el) => el.name);

      if (!this.isAdminView) {
        this.selectedCompany = this.user?.client?.name;
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== "Request an Assignment") {
      this.aSetClientFormData(null);
    }
    next()
  }
};
</script>

<style lang="scss" scoped>
.request {
  max-width: 1400px;
  margin: 0 auto;

  .client-selector {
    &__container {
      .button-column {
        .typeforms-button {
          height: 56px;
        }
      }
    }
  }
}

.invitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  &__title {
    font-size: 22px;
    font-weight: 400;
    line-height: 41.41px;
  }
  &__subtitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 41.41px;
  }
  & button {
    background-color: #1976D2 !important;
    border-radius: 4px !important;
    color: #fff;
  }
}
</style>