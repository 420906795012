<template>
  <div>
    <v-btn
      v-if="stageIsComplete"
      class="mx-2"
      :loading="stageProcessing"
      @click="handleStageCompletion(false)"
      >Set Stage Incomplete</v-btn
    >
    <v-btn
      v-if="!stageIsComplete"
      class="mx-2"
      color="secondary"
      :loading="stageProcessing"
      @click="handleStageCompletion(true)"
      >Set Stage Complete</v-btn
    >
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { handleAssignmentChangeFlowStep } from "@/utils/editorUtils";

export default {
  name: "EditorCompleteStageButton",
  props: {
    assignment: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      stageProcessing: false,
    };
  },
  computed: {
    ...mapGetters("editor", ["flowStage"]),
    stageIsComplete() {
      if (this.assignment) {
        return this.assignment.flow[this.flowStage].isComplete;
      }
    },
    isLastStep() {
      return this.flowStage === this.assignment.flow.length - 1;
    },
  },
  methods: {
    ...mapActions("editor", [
      "setOpenAssignment",
      "setTableAssignmentsNeedsRefresh",
    ]),
    handleStageCompletion(isComplete) {
      this.stageProcessing = true;
      handleAssignmentChangeFlowStep({
        assignment: this.assignment,
        flowStage: this.flowStage,
        isComplete,
      })
        .then(() => {
          this.stageProcessing = false;
          this.setOpenAssignment(null);
          this.setTableAssignmentsNeedsRefresh(true);
        })
        .catch((err) => {
          console.error(err);
          this.stageProcessing = false;
          this.setOpenAssignment(null);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
